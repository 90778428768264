import {
	STATUS_MODAL_ADD_ITEM,
	STATUS_MODAL_REMOVE_ITEM,
} from '../constants';

export const addStatusModalItem = item => {
	item.id = Date.now();
	return {
		type: STATUS_MODAL_ADD_ITEM,
		payload: {
			item,
		},
	}
};

export const removeStatusModalItem = id => ({
	type: STATUS_MODAL_REMOVE_ITEM,
	payload: {
		id,
	},
});
