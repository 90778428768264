import React, { PureComponent } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { DatePicker } from '@material-ui/pickers';
import { createStyles } from '@material-ui/styles';
import { IconButton, withStyles } from '@material-ui/core';

import { ToolbarComponent } from './Toolbar';

import { getStyles } from './styles';

class WeekPickerContainer extends PureComponent {

  handleWeekChange = date => {
    const { handleChangeSelectedDate, isOneDay } = this.props;

    let newDate = date.clone().weekday(0);
    if (isOneDay) {
      newDate = date.clone();
    }

    handleChangeSelectedDate(isOneDay ? newDate : newDate.startOf("isoWeek"));
  };

  formatWeekSelectLabel = (date, invalidLabel) => {
    const { isOneDay } = this.props;
    const dateClone = moment(date).weekday(0);
    return date && date.isValid()
      ? `${isOneDay
          ? date.format("MMM DD")
          : date.endOf('isoWeek').format("MMM DD")
        } (${dateClone.week()}th week of ${dateClone.year()})`
      : invalidLabel;
  };

  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes, isOneDay } = this.props;

    const dateClone = date.clone();
    const selectedDateClone = selectedDate.clone();

    const start = selectedDateClone.startOf("week").toDate();
    const end = selectedDateClone.endOf("week").toDate();

    const dayIsBetween = dateClone.isBetween(start, end, null, []);
    const isFirstDay = dateClone.isSame(start, "day");
    const isLastDay = dateClone.isSame(end, "day");
    const isThisDay = date.clone().isSame(selectedDate, "day");

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const wrapperClassName1 = clsx({
      [classes.highlight]: isThisDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      <div>
        <div className={isOneDay ? wrapperClassName1 : wrapperClassName}>
          <IconButton className={dayClassName}>
            <span>{dateClone.format("DD")}</span>
          </IconButton>
        </div>
      </div>
    );
  };

  render() {
    const { selectedDate, classes, isOneDay } = this.props;

    const pickerStyles = clsx(classes.wrapper);

    return (
      <DatePicker
        className={pickerStyles}
        value={selectedDate}
        onChange={this.handleWeekChange}
        renderDay={this.renderWrappedWeekDay}
        labelFunc={this.formatWeekSelectLabel}
        ToolbarComponent={(props) => (
          <ToolbarComponent
            isOneDay={isOneDay}
            { ...props }
          />
        )}
      />
    );
  }
}

const styles = createStyles(getStyles);

export const WeekPicker = withStyles(styles)(WeekPickerContainer);
