export const getStyles = theme => ({
  container: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
  },
  panel: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
  },
  input: {
    marginBottom: '16px',
  },
});
