import { gql } from '@apollo/client';

export const NETWORK_FRAGMENT = gql`
  fragment CoreNetworkFields on Network {
    _id
    name
  }
`;

export const GET_NETWORKS_FOR_TABLE = gql`
  ${NETWORK_FRAGMENT}
  query GetNetworksForTable {
    networks {
      ...CoreNetworkFields
    }
  }
`;

export const ADD_NETWORK = gql`
  mutation AddNetwork($payload: CreateNetworkDto!) {
    createNetwork(payload: $payload) {
      _id
    }
  }
`;

export const EDIT_NETWORK = gql`
  ${NETWORK_FRAGMENT}
  mutation EditNetwork($payload: UpdateNetworkDto!) {
    updateNetwork(payload: $payload) {
      ...CoreNetworkFields
    }
  }
`;

export const DELETE_NETWORK = gql`
  mutation DeleteNetwork($_id: String!) {
    deleteNetwork(_id: $_id) {
      _id
    }
  }
`;
