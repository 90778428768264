import {
  RESET_CREATE_ACCOUNT,
  SET_CREATE_ACCOUNT_TYPE,
  SET_CREATE_ACCOUNT_SUB_TYPE,
  SET_CREATE_ACCOUNT_SHORT_ACCOUNT_NAME,
  SET_CREATE_ACCOUNT_URL,
  SET_CREATE_ACCOUNT_USERNAME,
  SET_CREATE_ACCOUNT_PASSWORD,
  SET_CREATE_ACCOUNT_SHARE_PERCENT,
  SET_CREATE_ACCOUNT_NOTES,
  SET_CREATE_ACCOUNT_SITE_TYPE,
  SET_CREATE_ACCOUNT_PARTNER,
  SET_CREATE_ACCOUNT_SITE_TYPES_LIST,
  SET_CREATE_ACCOUNT_PARTNERS_LIST,
  SET_CREATE_ACCOUNT_SUB_OWNER,
  SET_CREATE_ACCOUNT_SUB_OWNER_PERCENT,
} from '../constants';

const initialState = {
  shortAccountName: '',
  url: '',
  type: '',
  subType: '',
  username: '',
  password: '',
  sharePercent: '',
  notes: '',
  partner: null,
  subOwner: null,
  subOwnerPercent: '',
  siteType: null,
  partnersList: [],
  siteTypesList: [],
}

export const createAccount = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CREATE_ACCOUNT:
      return initialState;
    case SET_CREATE_ACCOUNT_TYPE:
    case SET_CREATE_ACCOUNT_SUB_TYPE:
    case SET_CREATE_ACCOUNT_SHORT_ACCOUNT_NAME:
    case SET_CREATE_ACCOUNT_URL:
    case SET_CREATE_ACCOUNT_USERNAME:
    case SET_CREATE_ACCOUNT_PASSWORD:
    case SET_CREATE_ACCOUNT_SHARE_PERCENT:
    case SET_CREATE_ACCOUNT_NOTES:
    case SET_CREATE_ACCOUNT_SITE_TYPE:
    case SET_CREATE_ACCOUNT_PARTNER:
    case SET_CREATE_ACCOUNT_SITE_TYPES_LIST:
    case SET_CREATE_ACCOUNT_PARTNERS_LIST:
    case SET_CREATE_ACCOUNT_SUB_OWNER:
    case SET_CREATE_ACCOUNT_SUB_OWNER_PERCENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
