import { accountTypes } from '../../../components/App';

export const excludeKeys = ['action'];

export const reformatDataKeys = [
  {
    name: 'sharePercent',
    value: 'value',
  },
  {
    name: 'subOwnerPercent',
    value: 'value',
  },
  {
    name: 'amount',
    value: 'value',
  },
];

const accountsDepositColumn = (isWeekClosed) => [
  {
    width: 700,
    label: 'Url',
    dataKey: 'url',
  },
  {
    width: 700,
    label: 'Username',
    dataKey: 'username',
  },
  {
    width: 500,
    label: 'Password',
    dataKey: 'password',
  },
  {
    width: 700,
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
  {
    width: 300,
    label: 'Share Percent',
    dataKey: 'sharePercent',
  },
  {
    width: 300,
    label: 'Balance',
    dataKey: 'amount',
    isContainer: isWeekClosed ? false : true,
    isPanelInput: isWeekClosed ? false : true,
  },
];

const accountsApplicationColumn = (isWeekClosed) => [
  {
    width: 700,
    label: 'Name',
    dataKey: 'shortAccountName',
  },
  {
    width: 800,
    label: 'Username',
    dataKey: 'username',
  },
  {
    width: 500,
    label: 'Password',
    dataKey: 'password',
  },
  {
    width: 700,
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
  {
    width: 300,
    label: 'Share Percent',
    dataKey: 'sharePercent',
  },
  {
    width: 300,
    label: 'Balance',
    dataKey: 'amount',
    isContainer: isWeekClosed ? false : true,
    isPanelInput: isWeekClosed ? false : true,
  },
];

const accountsPayPerHeadColumn = (isWeekClosed) => [
  {
    width: 200,
    label: '',
    dataKey: 'botWeeklyFigure',
    handlerCellName: 'botWeeklyFigure',
  },
  {
    width: 600,
    label: 'Site Type',
    dataKey: 'siteTypeName',
  },
  {
    width: 600,
    label: 'Url',
    dataKey: 'url',
  },
  {
    width: 700,
    label: 'Username',
    dataKey: 'username',
  },
  {
    width: 500,
    label: 'Password',
    dataKey: 'password',
  },
  {
    width: 700,
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
  {
    width: 700,
    label: 'Sub Owner',
    dataKey: 'subOwnerName',
  },
  {
    width: 400,
    label: 'Sub Own %',
    dataKey: 'subOwnerPercent',
    isContainer: isWeekClosed ? false : true,
    isSharePercent: isWeekClosed ? false : true,
    isSharePercentId: 0,
  },
  {
    width: 400,
    label: 'Volume',
    dataKey: 'volume',
    isDollarHandler: true,
  },
  {
    width: 400,
    label: 'Result',
    dataKey: 'result',
    isDollarHandler: true,
  },
  {
    width: 400,
    label: 'Figure Update',
    dataKey: 'figureUpdate',
  },
  {
    width: 400,
    label: 'Share %',
    dataKey: 'sharePercent',
    isContainer: isWeekClosed ? false : true,
    isSharePercent: isWeekClosed ? false : true,
    isSharePercentId: 1,
  },
  {
    width: 300,
    label: 'Weekly Figure',
    dataKey: 'amount',
    isContainer: isWeekClosed ? false : true,
    isPanelInput: isWeekClosed ? false : true,
  },
  {
    width: 300,
    label: 'Notes',
    dataKey: 'notes',
    isTitleWrap: true,
  },
];

const accountsHandBetsColumn = (isWeekClosed) => [
  {
    width: 1000,
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
  {
    width: 1000,
    label: 'Sub Owner',
    dataKey: 'subOwnerName',
  },
  {
    width: 1000,
    label: 'Sub Own %',
    dataKey: 'subOwnerPercent',
    isContainer: isWeekClosed ? false : true,
    isSharePercent: isWeekClosed ? false : true,
    isSharePercentId: 0,
  },
  {
    width: 1000,
    label: 'Share Percent',
    dataKey: 'sharePercent',
    isContainer: isWeekClosed ? false : true,
    isSharePercent: isWeekClosed ? false : true,
    isSharePercentId: 1,
  },
  {
    width: 500,
    label: 'Weekly Figure',
    dataKey: 'amount',
    isContainer: isWeekClosed ? false : true,
    isPanelInput: isWeekClosed ? false : true,
  },
];

const accountsPrivateWalletsColumn = [
  {
    width: 1000,
    label: 'Wallet Type',
    dataKey: 'type',
  },
  {
    width: 1000,
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
  {
    width: 700,
    label: 'Weekly Figure',
    dataKey: 'weeklyFigure',
    isDollarHandler: true,
  },
  {
    width: 700,
    label: 'Confirmed Weekly Figure',
    dataKey: 'amount',
    isContainer: true,
    isPanelInput: true,
  },
];

export const getAccountPageColumn = (type, isWeekClosed) => {
  switch (type) {
    case accountTypes.DEPOSIT:
      return accountsDepositColumn(isWeekClosed);
    case accountTypes.APPLICATION:
      return accountsApplicationColumn(isWeekClosed);
    case accountTypes.PAY_PER_HEAD:
      return accountsPayPerHeadColumn(isWeekClosed);
    case accountTypes.HAND_BETS:
      return accountsHandBetsColumn(isWeekClosed);
    case accountTypes.PRIVATE_WALLETS:
      return accountsPrivateWalletsColumn;
    default:
      return [];
  }
};

const accountsDepositSort = [
  {
    dataKey: 'url',
    sortType: 'string',
  },
  {
    dataKey: 'username',
    sortType: 'string',
  },
  {
    dataKey: 'password',
    sortType: 'string',
  },
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'sharePercent',
    sortType: 'number',
  },
  {
    dataKey: 'amount',
    sortType: 'number',
    sortKey: 'value',
  },
];

const accountsApplicationSort = [
  {
    dataKey: 'shortAccountName',
    sortType: 'string',
  },
  {
    dataKey: 'username',
    sortType: 'string',
  },
  {
    dataKey: 'password',
    sortType: 'string',
  },
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'sharePercent',
    sortType: 'number',
  },
  {
    dataKey: 'amount',
    sortType: 'number',
    sortKey: 'value',
  },
];

const accountsPayPerHeadSort = [
  {
    dataKey: 'siteTypeName',
    sortType: 'string',
  },
  {
    dataKey: 'url',
    sortType: 'string',
  },
  {
    dataKey: 'username',
    sortType: 'string',
  },
  {
    dataKey: 'password',
    sortType: 'string',
  },
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'subOwnerName',
    sortType: 'string',
  },
  {
    dataKey: 'subOwnerPercent',
    sortType: 'number',
  },
  {
    dataKey: 'volume',
    sortType: 'number',
  },
  {
    dataKey: 'result',
    sortType: 'number',
  },
  {
    dataKey: 'sharePercent',
    sortType: 'number',
  },
  {
    dataKey: 'amount',
    sortType: 'number',
    sortKey: 'value',
  },
  {
    dataKey: 'notes',
    sortType: 'string',
  },
];

const accountsHandBetsSort = [
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'subOwnerName',
    sortType: 'string',
  },
  {
    dataKey: 'subOwnerPercent',
    sortType: 'number',
  },
  {
    dataKey: 'sharePercent',
    sortType: 'number',
  },
  {
    dataKey: 'amount',
    sortType: 'number',
    sortKey: 'value',
  },
];

const accountsPrivateWalletsSort = [
  {
    dataKey: 'type',
    sortType: 'string',
  },
  {
    dataKey: 'name',
    sortType: 'string',
  },
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'weeklyFigure',
    sortType: 'number',
  },
  {
    dataKey: 'confirmedWeeklyFigure',
    sortType: 'number',
    sortKey: 'value',
  },
];

export const getAccountPageSort = type => {
  switch (type) {
    case accountTypes.DEPOSIT:
      return accountsDepositSort;
    case accountTypes.APPLICATION:
      return accountsApplicationSort;
    case accountTypes.PAY_PER_HEAD:
      return accountsPayPerHeadSort;
    case accountTypes.HAND_BETS:
      return accountsHandBetsSort;
    case accountTypes.PRIVATE_WALLETS:
      return accountsPrivateWalletsSort;
    default:
      return [];
  }
};

const uniqueDepositLines = [
  {
    label: 'Url',
    dataKey: 'url',
  },
  {
    label: 'Username',
    dataKey: 'username',
  },
  {
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
]

const uniqueApplicationLines = [
  {
    label: 'Name',
    dataKey: 'shortAccountName',
  },
  {
    label: 'Username',
    dataKey: 'username',
  },
  {
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
]

const uniquePayPerHeadLines = [
  {
    label: 'Site Type',
    dataKey: 'siteTypeName',
  },
  {
    label: 'Url',
    dataKey: 'url',
  },
  {
    label: 'Username',
    dataKey: 'username',
  },
  {
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
  {
    label: 'Sub Owner',
    dataKey: 'subOwnerName',
  },
]

const uniqueHandBetsLines = [
  {
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
  {
    label: 'Sub Owner',
    dataKey: 'subOwnerName',
  },
]

const uniquePrivateWalletsLines = [
  {
    label: 'Wallet Type',
    dataKey: 'type',
  },
  {
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
]

export const getUniqueLines = type => {
  switch (type) {
    case accountTypes.DEPOSIT:
      return uniqueDepositLines;
    case accountTypes.APPLICATION:
      return uniqueApplicationLines;
    case accountTypes.PAY_PER_HEAD:
      return uniquePayPerHeadLines;
    case accountTypes.HAND_BETS:
      return uniqueHandBetsLines;
    case accountTypes.PRIVATE_WALLETS:
      return uniquePrivateWalletsLines;
    default:
      return [];
  }
}
