import { INFO_BUTTON } from './constants';
import { PanelButtonInfo } from './PanelButtonInfo';

export const handlerBooleanValues = (call, data) => call ? data.isTrue : data.isFalse;

export const getHandlerColumn = (
  name,
  {
    cellData,
  }
)=> {
  switch (name) {
    case INFO_BUTTON:
      return (
        <PanelButtonInfo
          data={cellData}
        />
      )
  }
}

export const isHttpOrHttps = (string) => new RegExp("^(http|https)://", "i").test(string);
