import { createSelector } from 'reselect';

// Default selectors
const getTransactionsSelector = state => state.transactions;

export const getTransactionsListSelector = createSelector(
  getTransactionsSelector,
  transactions => transactions.transactionsList
);

export const getTransactionsFilteredListSelector = createSelector(
	getTransactionsSelector,
	transactions => transactions.transactionsFilteredList
);

export const getTransactionsTypeSelector = createSelector(
	getTransactionsSelector,
	transactions => transactions.transactionType
);
