import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { TableComponent } from '../../../components/TableComponent';
import {
  TaskButtonsPanel,
  InputCustom,
} from '../../../components/UIComponents';
import { walletsPageColumn } from './constants';
import {
  getCreateWalletNotesSelector,
  getCreateWalletPartnerSelector,
  getCreateWalletTypeSelector,
  getCreateWalletPartnersListSelector,
  getCreateWalletTypesListSelector,
  getCreateWalletIsPrivateSelector,
  getEditWalletIdSelector,
  getEditWalletNotesSelector,
  getEditWalletPartnerSelector,
  getEditWalletTypeSelector,
  getEditWalletIsPrivateSelector,
  getEditWalletPartnersListSelector,
  getEditWalletTypesListSelector,
  getWalletsListSelector,
} from './selectors';
import {
  resetCreateWallet as resetCreateWalletAction,
  setCreateWalletPartner as setCreateWalletPartnerAction,
  setCreateWalletType as setCreateWalletTypeAction,
  setCreateWalletIsPrivate as setCreateWalletIsPrivateAction,
  setCreateWalletNotes as setCreateWalletNotesAction,
  resetEditWallet as resetEditWalletAction,
  setEditWalletPartner as setEditWalletPartnerAction,
  setEditWalletType as setEditWalletTypeAction,
  setEditWalletIsPrivate as setEditWalletIsPrivateAction,
  setEditWalletNotes as setEditWalletNotesAction,
} from '../../../store/actions';
import {
  ModalDelete,
  ModalWallet,
} from '../../../components/UIComponents/modals';
import {
  walletsForTable,
  addWallet,
  editWallet,
  deleteWallet,
  getForEditWallet,
  getPartnersAndWalletTypesForWallet,
} from '../../../api';
import { sortEvent } from '../../../utils/sortingService';
import {
  changeFilteredList,
  getUniqueValuesList,
} from '../../../utils';
import { walletsPageSort } from './constants';
import { MultiFilterPanel } from '../../../components/MultiFilterPanel';
import { uniqueLines } from './constants';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const WalletsTablePageContainer = ({
  walletsList,
  createWalletPartner,
  createWalletType,
  createWalletIsPrivate,
  createWalletNotes,
  createPartnersList,
  createTypesList,
  editWalletId,
  editWalletPartner,
  editWalletType,
  editWalletIsPrivate,
  editWalletNotes,
  editPartnersList,
  editTypesList,
  resetCreateWallet,
  setCreateWalletPartner,
  setCreateWalletType,
  setCreateWalletIsPrivate,
  setCreateWalletNotes,
  resetEditWallet,
  setEditWalletPartner,
  setEditWalletType,
  setEditWalletIsPrivate,
  setEditWalletNotes,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [walletId, setWalletId] = useState(null);
  const [showModalCreateWallet, setShowModalCreateWallet] = useState(false);
  const [showModalEditWallet, setShowModalEditWallet] = useState(false);

  const [ handleSortBy, setHandleSortBy ] = useState(false);
  const [ handleSortDirection, setHandleSortDirection ] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [filteredList, setFilteredList] = useState([]);

  const [ uniqueValuesList, setUniqueValuesList ] = useState([]);
  const [ uniqueFiltersList, setUniqueFiltersList] = useState([]);

  useEffect(() => {
    setUniqueValuesList(getUniqueValuesList(walletsList, uniqueLines));
  }, [walletsList])

  const getWallets = useCallback(async () =>
    await walletsForTable(),
  []);

  useEffect(() => {
    getWallets();
  }, [getWallets]);

  useEffect(() => {
    changeFilteredList(walletsList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'action', 'typeId', 'partnerId']);
  }, [walletsList, searchValue, uniqueFiltersList]);

  useEffect(() => {
    const getForEdit = async (walletId) => await getForEditWallet(walletId);

    if (showModalEditWallet === true) {
      getForEdit(walletId);
    }
  }, [walletId, showModalEditWallet]);

  useEffect(() => {
    const getForCreate = async () => await getPartnersAndWalletTypesForWallet();

    if (showModalCreateWallet === true) {
      getForCreate();
    }
  }, [showModalCreateWallet]);

  const closeModalDeleteAction = () => setShowModalDelete(false);

  const openModalDeleteAction = id => {
    setShowModalDelete(true);
    setWalletId(id);
  };

  const deleteWalletById = id => {
    Promise.resolve(deleteWallet(id))
      .then(setWalletId(null))
      .then(closeModalDeleteAction());
  }

  const sendCreateWallet = () => {
    Promise.resolve(addWallet({
      partner: createWalletPartner,
      type: createWalletType,
      isPrivate: createWalletIsPrivate,
      notes: createWalletNotes,
    }))
      .then(closeModalCreateWalletAction())
      .then(resetCreateWallet());
  }

  const sendEditWallet = () => {
    Promise.resolve(editWallet({
      id: editWalletId,
      partner: editWalletPartner,
      type: editWalletType,
      isPrivate: editWalletIsPrivate,
      notes: editWalletNotes,
    }))
      .then(closeModalEditWalletAction());
  }

  const closeModalCreateWalletAction = () => {
    setShowModalCreateWallet(false);
    resetCreateWallet();
  }

  const closeModalEditWalletAction = () => {
    setShowModalEditWallet(false);
    resetEditWallet();
    setWalletId(null);
  }

  const openModalEditAccount = id => {
    // const agent = walletsList.find(elem => elem.id === id);
    // handlerEditData(agent);
    setWalletId(id);
    setShowModalEditWallet(true);
  }

  const sort = sortEvent({
    handleSortBy,
    sortData: walletsPageSort,
    setHandleSortDirection,
    setHandleSortBy,
    list: filteredList,
  });

  const handleBlurInput = event => {
    const value = event.target.value;
    setSearchValue(value);
    changeFilteredList(walletsList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'action', 'typeId', 'partnerId']);
  }

  const changeUniqueValues = (items, dataKey) => {
    const newUniqueValuesList = uniqueValuesList.map(elem => {
      if (elem.dataKey === dataKey) {
        return {
          ...elem,
          selectedValues: items,
        }
      }
      return elem;
    })
    setUniqueValuesList(newUniqueValuesList);
    setUniqueFiltersList(newUniqueValuesList.filter(el => el.selectedValues.length > 0));
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.containerForFilters}>
        <MultiFilterPanel
          list={uniqueValuesList}
          onChange={changeUniqueValues}
        />
        <InputCustom
          className={classes.input}
          value={searchValue}
          onChange={handleBlurInput}
        />
      </Box>

      <TableComponent
        columns={walletsPageColumn}
        rows={filteredList}
        deleteRowAction={openModalDeleteAction}
        editRowAction={openModalEditAccount}
        sort={sort}
        sortBy={handleSortBy}
        sortDirection={handleSortDirection}
      />

      <TaskButtonsPanel
        createButton
        createButtonName="Create Wallet"
        createButtonAction={() => setShowModalCreateWallet(true)}
      />

      <ModalDelete
        open={showModalDelete}
        title={'Delete Wallet'}
        description={'Are you sure you want to delete this agent?'}
        eventDelete={() => deleteWalletById(walletId)}
        eventClose={closeModalDeleteAction}
      />

      <ModalWallet
        open={showModalCreateWallet}
        title={'Create Wallet'}
        eventClose={closeModalCreateWalletAction}
        eventApply={sendCreateWallet}
        partner={createWalletPartner}
        type={createWalletType}
        isPrivate={createWalletIsPrivate}
        notes={createWalletNotes}
        partnersList={createPartnersList}
        typesList={createTypesList}
        setPartner={setCreateWalletPartner}
        setType={setCreateWalletType}
        setIsPrivate={setCreateWalletIsPrivate}
        setNotes={setCreateWalletNotes}
      />

      <ModalWallet
        open={showModalEditWallet}
        title={'Edit Wallet'}
        eventClose={closeModalEditWalletAction}
        eventApply={sendEditWallet}
        partner={editWalletPartner}
        type={editWalletType}
        isPrivate={editWalletIsPrivate}
        notes={editWalletNotes}
        partnersList={editPartnersList}
        typesList={editTypesList}
        setPartner={setEditWalletPartner}
        setType={setEditWalletType}
        setIsPrivate={setEditWalletIsPrivate}
        setNotes={setEditWalletNotes}
      />
    </Box>
  )
}

const mapStateToProps = state => ({
  walletsList: getWalletsListSelector(state),
  createWalletPartner: getCreateWalletPartnerSelector(state),
  createWalletType: getCreateWalletTypeSelector(state),
  createWalletIsPrivate: getCreateWalletIsPrivateSelector(state),
  createWalletNotes: getCreateWalletNotesSelector(state),
  createPartnersList: getCreateWalletPartnersListSelector(state),
  createTypesList: getCreateWalletTypesListSelector(state),
  editWalletId: getEditWalletIdSelector(state),
  editWalletType: getEditWalletTypeSelector(state),
  editWalletIsPrivate: getEditWalletIsPrivateSelector(state),
  editWalletPartner: getEditWalletPartnerSelector(state),
  editWalletNotes: getEditWalletNotesSelector(state),
  editPartnersList: getEditWalletPartnersListSelector(state),
  editTypesList: getEditWalletTypesListSelector(state),
});

const mapDispatchToProps = {
  resetCreateWallet: resetCreateWalletAction,
  setCreateWalletPartner: setCreateWalletPartnerAction,
  setCreateWalletType: setCreateWalletTypeAction,
  setCreateWalletIsPrivate: setCreateWalletIsPrivateAction,
  setCreateWalletNotes: setCreateWalletNotesAction,
  resetEditWallet: resetEditWalletAction,
  setEditWalletPartner: setEditWalletPartnerAction,
  setEditWalletType: setEditWalletTypeAction,
  setEditWalletIsPrivate: setEditWalletIsPrivateAction,
  setEditWalletNotes: setEditWalletNotesAction,
}

export const WalletsTablePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletsTablePageContainer);
