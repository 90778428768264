import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import {
  setReportsTotalShareAmount as setReportsTotalShareAmountAction,
  editReportsListItemTelegramChecked as editReportsListItemTelegramCheckedAction,
  editReportsListItemConfirmedChecked as editReportsListItemConfirmedCheckedAction,
  setReportsSelectedType as setReportsSelectedTypeAction,
  resetModalTableInfoData as resetModalTableInfoDataAction,
} from '../../../store';
import {
  getModalTableInfoIsShowSelector,
  getModalTableInfoListSelector,
  getModalTableInfoPartnerNameSelector,
  getReportsListSelector,
  getReportsTotalShareAmountSelector,
} from '../selectors';
import {
  ModalTableInfo,
  InputCustom, ButtonCustom,
} from '../../../components/UIComponents';
import {
  updateAgentsPartnersReport,
} from '../../../api';
import { TableComponent } from '../../../components/TableComponent';
import {
  changeFilteredList, getUniqueValuesList,
} from '../../../utils';
import { sortEvent } from '../../../utils/sortingService';
import {
  getReportsPageColumn,
  reportsSort,
} from '../constants';
import {
  getReportColumn,
  getReportSort, getReportUniqueLines,
} from './constants';
import { MultiFilterPanel } from '../../../components/MultiFilterPanel';
import { buttonTypes } from "../../../components/UIComponents/buttons/ButtonCustom/constants";
import { excludeKeys, reformatDataKeys } from "./constants";

import { getStyles } from './styles';


const useStyles = makeStyles(getStyles);

const ReportsTablePageContainer = ({
  selectedType,
  reportsList,
  editReportsListItemTelegramChecked,
  editReportsListItemConfirmedChecked,
  isShowModalAgentData,
  agentDataList,
  partnerName,
  totalShareAmount,
  resetModalTableInfoData,
  setReportsSelectedType,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [ handleSortBy, setHandleSortBy ] = useState(false);
  const [ handleSortDirection, setHandleSortDirection ] = useState(false);

  const [ searchValue, setSearchValue ] = useState(null);
  const [ filteredList, setFilteredList ] = useState([]);

  const [ uniqueValuesList, setUniqueValuesList ] = useState([]);
  const [ uniqueFiltersList, setUniqueFiltersList] = useState([]);

  useEffect(() => {
    setUniqueFiltersList([]);
    setUniqueValuesList(getUniqueValuesList(reportsList, getReportUniqueLines(selectedType)));
  }, [reportsList])

  useEffect(() => {
    changeFilteredList(reportsList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'weeklyDetails', 'telegram', 'walletTransactions']);
  }, [reportsList, searchValue, uniqueFiltersList]);



  useEffect(() => {
    setReportsSelectedType(selectedType);
  }, []);

  const handleCheckboxClick = id => {
    editReportsListItemTelegramChecked(id);
  }

  const handleCheckboxConfirmClick = (id, checked, week, year) => {
    updateAgentsPartnersReport({
      accountType: selectedType,
      id,
      confirmed: !checked,
      week,
      year,
    })
      .then(() => editReportsListItemConfirmedChecked(id));
  }

  const sort = sortEvent({
    handleSortBy,
    sortData: reportsSort,
    setHandleSortDirection,
    setHandleSortBy,
    list: filteredList,
  });

  const handleChangeInput = event => {
    setSearchValue(event.target.value);
    changeFilteredList(reportsList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'weeklyDetails', 'telegram', 'walletTransactions']);
  }

  const changeUniqueValues = (items, dataKey) => {
    const newUniqueValuesList = uniqueValuesList.map(elem => {
      if (elem.dataKey === dataKey) {
        return {
          ...elem,
          selectedValues: items,
        }
      }
      return elem;
    })
    setUniqueValuesList(newUniqueValuesList);
    setUniqueFiltersList(newUniqueValuesList.filter(el => el.selectedValues.length > 0));
  }

  const handleExportToExcel = async () => {
    const keys = getReportsPageColumn(selectedType);

    const actualKeys = keys.map(key => ({
      header: key.label,
      key: key.dataKey,
    })).filter(key => !excludeKeys.includes(key.key));

    const actualAccountList = filteredList
      .map((account) => {
        const newAccount = {};
        actualKeys.forEach(key => {
          const reformatData = reformatDataKeys.find(el => el.name === key.key);
          let value = '';
          if (reformatData && typeof account[key.key] === 'object') {
            value = account[key.key]?.[reformatData.value];
          } else {
            value = account[key.key];
          }

          if (value === undefined || value === null || value === 'disabled') {
            value = '';
          }

          if (typeof value === 'number') {
            value = value.toFixed(2);
          }

          newAccount[key.key] = value;
        });
        return newAccount;
      })

    let data = actualAccountList;
    const workSheetName = `${selectedType} Report`;
    const workBookName = workSheetName;

    const workbook = new Excel.Workbook();
    const fileName = workBookName;
    const worksheet = workbook.addWorksheet(workSheetName);
    worksheet.columns = actualKeys;
    worksheet.getRow(1).font = {bold: true};
    worksheet.columns.forEach(column => {
      column.width = column.header.length + 5;
      column.alignment = {horizontal: 'center'};
    });
    data.forEach(singleData => {
      worksheet.addRow(singleData);
    });
    worksheet.eachRow({includeEmpty: false}, row => {
      const currentCell = row._cells;
      currentCell.forEach(singleCell => {
        const cellAddress = singleCell._address;
        worksheet.getCell(cellAddress).border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });
    });
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.btnContainer}>
        {selectedType !== 'Wallets' && (
          <Box className={classes.toolBox}>
            <Box className={classes.totalContainer}>
              <box className={classes.totalLabel}>Total Share Amount: </box>
              <box>{totalShareAmount}</box>
            </Box>
          </Box>
        )}

        <Box></Box>

        <ButtonCustom
          onClick={handleExportToExcel}
          customType={buttonTypes.CREATE}
          text='export to excel'
        />
      </Box>

      <Box className={classes.containerForFilters}>
        <MultiFilterPanel
          list={uniqueValuesList}
          onChange={changeUniqueValues}
        />
        <InputCustom
          className={classes.input}
          value={searchValue}
          onChange={handleChangeInput}
        />
      </Box>

      <TableComponent
        columns={getReportsPageColumn(selectedType)}
        rows={filteredList}
        eventHandleChangePanelCheckbox={handleCheckboxClick}
        eventHandleChangePanelCheckboxConfirm={handleCheckboxConfirmClick}
        sort={sort}
        sortBy={handleSortBy}
        sortDirection={handleSortDirection}
      />
      {
        isShowModalAgentData && (
          <ModalTableInfo
            open={isShowModalAgentData}
            list={agentDataList}
            title={partnerName}
            eventClose={resetModalTableInfoData}
            sortData={getReportSort(selectedType)}
            columns={getReportColumn(selectedType)}
          />
        )
      }
    </Box>
  );
}

const mapStateToProps = state => ({
  reportsList: getReportsListSelector(state),
  totalShareAmount: getReportsTotalShareAmountSelector(state),
  isShowModalAgentData: getModalTableInfoIsShowSelector(state),
  agentDataList: getModalTableInfoListSelector(state),
  partnerName: getModalTableInfoPartnerNameSelector(state),
});

const mapDispatchToProps = {
  editReportsListItemTelegramChecked: editReportsListItemTelegramCheckedAction,
  editReportsListItemConfirmedChecked: editReportsListItemConfirmedCheckedAction,
  setReportsTotalShareAmount: setReportsTotalShareAmountAction,
  resetModalTableInfoData: resetModalTableInfoDataAction,
  setReportsSelectedType: setReportsSelectedTypeAction,
};

export const ReportsTablePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsTablePageContainer);
