import React from 'react';
import { connect } from 'react-redux';

import Excel from 'exceljs';
import { saveAs } from 'file-saver';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { navigationList, transactionPageConst } from './constants';
import {
  resetTransactions as resetTransactionsAction,
} from '../../store';
import { TabNavigation } from '../../components/TabNavigation';
import { buttonTypes } from '../../components/UIComponents/buttons/ButtonCustom/constants';
import { ButtonCustom } from '../../components/UIComponents';
import { getTransactionsFilteredListSelector, getTransactionsTypeSelector } from './selectors';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const TransactionsPageContainer = ({
 resetTransactions,
 children,
 transactionsFilteredList,
 transactionsType,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleCreateExcel = async () => {

    const columns = [
      {header: 'Sender', key: 'sender'},
      {header: 'Sender Wallet', key: 'senderWallet'},
      {header: 'Receiver', key: 'receiver'},
      {header: 'Receiver Wallet', key: 'receiverWallet'},
      {header: 'Affects On', key: 'affectsOn'},
      {header: 'Type', key: 'type'},
      {header: 'Amount', key: 'amount'},
      {header: 'Week', key: 'week'},
      {header: 'Year', key: 'year'},
      {header: 'Date', key: 'date'},
      {header: 'Create Date', key: 'createDate'},
      {header: 'SubType', key: 'subType'},
      {header: 'Notes', key: 'notes'},
    ];
    let data = [];
    const workSheetName = 'TransactionsReport-' + new Date().toDateString();
    const workBookName = workSheetName;

    transactionsFilteredList.forEach(item => {
      data.push({
        sender: item?.senderName ?? '',
        senderWallet: item?.senderWalletType ?? '',
        receiver: item?.receiverName ?? '',
        receiverWallet: item?.receiverWalletType ?? '',
        type: item?.transactionType ?? '',
        affectsOn: item?.transactionAffectsOn ?? '',
        amount: item?.amount ?? '',
        week: item?.week ?? '',
        year: item?.year ?? '',
        date: item?.date ?? '',
        createDate: item?.createDate ?? '',
        subType: item?.subType?.value ?? '',
        notes: item?.notes ?? '',
      })
    });
    const workbook = new Excel.Workbook();
    const fileName = workBookName;
    const worksheet = workbook.addWorksheet(workSheetName);
    worksheet.columns = columns;
    worksheet.getRow(1).font = {bold: true};
    worksheet.columns.forEach(column => {
      column.width = column.header.length + 5;
      column.alignment = {horizontal: 'center'};
    });
    data.forEach(singleData => {
      worksheet.addRow(singleData);
    });
    worksheet.eachRow({includeEmpty: false}, row => {
      const currentCell = row._cells;
      currentCell.forEach(singleCell => {
        const cellAddress = singleCell._address;
        worksheet.getCell(cellAddress).border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });
    });
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.containerNav}>
        <TabNavigation
          navigationList={navigationList}
          eventItemClick={() => resetTransactions()}
        />
        {(transactionPageConst.ACTIVE.TYPE === transactionsType) && (
          <ButtonCustom
            onClick={handleCreateExcel}
            customType={buttonTypes.CREATE}
            text='Excel export'
          />
        )}
      </Box>
      <Box className={classes.content}>
        { children }
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  transactionsFilteredList: getTransactionsFilteredListSelector(state),
  transactionsType: getTransactionsTypeSelector(state),
});

const mapDispatchToProps = {
  resetTransactions: resetTransactionsAction,
};

export const TransactionsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionsPageContainer);