import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import {
  ButtonCustom,
  ModalPayment,
  ModalTransaction,
  ModalExpenses,
  NamedPanel,
} from '../../components/UIComponents';
import { buttonTypes } from '../../components/UIComponents/buttons/ButtonCustom/constants';
import {
  resetAppDepPayment as resetAppDepPaymentAction,
  resetCreateTransaction as resetCreateTransactionAction,
  setCreateTransactionAmount as setCreateTransactionAmountAction,
  setCreateTransactionPartnerSender as setCreateTransactionPartnerSenderAction,
  setCreateTransactionPartnerSenderWallet as setCreateTransactionPartnerSenderWalletAction,
  setCreateTransactionPartnerReceiver as setCreateTransactionPartnerReceiverAction,
  setCreateTransactionPartnerReceiverWallet as setCreateTransactionPartnerReceiverWalletAction,
  setCreateTransactionSubType as setCreateTransactionSubTypeAction,
  setCreateTransactionSelectedDate as setCreateTransactionSelectedDateAction,
  setCreateTransactionNotes as setCreateTransactionNotesAction,
  setAppDepPaymentSelectedDate as setAppDepPaymentSelectedDateAction,
  setAppDepPaymentNotes as setAppDepPaymentNotesAction,
  setAppDepPaymentAmount as setAppDepPaymentAmountAction,
  setAppDepPaymentAccount as setAppDepPaymentAccountAction,
  resetAccounts as resetAccountsAction,
  setAppDepPaymentPartnerReceiver as setAppDepPaymentPartnerReceiverAction,
  setAppDepPaymentPartnerReceiverWallet as setAppDepPaymentPartnerReceiverWalletAction,
} from '../../store';
import {
  getPartnersForTransaction,
  addTransaction, accountsForTable,
} from '../../api';
import {
  getCreateTransactionAmountSelector,
  getCreateTransactionPartnersListSelector,
  getCreateTransactionPartnerReceiverSelector,
  getCreateTransactionPartnerReceiverWalletSelector,
  getCreateTransactionPartnerSenderSelector,
  getCreateTransactionPartnerSenderWalletSelector,
  getCreateTransactionSubTypeSelector,
  getCreateTransactionSelectedDateSelector,
  getCreateTransactionNotesSelector,
  getAppDepPaymentNotesSelector,
  getAppDepPaymentAmountSelector,
  getAccountsListSelector,
  getAppDepPaymentAccountSelector,
  getAppDepPaymentPartnersListSelector,
  getAppDepPaymentPartnerReceiverSelector,
  getAppDepPaymentPartnerReceiverWalletSelector, getAppDepPaymentSelectedDateSelector, getSettingsIsAdminSelector,
} from './selectors';
import {
  setActionAppDepPaymentPartners,
  setActionTransactionPartners,
} from './actions';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const PaymentsPageContainer = ({
  history,
  isAdmin,
  accountsList,
  transactionAmount,
  transactionPartnerSender,
  transactionPartnerSenderWallet,
  transactionPartnerReceiver,
  transactionPartnerReceiverWallet,
  transactionSubType,
  transactionPartnersList,
  transactionSelectedDate,
  transactionNotes,
  resetAppDepPayment,
  resetTransaction,
  resetAccounts,
  appDepPaymentAccount,
  appDepPaymentSelectedDate,
  appDepPaymentNotes,
  appDepPaymentAmount,
  appDepPaymentPartnersList,
  appDepPaymentPartnerReceiver,
  appDepPaymentPartnerReceiverWallet,
  setTransactionAmount,
  setTransactionPartnerSender,
  setTransactionPartnerSenderWallet,
  setTransactionPartnerReceiver,
  setTransactionPartnerReceiverWallet,
  setTransactionSubType,
  setTransactionSelectedDate,
  setTransactionNotes,
  setAppDepPaymentAccount,
  setAppDepPaymentSelectedDate,
  setAppDepPaymentNotes,
  setAppDepPaymentAmount,
  setAppDepPaymentPartnerReceiver,
  setAppDepPaymentPartnerReceiverWallet,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [showModalTransactionCreate, setShowModalTransactionCreate] = useState(false);
  const [hiddenList, setHiddenList] = useState([]);

  const [showModalPayment, setShowModalPayment] = useState(false);
  const [modalPaymentName, setModalPaymentName] = useState('');

  const [showModalExpenses, setShowModalExpenses] = useState(false);

  useEffect(() => {
    if (showModalTransactionCreate || showModalExpenses) {
      const getPartners = async () => await getPartnersForTransaction();
      getPartners().then(data => {
        if (data) {
          setActionTransactionPartners(data);
        }
      });
    }
  }, [showModalTransactionCreate, showModalExpenses]);

  useEffect(() => {
    if (showModalPayment && modalPaymentName) {
      const getPartners = async () => await getPartnersForTransaction();
      const getAccounts = async () => await accountsForTable(modalPaymentName);
      getPartners().then(data => {
        //console.log(22222222, data);
        if (data) {
          setActionAppDepPaymentPartners(data)
        }
      });
      getAccounts().then(() => Promise.resolve());
    }
  }, [showModalPayment, modalPaymentName]);

  const openModalTransactionCreateAction = () => {
    setHiddenList([]);
    setShowModalTransactionCreate(true);
  };

  const closeModalTransactionCreateAction = () => {
    setShowModalTransactionCreate(false);
    resetTransaction();
  }

  const openModalExpenses = () => {
    setShowModalExpenses(true);
  };

  const closeModalExpenses = () => {
    setShowModalExpenses(false);
    resetTransaction();
  }

  const openModalRefillTransactionCreateAction = () => {
    setHiddenList(['PartnerSender', 'PartnerSenderWallet']);
    setShowModalTransactionCreate(true);
  }

  const openModalPayment = name => {
    setShowModalPayment(true);
    setModalPaymentName(name);
  }

  const closeModalPayment = () => {
    setShowModalPayment(false);
    setModalPaymentName('');
    resetAppDepPayment();
    resetAccounts();
  }

  const sendCreateTransaction = () => {
    Promise.resolve(addTransaction({
      partnerSender: transactionPartnerSender || null,
      partnerSenderWallet: transactionPartnerSenderWallet || null,
      partnerReceiver: transactionPartnerReceiver,
      partnerReceiverWallet: transactionPartnerReceiverWallet,
      subType: transactionSubType,
      amount: transactionAmount,
      date: transactionSelectedDate,
      notes: transactionNotes,
    }))
      .then(closeModalTransactionCreateAction)
      .then(history.push('/Transactions'));
  }

  const sendAppDepPayment = () => {
    Promise.resolve(addTransaction({
      account: appDepPaymentAccount,
      partnerSender: null,
      partnerSenderWallet: null,
      partnerReceiver: appDepPaymentPartnerReceiver,
      partnerReceiverWallet: appDepPaymentPartnerReceiverWallet,
      subType: 'Private',
      amount: appDepPaymentAmount,
      date: appDepPaymentSelectedDate,
      notes: appDepPaymentNotes,
      transactionType: modalPaymentName,
    }))
      .then(closeModalPayment)
      .then(history.push('/Transactions'));
  }

  const sendExpenses = () => {
    Promise.resolve(addTransaction({
      partnerReceiver: transactionPartnerReceiver,
      partnerReceiverWallet: transactionPartnerReceiverWallet,
      subType: transactionSubType,
      amount: transactionAmount,
      date: transactionSelectedDate,
      notes: transactionNotes,
      expenses: true,
    }))
      .then(closeModalExpenses)
      .then(history.push('/Transactions'));
  }

  return (
    <Box className={classes.container}>
      <NamedPanel types={classes.panel} title='Payment'>
        <ButtonCustom
          customType={buttonTypes.PANEL}
          text={'Partners/Agents payment'}
          onClick={openModalTransactionCreateAction}
        />
        <ButtonCustom
          customType={buttonTypes.PANEL}
          text={'Application payment'}
          onClick={() => openModalPayment('Application')}
        />
        <ButtonCustom
          customType={buttonTypes.PANEL}
          text={'Deposit payment'}
          onClick={() => openModalPayment('Deposit')}
        />
        <ButtonCustom
          customType={buttonTypes.CANCEL}
          text={'Refill Balance'}
          onClick={openModalRefillTransactionCreateAction}
          style={{
            marginTop: 10,
            marginBottom: 10,
            padding: '15px 20px',
          }}
        />
        {isAdmin && (
          <ButtonCustom
            customType={buttonTypes.EDIT}
            text={'Expenses'}
            onClick={openModalExpenses}
            style={{
              marginTop: 10,
              marginBottom: 10,
              padding: '15px 20px',
            }}
          />
        )}
      </NamedPanel>
      {
        showModalTransactionCreate && (
          <ModalTransaction
            open={showModalTransactionCreate}
            eventApply={sendCreateTransaction}
            eventClose={closeModalTransactionCreateAction}
            title='Create Payment Transaction'
            partnersList={transactionPartnersList}
            partnerSender={transactionPartnerSender}
            partnerSenderWallet={transactionPartnerSenderWallet}
            partnerReceiver={transactionPartnerReceiver}
            partnerReceiverWallet={transactionPartnerReceiverWallet}
            subType={transactionSubType}
            amount={transactionAmount}
            selectedDate={transactionSelectedDate}
            notes={transactionNotes}
            setPartnerSender={setTransactionPartnerSender}
            setPartnerSenderWallet={setTransactionPartnerSenderWallet}
            setPartnerReceiver={setTransactionPartnerReceiver}
            setPartnerReceiverWallet={setTransactionPartnerReceiverWallet}
            setSubType={setTransactionSubType}
            setAmount={setTransactionAmount}
            setSelectedDate={setTransactionSelectedDate}
            setNotes={setTransactionNotes}
            hiddenList={hiddenList}
          />
        )
      }
      {showModalPayment && (
        <ModalPayment
          open={showModalPayment}
          eventClose={closeModalPayment}
          title={`${modalPaymentName} Payment`}
          name={`${modalPaymentName} Account`}
          accountsList={accountsList}
          partnersList={appDepPaymentPartnersList}
          account={appDepPaymentAccount}
          amount={appDepPaymentAmount}
          notes={appDepPaymentNotes}
          partnerReceiver={appDepPaymentPartnerReceiver}
          partnerReceiverWallet={appDepPaymentPartnerReceiverWallet}
          selectedDate={appDepPaymentSelectedDate}
          setAccount={setAppDepPaymentAccount}
          setAmount={setAppDepPaymentAmount}
          setNotes={setAppDepPaymentNotes}
          setSelectedDate={setAppDepPaymentSelectedDate}
          setPartnerReceiver={setAppDepPaymentPartnerReceiver}
          setPartnerReceiverWallet={setAppDepPaymentPartnerReceiverWallet}
          eventApply={sendAppDepPayment}
        />
      )}
      {showModalExpenses && (
        <ModalExpenses
          open={showModalExpenses}
          eventApply={() => {}}
          eventClose={closeModalExpenses}
          title='Create Expenses'
          partnersList={transactionPartnersList}
          partnerReceiver={transactionPartnerReceiver}
          partnerReceiverWallet={transactionPartnerReceiverWallet}
          subType={transactionSubType}
          amount={transactionAmount}
          selectedDate={transactionSelectedDate}
          notes={transactionNotes}
          setPartnerReceiver={setTransactionPartnerReceiver}
          setPartnerReceiverWallet={setTransactionPartnerReceiverWallet}
          setSubType={setTransactionSubType}
          setAmount={setTransactionAmount}
          setSelectedDate={setTransactionSelectedDate}
          setNotes={setTransactionNotes}
          eventApply={sendExpenses}
          disabledList={['SubType']}
        />
      )}
    </Box>
  )
}

const mapStateToProps = state => ({
  isAdmin: getSettingsIsAdminSelector(state),
  transactionAmount: getCreateTransactionAmountSelector(state),
  transactionPartnerSender: getCreateTransactionPartnerSenderSelector(state),
  transactionPartnerSenderWallet: getCreateTransactionPartnerSenderWalletSelector(state),
  transactionPartnerReceiver: getCreateTransactionPartnerReceiverSelector(state),
  transactionPartnerReceiverWallet: getCreateTransactionPartnerReceiverWalletSelector(state),
  transactionSubType: getCreateTransactionSubTypeSelector(state),
  transactionPartnersList: getCreateTransactionPartnersListSelector(state),
  transactionSelectedDate: getCreateTransactionSelectedDateSelector(state),
  transactionNotes: getCreateTransactionNotesSelector(state),
  appDepPaymentSelectedDate: getAppDepPaymentSelectedDateSelector(state),
  appDepPaymentNotes: getAppDepPaymentNotesSelector(state),
  appDepPaymentAmount: getAppDepPaymentAmountSelector(state),
  accountsList: getAccountsListSelector(state),
  appDepPaymentAccount: getAppDepPaymentAccountSelector(state),
  appDepPaymentPartnersList: getAppDepPaymentPartnersListSelector(state),
  appDepPaymentPartnerReceiver: getAppDepPaymentPartnerReceiverSelector(state),
  appDepPaymentPartnerReceiverWallet: getAppDepPaymentPartnerReceiverWalletSelector(state),
});

const mapDispatchToProps = {
  resetTransaction: resetCreateTransactionAction,
  resetAppDepPayment: resetAppDepPaymentAction,
  resetAccounts: resetAccountsAction,
  setTransactionAmount: setCreateTransactionAmountAction,
  setTransactionPartnerSender: setCreateTransactionPartnerSenderAction,
  setTransactionPartnerSenderWallet: setCreateTransactionPartnerSenderWalletAction,
  setTransactionPartnerReceiver: setCreateTransactionPartnerReceiverAction,
  setTransactionPartnerReceiverWallet: setCreateTransactionPartnerReceiverWalletAction,
  setTransactionSubType: setCreateTransactionSubTypeAction,
  setTransactionSelectedDate: setCreateTransactionSelectedDateAction,
  setTransactionNotes: setCreateTransactionNotesAction,
  setAppDepPaymentSelectedDate: setAppDepPaymentSelectedDateAction,
  setAppDepPaymentNotes: setAppDepPaymentNotesAction,
  setAppDepPaymentAmount: setAppDepPaymentAmountAction,
  setAppDepPaymentAccount: setAppDepPaymentAccountAction,
  setAppDepPaymentPartnerReceiver: setAppDepPaymentPartnerReceiverAction,
  setAppDepPaymentPartnerReceiverWallet: setAppDepPaymentPartnerReceiverWalletAction,
}

export const PaymentsPage = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentsPageContainer)
);
