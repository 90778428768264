export const getStyles = theme => ({
  modalContent: {
    '& .MuiDialog-paper': {
      width: '100%',
      backgroundColor: theme.mainStyles.mainBackgroundColor,
      color: theme.mainStyles.mainColorText,
      borderRadius: 0,
      borderLeft: `4px solid ${theme.mainStyles.mainColorText}`
    },
  },
  title: {
    padding: '20px 24px',
    '& .MuiTypography-root': {
      fontSize: 18,
      color: theme.mainStyles.mainColorText,
      paddingRight: 50,
    }
  },
  text: {
    color: theme.mainStyles.mainColorText,
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: -10,
    marginRight: -10,
    marginBottom: 20,
  },
  rowTopWrapper: {
    marginTop: 35,
  },
  colWrapper_100: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  colWrapper_50: {
    width: '50%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-multiline': {
      paddingTop: '10px',
      paddingBottom: '10px',
      color: theme.mainStyles.mainColorText,
      backgroundColor: theme.mainStyles.mainBackgroundColor,
    },
    '& .MuiInputLabel-outlined': {
      color: theme.mainStyles.mainColorText,
      top: '-7px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      transform: 'translate(14px, 0px) scale(0.75)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.mainStyles.mainColorText,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.mainStyles.mainColorText,
    },
  },
  formButtons: {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '24px',
  },
  buttonClose: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: theme.mainStyles.mainColorText,
  }
});
