import { addStatusModalItem, setSettingsShowLoader, store } from '../../store';

export const requestWrapper = async (operationName, func) => {
  try {
    store.dispatch(setSettingsShowLoader(true));
    await func().then(() => store.dispatch(setSettingsShowLoader(false)));
  } catch(ex) {
    store.dispatch(setSettingsShowLoader(false));
    store.dispatch(addStatusModalItem({
      type: 'error',
      text: `Request operation: ${operationName}. Error:\n ${ex}`,
    }));
    console.log(`Request operation: ${operationName}. Error:\n`, ex);
  }
}
