import {
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";

import { API_URL } from './constants';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const apolloClient = new ApolloClient({
  uri: `${API_URL}/graphql`,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    console.log(graphQLErrors, networkError, operation, forward);
  },
});
