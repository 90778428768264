
export const getStyles = theme => ({
  button: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 16,
    color: theme.buttonSubmitStyles.buttonColor,
    backgroundColor: theme.buttonSubmitStyles.backgroundColor,
    '&:hover': {
      backgroundColor: theme.buttonSubmitStyles.hoverBackgroundColor,
    }
  }
})
