import {
	RESET_APP_DEP_PAYMENT,
	SET_APP_DEP_PAYMENT_AMOUNT,
	SET_APP_DEP_PAYMENT_SELECTED_DATE,
	SET_APP_DEP_PAYMENT_NOTES,
	SET_APP_DEP_PAYMENT_ACCOUNT,
	SET_APP_DEP_PAYMENT_PARTNERS_LIST,
	SET_APP_DEP_PAYMENT_PARTNER_RECEIVER,
	SET_APP_DEP_PAYMENT_PARTNER_RECEIVER_WALLET,
} from '../constants';

export const setAppDepPaymentSelectedDate = selectedDate => ({
	type: SET_APP_DEP_PAYMENT_SELECTED_DATE,
	payload: {
		selectedDate,
	}
});

export const setAppDepPaymentNotes = notes => ({
	type: SET_APP_DEP_PAYMENT_NOTES,
	payload: {
		notes,
	}
});

export const setAppDepPaymentAmount = amount => ({
	type: SET_APP_DEP_PAYMENT_AMOUNT,
	payload: {
		amount,
	}
});

export const setAppDepPaymentAccount = account => ({
	type: SET_APP_DEP_PAYMENT_ACCOUNT,
	payload: {
		account,
	}
});

export const setAppDepPaymentPartnerReceiver = partnerReceiver => ({
	type: SET_APP_DEP_PAYMENT_PARTNER_RECEIVER,
	payload: {
		partnerReceiver,
	}
});

export const setAppDepPaymentPartnerReceiverWallet = partnerReceiverWallet => ({
	type: SET_APP_DEP_PAYMENT_PARTNER_RECEIVER_WALLET,
	payload: {
		partnerReceiverWallet,
	}
});

export const setAppDepPaymentPartnersList = partnersList => ({
	type: SET_APP_DEP_PAYMENT_PARTNERS_LIST,
	payload: {
		partnersList,
	}
});

export const resetAppDepPayment = () => ({
	type: RESET_APP_DEP_PAYMENT,
});
