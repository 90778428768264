import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';
import { InputMiniCustom } from '../../UIComponents';

const useStyles = makeStyles(getStyles);

export const PanelInputData = ({
  id,
  inputValue,
  handleChange,
  handleBlur,
  data,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [ isSent, setIsSent ] = useState(false);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <InputMiniCustom
          value={inputValue && (inputValue !== 'disabled') ? inputValue : ''}
          type='number'
          
          onChange={(e) => {
            handleChange({
              id,
              event: e,
              amount: data?.amount,
              year: data?.year,
              week: data?.week,
            });
            setIsSent(false);
          }}
          onBlur={(e) => {
            if (!isSent) {
              handleBlur({
                id,
                event: e,
                amount: data?.amount,
                year: data?.year,
                week: data?.week,
              });
            }
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleBlur({
                id,
                event: e,
                amount: data?.amount,
                year: data?.year,
                week: data?.week,
              });
              setIsSent(true);
              setTimeout(() => e.target.blur(), 0);
            }
          }}
          onFocus={() => {
            setIsSent(true);
          }}
          disabled={inputValue === 'disabled'}
        />
      </Box>
    </Box>
  )
}
