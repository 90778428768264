import {
  RESET_EDIT_WALLET_TYPE,
  SET_EDIT_WALLET_TYPE,
  SET_EDIT_WALLET_TYPE_NAME,
  SET_EDIT_WALLET_TYPE_NOTES,
} from '../../constants';

const initialState = {
  id: null,
  name: '',
  notes: '',
}

export const editWalletType = (state = initialState, action) => {
  switch (action.type) {
    case RESET_EDIT_WALLET_TYPE:
      return initialState;
    case SET_EDIT_WALLET_TYPE:
      return action.payload.walletType;
    case SET_EDIT_WALLET_TYPE_NAME:
    case SET_EDIT_WALLET_TYPE_NOTES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
