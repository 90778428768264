export const getStyles = theme => ({
  modalContent: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 500,
      backgroundColor: theme.mainStyles.mainBackgroundColor,
      color: theme.mainStyles.mainColorText,
      borderRadius: 0,
      borderLeft: `4px solid ${theme.mainStyles.mainColorText}`
    },
  },
  title: {
    padding: '20px 24px',
    '& .MuiTypography-root': {
      fontSize: 18,
      color: theme.mainStyles.mainColorText,
      paddingRight: 50,
    }
  },
  text: {
    fontWeight: 400,
    color: theme.mainStyles.mainColorText,
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: -10,
    marginRight: -10,
    marginBottom: 30,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  colWrapper_100: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  formButtons: {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '24px',
  },
  buttonClose: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: theme.mainStyles.mainColorText,
  }
});
