import React from 'react';
import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const InputMiniCustom = ({
  className,
  onChange,
  value,
  variant,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <TextField
      className={clsx(
        classes.input,
        className
      )}
      variant={variant ? variant : 'outlined'}
      value={value}
      onChange={onChange ? onChange : null}
      {...props}
    />
  )
}
