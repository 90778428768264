import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { TableComponent } from '../../components/TableComponent';
import {
  TaskButtonsPanel,
  InputCustom, ButtonCustom,
} from '../../components/UIComponents';
import { partnersPageColumn, partnersPageSort } from './constants';
import {
  getPartnersListSelector,
  getCreatePartnerNameSelector,
  getCreatePartnerTermsSelector,
  getCreatePartnerTypeSelector,
  getCreatePartnerLocationSelector,
  getCreatePartnerSharePercentSelector,
  getEditPartnerIdSelector,
  getEditPartnerNameSelector,
  getEditPartnerTermsSelector,
  getEditPartnerTypeSelector,
  getEditPartnerLocationSelector,
  getEditPartnerSharePercentSelector,
} from './selectors';
import {
  resetCreatePartner as resetCreatePartnerAction,
  setCreatePartnerName as setCreatePartnerNameAction,
  setCreatePartnerType as setCreatePartnerTypeAction,
  setCreatePartnerLocation as setCreatePartnerLocationAction,
  setCreatePartnerTerms as setCreatePartnerTermsAction,
  setCreatePartnerSharePercent as setCreatePartnerSharePercentAction,
  resetEditPartner as resetEditPartnerAction,
  setEditPartnerName as setEditPartnerNameAction,
  setEditPartnerType as setEditPartnerTypeAction,
  setEditPartnerLocation as setEditPartnerLocationAction,
  setEditPartnerTerms as setEditPartnerTermsAction,
  setEditPartnerSharePercent as setEditPartnerSharePercentAction,
} from '../../store';
import {
  ModalDelete,
  ModalPartner,
} from '../../components/UIComponents/modals';
import {
  partnersForTable,
  addPartner,
  editPartner,
  deletePartner,
  getForEditPartner,
} from '../../api';
import { changeFilteredList, getUniqueValuesList } from '../../utils';
import { sortEvent } from '../../utils/sortingService';
import { MultiFilterPanel } from '../../components/MultiFilterPanel';
import { uniqueLines } from './constants';

import { getStyles } from './styles';
import {buttonTypes} from "../../components/UIComponents/buttons/ButtonCustom/constants";

import {excludeKeys, reformatDataKeys} from "./constants";

const useStyles = makeStyles(getStyles);

const PartnersPageContainer = ({
  partnersList,
  createPartnerName,
  createPartnerType,
  createPartnerLocation,
  createPartnerTerms,
  createPartnerSharePercent,
  editPartnerId,
  editPartnerName,
  editPartnerType,
  editPartnerLocation,
  editPartnerTerms,
  editPartnerSharePercent,
  resetCreatePartner,
  setCreatePartnerName,
  setCreatePartnerType,
  setCreatePartnerTerms,
  setCreatePartnerLocation,
  setCreatePartnerSharePercent,
  resetEditPartner,
  setEditPartnerName,
  setEditPartnerType,
  setEditPartnerTerms,
  setEditPartnerLocation,
  setEditPartnerSharePercent,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [partnerId, setPartnerId] = useState(null);
  const [showModalCreatePartner, setShowModalCreatePartner] = useState(false);
  const [showModalEditPartner, setShowModalEditPartner] = useState(false);

  const [ handleSortBy, setHandleSortBy ] = useState(false);
  const [ handleSortDirection, setHandleSortDirection ] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [filteredList, setFilteredList] = useState([]);

  const [ uniqueValuesList, setUniqueValuesList ] = useState([]);
  const [ uniqueFiltersList, setUniqueFiltersList] = useState([]);

  useEffect(() => {
    setUniqueValuesList(getUniqueValuesList(partnersList, uniqueLines));
  }, [partnersList]);

  const getPartners = useCallback(async () =>
    await partnersForTable(),
  []);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  useEffect(() => {
    changeFilteredList(partnersList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'action']);
  }, [partnersList, searchValue, uniqueFiltersList]);

  useEffect(() => {
    const getForEdit = async (partnerId) => await getForEditPartner(partnerId);

    if (showModalEditPartner === true) {
      getForEdit(partnerId);
    }
  }, [partnerId, showModalEditPartner]);

  const closeModalDeleteAction = () => setShowModalDelete(false);

  const openModalDeleteAction = id => {
    setShowModalDelete(true);
    setPartnerId(id);
  };

  const deletePartnerById = id => {
    Promise.resolve(deletePartner(id))
      .then(setPartnerId(null))
      .then(closeModalDeleteAction());
  }

  const sendCreatePartner = () => {
    Promise.resolve(addPartner({
      partnerName: createPartnerName,
      partnerType: createPartnerType,
      terms: createPartnerTerms,
      sharePercent: createPartnerSharePercent,
      location: createPartnerLocation,
    }))
      .then(closeModalCreatePartnerAction())
      .then(resetCreatePartner());
  }

  const sendEditPartner = () => {
    Promise.resolve(editPartner({
      id: editPartnerId,
      partnerName: editPartnerName,
      partnerType: editPartnerType,
      terms: editPartnerTerms,
      sharePercent: editPartnerSharePercent,
      location: editPartnerLocation,
    }))
      .then(closeModalEditPartnerAction());
  }

  const closeModalCreatePartnerAction = () => {
    setShowModalCreatePartner(false);
    resetCreatePartner();
  }

  const closeModalEditPartnerAction = () => {
    setShowModalEditPartner(false);
    resetEditPartner();
    setPartnerId(null);
  }

  const openModalEditAccount = id => {
    // const agent = partnersList.find(elem => elem.id === id);
    // handlerEditData(agent);
    setPartnerId(id);
    setShowModalEditPartner(true);
  }

  const sort = sortEvent({
    handleSortBy,
    sortData: partnersPageSort,
    setHandleSortDirection,
    setHandleSortBy,
    list: filteredList,
  });

  const handleBlurInput = event => {
    const value = event.target.value;
    setSearchValue(value);

    changeFilteredList(partnersList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'action']);
  }

  const changeUniqueValues = (items, dataKey) => {
    const newUniqueValuesList = uniqueValuesList.map(elem => {
      if (elem.dataKey === dataKey) {
        return {
          ...elem,
          selectedValues: items,
        }
      }
      return elem;
    })
    setUniqueValuesList(newUniqueValuesList);
    setUniqueFiltersList(newUniqueValuesList.filter(el => el.selectedValues.length > 0));
  }

  const handleExportToExcel = async () => {
    const keys = partnersPageColumn;

    const actualKeys = keys.map(key => ({
      header: key.label,
      key: key.dataKey,
    })).filter(key => !excludeKeys.includes(key.key));

    const actualAccountList = filteredList
      .map((account) => {
        const newAccount = {};
        actualKeys.forEach(key => {
          const reformatData = reformatDataKeys.find(el => el.name === key.key);
          let value = '';
          if (reformatData && typeof account[key.key] === 'object') {
            value = account[key.key]?.[reformatData.value];
          } else {
            value = account[key.key];
          }

          if (value === undefined || value === null || value === 'disabled') {
            value = '';
          }

          if (typeof value === 'number') {
            value = value.toFixed(2);
          }

          newAccount[key.key] = value;
        });
        return newAccount;
      })

    let data = actualAccountList;
    const workSheetName = `Partners`;
    const workBookName = workSheetName;

    const workbook = new Excel.Workbook();
    const fileName = workBookName;
    const worksheet = workbook.addWorksheet(workSheetName);
    worksheet.columns = actualKeys;
    worksheet.getRow(1).font = {bold: true};
    worksheet.columns.forEach(column => {
      column.width = column.header.length + 5;
      column.alignment = {horizontal: 'center'};
    });
    data.forEach(singleData => {
      worksheet.addRow(singleData);
    });
    worksheet.eachRow({includeEmpty: false}, row => {
      const currentCell = row._cells;
      currentCell.forEach(singleCell => {
        const cellAddress = singleCell._address;
        worksheet.getCell(cellAddress).border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });
    });
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.containerForFilters}>
        <Box></Box>
        <ButtonCustom
          onClick={handleExportToExcel}
          customType={buttonTypes.CREATE}
          text='export to excel'
        />
      </Box>
      <Box className={classes.containerForFilters}>
        <MultiFilterPanel
          list={uniqueValuesList}
          onChange={changeUniqueValues}
        />
        <InputCustom
          className={classes.input}
          value={searchValue}
          onChange={handleBlurInput}
        />
      </Box>

      <TableComponent
        columns={partnersPageColumn}
        rows={filteredList}
        deleteRowAction={openModalDeleteAction}
        editRowAction={openModalEditAccount}
        sort={sort}
        sortBy={handleSortBy}
        sortDirection={handleSortDirection}
      />
      <TaskButtonsPanel
        createButton
        createButtonName="Create Partner"
        createButtonAction={() => setShowModalCreatePartner(true)}
      />

      <ModalDelete
        open={showModalDelete}
        title={'Delete Partner'}
        description={'Are you sure you want to delete this agent?'}
        eventDelete={/*() => deletePartnerById(partnerId)*/closeModalDeleteAction}
        eventClose={closeModalDeleteAction}
      />

      <ModalPartner
        open={showModalCreatePartner}
        title={'Create Partner'}
        eventClose={closeModalCreatePartnerAction}
        eventApply={sendCreatePartner}
        partnerName={createPartnerName}
        partnerType={createPartnerType}
        terms={createPartnerTerms}
        location={createPartnerLocation}
        sharePercent={createPartnerSharePercent}
        setPartnerName={setCreatePartnerName}
        setPartnerType={setCreatePartnerType}
        setTerms={setCreatePartnerTerms}
        setLocation={setCreatePartnerLocation}
        setSharePercent={setCreatePartnerSharePercent}
      />

      <ModalPartner
        open={showModalEditPartner}
        title={'Edit Partner'}
        eventClose={closeModalEditPartnerAction}
        eventApply={sendEditPartner}
        partnerName={editPartnerName}
        partnerType={editPartnerType}
        terms={editPartnerTerms}
        location={editPartnerLocation}
        sharePercent={editPartnerSharePercent}
        setPartnerName={setEditPartnerName}
        setPartnerType={setEditPartnerType}
        setTerms={setEditPartnerTerms}
        setLocation={setEditPartnerLocation}
        setSharePercent={setEditPartnerSharePercent}
      />
    </Box>
  )
}

const mapStateToProps = state => ({
  partnersList: getPartnersListSelector(state),
  createPartnerName: getCreatePartnerNameSelector(state),
  createPartnerType: getCreatePartnerTypeSelector(state),
  createPartnerTerms: getCreatePartnerTermsSelector(state),
  createPartnerLocation: getCreatePartnerLocationSelector(state),
  createPartnerSharePercent: getCreatePartnerSharePercentSelector(state),
  editPartnerId: getEditPartnerIdSelector(state),
  editPartnerName: getEditPartnerNameSelector(state),
  editPartnerType: getEditPartnerTypeSelector(state),
  editPartnerTerms: getEditPartnerTermsSelector(state),
  editPartnerLocation: getEditPartnerLocationSelector(state),
  editPartnerSharePercent: getEditPartnerSharePercentSelector(state),
});

const mapDispatchToProps = {
  resetCreatePartner: resetCreatePartnerAction,
  setCreatePartnerName: setCreatePartnerNameAction,
  setCreatePartnerType: setCreatePartnerTypeAction,
  setCreatePartnerTerms: setCreatePartnerTermsAction,
  setCreatePartnerLocation: setCreatePartnerLocationAction,
  setCreatePartnerSharePercent: setCreatePartnerSharePercentAction,
  resetEditPartner: resetEditPartnerAction,
  setEditPartnerName: setEditPartnerNameAction,
  setEditPartnerType: setEditPartnerTypeAction,
  setEditPartnerTerms: setEditPartnerTermsAction,
  setEditPartnerLocation: setEditPartnerLocationAction,
  setEditPartnerSharePercent: setEditPartnerSharePercentAction,
}

export const PartnersPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnersPageContainer)
