import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { ButtonIconCustom } from '../../UIComponents/buttons';
import { iconTypes } from '../../UIComponents/buttons/ButtonIconCustom/constants';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const PanelEventButtons = ({
  id,
  deleteItem,
  restoreAction,
  editItem,
  infoItem,
  enableItem,
  disableItem,
  startItem,
  stopItem,
  startAction,
  stopAction,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.wrapper}>
      {
        restoreAction &&
        <Box className={classes.buttonContainer}>
          <ButtonIconCustom
            className={classes.buttonStyle}
            onClick={() => restoreAction(id)}
            customType={iconTypes.RESTORE}
          />
        </Box>
      }
      {
        infoItem &&
        <Box className={classes.buttonContainer}>
          <ButtonIconCustom
            className={classes.buttonStyle}
            onClick={infoItem ? () => infoItem(id) : null}
            customType={iconTypes.INFO}
          />
        </Box>
      }
      {
        editItem &&
        <Box className={classes.buttonContainer}>
          <ButtonIconCustom
            className={classes.buttonStyle}
            onClick={editItem ? () => editItem(id) : null}
            customType={iconTypes.EDIT}
          />
        </Box>
      }

      {
        startItem &&
        <Box className={classes.buttonContainer}>
          <ButtonIconCustom
            className={classes.buttonStyle}
            onClick={startAction ? () => startAction(id) : null}
            customType={iconTypes.START}
          />
        </Box>
      }

      {
        stopItem &&
        <Box className={classes.buttonContainer}>
          <ButtonIconCustom
            className={classes.buttonStyle}
            onClick={stopAction ? () => stopAction(id) : null}
            customType={iconTypes.STOP}
          />
        </Box>
      }

      {
        deleteItem &&
        <Box className={classes.buttonContainer}>
          <ButtonIconCustom
            className={classes.buttonStyle}
            onClick={deleteItem ? () => deleteItem(id) : null}
            customType={iconTypes.DELETE}
          />
        </Box>
      }

      {
        enableItem &&
        <Box className={classes.buttonContainer}>
          <ButtonIconCustom
            className={classes.buttonStyle}
            onClick={enableItem ? () => enableItem(id) : null}
            customType={iconTypes.ENABLE}
          />
        </Box>
      }

      {
        disableItem &&
        <Box className={classes.buttonContainer}>
          <ButtonIconCustom
            className={classes.buttonStyle}
            onClick={disableItem ? () => disableItem(id) : null}
            customType={iconTypes.DISABLE}
          />
        </Box>
      }
    </Box>
  )
}
