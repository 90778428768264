import { filterObjArray } from '../filters';

export const getUniqueValues = (data, arrayLines) => {
	if (data && arrayLines && data.length && arrayLines.length) {
		const result = [];
		arrayLines.forEach(elem => {
			const allValues = data.map(el => el[elem.dataKey]);
			const uniqueValues = [];
			allValues.forEach(item => {
				if (item && !uniqueValues.includes(item)) {
					uniqueValues.push(item);
				}
			});
			result.push({
				...elem,
				values: uniqueValues.sort(),
			})
		});

		return result;
	}
}

export const filterObjUniqueArray = (array, uniqueFiltersList) => {
	return array.filter(elem => {
		let result = true;
		uniqueFiltersList.forEach(item => {
			const val = elem[item.dataKey];
			const verifyArray = item.selectedValues.map(el => el.value);
			if (!verifyArray.includes(val)) {
				result = false;
			}
		})
		return result;
	})
}

export const getUniqueValuesList = (list, uniqueLines) => {
	if (list && list.length) {
		const uniqueValues = getUniqueValues(list, uniqueLines);
		return uniqueValues.map(elem => ({
			...elem,
			selectedValues: [],
			values: elem.values.map(item => ({
				name: String(item),
				value: item,
			}))
		}));
	}
	return [];
}

export const changeFilteredList = (list, searchValue, uniqueFiltersList, setFilteredList, excludeFilterList) => {
	const isUnique = uniqueFiltersList && uniqueFiltersList.length;
	let array = list;
	if (searchValue) {
		array = filterObjArray(array, searchValue, excludeFilterList)
	}
	if (isUnique) {
		array = filterObjUniqueArray(array, uniqueFiltersList);
	}
	if (!searchValue && !isUnique) {
		setFilteredList(list);
	} else {
		setFilteredList(array);
	}
}
