export const telegramUsersPageColumn = [
  {
    width: 1500,
    label: 'Nickname',
    dataKey: 'nickname',
  },
  {
    width: 1500,
    label: 'Telegram User Id',
    dataKey: 'telegramUserId',
  },
  {
    width: 1500,
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
];

export const telegramUsersCodesSort = [
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'code',
    sortType: 'string',
  },
  {
    dataKey: 'activated',
    sortType: 'string',
  },
];
