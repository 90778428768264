import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

export const getStyles = theme => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  paper: {
    backgroundColor: theme.mainStyles.mainBackgroundColor,
    flexGrow: 1,
    minHeight: 300,
    width: '100%',
    color: theme.mainStyles.mainColorText,
    border: `1px solid ${theme.mainStyles.mainColorText}`,
    overflow: 'hidden',
    '& .ReactVirtualized__Grid .body_row': {
      width: '100% !important'
    }
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    color: theme.mainStyles.mainColorText,
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    '&.MuiTableCell-root': {
      padding: 10,
      lineHeight: 1.3,
    },
    '&.row-color-red': {
      color: red[500],
    },
    '&.row-color-green': {
      color: green[500],
    },
    '&.row-background-color-red': {
      backgroundColor: 'rgb(98, 31, 50)',
    }
  },
  table: {
    backgroundColor: theme.mainStyles.mainBackgroundColor,
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
    },
    '& .buttons_container' : {
      padding: 0,
    },
    '& .ReactVirtualized__Grid__innerScrollContainer .body_row:nth-child(odd)': {
      backgroundColor: theme.mainStyles.tableOddRowBg,
    },
  },
  tableHeader: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1,
    color: theme.tableStyles.colorText,
    fill: theme.tableStyles.colorText,
    cursor: 'pointer',
    '& .ReactVirtualized__Table__sortableHeaderIcon': {
      marginLeft: 8,
    },
  },
  noClick: {
    cursor: 'initial',
  },
  link: {
    color: theme.mainStyles.mainColorLink,
  },
  statusActive: {
    color: theme.statusStyles.colorActive,
    fontWeight: 500,
  },
  statusError: {
    color: theme.statusStyles.colorError,
    fontWeight: 500,
  },
  statusPublic: {
    color: theme.statusStyles.colorPublic,
    fontWeight: 500,
  }
})
