import {
  RESET_ACCOUNT_FIGURES,
  SET_ACCOUNT_FIGURES_IS_CLOSED_WEEK,
  SET_ACCOUNT_FIGURES_TOTAL_WEEKLY_FIGURE,
  SET_ACCOUNT_FIGURES_ITEM_VALUE,
  SET_ACCOUNT_FIGURES_SHARE_PERCENT,
  SET_ACCOUNT_FIGURES_SUB_OWNER_PERCENT,
  SET_ACCOUNT_FIGURES_LIST,
  SET_ACCOUNT_FIGURES_SELECTED_DATE,
  SET_ACCOUNT_FIGURES_SELECTED_TYPE,
  RESET_ACCOUNT_FIGURES_TAB,
} from '../constants';

export const resetAccountFigures = () => ({
  type: RESET_ACCOUNT_FIGURES,
});

export const resetAccountFiguresTab = () => ({
  type: RESET_ACCOUNT_FIGURES_TAB,
});

export const setAccountFiguresList = accountsList => ({
  type: SET_ACCOUNT_FIGURES_LIST,
  payload: {
    accountsList,
  },
});

export const setAccountFigureIsClosedWeek = isWeekClosed => ({
  type: SET_ACCOUNT_FIGURES_IS_CLOSED_WEEK,
  payload: {
    isWeekClosed,
  },
});

export const setAccountFigureTotalWeeklyFigure = totalWeeklyFigure => ({
  type: SET_ACCOUNT_FIGURES_TOTAL_WEEKLY_FIGURE,
  payload: {
    totalWeeklyFigure,
  },
});

export const setAccountFiguresSelectedDate = selectedDate => ({
  type: SET_ACCOUNT_FIGURES_SELECTED_DATE,
  payload: {
    selectedDate,
  },
});

export const setAccountFiguresSelectedType = selectedType => ({
  type: SET_ACCOUNT_FIGURES_SELECTED_TYPE,
  payload: {
    selectedType,
  },
});

export const setAccountFiguresItemValue = ({ id, value }) => ({
  type: SET_ACCOUNT_FIGURES_ITEM_VALUE,
  payload: {
    id,
    value,
  },
});

export const setAccountFiguresItemSharePercent = ({ id, value }) => ({
  type: SET_ACCOUNT_FIGURES_SHARE_PERCENT,
  payload: {
    id,
    value,
  },
});

export const setAccountFiguresItemSubOwnerPercent = ({ id, value }) => ({
  type: SET_ACCOUNT_FIGURES_SUB_OWNER_PERCENT,
  payload: {
    id,
    value,
  },
});
