export const getStyles = theme => ({
  container: {
    position: 'relative',
    height: '100%',
    padding: '1rem 2rem 2rem',
    border: '1px #ffff',
    borderStyle: 'ridge',
    //boxShadow: '1px 1px 3px 1px rgb(239 239 239 / 20%), 0px 1px 1px 0px rgb(239 239 239 / 14%), 0px 1px 3px 0px rgb(239 239 239 / 12%)',
    backgroundColor: 'transparent',
    color: '#fff',
    
  },
  title: {
    width: '100%',
    margin: '0 auto 6rem 0',
    padding: '0'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // marginTop: '3rem',
    padding: '0 15%'
  }
})
