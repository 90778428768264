export const getStyles = (theme) => ({
  input: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      paddingTop: 10,
      paddingBottom: 10,
      color: theme.inputsStyles.colorText,
      backgroundColor: theme.inputsStyles.backgroundColor,
    },
    '& .MuiInputLabel-outlined': {
      color: theme.inputsStyles.labelColorText,
      top: -7,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      transform: 'translate(14px, 0px) scale(0.75)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.inputsStyles.borderColor,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.inputsStyles.borderColorHover,
    },
    '&:hover .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      opacity: 0.8,
    }
  },
})
