import {
  RESET_ACCOUNT_FIGURES,
  RESET_ACCOUNT_FIGURES_TAB,
  SET_ACCOUNT_FIGURES_LIST,
  SET_ACCOUNT_FIGURES_IS_CLOSED_WEEK,
  SET_ACCOUNT_FIGURES_SELECTED_DATE,
  SET_ACCOUNT_FIGURES_SELECTED_TYPE,
  SET_ACCOUNT_FIGURES_ITEM_VALUE,
  SET_ACCOUNT_FIGURES_SHARE_PERCENT,
  SET_ACCOUNT_FIGURES_SUB_OWNER_PERCENT,
  SET_ACCOUNT_FIGURES_TOTAL_WEEKLY_FIGURE,
} from '../constants';

const initialState = {
  isLoading: false,
  selectedDate: null,
  selectedType: null,
  accountsList: [],
  isWeekClosed: false,
  totalWeeklyFigure: 0,
}

export const accountFigures = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ACCOUNT_FIGURES:
      return initialState;
    case RESET_ACCOUNT_FIGURES_TAB:
      return {
        ...state,
        selectedType: null,
        accountsList: [],
      }
    case SET_ACCOUNT_FIGURES_LIST:
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    case SET_ACCOUNT_FIGURES_SELECTED_DATE:
    case SET_ACCOUNT_FIGURES_SELECTED_TYPE:
    case SET_ACCOUNT_FIGURES_IS_CLOSED_WEEK:
    case SET_ACCOUNT_FIGURES_TOTAL_WEEKLY_FIGURE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ACCOUNT_FIGURES_ITEM_VALUE:
      return {
        ...state,
        accountsList: state.accountsList.map(item => ({
          ...item,
          amount: item.id === action.payload.id
            ? {
              ...item.amount,
              value: action.payload.value,
            }
            : item.amount,
          sharePercent: item.type === 'HandBets' || item.type === 'PayPerHead' ?
            item.id === action.payload.id
              ? {
                ...item.sharePercent,
                amount: action.payload.value,
              }
              : item.sharePercent
            : item.sharePercent,
          subOwnerPercent: item.type === 'HandBets' || item.type === 'PayPerHead' ?
            item.id === action.payload.id
              ? {
                ...item.subOwnerPercent,
                amount: action.payload.value,
              }
              : item.subOwnerPercent
            : item.subOwnerPercent,
        }))
      }
    case SET_ACCOUNT_FIGURES_SHARE_PERCENT:
      return {
        ...state,
        accountsList: state.accountsList.map(item => ({
          ...item,
          sharePercent: item.id === action.payload.id
            ? {
              ...item.sharePercent,
              value: action.payload.value,
            }
            : item.sharePercent,
        }))
      }
    case SET_ACCOUNT_FIGURES_SUB_OWNER_PERCENT:
      return {
        ...state,
        accountsList: state.accountsList.map(item => ({
          ...item,
          subOwnerPercent: item.id === action.payload.id
            ? {
              ...item.subOwnerPercent,
              value: action.payload.value,
            }
            : item.subOwnerPercent,
        }))
      }
    default:
      return state;
  }
}
