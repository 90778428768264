import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';
import amber from '@material-ui/core/colors/amber';

export const darkTheme = createMuiTheme({
  mainStyles: {
    mainBackgroundColor: grey[900],
    mainWrapperBackgroundColor: grey[800],
    mainColorText: grey[100],
    mainColorLink: lightBlue[200],
    tableOddRowBg: '#333333',
  },
  inputsStyles: {
    colorText: grey[100],
    labelColorText: lightBlue[100],
    backgroundColor: grey[800],
    borderColor: grey[200],
    borderColorHover: grey[100],
  },
  SelectsStyles: {
    colorText: grey[100],
    colorCategoryText: lightBlue[500],
    labelColorText: lightBlue[100],
    backgroundColor: grey[800],
    backgroundColorLabel: grey[800],
    borderColor: grey[200],
    borderColorHover: grey[100],
    backgroundColorHover: blueGrey[600],
    backgroundColorItemSelected: blue[400],
  },
  buttonSubmitStyles: {
    buttonColor: blueGrey[50],
    backgroundColor: blueGrey[600],
    hoverBackgroundColor: blueGrey[700],
  },
  buttonCreateStyles: {
    buttonColor: grey[50],
    backgroundColor: green[600],
    hoverBackgroundColor: green[700],
  },
  buttonDefaultStyles: {
    buttonColor: grey[50],
    backgroundColor: grey[600],
    hoverBackgroundColor: grey[700],
  },
  buttonEditStyles: {
    buttonColor: grey[50],
    backgroundColor: lightBlue[800],
    hoverBackgroundColor: lightBlue[900],
  },
  buttonDeleteStyles: {
    buttonColor: grey[50],
    backgroundColor: red[600],
    hoverBackgroundColor: red[700],
  },
  buttonCancelStyles: {
    buttonColor: grey[50],
    backgroundColor: amber[800],
    hoverBackgroundColor: amber[900],
  },
  buttonPanelStyles: {
    buttonColor: grey[50],
    backgroundColor: green[800],
    hoverBackgroundColor: green[900],
  },
  navigations: {
    colorText: blue[100],
    hoverColorText: blue[400],
    borderColor: blue[200],
    hoverBorderColor: blue[400],
  },
  tableStyles: {
    colorText: blue[100],
  },
  templateElement: {
    colorText: blue[100],
  },
  statusStyles: {
    colorActive: green[400],
    colorError: red[400],
    colorPublic: blue[400],
  },

});
