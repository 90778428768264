import { createSelector } from 'reselect';

// Default selectors
const getNetworksSelector = state => state.networks;
const getCreateNetworkSelector = state => state.createNetwork;
const getEditNetworkSelector = state => state.editNetwork;

export const getNetworksListSelector = createSelector(
  getNetworksSelector,
  networks => networks.networksList
);

// Create Selectors
export const getCreateNetworkNameSelector = createSelector(
  getCreateNetworkSelector,
  createNetwork => createNetwork.name
);

// Edit Selectors
export const getEditNetworkIdSelector = createSelector(
  getEditNetworkSelector,
  editNetwork => editNetwork.id
);

export const getEditNetworkNameSelector = createSelector(
  getEditNetworkSelector,
  editNetwork => editNetwork.name
);
