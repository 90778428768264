import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { ModalText } from '../../UIComponents';

import { getStyles } from './styles';
import { getStatusItem } from './utils';

const useStyles = makeStyles(getStyles);

export const TelegramApproveStatus = ({
	approveMessage,
	status,
	feedback,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	const [ openModal, setOpenModal ] = useState(false);

	return (
		<>
			<Box
				onClick={() => setOpenModal(true)}
			>
				{status
					? getStatusItem({ classes, status })
					: ''
				}
			</Box>
			{openModal && (
				<ModalText
					open={openModal}
					title={status}
					text={approveMessage}
					textPreFormat={true}
					textTop={feedback}
					eventClose={() => setOpenModal(false)}
				/>
			)}
		</>
	)
}
