import { store } from '../../store';
import {
  getLocalStorageData,
} from '../../utils';

import {
  accountFiguresToAccountFiguresListParser,
  privateWeekFiguresParser,
  addAccountFigureToBackendParser,
  addWalletFigureParser,
  accountFigureParser,
  submitWeeklyFigureWeekToBackendParser,
} from './reformatters';
import {
  GET_WEEKLY_FIGURES_BY_TYPE_WEEK_YEAR,
  GET_WEEKLY_FIGURES_WALLETS_WEEK_YEAR,
  ADD_ACCOUNT_FIGURE,
  ADD_PRIVATE_WALLET_FIGURE,
  SUBMIT_WEEKLY_FIGURES_WEEK,
  DELETE_WEEKLY_FIGURES_WEEK, UPDATE_BOT_WEEKLY_FIGURE,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';
import {
  setAccountFiguresList,
  setAccountFiguresItemValue,
  setAccountFigureIsClosedWeek,
  setAccountFigureTotalWeeklyFigure,
} from '../../store';

export const accountFiguresForTable = async ({
  year,
  week,
  type,
}) => {
  await requestWrapper('AccountFiguresForTable', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_WEEKLY_FIGURES_BY_TYPE_WEEK_YEAR)
      .setBearerToken(token)
      .setVariables({
        weeklyFigureByWeekDto: {
          week,
          year,
          accountType: type,
        },
        skipUsername: type === 'HandBets',
        skipUrl: type !== 'Deposit' && type !== 'PayPerHead',
        skipShortAccountName: type !== 'Application',
        skipSiteType: type !== 'PayPerHead',
      })
      .build();

    const { getAllFiguresForAccountsByWeek } = data || null;

    if (!loading && !error && getAllFiguresForAccountsByWeek) {
      const reformattedAccountFigures = accountFiguresToAccountFiguresListParser(getAllFiguresForAccountsByWeek);
      store.dispatch(setAccountFigureIsClosedWeek(reformattedAccountFigures.isWeekClosed));
      store.dispatch(setAccountFigureTotalWeeklyFigure(reformattedAccountFigures.totalWeeklyFigure));
      store.dispatch(setAccountFiguresList(reformattedAccountFigures.accountFigures));
    } else {

    }
  });
}

export const updateBotFiguresForTable = async ({
  year,
  week,
  type,
}) => {
  await requestWrapper('UpdateBotWeeklyFigure', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(UPDATE_BOT_WEEKLY_FIGURE)
      .setBearerToken(token)
      .setVariables({
        payload: {
          year,
          week,
        }
      })
      .build();

    const { updateBotWeeklyFigure } = data || null;

    if (!loading && !error && updateBotWeeklyFigure) {
      console.log('updateBotWeeklyFigure', updateBotWeeklyFigure);
      await accountFiguresForTable({
        year,
        week,
        type,
      });
    } else {

    }
  });
}


export const privateWalletsForTable = async ({
  year,
  week,
  isPrivate,
}) => {
  await requestWrapper('PrivateWalletsForTable', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_WEEKLY_FIGURES_WALLETS_WEEK_YEAR)
      .setBearerToken(token)
      .setVariables({
        weeklyFiguresWalletByWeekDto: {
          week,
          year,
          isPrivate,
        },
      })
      .build();

    const { weeklyFiguresWallet } = data || null;

    if (!loading && !error && weeklyFiguresWallet) {
      const reformattedWeekFigures = privateWeekFiguresParser(weeklyFiguresWallet);
      store.dispatch(setAccountFiguresList(reformattedWeekFigures));
    } else {

    }
  });
}

export const addWeeklyFigure = async ({
  year,
  week,
  amount,
  accountId,
  type,
  sharePercent,
  subOwnerPercent,
}) => {
  await requestWrapper('AddWeeklyFigure', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(ADD_ACCOUNT_FIGURE)
      .setBearerToken(token)
      .setVariables({
        payload: addAccountFigureToBackendParser({
          year,
          week,
          amount,
          accountId,
          sharePercent,
          subOwnerPercent,
        })
      })
      .build();

    const { createWeeklyFigure } = data || null;

    if (!loading && !error && createWeeklyFigure?._id) {
      store.dispatch(setAccountFiguresItemValue(accountFigureParser(createWeeklyFigure)));
    } else {

    }
  });
}

export const confirmWeeklyFigureWeek = async ({
  year,
  week,
  accountType,
}) => {
  await requestWrapper('ConfirmWeeklyFigureWeek', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(SUBMIT_WEEKLY_FIGURES_WEEK)
      .setBearerToken(token)
      .setVariables({
        payload: submitWeeklyFigureWeekToBackendParser({
          year,
          week,
          accountType,
        })
      })
      .build();

    const { createPartnerWeeklyFigure } = data || null;

    if (!loading && !error && createPartnerWeeklyFigure) {
    } else {
    }
  });
}

export const deleteWeeklyFigureWeek = async ({
  year,
  week,
  accountType,
}) => {
  await requestWrapper('DeleteWeeklyFigureWeek', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(DELETE_WEEKLY_FIGURES_WEEK)
      .setBearerToken(token)
      .setVariables({
        payload: submitWeeklyFigureWeekToBackendParser({
          year,
          week,
          accountType,
        })
      })
      .build();

    const { deletePartnerWeeklyFigure } = data || null;

    if (!loading && !error && deletePartnerWeeklyFigure) {
    } else {
    }
  });
}

export const addWeeklyFigureWallet = async ({
  year,
  week,
  amount,
  walletId,
}) => {
  await requestWrapper('AddWeeklyFigureWallet', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(ADD_PRIVATE_WALLET_FIGURE)
      .setBearerToken(token)
      .setVariables({
        payload: addWalletFigureParser({
          year,
          week,
          amount,
          walletId,
        })
      })
      .build();

    const { createWeeklyFiguresWallet } = data || null;

    if (!loading && !error && createWeeklyFiguresWallet?._id) {
      store.dispatch(setAccountFiguresItemValue(accountFigureParser(createWeeklyFiguresWallet)));
    } else {

    }
  });
}
