export const getStyles = theme => ({
  container: {
    display: 'flex',
    marginBottom: 25,
    alignItems: 'center',
  },
  title: {
    marginRight: 15,
    fontWeight: 500,
    fontSize: 14,
  },
})
