import {
  SET_ACCOUNTS_LIST,
  RESET_ACCOUNTS,
  REMOVE_ACCOUNT_FROM_LIST,
  SET_ACCOUNT_ITEM_VALUE,
  SET_ACCOUNT_ITEM_DISABLED,
  SET_ACCOUNT_SUB_OWNER_ITEM_VALUE,
  START_STOP_UPDATE_ACCOUNT,
} from '../constants';

export const resetAccounts = () => ({
  type: RESET_ACCOUNTS,
});

export const setAccountsList = accountsList => ({
  type: SET_ACCOUNTS_LIST,
  payload: {
    accountsList,
  }
});

export const removeAccountItem = id => ({
  type: REMOVE_ACCOUNT_FROM_LIST,
  payload: {
    id,
  }
});

export const setAccountItemValue = ({ id, value }) => ({
  type: SET_ACCOUNT_ITEM_VALUE,
  payload: {
    id,
    value,
  },
});

export const setAccountSubOwnerItemValue = ({ id, value }) => ({
  type: SET_ACCOUNT_SUB_OWNER_ITEM_VALUE,
  payload: {
    id,
    value,
  },
});

export const setAccountItemDisabled = ({ id, disabled }) => ({
  type: SET_ACCOUNT_ITEM_DISABLED,
  payload: {
    id,
    disabled,
  },
});

export const startStopUpdateAccount = ({ id, date, stop, status }) => ({
  type: START_STOP_UPDATE_ACCOUNT,
  payload: {
    id,
    date,
    stop,
    status,
  },
})
