import { STATUS_MODAL_ADD_ITEM, STATUS_MODAL_REMOVE_ITEM } from '../constants';

const initialState = {
	statusList: [],
}

export const statusModal = (state = initialState, action) => {
	switch (action.type) {
		case STATUS_MODAL_ADD_ITEM:
			return {
				...state,
				statusList: [ ...state.statusList, action.payload.item],
			};
		case STATUS_MODAL_REMOVE_ITEM:
			return {
				...state,
				statusList: state.statusList.filter(el => el.id !== action.payload.id),
			}
		default:
			return state;
	}
}