export const getStyles = theme => ({
  wrapper: {
    color: theme.SelectsStyles.colorText,
    backgroundColor: theme.SelectsStyles.backgroundColor,
    '& .MuiListItem-root:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
    '& .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  wrapperIsCategory: {
    color: theme.SelectsStyles.colorText,
    backgroundColor: theme.SelectsStyles.backgroundColor,
    '& .MuiListItem-root:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
    '& .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: theme.SelectsStyles.backgroundColorHover,
    },
    '& .MuiMenuItem-root': {
      paddingLeft: '30px',
    },
    '& .MuiListItem-root.Mui-disabled': {
      paddingLeft: '16px',
      color: theme.SelectsStyles.colorCategoryText,
    }
  },
  formControl: {
    width: '100%',
    '& .MuiSelect-select': {
      paddingTop: '10px',
      paddingBottom: '10px',
      color: theme.SelectsStyles.colorText,
      backgroundColor: theme.SelectsStyles.backgroundColor,
    },
    '& .MuiSelect-icon': {
      color: theme.SelectsStyles.colorText,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.SelectsStyles.colorText,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.SelectsStyles.colorText,
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      borderColor: '#ff0000',
    },
    '& .MuiInputLabel-outlined': {
      color: theme.SelectsStyles.labelColorText,
      top: '-7px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      transform: 'translate(14px, 0px) scale(0.75)',
    },
    '& .MuiMenu-paper': {
      color: theme.SelectsStyles.colorText,
      backgroundColor: theme.SelectsStyles.backgroundColor,
    },
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'normal',
    },
    '& .MuiChip-root': {
      backgroundColor: theme.SelectsStyles.backgroundColor,
      margin: '2px',
      border: `1px solid ${theme.SelectsStyles.colorText}`,
      color: theme.SelectsStyles.colorText,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.SelectsStyles.colorText,
    },
  },
})
