export const partnersForTableParser = (partners) => partners?.map(partner => (
  {
    id: partner?._id,
    partnerName: partner?.name,
    partnerType: partner?.partnerType,
    terms: partner?.terms,
    location: partner?.location,
    sharePercent: partner?.sharePercent,
    action: {
      id: partner?._id,
    },
  }
));

export const partnersForSelectParser = partners => partners?.map(partner => (
  {
    id: partner?._id,
    name: partner?.name,
  }
));

export const addPartnerToBackendParser = (partner) => ({
  name: partner?.partnerName,
  partnerType: partner?.partnerType,
  terms: partner?.terms || undefined,
  location: partner?.location?.name,
  sharePercent: Number(partner?.sharePercent),
});

export const editPartnerToBackendParser = (partner) => ({
  _id: partner?.id,
  name: partner?.partnerName,
  partnerType: partner?.partnerType,
  terms: partner?.terms || undefined,
  location: partner?.location?.name,
  sharePercent: Number(partner?.sharePercent),
});

export const partnerToEditParser = (partner) => ({
  id: partner?._id,
  partnerName: partner?.name,
  partnerType: partner?.partnerType,
  terms: partner?.terms,
  location: { 
    id: partner?.location,
    name: partner?.location
  },
  sharePercent: partner?.sharePercent,
});
