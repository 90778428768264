import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { TableComponent } from '../../../components/TableComponent';
import {
  TaskButtonsPanel,
  InputCustom,
} from '../../../components/UIComponents';
import { walletsPageColumn, walletsPageSort } from './constants';
import {
  getCreateWalletTypeNameSelector,
  getCreateWalletTypeNotesSelector,
  getEditWalletTypeNameSelector,
  getEditWalletTypeIdSelector,
  getEditWalletTypeNotesSelector,
  getWalletTypesListSelector,
} from './selectors';
import {
  resetCreateWalletType as resetCreateWalletTypeAction,
  setCreateWalletTypeName as setCreateWalletTypeNameAction,
  setCreateWalletTypeNotes as setCreateWalletTypeNotesAction,
  resetEditWalletType as resetEditWalletTypeAction,
  setEditWalletTypeName as setEditWalletTypeNameAction,
  setEditWalletTypeNotes as setEditWalletTypeNotesAction,
} from '../../../store/actions';
import {
  ModalDelete,
  ModalWalletType,
} from '../../../components/UIComponents/modals';
import {
  walletTypesForTable,
  addWalletType,
  editWalletType,
  deleteWalletType,
  getForEditWalletType,
} from '../../../api';
import { sortEvent } from '../../../utils/sortingService';
import {
  filterObjArray,
  removeBlacklistedKeys,
} from '../../../utils';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const WalletTypesPageContainer = ({
  walletTypesList,
  createWalletTypeName,
  createWalletTypeNotes,
  editWalletTypeId,
  editWalletTypeName,
  editWalletTypeNotes,
  resetCreateWalletType,
  setCreateWalletTypeName,
  setCreateWalletTypeNotes,
  resetEditWalletType,
  setEditWalletTypeName,
  setEditWalletTypeNotes,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [walletTypeId, setWalletTypeId] = useState(null);
  const [showModalCreateWalletType, setShowModalCreateWalletType] = useState(false);
  const [showModalEditWalletType, setShowModalEditWalletType] = useState(false);

  const [ handleSortBy, setHandleSortBy ] = useState(false);
  const [ handleSortDirection, setHandleSortDirection ] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [filteredList, setFilteredList] = useState([]);

  const getWalletTypes = useCallback(async () =>
    await walletTypesForTable(),
  []);

  useEffect(() => {
    getWalletTypes();
  }, [getWalletTypes]);

  useEffect(() => {
    setFilteredList(searchValue
      ? filterObjArray(walletTypesList, searchValue, ['id', 'action'])
      : walletTypesList
    );
  }, [walletTypesList, searchValue]);

  useEffect(() => {
    const getForEdit = async (walletTypeId) => await getForEditWalletType(walletTypeId);

    if (showModalEditWalletType === true) {
      getForEdit(walletTypeId);
    }
  }, [walletTypeId, showModalEditWalletType]);

  const closeModalDeleteAction = () => setShowModalDelete(false);

  const openModalDeleteAction = id => {
    setShowModalDelete(true);
    setWalletTypeId(id);
  };

  const deleteWalletTypeById = id => {
    Promise.resolve(deleteWalletType(id))
      .then(setWalletTypeId(null))
      .then(closeModalDeleteAction());
  }

  const sendCreateWalletType = () => {
    Promise.resolve(addWalletType({
      name: createWalletTypeName,
      notes: createWalletTypeNotes,
    }))
      .then(closeModalCreateWalletTypeAction())
      .then(resetCreateWalletType());
  }

  const sendEditWalletType = () => {
    Promise.resolve(editWalletType({
      id: editWalletTypeId,
      name: editWalletTypeName,
      notes: editWalletTypeNotes,
    }))
      .then(closeModalEditWalletTypeAction());
  }

  const closeModalCreateWalletTypeAction = () => {
    setShowModalCreateWalletType(false);
    resetCreateWalletType();
  }

  const closeModalEditWalletTypeAction = () => {
    setShowModalEditWalletType(false);
    resetEditWalletType();
    setWalletTypeId(null);
  }

  const openModalEditAccount = id => {
    // const agent = walletsList.find(elem => elem.id === id);
    // handlerEditData(agent);
    setWalletTypeId(id);
    setShowModalEditWalletType(true);
  }

  const sort = sortEvent({
    handleSortBy,
    sortData: walletsPageSort,
    setHandleSortDirection,
    setHandleSortBy,
    list: filteredList,
  });

  const handleBlurInput = event => {
    const value = event.target.value;
    setSearchValue(value);

    setFilteredList(value
      ? filterObjArray(walletTypesList, value, ['id', 'action'])
      : walletTypesList
    );
  }

  return (
    <Box className={classes.container}>
      <InputCustom
        className={classes.input}
        value={searchValue}
        onChange={handleBlurInput}
      />

      <TableComponent
        columns={walletsPageColumn}
        rows={filteredList}
        deleteRowAction={openModalDeleteAction}
        editRowAction={openModalEditAccount}
        sort={sort}
        sortBy={handleSortBy}
        sortDirection={handleSortDirection}
      />
      <TaskButtonsPanel
        createButton
        createButtonName="Create Wallet Type"
        createButtonAction={() => setShowModalCreateWalletType(true)}
      />

      <ModalDelete
        open={showModalDelete}
        title={'Delete Wallet Type'}
        description={'Are you sure you want to delete this wallet type?'}
        eventDelete={() => deleteWalletTypeById(walletTypeId)}
        eventClose={closeModalDeleteAction}
      />

      <ModalWalletType
        open={showModalCreateWalletType}
        title={'Create Wallet Type'}
        eventClose={closeModalCreateWalletTypeAction}
        eventApply={sendCreateWalletType}
        name={createWalletTypeName}
        notes={createWalletTypeNotes}
        setName={setCreateWalletTypeName}
        setNotes={setCreateWalletTypeNotes}
      />

      <ModalWalletType
        open={showModalEditWalletType}
        title={'Edit Wallet Type'}
        eventClose={closeModalEditWalletTypeAction}
        eventApply={sendEditWalletType}
        name={editWalletTypeName}
        notes={editWalletTypeNotes}
        setName={setEditWalletTypeName}
        setNotes={setEditWalletTypeNotes}
      />
    </Box>
  )
}

const mapStateToProps = state => ({
  walletTypesList: getWalletTypesListSelector(state),
  createWalletTypeName: getCreateWalletTypeNameSelector(state),
  createWalletTypeNotes: getCreateWalletTypeNotesSelector(state),
  editWalletTypeId: getEditWalletTypeIdSelector(state),
  editWalletTypeName: getEditWalletTypeNameSelector(state),
  editWalletTypeNotes: getEditWalletTypeNotesSelector(state),
});

const mapDispatchToProps = {
  resetCreateWalletType: resetCreateWalletTypeAction,
  setCreateWalletTypeName: setCreateWalletTypeNameAction,
  setCreateWalletTypeNotes: setCreateWalletTypeNotesAction,
  resetEditWalletType: resetEditWalletTypeAction,
  setEditWalletTypeName: setEditWalletTypeNameAction,
  setEditWalletTypeNotes: setEditWalletTypeNotesAction,
}

export const WalletTypesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletTypesPageContainer);
