export const getAccountClassFromStatus = data => {
	switch(data) {
		case 'new':
			return 'row-color-green';
		case 'inActive':
			return 'row-color-red';
		case 'stop':
			return 'row-background-color-red';
		default:
			return null;
	}
}