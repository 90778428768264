import { createSelector } from 'reselect';

// Default Selectors
const getCreateWalletSelector = state => state.createWallet;
const getEditWalletSelector = state => state.editWallet;

const getWalletsSelector = state => state.wallets;

export const getWalletsListSelector = createSelector(
  getWalletsSelector,
  accounts => accounts.walletsList
);

// Create Selectors
export const getCreateWalletPartnerSelector = createSelector(
  getCreateWalletSelector,
  createWallet => createWallet.partner
);

export const getCreateWalletTypeSelector = createSelector(
  getCreateWalletSelector,
  createWallet => createWallet.type
);

export const getCreateWalletIsPrivateSelector = createSelector(
  getCreateWalletSelector,
  createWallet => createWallet.isPrivate
);

export const getCreateWalletNotesSelector = createSelector(
  getCreateWalletSelector,
  createWallet => createWallet.notes
);

export const getCreateWalletPartnersListSelector = createSelector(
  getCreateWalletSelector,
  createWallet => createWallet.partnersList
);

export const getCreateWalletTypesListSelector = createSelector(
  getCreateWalletSelector,
  createWallet => createWallet.typesList
);

// Edit Selectors
export const getEditWalletIdSelector = createSelector(
  getEditWalletSelector,
  editWallet => editWallet.id
);

export const getEditWalletPartnerSelector = createSelector(
  getEditWalletSelector,
  editWallet => editWallet.partner
);

export const getEditWalletTypeSelector = createSelector(
  getEditWalletSelector,
  editWallet => editWallet.type
);

export const getEditWalletIsPrivateSelector = createSelector(
  getEditWalletSelector,
  editWallet => editWallet.isPrivate
);

export const getEditWalletNotesSelector = createSelector(
  getEditWalletSelector,
  editWallet => editWallet.notes
);

export const getEditWalletPartnersListSelector = createSelector(
  getEditWalletSelector,
  editWallet => editWallet.partnersList
);

export const getEditWalletTypesListSelector = createSelector(
  getEditWalletSelector,
  editWallet => editWallet.typesList
);
