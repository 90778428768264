export const partnersTelegramCodesPageColumn = [
  {
    width: 1000,
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    width: 1000,
    label: 'Telegram Code',
    dataKey: 'code',
  },
  {
    width: 1000,
    label: 'Activated',
    dataKey: 'activated',
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
];

export const partnersTelegramCodesSort = [
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'code',
    sortType: 'string',
  },
  {
    dataKey: 'activated',
    sortType: 'string',
  },
];
