import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation Login {
    login {
      token
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation Logout {
    logout
  }
`;