import { gql } from '@apollo/client';

import { 
  PARTNER_SHORT_FRAGMENT, 
  PARTNER_WITH_WALLETS_FRAGMENT,
} from '../partners/constants';
import { WALLET_SHORT_FRAGMENT, /*WALLET_WITHOUT_NOTES_FRAGMENT*/ } from '../wallets/constants';

export const TRANSACTION_FRAGMENT = gql`
  ${PARTNER_SHORT_FRAGMENT}
  ${WALLET_SHORT_FRAGMENT}
  
  fragment CoreTransactionFields on Transaction {
    _id
    partnerSender {
      ...CorePartnerShortFields
    }
    walletSender {
      ...CoreWalletShortFields
    }
    partnerReceiver {
      ...CorePartnerShortFields
    }
    walletReceiver {
      ...CoreWalletShortFields
    }
    wallet {
      partner {
        name
      }
      ...CoreWalletShortFields
    }
    transactionType
    subType
    amount
    week
    year
    date
    createDateUTC
    notes
  }
`;

export const GET_TRANSACTIONS_FOR_TABLE = gql`
  ${TRANSACTION_FRAGMENT}
  query GetTransactionsForTable {
    transactionFindAll {
      ...CoreTransactionFields
    }
  }
`;

export const GET_TRANSACTIONS_FOR_TABLE_UNIQUE = gql`
  query GetTransactionsForTableUnique {
    transactionFindAllUnique {
      partnerReceivers{name}
      partnerSenders{name}
      walletSenders{partner{name}}
      walletReceivers{partner{name}}
      wallets{partner{name}}
      partners{name}
      transactionTypes
      years
      weeks
    }
  }
`;

export const GET_TRANSACTIONS_FOR_TABLE_SEPARATE = gql`
  ${PARTNER_SHORT_FRAGMENT}
  ${WALLET_SHORT_FRAGMENT}
  mutation GetTransactionsForTableSeparate($transactionType: String!) {
    transactionFindAllSeparate(transactionType: $transactionType) {
      _id
      partnerSender {
        ...CorePartnerShortFields
      }
      walletSender {
        ...CoreWalletShortFields
      }
      partnerReceiver {
        ...CorePartnerShortFields
      }
      walletReceiver {
        ...CoreWalletShortFields
      }
      wallet {
        partner {
          name
        }
        ...CoreWalletShortFields
      }
      transactionType
      subType
      amount
      week
      year
      date
      createDateUTC
      notes
    }
  }
`;

export const GET_TRANSACTIONS_FOR_TABLE_DATE_RANGE = gql`
  ${TRANSACTION_FRAGMENT}
  mutation GetTransactionsForTableDateRange($payload: GetAllTransactionsDateRangeDto!) {
    transactionFindAllDateRange(getAllTransactionsDateRangeDto: $payload) {
      ...CoreTransactionFields
    }
  }
`;

export const GET_TRANSACTIONS_FOR_JOIN_TABLE_DATE_RANGE = gql`
  ${TRANSACTION_FRAGMENT}
  mutation GetTransactionsForTableJoinTransactionsDateRange($payload: GetAllTransactionsDateRangeDto!) {
    getTransactionsForTableJoinTransactionsDateRange(getTransactionsForTableJoinTransactionsDateRangeDto: $payload) {
      ...CoreTransactionFields
    }
  }
`;
export const GET_TRANSACTIONS_FOR_TABLE_BACKUP = gql`
  ${TRANSACTION_FRAGMENT}
  query GetTransactionsForTableBackup {
    transactionBackupFindAll {
      ...CoreTransactionFields
    }
  }
`;

export const GET_PARTNERS_FOR_SELECT = gql`
  ${PARTNER_WITH_WALLETS_FRAGMENT}
  query GetPartnersForSelect {
    partners {
      ...CorePartnerWithWalletsFields
    }
  }
`;

export const ADD_TRANSACTION = gql`
  mutation AddTransaction($payload: CreateTransactionDto!) {
    createTransaction(createTransactionDto: $payload) {
      idReceiver
      idSender
    }
  }
`;

export const ADD_TRANSACTION_APPLICATION = gql`
  mutation AddTransaction($payload: CreateTransactionDto!) {
    createApplicationTransaction(createTransactionDto: $payload) {
      idReceiver
    }
  }
`;

export const ADD_TRANSACTION_DEPOSIT = gql`
  mutation AddTransaction($payload: CreateTransactionDto!) {
    createDepositTransaction(createTransactionDto: $payload) {
      idReceiver
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction($payload: UpdateTransactionDto!) {
    updateTransaction(updateTransactionDto: $payload) {
      _id
    }
  }
`;

export const DELETE_TRANSACTION = gql`
  mutation DeleteTransaction($_id: String!) {
    removeTransaction(_id: $_id) {
      _id
    }
  }
`;

export const DELETE_JOIN_TRANSACTIONS = gql`
  mutation MultiRemoveTransactions($idsList: [String!]!) {
    multiRemoveTransactions(idsList: $idsList) {
      _id
    }
  }
`;

export const PERMANENT_DELETE_TRANSACTION = gql`
  mutation PermanentDeleteTransaction($_id: String!) {
    permanentRemoveTransaction(_id: $_id) {
      _id
    }
  }
`;

export const PERMANENT_DELETE_ALL_BACKUP_TRANSACTIONS = gql`
  query PermanentDeleteAllBackupTransactions {
    permanentDeleteAllBackupTransactions
  }
`;

export const RESTORE_TRANSACTION = gql`
  mutation RestoreTransaction($_id: String!) {
    restoreTransaction(_id: $_id) {
      _id
    }
  }
`;
