export const getStyles = (theme) => ({
  wrapper: {
    width: '100%',
    backgroundColor: theme.inputsStyles.backgroundColor,
    '& .MuiOutlinedInput-input': {
      paddingTop: 10,
      paddingBottom: 10,
      color: theme.inputsStyles.colorText,
    },
    '& .MuiInputLabel-outlined': {
      color: theme.inputsStyles.labelColorText,
      top: -7,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      transform: 'translate(14px, 0px) scale(0.75)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.inputsStyles.borderColor,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.inputsStyles.borderColorHover,
    },
    '& .MuiIconButton-root': {
      color: theme.inputsStyles.labelColorText,
    }
  },
})
