import { createSelector } from 'reselect';

// Default Selectors
const getCreateAccountSelector = state => state.createAccount;
const getEditAccountSelector = state => state.editAccount;

const getAccountsSelector = state => state.accounts;
const getSiteTypesSelector = state => state.siteTypes;
const getPartnersSelector = state => state.partners;

export const getAccountsListSelector = createSelector(
  getAccountsSelector,
  accounts => accounts.accountsList
);

export const getSiteTypesListSelector = createSelector(
  getSiteTypesSelector,
  siteTypes => siteTypes.siteTypesList
);

export const getPartnersListSelector = createSelector(
  getPartnersSelector,
  partners => partners.partnersList
);

// Create Selectors
export const getCreateAccountSiteTypeSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.siteType
);

export const getCreateAccountPartnerSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.partner
);

export const getCreateAccountSubOwnerSelector = createSelector(
	getCreateAccountSelector,
	createAccount => createAccount.subOwner
);

export const getCreateAccountSubOwnerPercentSelector = createSelector(
	getCreateAccountSelector,
	createAccount => createAccount.subOwnerPercent
);

export const getCreateAccountShortAccountNameSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.shortAccountName
);

export const getCreateAccountUrlSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.url
);

export const getCreateAccountTypeSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.type
);

export const getCreateAccountSubTypeSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.subType
);

export const getCreateAccountUsernameSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.username
);

export const getCreateAccountPasswordSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.password
);

export const getCreateAccountSharePercentSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.sharePercent
);

export const getCreateAccountNotesSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.notes
);

export const getCreateAccountSiteTypesListSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.siteTypesList
);

export const getCreateAccountPartnersListSelector = createSelector(
  getCreateAccountSelector,
  createAccount => createAccount.partnersList
);

// Edit Selectors
export const getEditAccountIdSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.id
);

export const getEditAccountSiteTypeSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.siteType
);

export const getEditAccountPartnerSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.partner
);

export const getEditAccountSubOwnerSelector = createSelector(
	getEditAccountSelector,
	editAccount => editAccount.subOwner
);

export const getEditAccountSubOwnerPercentSelector = createSelector(
	getEditAccountSelector,
	editAccount => editAccount.subOwnerPercent
);

export const getEditAccountShortAccountNameSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.shortAccountName
);

export const getEditAccountUrlSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.url
);

export const getEditAccountTypeSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.type
);

export const getEditAccountSubTypeSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.subType
);

export const getEditAccountUsernameSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.username
);

export const getEditAccountPasswordSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.password
);

export const getEditAccountSharePercentSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.sharePercent
);

export const getEditAccountNotesSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.notes
);

export const getEditAccountSiteTypesListSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.siteTypesList
);

export const getEditAccountPartnersListSelector = createSelector(
  getEditAccountSelector,
  editAccount => editAccount.partnersList
);
