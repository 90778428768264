export const getStyles = theme => ({
  modalContent: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.mainStyles.mainBackgroundColor,
      color: theme.mainStyles.mainColorText,
    },
  },
  title: {
    padding: '20px 24px',
    '& .MuiTypography-root': {
      fontSize: '18px',
      color: theme.mainStyles.mainColorText,
      paddingRight: '50px',
    }
  },
  text: {
    color: theme.mainStyles.mainColorText,
  },
  formButtons: {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '24px',
  },
  buttonClose: {
    position: 'absolute',
    right: '6px',
    top: '10px',
    color: theme.mainStyles.mainColorText,
  },
})
