import green from '@material-ui/core/colors/green';

export const balancesReportsRows = (values) => (
  [
    {
      name: "Cash Balance",
      value: values?.cashBalance,
    },
    {
      name: "Online Balances",
      value: values?.onlineBalances,
    },
    {
      name: "App Balances",
      value: values?.appBalances,
    },
    {
      name: "PPH Balances",
      value: values?.agentBalances,
    },
    {
      name: "PPH Share %",
      value: values?.agentBalancesShare,
    },
    {
      name: "HandBets Balances",
      value: values?.partnerBalances,
    },
      {
          name: "Previous Carry",
          value: values?.previousCarry,
      },
    {
      name: "Bitcoin Harout",
      value: values?.bitcoinHarout,
    },
    {
      name: "Bitcoin Drew",
      value: values?.bitcoinDrew,
    },
    {
      name: "Bankroll Value",
      value: values?.bankrollValue,
      isColor: green[400],
    },
  ]
);
