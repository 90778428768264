import React from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const NamedPanel = ({
  types,
  title = 'Unknown',
  contentComponent = null,
  children = null
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={types}>
      <Card className={classes.container}>
        <CardHeader
          className={classes.title}
          title={title}
        />

        <CardContent
          className={classes.content}
          children={children || contentComponent}
        />
      </Card>
    </Box>
  )
}
