import React, { useState } from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { InputCustom } from '../../inputs';
import { ButtonCustom } from '../../buttons';
import { buttonTypes } from '../../buttons/ButtonCustom/constants';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const ModalWalletType = ({
  open,
  eventApply,
  eventClose,
  title,
  name,
  notes,
  setName,
  setNotes,
  disabledList = [],
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [nameError, setNameError] = useState(false);
  const [notesError, setNotesError] = useState(false);

  const validate = () => {
    let valid = true;
    if (!Boolean(name)) {
      valid = false;
      setNameError(true);
    }
    return valid;
  }

  const sendApplyData = () => {
    if (validate()) {
      eventApply();
    }
  }

  return (
    <Box
      component='div'
    >
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-create-title'
        className={classes.modalContent}
      >
        <Box
          component='div'
        >
          <IconButton
            aria-label="close"
            className={classes.buttonClose}
            onClick={eventClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            id='alert-dialog-create-title'
            className={classes.title}
          >
            {title ? title : 'Create'}
          </DialogTitle>
          <DialogContent>

            <Box className={classes.rowWrapper}>
              <Box className={classes.colWrapper_50}>
                <InputCustom
                  label='Name'
                  error={nameError}
                  value={name}
                  onChange={e => {
                    setName(e.target.value);
                    setNameError(false);
                  }}
                  disabled={disabledList.find(el => el === 'Name')}
                />
              </Box>
            </Box>

            <Box
              className={
                clsx(
                  classes.rowWrapper,
                  classes.rowTopWrapper
                )
              }
            >
              <Box
                component='div'
                className={classes.colWrapper_100}
              >
                <InputCustom
                  label='Notes'
                  error={notesError}
                  value={notes}
                  onChange={e => {
                    setNotes(e.target.value);
                    setNotesError(false);
                  }}
                  disabled={disabledList.find(el => el === 'Notes')}
                />
              </Box>
            </Box>

          </DialogContent>
          <DialogActions
            className={classes.formButtons}
          >
            <ButtonCustom
              onClick={eventClose}
              customType={buttonTypes.CANCEL}
            />

            <ButtonCustom
              autoFocus
              onClick={sendApplyData}
              customType={buttonTypes.CREATE}
              text='Apply'
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
