import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { Navigation } from '../Navigation';
import { ButtonIconCustom } from '../UIComponents/buttons';
import { iconTypes } from '../UIComponents/buttons/ButtonIconCustom/constants';
import { logout } from '../../api';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const Header = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleClickLogOut = () => {
    // As we were talking with Valery there is should not be logic like delete token on front-end and etc
    // store.dispatch(setAuthenticated(false));
    // setLocalStorageData('token', null);
    logout().then(() => Promise.resolve());
  }

  return(
    <Box
      component="header"
      className={classes.header}
    >
      <Navigation />
      <Box>
        <ButtonIconCustom
          customType={iconTypes.LOGOUT}
          onClick={handleClickLogOut}
        />
      </Box>
    </Box>
  )
};
