import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';


import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const ModalLoaderGlobal = ({
  open,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-spiner-title'
        aria-describedby='alert-dialog-spiner-description'
        className={classes.modalContent}
        style={{
          zIndex: 3000,
        }}
      >
        <CircularProgress
          style={{
            width: '100px',
            height: '100px',
            color: '#bbdefb',
          }}
        />
      </Dialog>
    </Box>
  );
}
