import { numberToDollar } from '../../utils/number'
import moment from 'moment';

export const accountFiguresToAccountFiguresListParser = accountFigures => {
  const isNotClosed = !accountFigures?.find(figure => figure?.isClosed);

  return {
    accountFigures: accountFigures?.map(accountFigure => (
      {
        id: accountFigure?.account?._id,
        type: accountFigure?.account?.type,
        partnerName: accountFigure?.account?.partner?.name,
        password: accountFigure?.account?.password,
        amount: isNotClosed
          ? {
              id: accountFigure?.account?._id,
              value: (accountFigure?.amount || accountFigure?.amount === 0)
                ? String(accountFigure?.amount)
                : null,
              week: accountFigure?.week || null,
              year: accountFigure?.year || null,
            }
          : (accountFigure?.amount || accountFigure?.amount === 0)
            ? String(accountFigure?.amount)
            : null,
        sharePercent: (accountFigure?.account?.type === 'HandBets' || accountFigure?.account?.type === 'PayPerHead') && isNotClosed
          ? {
              id: accountFigure?.account?._id,
              value: (accountFigure?.sharePercent || accountFigure?.sharePercent === 0)
                ? String(accountFigure?.sharePercent)
                : String(accountFigure?.account?.sharePercent),
              amount: (accountFigure?.amount || accountFigure?.amount === 0)
                ? String(accountFigure?.amount)
                : null,
              week: accountFigure?.week || null,
              year: accountFigure?.year || null,
            }
          : accountFigure?.sharePercent || accountFigure?.account?.sharePercent,
        username: accountFigure?.account?.username,
        url: accountFigure?.account?.url,
        shortAccountName: accountFigure?.account?.shortAccountName,
        siteTypeName: accountFigure?.account?.siteType?.name,
        subOwnerName: accountFigure?.account?.subOwner?.name,
        subOwnerPercent: (accountFigure?.account?.type === 'HandBets' || accountFigure?.account?.type === 'PayPerHead') && isNotClosed
          ? {
              id: accountFigure?.account?._id,
              value: (accountFigure?.subOwnerPercent || accountFigure?.subOwnerPercent === 0)
                ? String(accountFigure?.subOwnerPercent)
                : (accountFigure?.account?.subOwnerPercent || accountFigure?.account?.subOwnerPercent === 0)
                  ? String(accountFigure?.account?.subOwnerPercent)
                  : accountFigure?.account?.subOwner?.name
                    ? null
                    : 'disabled',
              amount: (accountFigure?.amount || accountFigure?.amount === 0)
                ? String(accountFigure?.amount)
                : null,
              week: accountFigure?.week || null,
              year: accountFigure?.year || null,
            }
          : accountFigure?.subOwnerPercent || accountFigure?.account?.subOwnerPercent,
        result: accountFigure?.botWeeklyFigure?.result ?? null,
        volume: accountFigure?.botWeeklyFigure?.volume ?? null,
        figureUpdate: accountFigure?.botWeeklyFigure?.figure_update
          ? moment(accountFigure?.botWeeklyFigure?.figure_update).format('MMM Do YY')
          : null,
        botWeeklyFigure: accountFigure?.botWeeklyFigure
          ? {
              ...accountFigure?.botWeeklyFigure,
              figure_update: moment(accountFigure?.botWeeklyFigure?.figure_update).format('MMM Do YY')
            }
          : null,
        notes: accountFigure?.account?.notes,
      }
    )),
    isWeekClosed: !!accountFigures?.find(figure => figure?.isClosed),
    totalWeeklyFigure: numberToDollar(accountFigures?.reduce((prev, current) => prev + current?.amount, 0)),
  };
};

export const privateWeekFiguresParser = (privateWeekFigures) =>
  privateWeekFigures?.map(item => (
    {
      id: item?.wallet?._id,
      type: item?.wallet?.type?.name,
      partnerName: item?.wallet?.partner?.name,
      weeklyFigure: item?.transactionsAmount,
      amount: {
        id: item?.wallet?._id,
        value: (item?.amount || item?.amount === 0)
          ? String(item?.amount)
          : null,
        week: item?.week || null,
        year: item?.year || null,
      },
    }
  ));

export const addAccountFigureToBackendParser = accountFigure => {
  const obj = {
    year: Number(accountFigure?.year),
    week: Number(accountFigure?.week),
    amount: (accountFigure?.amount || accountFigure?.amount === 0)
      ? Number(accountFigure?.amount)
      : null,
    account: accountFigure?.accountId,
    sharePercent: accountFigure?.sharePercent
      ? Number(accountFigure?.sharePercent)
      : undefined,
    subOwnerPercent: accountFigure?.subOwnerPercent
      ? Number(accountFigure?.subOwnerPercent)
      : undefined,
  }

  for (const i in obj) {
    if (obj[i] === undefined) {
      delete obj[i];
    }
  }

  return obj;
}

export const submitWeeklyFigureWeekToBackendParser = submitWeek => (
  {
    year: Number(submitWeek?.year),
    week: Number(submitWeek?.week),
    accountType: submitWeek?.accountType,
  }
);

export const addWalletFigureParser = weeklyFigure => (
  {
    year: Number(weeklyFigure?.year),
    week: Number(weeklyFigure?.week),
    amount: (weeklyFigure?.amount || weeklyFigure?.amount === 0)
      ? Number(weeklyFigure?.amount)
      : null,
    wallet: weeklyFigure?.walletId,
  }
);

export const accountFigureParser = item => (
  {
    id: item?._id,
    value: item?.amount,
    year: item?.year,
    week: item?.week,
  }
);
