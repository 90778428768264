export const iconTypes = {
  DEFAULT: 'DEFAULT',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  INFO: 'INFO',
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
  LOGOUT: 'LOGOUT',
  RESTORE: 'RESTORE',
  START: 'START',
  STOP: 'STOP',
}
