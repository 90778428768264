import { reportPageConst } from '../constants';

export const excludeKeys = [
  'walletTransactions',
  'telegram',
  'telegramMessage',
  'partnerApprove',
];

export const reformatDataKeys = [
  {
    name: 'confirmed',
    value: 'checked',
  },
];

const agentReportColumn = [
  {
    width: 200,
    label: 'User Name',
    dataKey: 'userName',
  },
  {
    width: 200,
    label: 'Url',
    dataKey: 'url',
  },
  {
    width: 200,
    label: 'Password',
    dataKey: 'password',
  },
  {
    width: 300,
    label: 'Type',
    dataKey: 'subType',
  },
  {
    width: 200,
    label: 'Week',
    dataKey: 'week',
  },
  {
    width: 200,
    label: 'Year',
    dataKey: 'year',
  },
  {
    width: 200,
    label: 'Amount',
    dataKey: 'amount',
    isDollarHandler: true,
  },
  {
    width: 200,
    label: 'Sub Owner',
    dataKey: 'subOwnerName',
  },
  {
    width: 200,
    label: 'Sub Own %',
    dataKey: 'subOwnerPercent',
  },
  {
    width: 200,
    label: 'Sub Own Amt',
    dataKey: 'subOwnerAmount',
    isDollarHandler: true,
  },
  {
    width: 200,
    label: 'Redline %',
    dataKey: 'redlinePercent',
  },
  {
    width: 200,
    label: 'Share %',
    dataKey: 'sharePercent',
  },
]

const walletReportColumn = [
  {
    width: 400,
    label: 'Sender',
    dataKey: 'senderName',
  },
  {
    width: 400,
    label: 'Sender Wallet',
    dataKey: 'senderWalletType',
  },
  {
    width: 400,
    label: 'Receiver',
    dataKey: 'receiverName',
  },
  {
    width: 400,
    label: 'Receiver Wallet',
    dataKey: 'receiverWalletType',
  },
  {
    width: 600,
    label: 'Affects On',
    dataKey: 'transactionAffectsOn',
  },
  {
    width: 350,
    label: 'Type',
    dataKey: 'transactionType',
  },
  {
    width: 450,
    label: 'Amount',
    dataKey: 'amount',
    isDollarHandler: true,
  },
  {
    width: 200,
    label: 'Week',
    dataKey: 'week',
  },
  {
    width: 200,
    label: 'Year',
    dataKey: 'year',
  },
  {
    width: 350,
    label: 'Date',
    dataKey: 'date',
  },
  {
    width: 700,
    label: 'Create Date',
    dataKey: 'createDate',
  },
  {
    width: 500,
    label: 'SubType',
    dataKey: 'subType',
  },
  {
    width: 800,
    label: 'Notes',
    dataKey: 'notes',
  }
]

export const getReportColumn = type => {
  switch (type) {
    case reportPageConst.AGENTS.TYPE:
      return agentReportColumn;
    case reportPageConst.WALLETS.TYPE:
      return walletReportColumn;
    default:
      return [];
  }
}

const agentReportSort = [
  {
    sortType: 'string',
    dataKey: 'userName',
  },
  {
    sortType: 'string',
    dataKey: 'url',
  },
  {
    sortType: 'string',
    dataKey: 'password',
  },
  {
    sortType: 'number',
    dataKey: 'sharePercent',
  },
  {
    sortType: 'number',
    dataKey: 'week',
  },
  {
    sortType: 'number',
    dataKey: 'year',
  },
  {
    sortType: 'number',
    dataKey: 'amount',
  },
];

const walletReportSort = [
  {
    sortType: 'string',
    dataKey: 'senderName',
  },
  {
    sortType: 'string',
    dataKey: 'senderWalletType',
  },
  {
    sortType: 'string',
    dataKey: 'receiverName',
  },
  {
    sortType: 'string',
    dataKey: 'receiverWalletType',
  },
  {
    sortType: 'string',
    dataKey: 'transactionAffectsOn',
  },
  {
    sortType: 'string',
    dataKey: 'transactionType',
  },
  {
    sortType: 'number',
    dataKey: 'amount',
  },
  {
    sortType: 'number',
    dataKey: 'week',
  },
  {
    sortType: 'number',
    dataKey: 'year',
  },
  {
    sortType: 'string',
    dataKey: 'date',
  },
  {
    sortType: 'string',
    dataKey: 'createDate',
  },
  {
    sortType: 'string',
    dataKey: 'subType',
  },
  {
    sortType: 'string',
    dataKey: 'notes',
  }
]

export const getReportSort = type => {
  switch (type) {
    case reportPageConst.AGENTS.TYPE:
      return agentReportSort;
    case reportPageConst.WALLETS.TYPE:
      return walletReportSort;
    default:
      return [];
  }
}


const uniqueLines = [
  {
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
]

const uniqueWalletLines = [
  {
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
  {
    label: 'Wallet Type',
    dataKey: 'type',
  },
]

export const getReportUniqueLines = type => {
  switch (type) {
    case reportPageConst.AGENTS.TYPE:
    case reportPageConst.PARTNERS.TYPE:
      return uniqueLines;
    case reportPageConst.WALLETS.TYPE:
      return uniqueWalletLines;
    default:
      return [];
  }
}
