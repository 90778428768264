import {
  RESET_CREATE_WALLET_TYPE,
  SET_CREATE_WALLET_TYPE_NAME,
  SET_CREATE_WALLET_TYPE_NOTES,
} from '../../constants';

export const resetCreateWalletType = () => ({
  type: RESET_CREATE_WALLET_TYPE,
});

export const setCreateWalletTypeName = name => ({
  type: SET_CREATE_WALLET_TYPE_NAME,
  payload: {
    name,
  },
});

export const setCreateWalletTypeNotes = notes => ({
  type: SET_CREATE_WALLET_TYPE_NOTES,
  payload: {
    notes,
  },
});
