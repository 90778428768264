import { store } from '../../store';
import { setReportsList, setReportsTotalShareAmount } from '../../store';
import { agentsPartnersReformat } from './reformatters';

import { getLocalStorageData } from '../../utils';
import {
  GET_AGENTS_PARTNERS_REPORTS_FOR_TABLE,
  UPDATE_PARTNER_WEEKLY_FIGURE,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';

export const getAgentsPartnersReports = async ({
  accountType,
  week,
  year,
}) => {
  await requestWrapper('GetAgentsPartnersReports', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_AGENTS_PARTNERS_REPORTS_FOR_TABLE)
      .setBearerToken(token)
      .setVariables({
        getAgentsPartnerDto: {
          accountType,
          week,
          year,
        },
      })
      .build();

    if (!loading && !error && data) {
      const agentsReports = agentsPartnersReformat({
        year,
        week,
        list: data?.agentsPartners,
      });
      store.dispatch(setReportsList(agentsReports.list));
      store.dispatch(setReportsTotalShareAmount(agentsReports?.totalShareAmount));
    } else {

    }
  });
}

export const updateAgentsPartnersReport = async ({
  accountType,
  id,
  confirmed,
  week,
  year,
}) => {
  await requestWrapper('UpdateAgentsPartnersReports', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(UPDATE_PARTNER_WEEKLY_FIGURE)
      .setBearerToken(token)
      .setVariables({
        createReportsConfirmationInput: {
          partner_id: id,
          accountType,
          confirmed,
          week,
          year,
        },
      })
      .build();

    if (!loading && !error && data) {

    } else {

    }
  });
}
