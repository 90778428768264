export const getStyles = theme => ({
  wrapper: {
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    borderRadius: 3,
    display: 'flex',
    height: 40,
  },
  container: {
    maxWidth: 100,
    width: '100%',
    '& .MuiSelect-select': {
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 28,
    }
  },
});
