import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';

export const getStyles = theme => ({
  DEFAULT: {
    color: teal[600],
    '&:hover': {
      color: teal[800],
    }
  },
  DELETE: {
    color: red[800],
    '&:hover': {
      color: red[600],
    }
  },
  EDIT: {
    color: lightBlue[800],
    '&:hover': {
      color: lightBlue[900],
    }
  },
  INFO: {
    color: amber[200],
    '&:hover': {
      color: amber[400],
    }
  },
  ENABLE: {
    color: green[800],
    '&:hover': {
      color: green[900],
    }
  },
  DISABLE: {
    color: yellow[800],
    '&:hover': {
      color: yellow[600],
    }
  },
  LOGOUT: {
    color: grey[400],
    '&:hover': {
      color: grey[200],
    }
  },
  RESTORE: {
    color: green[600],
    '&:hover': {
      color: green[700],
    }
  },
  START: {
    color: green[600],
    '&:hover': {
      color: green[700],
    }
  },
  STOP: {
    color: red[600],
    '&:hover': {
      color: red[700],
    }
  },
})
