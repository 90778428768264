import { store } from '../../store';
import { setSettingsIsAuthenticated } from '../../store';

import {
  getLocalStorageData,
  setLocalStorageData,
} from '../../utils';
import {
  LOGIN_USER,
  LOGOUT_USER,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';

// export class Authentication {
  export async function login(username, password) {
    await requestWrapper('Login', async () => {
      const encodedCredentials = btoa(`${username}:${password}`);
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
      .mutate(LOGIN_USER)
      .setBasicAuth(encodedCredentials)
      .build();
      
      const { login: { token } } = data || null;

      if (!loading && !error && token) {
        setLocalStorageData('token', token || null);
        store.dispatch(setSettingsIsAuthenticated(true));
      } else {
        store.dispatch(setSettingsIsAuthenticated(false));
      }
    });
  }

  export const relogin = () => {

  }

  export async function logout() {
    await requestWrapper('Logout', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
      .mutate(LOGOUT_USER)
      .setBearerToken(token)
      .build();

      const { logout } = data;

      if (!loading && !error && logout) {
        store.dispatch(setSettingsIsAuthenticated(false));
        setLocalStorageData('token', null);
      }
    });
  }
// }
