export const statusConstants = {
  STOP: 'STOP',
  ACTIVE: 'ACTIVE',
  ERROR: 'ERROR',
  PUBLIC: 'PUBLIC',
  ACTION: 'action',
  OFFLINE: 'OFFLINE',
}

export const SortingDirection = {
  ASC: "ASC",
  DESC: "DESC"
};

export const INFO_BUTTON = 'INFO_BUTTON';
