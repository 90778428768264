import { partnersToAccountPartnersListParser } from '../accounts/reformatters';

export const walletsForTableToParser = wallets => wallets?.map(wallet => (
  {
    id: wallet?._id,
    partnerId: wallet?.partner?._id,
    partnerName: wallet?.partner?.name,
    typeId: wallet?.type?._id,
    typeName: wallet?.type?.name,
    isPrivate: wallet?.isPrivate,
    notes: wallet?.notes,
    action: {
      id: wallet?._id,
    },
  }
));

export const walletToBackendWalletParser = wallet => ({
  partner: wallet?.partner?.id,
  type: wallet?.type?.id,
  isPrivate: wallet?.isPrivate,
  notes: wallet?.notes,
});

export const editWalletToBackendWalletParser = wallet => ({
  _id: wallet?.id,
  partner: wallet?.partner?.id,
  type: wallet?.type?.id,
  isPrivate: wallet?.isPrivate,
  notes: wallet?.notes,
});

export const dataToEditWalletParser = data => ({
  id: data?.findOneWallet?._id,
  notes: data?.findOneWallet?.notes,
  isPrivate: data?.findOneWallet?.isPrivate,
  partner: {
    id: data?.findOneWallet?.partner?._id,
    name: data?.findOneWallet?.partner?.name,
  },
  type: {
    id: data?.findOneWallet?.type?._id,
    name: data?.findOneWallet?.type?.name,
  },
  partnersList: partnersToAccountPartnersListParser(data?.partners),
  typesList: walletTypesToWalletTypesListParser(data?.findAllWalletTypes),
});

export const walletTypesToWalletTypesListParser = types => types?.map(type => (
  {
    id: type?._id,
    name: type?.name,
  }
));
