import {
  RESET_CREATE_TRANSACTION,
  SET_CREATE_TRANSACTION,
  SET_CREATE_TRANSACTION_PARTNER_SENDER,
  SET_CREATE_TRANSACTION_PARTNER_SENDER_WALLET,
  SET_CREATE_TRANSACTION_PARTNER_RECEIVER,
  SET_CREATE_TRANSACTION_PARTNER_RECEIVER_WALLET,
  SET_CREATE_TRANSACTION_AMOUNT,
  SET_CREATE_TRANSACTION_SUB_TYPE,
  SET_CREATE_TRANSACTION_PARTNERS_LIST,
  SET_CREATE_TRANSACTION_SELECTED_DATE,
  SET_CREATE_TRANSACTION_NOTES,
} from '../constants';

export const resetCreateTransaction = () => ({
  type: RESET_CREATE_TRANSACTION,
});

export const setCreateTransaction = transaction => ({
  type: SET_CREATE_TRANSACTION,
  payload: {
    transaction,
  },
});

export const setCreateTransactionPartnerSender = partnerSender => ({
  type: SET_CREATE_TRANSACTION_PARTNER_SENDER,
  payload: {
    partnerSender,
  },
});

export const setCreateTransactionSelectedDate = selectedDate => ({
  type: SET_CREATE_TRANSACTION_SELECTED_DATE,
  payload: {
    selectedDate,
  },
});

export const setCreateTransactionNotes = notes => ({
  type: SET_CREATE_TRANSACTION_NOTES,
  payload: {
    notes,
  },
});

export const setCreateTransactionPartnerSenderWallet = partnerSenderWallet => ({
  type: SET_CREATE_TRANSACTION_PARTNER_SENDER_WALLET,
  payload: {
    partnerSenderWallet,
  },
});

export const setCreateTransactionPartnerReceiver = partnerReceiver => ({
  type: SET_CREATE_TRANSACTION_PARTNER_RECEIVER,
  payload: {
    partnerReceiver,
  },
});

export const setCreateTransactionPartnerReceiverWallet = partnerReceiverWallet => ({
  type: SET_CREATE_TRANSACTION_PARTNER_RECEIVER_WALLET,
  payload: {
    partnerReceiverWallet,
  },
});

export const setCreateTransactionSubType = subType => ({
  type: SET_CREATE_TRANSACTION_SUB_TYPE,
  payload: {
    subType,
  },
});

export const setCreateTransactionAmount = amount => ({
  type: SET_CREATE_TRANSACTION_AMOUNT,
  payload: {
    amount,
  },
});

export const setCreateTransactionPartnersList = partnersList => ({
  type: SET_CREATE_TRANSACTION_PARTNERS_LIST,
  payload: {
    partnersList,
  },
});
