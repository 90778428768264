import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { ButtonCustom } from '../../buttons';
import { buttonTypes } from '../../buttons/ButtonCustom/constants';
import { SelectMultipleCustom } from '../../selects';
import { InputCustom } from '../../inputs';
import { WeekSwitcher } from '../../../WeekSwitcher';

import { getStyles } from './styles';
import moment from 'moment';

const useStyles = makeStyles(getStyles);

export const ModalPayment = ({
	open,
	eventClose,
	title,
	name,
	accountsList,
	partnersList,
	account,
	amount,
	partnerReceiver,
	partnerReceiverWallet,
	notes,
	selectedDate,
	setAccount,
	setAmount,
	setPartnerReceiver,
	setPartnerReceiverWallet,
	setNotes,
	setSelectedDate,
	eventApply,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	const [accountError, setAccountError] = useState(false);
	const [amountError, setAmountError] = useState(false);
	const [partnerReceiverError, setPartnerReceiverError] = useState(false);
	const [partnerReceiverWalletError, setPartnerReceiverWalletError] = useState(false);

	useEffect(() => {
		const today = moment();
		const lastDay = today.clone().weekday(6);
		setSelectedDate(lastDay);
	}, []);

	const handleChangeAccount = elem => {
		const selectedAccount = accountsList?.find(el => el?.id === elem?.id)
		setAccount(selectedAccount);
		setNotes(`Transaction for ${selectedAccount?.name} (${selectedAccount?.username})`);
	}

	const handleChangePartnerReceiver = elem => {
		const element = partnersList?.find(el => el?.id === elem?.id);
		setPartnerReceiver(element);
		setPartnerReceiverWallet(null);
	}

	const handleChangePartnerReceiverWallet = elem => {
		const receiverWallet = partnersList
			?.find(partner => partner?.id === partnerReceiver?.id)
			?.wallets
			?.find(wallet => wallet?.id === elem?.id);

		setPartnerReceiverWallet(receiverWallet);
	}

	const validate = () => {
		let valid = true;
		if (!Boolean(account)) {
			valid = false;
			setAccountError(true);
		}
		if (!Boolean(partnerReceiver)) {
			valid = false;
			setPartnerReceiverError(true);
		}
		if (!Boolean(partnerReceiverWallet)) {
			valid = false;
			setPartnerReceiverWalletError(true);
		}
		if (!Boolean(amount)) {
			valid = false;
			setAmountError(true);
		}
		if (!Boolean(selectedDate)) {
			valid = false;
		}

		return valid;
	}

	const sendApplyData = () => {
		if (validate()) {
			eventApply();
		}
	}

	return (
		<Box>
			<Dialog
				open={open}
				aria-labelledby='alert-dialog-payment-title'
				className={classes.modalContent}
			>
				<Box>
					<IconButton
						aria-label="close"
						className={classes.buttonClose}
						onClick={eventClose}
					>
						<CloseIcon />
					</IconButton>
					<DialogTitle
						id='alert-dialog-create-title'
						className={classes.title}
					>
						{title ? title : 'Create'}
					</DialogTitle>

					<DialogContent>

						<Box className={classes.rowWrapper}>
							<Box className={classes.colWrapper_100}>
								<SelectMultipleCustom
									label={name}
									placeholder={name}
									onChange={(e, elem) => {
										handleChangeAccount(elem);
										setAccountError(false);
									}}
									value={account}
									error={accountError}
									disableClearable
									list={accountsList}
									optionTitle={['name', 'username']}
								/>
							</Box>
						</Box>
						<Box className={classes.rowWrapper}>
							<Box className={classes.colWrapper_100}>
								<InputCustom
									label='Amount'
									error={amountError}
									value={amount}
									style={{
										marginTop: 16,
									}}
									onChange={e => {
										setAmount(e.target.value);
										setAmountError(false);
									}}
								/>
							</Box>
						</Box>

						<Box className={classes.rowWrapper}>
							<Box className={classes.colWrapper_50}>
								<SelectMultipleCustom
									label='Partner Receiver'
									placeholder='Partner Receiver'
									onChange={(e, elem) => {
										handleChangePartnerReceiver(elem);
										setPartnerReceiverError(false);
									}}
									value={partnerReceiver}
									error={partnerReceiverError}
									disableClearable
									list={
										partnersList
											? partnersList.filter(partner => partner?.wallets?.find(el => Boolean(el.isPrivate)))
											: []
									}
									optionTitle='name'
								/>
							</Box>
							<Box className={classes.colWrapper_50}>
								<SelectMultipleCustom
									label='Partner Receiver Wallet'
									placeholder='Partner Receiver Wallet'
									onChange={(e, elem) => {
										handleChangePartnerReceiverWallet(elem);
										setPartnerReceiverWalletError(false);
									}}
									value={partnerReceiverWallet}
									error={partnerReceiverWalletError}
									disableClearable
									list={
										partnerReceiver
											? partnerReceiver?.wallets?.filter(el => Boolean(el.isPrivate))
											: []
									}
									optionTitle='name'
								/>
							</Box>
						</Box>

						<Box className={classes.rowWrapper}>
							<Box className={classes.colWrapper_100}>
								<InputCustom
									label="Notes"
									value={notes}
									onChange={e => {
										setNotes(e.target.value);
									}}
								/>
							</Box>
						</Box>

						<Box className={classes.rowWrapper}>
							<Box className={classes.colWrapper_100}>
								<WeekSwitcher
									selectedDate={selectedDate}
									handleChangeSelectedDate={setSelectedDate}
									isOneDay
								/>
							</Box>
						</Box>

					</DialogContent>

					<DialogActions
						className={classes.formButtons}
					>
						<ButtonCustom
							onClick={eventClose}
							customType={buttonTypes.CANCEL}
						/>

						<ButtonCustom
							autoFocus
							onClick={sendApplyData}
							customType={buttonTypes.CREATE}
							text='Apply'
						/>
					</DialogActions>

				</Box>
			</Dialog>
		</Box>
	)
}