import {
  SET_NETWORKS_LIST,
} from '../../constants';

export const setNetworksList = networksList => ({
  type: SET_NETWORKS_LIST,
  payload: {
    networksList,
  }
});
