import { numberToDollar } from '../../utils/number';

export const agentsPartnersReformat = ({ year, week, list}) => ({
  list: Array.isArray(list)
    ? list.map(item => ({
        partnerName: item?.partner?.name,
        weeklyFigure: item?.weeklyFigure,
        weeklyDetails: item?.weeklyFiguresList
          ? {
            partnerName: item?.partner?.name,
            list: item?.weeklyFiguresList,
            handlerType: 'AgentReport',
          } : null,
        previousCarry: item?.previousCarry,
        balance: item?.balance,
        settled: item?.settled,
        redLineAmt: item?.redLineAmt,
        shareAmt: item?.shareAmt,
        previousMakeUp: item?.previousMakeUp,
        makeUp: item?.makeUp,
        subOwnerAmount: item?.subOwnerAmount,
        partnerApprove: item?.partnerApprove ?? {
          approveMessage: null,
          status: null,
          feedback: null,
        },
        confirmed: {
          id: item?.partner?._id,
          checked: !!item?.confirmed,
          year,
          week,
        },
        net: item?.net,
        telegram: {
          id: item?.partner?._id,
          checked: false,
          activated: item?.telegramActivated,
          year,
          week,
        },
        subOwnerName: item?.subOwner?.name,
        telegramMessage: item?.telegramMessage
          ? {
            name: item?.partner?.name,
            message: item.telegramMessage,
          }
          : null,
      }))
    : [],
  totalShareAmount: numberToDollar(list.reduce((prev, current) => prev + current.shareAmt, 0)),
});
