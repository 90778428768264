import { store } from '../../store';
import { 
  setNetworksList,
  setEditNetwork,
} from '../../store/actions';
import { 
  networksForTableParser,
  addNetworkToBackendParser,
  editNetworkToBackendParser,
  networkToEditParser,
} from './reformatters';

import {
  getLocalStorageData,
} from '../../utils';
import { 
  GET_NETWORKS_FOR_TABLE,
  ADD_NETWORK,
  EDIT_NETWORK,
  DELETE_NETWORK,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';

// export class Accounts {
  export async function networksForTable() {
    await requestWrapper('NetworksForTable', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_NETWORKS_FOR_TABLE)
        .setBearerToken(token)
        .build();
      
      const { networks } = data || null;

      if (!loading && !error && networks) {
        const reformattedNetworks = networksForTableParser(networks);
        store.dispatch(setNetworksList(reformattedNetworks));
      } else {
        
      }
    });
  }

  export async function addNetwork(newNetwork) {
    await requestWrapper('AddNetwork', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(ADD_NETWORK)
        .setBearerToken(token)
        .setVariables({
          payload: addNetworkToBackendParser(newNetwork)
        })
        .build();
      
      const { createNetwork } = data || null;

      if (!loading && !error && createNetwork?._id) {
        await networksForTable();
      } else {
        
      }
    });
  }

  export async function editNetwork(editedNetwork) {
    await requestWrapper('EditNetwork', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(EDIT_NETWORK)
        .setBearerToken(token)
        .setVariables({
          payload: editNetworkToBackendParser(editedNetwork)
        })
        .build();
      
      const { updateNetwork } = data || null;

      if (!loading && !error && updateNetwork) {
        setEditNetwork(networkToEditParser(updateNetwork));
        await networksForTable();
      } else {
        
      }
    });
  }

  export async function deleteNetwork(id) {
    await requestWrapper('DeleteNetwork', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(DELETE_NETWORK)
        .setBearerToken(token)
        .setVariables({ _id: id, })
        .build();
      
      const { deleteNetwork } = data || null;

      if (!loading && !error && deleteNetwork?._id) {
        await networksForTable();
      } else {
        
      }
    });
  }