export const getStyles = theme => ({
  wrapper: {
    paddingTop: 10,
    paddingBottom: 25,
    position: 'relative',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: -5,
    marginRight: -5,
  },
  column: {
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 200,
  },
})
