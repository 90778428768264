export const getStyles = theme => ({
  wrapper: {
    paddingBottom: 10,
    marginBottom: 15,
  },
  button: {
    color: theme.mainStyles.mainColorText,
    textDecoration: 'none',
    borderBottom: `1px solid rgba(0,0,0,0)`,
    fontSize: 14,
    padding: '6px 12px',
    "&:hover": {
      color: theme.navigations.colorText,
      textDecoration: 'none',
      borderBottom: `1px solid ${theme.navigations.borderColor}`,
    },
    "&.active": {
      color: theme.navigations.hoverColorText,
      textDecoration: 'none',
      borderBottom: `1px solid ${theme.navigations.hoverBorderColor}`,
    }
  },
});