import { SortingDirection } from '../components/TableComponent/constants';
import { naturalSort } from './sortingFunctions.val';

const sortString = (a, b) => {
  return (a < b) ? -1 : (a > b) ? 1 : 0;
  
  
  // if (a === null) {
  //   a = '';
  // }
  // if (b === null) {
  //   b = '';
  // }
  // if (a > b) {
  //   return 1;
  // }
  // if (a < b) {
  //   return -1;
  // }
  // return 0;
}

const sortNumberASC = (a, b) => {
  if (a === null || a === '' || a === undefined) {
    a = Infinity;
  }
  if (b === null || b === '' || b === undefined) {
    b = Infinity;
  }
  return a - b;
}

const sortNumberDESC = (a, b) => {
  if (a === null || a === '' || a === undefined) {
    a = -Infinity;
  }
  if (b === null || b === '' || b === undefined) {
    b = -Infinity;
  }
  return b - a;
}

export const sortingService = ({
  list,
  direction,
  sortItemData: {
    dataKey,
    sortType,
    sortKey,
  },
}) => {
  if (SortingDirection.DESC === direction) {
    if (sortType === 'string') {
      return naturalSort(list, dataKey, SortingDirection.DESC, direction, sortKey);
      // return list.sort((a, b) => sortString(
      //   sortKey ? a[dataKey][sortKey] : a[dataKey],
      //   sortKey ? b[dataKey][sortKey] : b[dataKey]
      //   )
      // );
    }
    if (sortType === 'number') {
      return list.sort((a, b) => sortNumberASC(
        sortKey ? a[dataKey][sortKey] : a[dataKey],
        sortKey ? b[dataKey][sortKey] : b[dataKey]
        )
      );
    }
  } else {
    if (sortType === 'string') {
      return naturalSort(list, dataKey, SortingDirection.DESC, direction, sortKey);
      // return list.sort((a, b) => sortString(
      //   sortKey ? b[dataKey][sortKey] : b[dataKey],
      //   sortKey ? a[dataKey][sortKey] : a[dataKey]
      //   )
      // );
    }
    if (sortType === 'number') {
      return list.sort((a, b) => sortNumberDESC(
        sortKey ? a[dataKey][sortKey] : a[dataKey],
        sortKey ? b[dataKey][sortKey] : b[dataKey]
        )
      );
    }
  }
}

export const sortEvent = ({
  handleSortBy,
  sortData,
  setHandleSortDirection,
  setHandleSortBy,
  list,
  setNewList,
}) => {
  return ({
    sortBy,
    sortDirection,
  }) => {
    const sortItemData = sortData.find(item => item.dataKey === sortBy);
    if (sortItemData && sortBy && sortDirection) {
      let direction;
      if (sortBy === handleSortBy) {
        direction = sortDirection === SortingDirection.ASC
          ? SortingDirection.ASC
          : SortingDirection.DESC;
      } else {
        direction = SortingDirection.DESC;
      }
      setHandleSortDirection(direction);
      setHandleSortBy(sortBy);
      if (setNewList) {
        return setNewList(sortingService({
          list,
          direction,
          sortItemData,
        }));
      }
      return sortingService({
        list,
        direction,
        sortItemData,
      });
    }
  }
}
