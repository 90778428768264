import {
  RESET_BALANCES_REPORTS,
  SET_BALANCES_REPORTS,
  SET_BALANCES_REPORTS_SELECTED_DATE,
} from '../constants';

const initialState = {
  reports: {
    cashBalance: '',
    onlineBalances: '',
    appBalances: '',
    agentBalances: '',
    partnerBalances: '',
    bitcoinHarout: '',
    bitcoinDrew: '',
    bankrollValue: '',
  },
  selectedDate: null,
}

export const balancesReports = (state = initialState, action) => {
  switch (action.type) {
    case RESET_BALANCES_REPORTS:
      return initialState;
    case SET_BALANCES_REPORTS:
    case SET_BALANCES_REPORTS_SELECTED_DATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
