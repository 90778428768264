import { gql } from '@apollo/client';
import { PARTNER_SHORT_FRAGMENT } from '../partners/constants';
import { WALLET_TYPE_SHORT_FRAGMENT } from '../wallet-types/constants';

export const WALLET_SHORT_FRAGMENT = gql`
  ${WALLET_TYPE_SHORT_FRAGMENT}
  fragment CoreWalletShortFields on Wallet {
    _id
    type {
      ...CoreShortWalletTypeFields
    }
  }
`;

// export const WALLET_WITHOUT_NOTES_FRAGMENT = gql`
//   ${WALLET_TYPE_SHORT_FRAGMENT}
//   ${PARTNER_SHORT_FRAGMENT}
//   fragment CoreWalletWithoutNotesFields on Wallet {
//     _id
//     type {
//       ...CoreShortWalletTypeFields
//     }
//     partner {
//       ...CorePartnerShortFields
//     }
//   }
// `;

export const WALLET_FRAGMENT = gql`
  ${WALLET_TYPE_SHORT_FRAGMENT}
  ${PARTNER_SHORT_FRAGMENT}
  fragment CoreWalletFields on Wallet {
    _id
    partner {
      ...CorePartnerShortFields
    }
    type {
      ...CoreShortWalletTypeFields
    }
    isPrivate
    notes
  }
`;

export const GET_WALLETS_FOR_TABLE = gql`
  ${WALLET_FRAGMENT}
  query GetWalletsForTable {
    findAllWallets {
      ...CoreWalletFields
    }
  }
`;

export const GET_PARTNERS_AND_WALLET_TYPES_FOR_WALLET = gql`
  ${WALLET_TYPE_SHORT_FRAGMENT}
  ${PARTNER_SHORT_FRAGMENT}
  query GetPartnersAndWalletTypesForWallet {
    partners {
      ...CorePartnerShortFields
    }
    findAllWalletTypes {
      ...CoreShortWalletTypeFields
    }
  }
`;

export const GET_WALLET_AND_LISTS_FOR_EDIT = gql`
  ${WALLET_FRAGMENT}
  ${WALLET_TYPE_SHORT_FRAGMENT}
  ${PARTNER_SHORT_FRAGMENT}
  query GetWalletAndListsForEdit($_id: String!) {
    findOneWallet(_id: $_id) {
      ...CoreWalletFields
    }
    findAllWalletTypes {
      ...CoreShortWalletTypeFields
    }
    partners {
      ...CorePartnerShortFields
    }
  }
`;

export const ADD_WALLET = gql`
  mutation AddWallet($payload: CreateWalletDto!) {
    createWallet(createWalletDto: $payload) {
      _id
    }
  }
`;

export const EDIT_WALLET = gql`
  ${WALLET_FRAGMENT}
  mutation EditWallet($payload: UpdateWalletDto!) {
    updateWallet(updateWalletDto: $payload) {
      ...CoreWalletFields
    }
  }
`;

export const DELETE_WALLET = gql`
  mutation DeleteWallet($_id: String!) {
    removeWallet(_id: $_id) {
      _id
    }
  }
`;
