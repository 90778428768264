export const getStyles = theme => ({
  buttonCreate: {
    backgroundColor: theme.buttonCreateStyles.backgroundColor,
    color: theme.buttonCreateStyles.buttonColor,
    whiteSpace: 'nowrap',
    "&:hover": {
      backgroundColor: theme.buttonCreateStyles.hoverBackgroundColor,
    },
  },
  buttonDefault: {
    backgroundColor: theme.buttonDefaultStyles.backgroundColor,
    color: theme.buttonDefaultStyles.buttonColor,
    "&:hover": {
      backgroundColor: theme.buttonDefaultStyles.hoverBackgroundColor,
    },
  },
  buttonEdit: {
    backgroundColor: theme.buttonEditStyles.backgroundColor,
    color: theme.buttonEditStyles.buttonColor,
    "&:hover": {
      backgroundColor: theme.buttonEditStyles.hoverBackgroundColor,
    },
  },
  buttonDelete: {
    backgroundColor: theme.buttonDeleteStyles.backgroundColor,
    color: theme.buttonDeleteStyles.buttonColor,
    "&:hover": {
      backgroundColor: theme.buttonDeleteStyles.hoverBackgroundColor,
    },
  },
  buttonCancel: {
    backgroundColor: theme.buttonCancelStyles.backgroundColor,
    color: theme.buttonCancelStyles.buttonColor,
    "&:hover": {
      backgroundColor: theme.buttonCancelStyles.hoverBackgroundColor,
    },
  },
  buttonPanel: {
    backgroundColor: theme.buttonPanelStyles.backgroundColor,
    color: theme.buttonPanelStyles.buttonColor,
    padding: '15px 20px',
    margin: '10px 0',

    "&:hover": {
      backgroundColor: theme.buttonPanelStyles.hoverBackgroundColor,
    },
  }
})
