import {
  SET_REPORTS_LIST,
  SET_REPORTS_SELECTED_DATE,
  SET_REPORTS_SELECTED_TYPE,
  SET_REPORTS_TOTAL_SHARE_AMOUNT,
  EDIT_REPORTS_LIST_ITEM_TELEGRAM_CHECKED,
  EDIT_REPORTS_LIST_ITEM_CONFIRMED_CHECKED,
  RESET_REPORTS,
  RESET_REPORTS_LIST,
  RESET_REPORTS_SEND_PARTNER_IDS_LIST,
} from '../constants';

export const resetReportsList = () => ({
  type: RESET_REPORTS_LIST,
});

export const resetReports = () => ({
  type: RESET_REPORTS,
});

export const setReportsList = reportsList => ({
  type: SET_REPORTS_LIST,
  payload: {
    reportsList,
  }
});

export const setReportsTotalShareAmount = totalShareAmount => ({
  type: SET_REPORTS_TOTAL_SHARE_AMOUNT,
  payload: {
    totalShareAmount,
  }
});

export const setReportsSelectedDate = selectedDate => ({
  type: SET_REPORTS_SELECTED_DATE,
  payload: {
    selectedDate,
  }
});

export const setReportsSelectedType = selectedType => ({
  type: SET_REPORTS_SELECTED_TYPE,
  payload: {
    selectedType,
  }
});

export const editReportsListItemTelegramChecked = partnerId => ({
  type: EDIT_REPORTS_LIST_ITEM_TELEGRAM_CHECKED,
  payload: {
    partnerId,
  }
});

export const editReportsListItemConfirmedChecked = partnerId => ({
  type: EDIT_REPORTS_LIST_ITEM_CONFIRMED_CHECKED,
  payload: {
    partnerId,
  },
});

export const resetReportsSendPartnerIdsList = () => ({
  type: RESET_REPORTS_SEND_PARTNER_IDS_LIST,
});
