import { apolloClient } from './apolloClient';

export const apolloClientQueryBuilder = class {
    constructor() {
      this.request = {};
      this.logger = console.log;
      this.apolloClient = apolloClient;
    }
    
    query(query){
      this.request.query = query;
      return this;
    }
    
    mutate(mutate){
      this.request.mutation = mutate;
      return this;
    }
    
    setContext(context){
      this.request.context = context;
      return this;
    }
    
    setBearerToken(token){
      if (!this.request.context) this.request.context = {};
      if (!this.request.context.headers) this.request.context.headers = {};
      this.request.context.headers.authorization = `Bearer ${token}`;
      return this;
    }
    
    setBasicAuth(basic){
      if (!this.request.context) this.request.context = {};
      if (!this.request.context.headers) this.request.context.headers = {};
      this.request.context.headers.authorization = `Basic ${basic}`;
      return this;
    }
    
    setVariables(variables){
      this.request.variables = variables;
      return this;
    }
    
    async build(){
      const response = this.request.query !== undefined
        ? await this.apolloClient.query(this.request)
        : (this.request.mutation !== undefined
          ? await this.apolloClient.mutate(this.request)
          : await this.apolloClient.query(this.request)
        );
      const {data} = response;
      if (data) this.logger('data: ', data);
      return response;
    }
}
