import {
  RESET_EDIT_ACCOUNT,
  SET_EDIT_ACCOUNT,
  SET_EDIT_ACCOUNT_TYPE,
  SET_EDIT_ACCOUNT_SUB_TYPE,
  SET_EDIT_ACCOUNT_SHORT_ACCOUNT_NAME,
  SET_EDIT_ACCOUNT_URL,
  SET_EDIT_ACCOUNT_USERNAME,
  SET_EDIT_ACCOUNT_PASSWORD,
  SET_EDIT_ACCOUNT_SHARE_PERCENT,
  SET_EDIT_ACCOUNT_NOTES,
  SET_EDIT_ACCOUNT_SITE_TYPE,
  SET_EDIT_ACCOUNT_PARTNER,
  SET_EDIT_ACCOUNT_SITE_TYPES_LIST,
  SET_EDIT_ACCOUNT_PARTNERS_LIST,
  SET_EDIT_ACCOUNT_SUB_OWNER_PERCENT,
  SET_EDIT_ACCOUNT_SUB_OWNER,
} from '../constants';

const initialState = {
  id: null,
  shortAccountName: '',
  url: '',
  type: '',
  subType: '',
  username: '',
  password: '',
  sharePercent: '',
  notes: '',
  partner: null,
  subOwner: null,
  subOwnerPercent: '',
  siteType: null,
  partnersList: [],
  siteTypesList: [],
}

export const editAccount = (state = initialState, action) => {
  switch (action.type) {
    case RESET_EDIT_ACCOUNT:
      return initialState;
    case SET_EDIT_ACCOUNT:
      return action.payload.account;
    case SET_EDIT_ACCOUNT_TYPE:
    case SET_EDIT_ACCOUNT_SUB_TYPE:
    case SET_EDIT_ACCOUNT_SHORT_ACCOUNT_NAME:
    case SET_EDIT_ACCOUNT_URL:
    case SET_EDIT_ACCOUNT_USERNAME:
    case SET_EDIT_ACCOUNT_PASSWORD:
    case SET_EDIT_ACCOUNT_SHARE_PERCENT:
    case SET_EDIT_ACCOUNT_NOTES:
    case SET_EDIT_ACCOUNT_SITE_TYPE:
    case SET_EDIT_ACCOUNT_PARTNER:
    case SET_EDIT_ACCOUNT_SITE_TYPES_LIST:
    case SET_EDIT_ACCOUNT_PARTNERS_LIST:
    case SET_EDIT_ACCOUNT_SUB_OWNER:
    case SET_EDIT_ACCOUNT_SUB_OWNER_PERCENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
