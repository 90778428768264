import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { MuiThemeProvider } from '@material-ui/core/styles';
import { themesAdapter } from '../../config/themes/themesAdapter';
import { AppWrapper } from './AppWrapper';

import {
  getThemeNameSelector,
  getIsFetchingSelector,
  getIsAuthenticatedSelector,
} from './selectors';
import moment from 'moment';

import 'typeface-roboto';

moment.updateLocale("en", { week: {
  dow: 1, // First day of week is Monday
  doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
}});

const customHistory = createBrowserHistory();

const AppContainer = ({
  themeName,
  isFetching,
  isAuthenticated,
}) => {
  const theme = themesAdapter(themeName);

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
        <BrowserRouter history={customHistory}>
          <AppWrapper
            isAuthenticated={isAuthenticated}
            isFetching={isFetching}
          />
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

const mapStateToProps = state => ({
  themeName: getThemeNameSelector(state),
  isFetching: getIsFetchingSelector(state),
  isAuthenticated: getIsAuthenticatedSelector(state),
});

export const App = connect(
  mapStateToProps,
)(AppContainer);
