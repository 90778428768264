import { createSelector } from 'reselect';

// Default Selectors
const getCreateTelegramCodeSelector = state => state.createTelegramCode;

// Create Telegram Code selectors
export const getCreateTelegramCodePartnerSelector = createSelector(
  getCreateTelegramCodeSelector,
  createTelegramCode => createTelegramCode.partner
);

export const getCreateTelegramCodePartnersListSelector = createSelector(
  getCreateTelegramCodeSelector,
  createTelegramCode => createTelegramCode.partnersList
);
