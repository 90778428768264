import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import { SelectCustom } from '../../selects/SelectCustom';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const WeeksPanel = ({
  activeWeek,
  setWeek,
  weeksList,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box
      component='footer'
      className={classes.wrapper}
    >
      <Box className={classes.container}>
        <Box className={classes.column}>
          <SelectCustom
            label='Week'
            value={activeWeek}
            onChange={e => setWeek(e.target.value)}
            itemList={weeksList}
          />
        </Box>
      </Box>
    </Box>
  )
}
