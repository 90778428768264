export const navigationList = [
  {
    link: '/Wallets',
    title: 'Wallets',
    exact: true,
  },
  {
    link: '/Wallets/WalletTypes',
    title: 'Wallet Types',
  },
]
