import {
  RESET_EDIT_WALLET,
  SET_EDIT_WALLET,
  SET_EDIT_WALLET_NOTES,
  SET_EDIT_WALLET_PARTNER,
  SET_EDIT_WALLET_WALLET_TYPE,
  SET_EDIT_WALLET_IS_PRIVATE,
  SET_EDIT_WALLET_PARTNERS_LIST,
  SET_EDIT_WALLET_WALLET_TYPES_LIST,
} from '../../constants';

export const resetEditWallet = () => ({
  type: RESET_EDIT_WALLET,
});

export const setEditWallet = wallet => {
  return {
  type: SET_EDIT_WALLET,
  payload: {
    wallet,
  }
}};

export const setEditWalletPartner = partner => ({
  type: SET_EDIT_WALLET_PARTNER,
  payload: {
    partner,
  },
});

export const setEditWalletType = type => ({
  type: SET_EDIT_WALLET_WALLET_TYPE,
  payload: {
    type,
  },
});

export const setEditWalletIsPrivate = isPrivate => ({
  type: SET_EDIT_WALLET_IS_PRIVATE,
  payload: {
    isPrivate,
  },
});

export const setEditWalletNotes = notes => ({
  type: SET_EDIT_WALLET_NOTES,
  payload: {
    notes,
  },
});

export const setEditWalletPartnersList = partnersList => ({
  type: SET_EDIT_WALLET_PARTNERS_LIST,
  payload: {
    partnersList,
  },
});

export const setEditWalletWalletTypesList = typesList => ({
  type: SET_EDIT_WALLET_WALLET_TYPES_LIST,
  payload: {
    typesList,
  },
});
