import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { InputCustom } from '../../inputs';
import { ButtonCustom } from '../../buttons';
import { buttonTypes } from '../../buttons/ButtonCustom/constants';
import { SelectMultipleCustom, SelectCustom } from '../../selects';
import { WeekSwitcher } from '../../../WeekSwitcher';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const ModalExpenses = ({
  open,
  eventApply,
  eventClose,
  title,
  partnersList,
  partnerReceiver,
  partnerReceiverWallet,
  subType,
  amount,
  notes,
  selectedDate,
  setSelectedDate,
  setPartnerReceiver,
  setPartnerReceiverWallet,
  setSubType,
  setAmount,
  setNotes,
  disabledList = [],
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [partnerReceiverError, setPartnerReceiverError] = useState(false);
  const [partnerReceiverWalletError, setPartnerReceiverWalletError] = useState(false);
  const [amountError, setAmountError] = useState(false);

  const today = moment();

  useEffect(() => {
    const lastDay = today.clone().weekday(6);
    setSelectedDate(lastDay);
    setSubType(typesList[2].name);
    setNotes('Expenses')
  }, []);

  const typesList = [
    {
      id: 'Agent',
      name: 'Agent',
    },
    {
      id: 'Handbet',
      name: 'Handbet',
    },
    {
      id: 'Private',
      name: 'Private',
    },
  ];

  const handleChangePartnerReceiver = elem => {
    setPartnerReceiver(partnersList?.find(el => el?.id === elem?.id));
    setPartnerReceiverWallet(null);
  }

  const handleChangePartnerReceiverWallet = elem => {
    const receiverWallet = partnersList
      ?.find(partner => partner?.id === partnerReceiver?.id)
      ?.wallets
      ?.find(wallet => wallet?.id === elem?.id);

    setPartnerReceiverWallet(receiverWallet);
  }

  const validate = () => {
    let valid = true;
    if (!Boolean(partnerReceiver)) {
      valid = false;
      setPartnerReceiverError(true);
    }
    if (!Boolean(partnerReceiverWallet)) {
      valid = false;
      setPartnerReceiverWalletError(true);
    }
    if (!Boolean(amount)) {
      valid = false;
      setAmountError(true);
    }
    return valid;
  }

  const sendApplyData = () => {
    if (validate()) {
      eventApply();
    }
  }

  return (
    <Box
      component='div'
    >
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-create-title'
        className={classes.modalContent}
      >
        <Box
          component='div'
        >
          <IconButton
            aria-label="close"
            className={classes.buttonClose}
            onClick={eventClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            id='alert-dialog-create-title'
            className={classes.title}
          >
            {title ? title : 'Create'}
          </DialogTitle>
          <DialogContent>

            <Box
              component='div'
              className={
                clsx(
                  classes.rowWrapper,
                  classes.rowTopWrapper
                )
              }
            >
              <Box
                component='div'
                className={clsx(
                  classes.colWrapper_50,
                  classes.colWrapperCenter,
                )}
              >
                <InputCustom
                  label='Amount'
                  error={amountError}
                  value={amount}
                  onChange={e => {
                    setAmount(e.target.value);
                    setAmountError(false);
                  }}
                  disabled={disabledList.find(el => el === 'Amount')}
                />
              </Box>
              <Box
                component='div'
                className={clsx(
                  classes.colWrapper_50,
                  classes.colWrapperCenter,
                )}
              >
                <SelectCustom
                  label="SubType"
                  placeholder='SubType'
                  value={subType}
                  onChange={e => {
                    setSubType(e.target.value);
                  }}
                  disableClearable
                  itemList={typesList}
                  optionTitle='name'
                  disabled={disabledList.find(el => el === 'SubType')}
                />
              </Box>
            </Box>

            <Box
              component='div'
              className={classes.rowWrapper}
            >
              <Box
                component='div'
                className={classes.colWrapper_50}
              >
                <SelectMultipleCustom
                  label='Partner Receiver'
                  placeholder='Partner Receiver'
                  onChange={(e, elem) => {
                    handleChangePartnerReceiver(elem);
                    setPartnerReceiverError(false);
                  }}
                  value={partnerReceiver}
                  error={partnerReceiverError}
                  disableClearable
                  list={
                    partnersList
                      ? partnersList.filter(partner => partner?.wallets?.find(el => Boolean(el.isPrivate)))
                      : []
                  }
                  optionTitle='name'
                  disabled={disabledList.find(el => el === 'PartnerReceiver')}
                />
              </Box>
              <Box
                component='div'
                className={classes.colWrapper_50}
              >
                <SelectMultipleCustom
                  label='Partner Receiver Wallet'
                  placeholder='Partner Receiver Wallet'
                  onChange={(e, elem) => {
                    handleChangePartnerReceiverWallet(elem);
                    setPartnerReceiverWalletError(false);
                  }}
                  value={partnerReceiverWallet}
                  error={partnerReceiverWalletError}
                  disableClearable
                  list={
                    partnerReceiver
                      ? partnerReceiver?.wallets?.filter(el => Boolean(el.isPrivate))
                      : []
                  }
                  optionTitle='name'
                  disabled={disabledList.find(el => el === 'PartnerReceiverWallet')}
                />
              </Box>
            </Box>

            <Box
              component='div'
              className={classes.rowWrapper}
            >
              <Box
                component='div'
                className={classes.colWrapper_100}
              >
                <InputCustom
                  label="Notes"
                  value={notes}
                  onChange={e => {
                    setNotes(e.target.value);
                  }}

                  disabled={disabledList.find(el => el === 'Notes')}
                />
              </Box>
            </Box>

            <Box
              component='div'
              className={classes.rowWrapper}
            >
              <Box
                component='div'
                className={classes.colWrapper_100}
              >
                <WeekSwitcher
                  selectedDate={selectedDate}
                  handleChangeSelectedDate={setSelectedDate}
                  isOneDay
                />
              </Box>
            </Box>

          </DialogContent>
          <DialogActions
            className={classes.formButtons}
          >
            <ButtonCustom
              onClick={eventClose}
              customType={buttonTypes.CANCEL}
            />

            <ButtonCustom
              autoFocus
              onClick={sendApplyData}
              customType={buttonTypes.CREATE}
              text='Apply'
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
