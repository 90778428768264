import React, { useState } from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { ButtonIconCustom, ModalText } from '../../UIComponents';
import { iconTypes } from '../../UIComponents/buttons/ButtonIconCustom/constants';
import { getRowData } from './utils';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);


export const PanelButtonInfoBotWeeklyFigure = ({
	data,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	const [ openModal, setOpenModal ] = useState(false);

	const TextComponent = ({list}) => (
		<Table
			aria-label="WeeklyFigureTable"
			className={classes.table}
		>
			<TableBody className={classes.tableBody}>
				{
					list.map(row => (
						<TableRow
							key={row.label}
							className={clsx(
								{[classes.tableRowResult]: row.isResult},
								{[classes.tableRowDayOfWeek]: row.isDayOfWeek},
							)}
						>
							<TableCell
								className={classes.tableCell}
							>
								{ row.label }:
							</TableCell>
							<TableCell
								className={clsx(
									classes.tableCell,
									classes.tableCellAlignRight,
								)}
							>
								{ row.value }
							</TableCell>
						</TableRow>
					))
				}
			</TableBody>
		</Table>
	);

	return data && (
		<>
			<Box>
				<ButtonIconCustom
					customType={iconTypes.INFO}
					className={classes.buttonInfo}
					onClick={() => setOpenModal(true)}
				/>
			</Box>
			{openModal && (
				<ModalText
					open={openModal}
					title={data?.username ?? ''}
					text={<TextComponent
						list={getRowData(data)}
					/>}
					eventClose={() => setOpenModal(false)}
				/>
			)}
		</>
	)
}