import { gql } from '@apollo/client';

import { PARTNER_SHORT_FRAGMENT } from '../partners/constants';

export const TELEGRAM_USER_SHORT_FRAGMENT = gql`
  ${PARTNER_SHORT_FRAGMENT}
  fragment CoreTelegramUserShortFields on PartnerTelegramUser {
    _id
    activated
    partner {
      ...CorePartnerShortFields
    }
  }
`;

export const GENERATE_CODE = gql`
  mutation GenerateCode($createPartnerTelegramUserDto: CreatePartnerTelegramUserDto!) {
    createPartnerTelegramUser(createPartnerTelegramUserDto: $createPartnerTelegramUserDto)
  }
`;

export const SEND_REPORTS_TO_TELEGRAM = gql`
  mutation SendReportsToTelegram($reportsToTelegramDto: ReportsToTelegramDto!) {
    sendReportsToTelegram(reportsToTelegramDto: $reportsToTelegramDto)
  }
`;

export const DELETE_PARTNER_TELEGRAM_CODE = gql`
  mutation DeletePartnerTelegramCode($id: String!) {
    removePartnerTelegramUser(_id: $id)
  }
`;

export const GET_PARTNERS_TELEGRAM_CODES_FOR_TABLE = gql`
  ${TELEGRAM_USER_SHORT_FRAGMENT}
  query GetPartnersTelegramCodesForTable {
    partnersTelegramUsersFindAll(allowActivated: false) {
      code
      ...CoreTelegramUserShortFields
    }
  }
`;

export const GET_TELEGRAM_USERS_FOR_TABLE = gql`
  ${TELEGRAM_USER_SHORT_FRAGMENT}
  query GetTelegramUsersForTable {
    partnersTelegramUsersFindAll(allowActivated: true) {
      ...CoreTelegramUserShortFields
      # user
      nickname
      telegramUserId
    }
  }
`;

export const GET_PARTNERS_FOR_LIST = gql`
  ${PARTNER_SHORT_FRAGMENT}
  query GetPartnersForList {
    partnersWithoutTelegramCode {
      ...CorePartnerShortFields
    }
  }
`;
