import { store } from '../../store';
import {
  getLocalStorageData,
} from '../../utils';

import {
  walletsReportsParser,
} from './reformatters';
import {
  GET_WALLETS_REPORTS,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';
import { setReportsList } from '../../store';

export const walletsReportsForTable = async ({
  year,
  week
}) => {
  await requestWrapper('WalletsReportsForTable', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_WALLETS_REPORTS)
      .setBearerToken(token)
      .setVariables({
        weeklyFiguresWalletByWeekDto: {
          week,
          year,
          isPrivate: false,
        },
      })
      .build();

    const { reportWallets } = data || null;

    if (!loading && !error && reportWallets) {
      const reformattedReportsWallets = walletsReportsParser(reportWallets);
      store.dispatch(setReportsList(reformattedReportsWallets));
    } else {

    }
  });
}
