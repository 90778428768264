import { createSelector } from 'reselect';

const getReportsSelector = state => state.reports;
const getModalTableInfoSelector = state => state.modalTableInfo;

export const getReportsListSelector = createSelector(
  getReportsSelector,
  reports => reports.reportsList
); 

export const getReportsSelectedDateSelector = createSelector(
  getReportsSelector,
  reports => reports.selectedDate
);

export const getReportsSelectedTypeSelector = createSelector(
	getReportsSelector,
	reports => reports.selectedType
);

export const getReportsTotalShareAmountSelector = createSelector(
  getReportsSelector,
  reports => reports.totalShareAmount
);

export const getModalTableInfoIsShowSelector = createSelector(
  getModalTableInfoSelector,
  modalData => modalData.isShow,
);

export const getModalTableInfoListSelector = createSelector(
  getModalTableInfoSelector,
  modalData => modalData.list,
);

export const getModalTableInfoPartnerNameSelector = createSelector(
  getModalTableInfoSelector,
  modalData => modalData.partnerName,
);
