import { gql } from '@apollo/client';

export const PARTNER_SHORT_FRAGMENT = gql`
  fragment CorePartnerShortFields on Partner {
    _id
    name
    # wallet {
    #   _id
    #   accountNumber
    # }
  }
`;

export const PARTNER_WITH_WALLETS_FRAGMENT = gql`
  ${PARTNER_SHORT_FRAGMENT}
  fragment CorePartnerWithWalletsFields on Partner {
    ...CorePartnerShortFields
    wallets {
      _id
      type {
        name
      }
      isPrivate
    }
  }
`;

export const PARTNER_FRAGMENT = gql`
  ${PARTNER_SHORT_FRAGMENT}
  fragment CorePartnerFields on Partner {
    ...CorePartnerShortFields
    partnerType
    terms
    location
    sharePercent
  }
`;

export const GET_PARTNERS_FOR_TABLE = gql`
  ${PARTNER_FRAGMENT}
  query GetPartnersForTable {
    partners {
      ...CorePartnerFields
    }
  }
`;

export const ADD_PARTNER = gql`
  mutation AddPartner($payload: CreatePartnerDto!) {
    createPartner(payload: $payload) {
      _id
    }
  }
`;

export const GET_PARTNER_FOR_EDIT = gql`
  ${PARTNER_FRAGMENT}
  query GetPartnerForEdit($_id: String!) {
    partner(_id: $_id) {
      ...CorePartnerFields
    }
  }
`;

export const EDIT_PARTNER = gql`
  ${PARTNER_FRAGMENT}
  mutation EditPartner($payload: UpdatePartnerDto!) {
    updatePartner(payload: $payload) {
      ...CorePartnerFields
    }
  }
`;

export const DELETE_PARTNER = gql`
  mutation DeletePartner($_id: String!) {
    deletePartner(_id: $_id) {
      _id
    }
  }
`;
