import { store } from '../../store';
import {
  setCreateTelegramCodePartnersList,
  setPartnersTelegramCodesList,
} from '../../store';
import {
  generateCodeDtoParser,
  partnersTelegramCodesParser,
  telegramUsersParser,
  sendReportsToTelegramDtoParser,
} from './reformatters';
import { partnersToAccountPartnersListParser } from '../accounts/reformatters';

import { getLocalStorageData } from '../../utils';
import {
  GENERATE_CODE,
  GET_PARTNERS_TELEGRAM_CODES_FOR_TABLE,
  GET_PARTNERS_FOR_LIST,
  DELETE_PARTNER_TELEGRAM_CODE,
  GET_TELEGRAM_USERS_FOR_TABLE,
  SEND_REPORTS_TO_TELEGRAM,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';

async function getTelegramUsers({
  operationName,
  query,
  dispatchEvent,
  dataParser,
}) {
  await requestWrapper(operationName, async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(query)
      .setBearerToken(token)
      .build();
    const { partnersTelegramUsersFindAll } = data || null;

    if (!loading && !error && partnersTelegramUsersFindAll) {
      store.dispatch(
        dispatchEvent(
          dataParser(partnersTelegramUsersFindAll)
        )
      );
    } else {

    }
  });
}

export async function getPartnersTelegramCodesForTable() {
  await getTelegramUsers({
    operationName: 'GetPartnersTelegramCodesForTable',
    query: GET_PARTNERS_TELEGRAM_CODES_FOR_TABLE,
    dispatchEvent: setPartnersTelegramCodesList,
    dataParser: partnersTelegramCodesParser,
  });
}

export async function getTelegramUsersForTable() {
  await getTelegramUsers({
    operationName: 'GetTelegramUsersForTable',
    query: GET_TELEGRAM_USERS_FOR_TABLE,
    dispatchEvent: setPartnersTelegramCodesList,
    dataParser: telegramUsersParser,
  });
}

export async function deletePartnerTelegramCode({
  id,
}) {
  await requestWrapper('DeletePartnerTelegramCode', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(DELETE_PARTNER_TELEGRAM_CODE)
      .setBearerToken(token)
      .setVariables({
        id,
      })
      .build();
    const { removePartnerTelegramCode } = data || null;

    if (!loading && !error && removePartnerTelegramCode) {
      await getPartnersTelegramCodesForTable();
    } else {

    }
  });
}

export async function getPartnersForGenerateCode() {
  await requestWrapper('GetPartnersForGenerateCode', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_PARTNERS_FOR_LIST)
      .setBearerToken(token)
      .build();
    const { partnersWithoutTelegramCode } = data || null;

    if (!loading && !error && partnersWithoutTelegramCode) {
      store.dispatch(
        setCreateTelegramCodePartnersList(
          partnersToAccountPartnersListParser(partnersWithoutTelegramCode)
        )
      );
    } else {

    }
  });
}

export async function generateCode({
  partner,
}) {
  await requestWrapper('GenerateCode', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(GENERATE_CODE)
      .setBearerToken(token)
      .setVariables({
        createPartnerTelegramUserDto: generateCodeDtoParser({
          partner,
        })
      })
      .build();

    const { createPartnerTelegramUser } = data || null;

    if (!loading &&
        !error &&
        createPartnerTelegramUser
    ) {
      // await partnersForTable();
    } else {

    }
  });
}

export async function sendReportsToTelegram({
  year,
  week,
  partnerIds,
  accountType,
}) {
  await requestWrapper('SendReportsToTelegram', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(SEND_REPORTS_TO_TELEGRAM)
      .setBearerToken(token)
      .setVariables({
        reportsToTelegramDto: sendReportsToTelegramDtoParser({
          year,
          week,
          partnerIds,
          accountType,
        })
      })
      .build();

    const { sendReportsToTelegram } = data || null;

    if (!loading &&
        !error &&
        sendReportsToTelegram
    ) {
      // await partnersForTable();
    } else {

    }
  });
}
