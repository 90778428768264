import { gql } from '@apollo/client';

export const WALLET_TYPE_SHORT_FRAGMENT = gql`
  fragment CoreShortWalletTypeFields on WalletType {
    _id
    name
  }
`;

export const WALLET_TYPE_FRAGMENT = gql`
  ${WALLET_TYPE_SHORT_FRAGMENT}
  fragment CoreWalletTypeFields on WalletType {
    ...CoreShortWalletTypeFields
    notes
  }
`;

export const GET_WALLET_TYPES_FOR_TABLE = gql`
  ${WALLET_TYPE_FRAGMENT}
  query GetWalletTypesForTable {
    findAllWalletTypes {
      ...CoreWalletTypeFields
    }
  }
`;

export const GET_WALLET_TYPE_FOR_EDIT = gql`
  ${WALLET_TYPE_FRAGMENT}
  query GetWalletForEdit($_id: String!) {
    findOneWalletType(_id: $_id) {
      ...CoreWalletTypeFields
    }
  }
`;

export const ADD_WALLET_TYPE = gql`
  mutation AddWalletType($payload: CreateWalletTypeDto!) {
    createWalletType(createWalletTypeDto: $payload) {
      _id
    }
  }
`;

export const EDIT_WALLET_TYPE = gql`
  ${WALLET_TYPE_FRAGMENT}
  mutation EditWalletType($payload: UpdateWalletTypeDto!) {
    updateWalletType(updateWalletTypeDto: $payload) {
      ...CoreWalletTypeFields
    }
  }
`;

export const DELETE_WALLET_TYPE = gql`
  mutation DeleteWalletType($_id: String!) {
    removeWalletType(_id: $_id) {
      _id
    }
  }
`;
