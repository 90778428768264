import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';
import { StatusModal } from './StatusModal';

const useStyles = makeStyles(getStyles);

export const StatusModalContainer = () => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const { statusList } = useSelector(state => state.statusModal)

	return (
		<Box className={classes.container}>
			{statusList.map(element => (
				<StatusModal
					key={element.id}
					id={element.id}
					type={element.type}
					text={element.text}
				/>
			))}
		</Box>
	)
}