import blue from '@material-ui/core/colors/blue';

export const getStyles = theme => ({
	buttonInfo: {
		color: blue[200],
		padding: 0,
		width: 18,
		height: 18,
		'&:hover': {
			color: blue[300],
		},
		'& .MuiSvgIcon-root': {
			width: 18,
			height: 18,
		},
	}
});
