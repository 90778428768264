import {
	RESET_APP_DEP_PAYMENT,
	SET_APP_DEP_PAYMENT_SELECTED_DATE,
	SET_APP_DEP_PAYMENT_NOTES,
	SET_APP_DEP_PAYMENT_AMOUNT,
	SET_APP_DEP_PAYMENT_ACCOUNT,
	SET_APP_DEP_PAYMENT_PARTNERS_LIST,
	SET_APP_DEP_PAYMENT_PARTNER_RECEIVER,
	SET_APP_DEP_PAYMENT_PARTNER_RECEIVER_WALLET,
} from '../constants';

const initialState = {
	account: null,
	partnerReceiver: null,
	partnerReceiverWallet: null,
	partnersList: [],
	amount: '',
	notes: '',
	selectedDate: null,
}

export const appDepPayment = (state = initialState, action) => {
	switch(action.type) {
		case RESET_APP_DEP_PAYMENT: {
			return initialState;
		}
		case SET_APP_DEP_PAYMENT_SELECTED_DATE:
		case SET_APP_DEP_PAYMENT_AMOUNT:
		case SET_APP_DEP_PAYMENT_NOTES:
		case SET_APP_DEP_PAYMENT_ACCOUNT:
		case SET_APP_DEP_PAYMENT_PARTNERS_LIST:
		case SET_APP_DEP_PAYMENT_PARTNER_RECEIVER:
		case SET_APP_DEP_PAYMENT_PARTNER_RECEIVER_WALLET: {
			return {
				...state,
				...action.payload,
			};
		}
		default:
			return state;
	}
}