import {
  RESET_MODAL_TABLE_INFO_DATA,
  SET_MODAL_TABLE_INFO_DATA,
} from '../constants';

const initialState = {
  isShow: false,
  list: [],
}

export const modalTableInfo = (state = initialState, action) => {
  switch (action.type) {
    case RESET_MODAL_TABLE_INFO_DATA:
      return initialState;
    case SET_MODAL_TABLE_INFO_DATA:
      return {
        ...state,
        ...action.payload,
        isShow: true,
      };
    default:
      return state;
  }
}
