import React from 'react';
import { NavLink } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const TabNavigation = ({
  navigationList,
  eventItemClick,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleClick = () => {
    if (eventItemClick) {
      eventItemClick();
    }
  }

  return (
    <Box
      component='nav'
      className={classes.wrapper}
    >
      {
        navigationList && navigationList.map(elem => (
          <Box
            component={NavLink}
            activeClassName='active'
            className={classes.button}
            to={elem.link}
            exact={elem.exact}
            key={elem.link}
            onClick={handleClick}
          >
            {elem.title}
          </Box>
        ))
      }
    </Box>
  );
}
