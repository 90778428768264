import React, { Fragment } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const SelectMultiCustom = ({
  labelId,
  value,
  onChange,
  label,
  isCategory,
  variant,
  renderValue,
  list,
  children,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);


  return (
    <FormControl
      variant={variant ? variant : 'outlined'}
      className={classes.formControl}
    >
      <InputLabel id={labelId}>{ label }</InputLabel>
      <Select
        labelId={labelId}
        value={value}
        onChange={onChange}
        label={label}
        multiple
        renderValue={
          renderValue
            ? renderValue
            : (selected) => (
                <Fragment>
                  {selected.map((value) => {
                    const elem = (typeof value === 'number' || typeof value === 'string')
                      ? list.filter(e => e.id === value)
                      : [value];
                    return (
                      <Chip
                        key={elem[0].id}
                        label={elem[0].account ? elem[0].account : elem[0].name}
                      />
                    )
                  })}
                </Fragment>
              )
        }
        MenuProps={{
          classes: {
            paper: isCategory ? classes.wrapperIsCategory : classes.wrapper,
          }
        }}
      >
        { 
          children ? children : list && list.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              disabled={item.isCategoryName}
            >
              {item.account ? item.account : item.name}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
};
