export const getStyles = theme => ({
  container: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  tableCard: {
    maxWidth: 700,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: `1px solid ${theme.mainStyles.mainColorLink}`,
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${theme.mainStyles.mainColorLink}`,
    },
    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiTableCell-body': {
      color: theme.mainStyles.mainColorText,
    }
  }
});
