import { store } from '../../store';
import {
  setWalletTypesList,
} from '../../store/actions';
import {
  setEditWalletType,
} from '../../store/actions/editWalletType'
import {
  walletTypesForTableToParser,
  walletTypeToBackendWalletTypeParser,
  editWalletTypeToBackendWalletTypeParser,
  dataToEditWalletTypeParser,
} from './reformatters';

import {
  getLocalStorageData,
} from '../../utils';
import {
  GET_WALLET_TYPES_FOR_TABLE,
  GET_WALLET_TYPE_FOR_EDIT,
  ADD_WALLET_TYPE,
  EDIT_WALLET_TYPE,
  DELETE_WALLET_TYPE,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';

// export class Accounts {
  export async function walletTypesForTable() {
    await requestWrapper('WalletTypesForTable', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_WALLET_TYPES_FOR_TABLE)
        .setBearerToken(token)
        .build();

      const { findAllWalletTypes } = data || null;

      if (!loading && !error && findAllWalletTypes) {
        const reformattedWalletTypes = walletTypesForTableToParser(findAllWalletTypes);
        store.dispatch(setWalletTypesList(reformattedWalletTypes));
      } else {
      
      }
    });
  }

  export async function addWalletType(newWalletType) {
    await requestWrapper('AddWalletType', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(ADD_WALLET_TYPE)
        .setBearerToken(token)
        .setVariables({ payload: walletTypeToBackendWalletTypeParser(newWalletType)})
        .build();
      
      const { createWalletType } = data || null;

      if (!loading && !error && createWalletType?._id) {
        await walletTypesForTable();
      } else {
      
      }
    });
  }

  export async function getForEditWalletType(id) {
    await requestWrapper('GetForEditWalletType', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_WALLET_TYPE_FOR_EDIT)
        .setBearerToken(token)
        .setVariables({ _id: id, })
        .build();

      const { findOneWalletType } = data || null;

      if (!loading && !error && findOneWalletType) {
        store.dispatch(setEditWalletType(dataToEditWalletTypeParser(data)));
      } else {
      
      }
    });
  }

  export async function editWalletType(editedWalletType) {
    await requestWrapper('EditWalletType', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(EDIT_WALLET_TYPE)
        .setBearerToken(token)
        .setVariables({ payload: editWalletTypeToBackendWalletTypeParser(editedWalletType)})
        .build();
     
      const { updateWalletType } = data || null;
      const { _id } = updateWalletType || null;

      if (!loading && !error && _id) {
        await getForEditWalletType(_id);
        await walletTypesForTable();
      } else {
      
      }
    });
  }

  export async function deleteWalletType(id) {
    await requestWrapper('DeleteWalletType', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(DELETE_WALLET_TYPE)
        .setBearerToken(token)
        .setVariables({ _id: id, })
        .build();
      
      const { removeWalletType } = data || null;

      if (!loading && !error && removeWalletType?._id) {
        await walletTypesForTable();
      } else {
      
      }
    });
  }
// }
