import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { InputCustom } from '../../inputs';
import { ButtonCustom } from '../../buttons';
import { buttonTypes } from '../../buttons/ButtonCustom/constants';
import { SelectMultipleCustom } from '../../selects/SelectMultipleCustom';

import { getStyles } from './styles';
import { usaStatesList } from '../../../../utils/static-data';

const useStyles = makeStyles(getStyles);

export const ModalPartner = ({
  open,
  eventApply,
  eventClose,
  title,
  partnerName,
  partnerType,
  terms,
  location,
  sharePercent,
  setPartnerName,
  setPartnerType,
  setTerms,
  setLocation,
  setSharePercent,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [partnerNameError, setPartnerNameError] = useState(false);
  const [partnerTypeError, setPartnerTypeError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [sharePercentError, setSharePercentError] = useState(false);

  const usaStatesMemoList = useMemo(() => usaStatesList.map(state => ({ id: state, name: state })), []);

  const handleChangeLocation = elem => {
    setLocation(usaStatesMemoList.find(el => el.id === elem.id));
  }

  const validate = () => {
    let valid = true;
    if (!Boolean(partnerName)) {
      valid = false;
      setPartnerNameError(true);
    }
    if (!Boolean(partnerType)) {
      valid = false;
      setPartnerTypeError(true);
    }
    if (!Boolean(location)) {
      valid = false;
      setLocationError(true);
    }
    if (!Boolean(sharePercent) && sharePercent !== 0) {
      valid = false;
      setSharePercentError(true);
    }
    return valid;
  }

  const sendApplyData = () => {
    if (validate()) {
      eventApply();
    }
  }

  return (
    <Box
      component='div'
    >
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        className={classes.modalContent}
      >
        <Box
          component='div'
        >
          <IconButton
            aria-label="close"
            className={classes.buttonClose}
            onClick={eventClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            id='alert-dialog-title'
            className={classes.title}
          >
            {title ? title : 'Create'}
          </DialogTitle>
          <DialogContent>

            <Box
              component='div'
              className={classes.rowWrapper}
            >
              <Box
                component='div'
                className={classes.colWrapper_50}
              >
                <InputCustom
                  label="Partner Name"
                  error={partnerNameError}
                  value={partnerName}
                  onChange={e => {
                    setPartnerName(e.target.value);
                    setPartnerNameError(false);
                  }}
                />
              </Box>
              <Box
                component='div'
                className={classes.colWrapper_50}
              >
                <InputCustom
                  label="Partner Type"
                  error={partnerTypeError}
                  value={partnerType}
                  onChange={e => {
                    setPartnerType(e.target.value);
                    setPartnerTypeError(false);
                  }}
                />
              </Box>
            </Box>

            <Box
              component='div'
              className={classes.rowWrapper}
            >
              <Box
                component='div'
                className={classes.colWrapper_50}
              >
                <InputCustom
                  label="Share Percent"
                  error={sharePercentError}
                  value={sharePercent}
                  onChange={e => {
                    setSharePercent(e.target.value);
                    setSharePercentError(false);
                  }}
                />
              </Box>
              <Box
                component='div'
                className={classes.colWrapper_50}
              >
                <SelectMultipleCustom
                  // label='Location'
                  placeholder='Location'
                  onChange={(e, elem) => {
                    handleChangeLocation(elem);
                    setLocationError(false);
                  }}
                  value={location}
                  error={locationError}
                  disableClearable
                  list={usaStatesMemoList}
                  optionTitle='name'
                  // disabled={disabledList.find(el => el === 'SiteType')}
                />
              </Box>
            </Box>

            <Box
              component='div'
              className={classes.rowWrapper}
            >
              <Box
                component='div'
                className={classes.colWrapper_100}
              >
                <InputCustom
                  label="Terms"
                  error={termsError}
                  value={terms}
                  onChange={e => {
                    setTerms(e.target.value);
                    setTermsError(false);
                  }}
                />
              </Box>
            </Box>

          </DialogContent>
          <DialogActions
            className={classes.formButtons}
          >
            <ButtonCustom
              onClick={eventClose}
              customType={buttonTypes.CANCEL}
            />

            <ButtonCustom
              autoFocus
              onClick={sendApplyData}
              customType={buttonTypes.CREATE}
              text='Apply'
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
