export const getStyles = theme => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.mainStyles.mainBackgroundColor,
    position: 'relative',
  },
})
