export const getStyles = theme => ({
  buttonInfo: {
    padding: 0,
    width: 18,
    height: 18,
    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18,
    }
  },
});
