import React from 'react';
import moment from 'moment';

import { createStyles } from '@material-ui/styles';
import { Toolbar, Button } from '@material-ui/core';

export const styles = theme =>
  createStyles({
    toolbar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      height: 100,
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.primary.main
          : theme.palette.background.default
    },
    label: {
      color:
        theme.palette.type === "light"
          ? theme.palette.primary.contrastText
          : theme.palette.background.default
    }
  });

export const ToolbarComponent = ({ setOpenView, date, isOneDay }) => {
  const today = moment(date);
  const weekNumber = today.clone().weekday(0).week();
  const year = today.clone().weekday(0).year();
  const month = today.clone().weekday(0).format("MMM");

  const fromDate = today.startOf("week").date();
  const toDate = today.endOf("week").date();

  return (
    <Toolbar className="MuiToolbar-root MuiToolbar-regular MuiPickersToolbar-toolbar MuiPickersDatePickerRoot-toolbar MuiToolbar-gutters">
      <div>
        <Button onClick={() => setOpenView("year")}>
          <span className="MuiPickersToolbar-label">
            <div className="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiTypography-subtitle1">
              {year} Week {weekNumber}
            </div>
          </span>
          <span className="MuiTouchRipple-root" />
        </Button>
      </div>
      <div>
        <Button onClick={() => setOpenView("date")}>
          <span className="MuiPickersToolbar-label">
            <div className="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiPickersToolbarText-toolbarBtnSelected MuiTypography-h6 MuiTypography-alignCenter">
              {isOneDay ? today.format("MMM") : month} {isOneDay ? date.clone().format("DD") : `${fromDate} to ${toDate}`}
            </div>
          </span>
          <span className="MuiTouchRipple-root" />
        </Button>
      </div>
    </Toolbar>
  );
};
