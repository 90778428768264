import {
  RESET_EDIT_PARTNER,
  SET_EDIT_PARTNER,
  SET_EDIT_PARTNER_NAME,
  SET_EDIT_PARTNER_TYPE,
  SET_EDIT_PARTNER_TERMS,
  SET_EDIT_PARTNER_LOCATION,
  SET_EDIT_PARTNER_SHARE_PERCENT,
} from '../constants';

const initialState = {
  id: null,
  partnerName: '',
  partnerType: '',
  terms: '',
  location: null,
  sharePercent: '',
}

export const editPartner = (state = initialState, action) => {
  switch (action.type) {
    case RESET_EDIT_PARTNER:
      return initialState;
    case SET_EDIT_PARTNER:
      return action.payload.partner;
    case SET_EDIT_PARTNER_NAME:
    case SET_EDIT_PARTNER_TYPE:
    case SET_EDIT_PARTNER_TERMS:
    case SET_EDIT_PARTNER_LOCATION:
    case SET_EDIT_PARTNER_SHARE_PERCENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
