import React, { useState } from 'react';
import parse from 'html-react-parser';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Message from '@material-ui/icons/Message';

import { ButtonIconCustom, ModalText } from '../../UIComponents';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const TelegramShowMessage = ({
	name,
	message,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	const [ openModal, setOpenModal ] = useState(false);

	return (
		<Box>
			<ButtonIconCustom
				icon={<Message />}
				className={classes.buttonInfo}
				onClick={() => setOpenModal(true)}
			/>
			{openModal && (
				<ModalText
					open={openModal}
					title={name}
					text={parse(message)}
					textPreFormat={true}
					eventClose={() => setOpenModal(false)}
				/>
			)}
		</Box>
	)
}