import {
  RESET_EDIT_PARTNER,
  SET_EDIT_PARTNER,
  SET_EDIT_PARTNER_NAME,
  SET_EDIT_PARTNER_TYPE,
  SET_EDIT_PARTNER_TERMS,
  SET_EDIT_PARTNER_LOCATION,
  SET_EDIT_PARTNER_SHARE_PERCENT,
} from '../constants';

export const resetEditPartner = () => ({
  type: RESET_EDIT_PARTNER,
});

export const setEditPartner = partner => ({
  type: SET_EDIT_PARTNER,
  payload: {
    partner,
  }
});

export const setEditPartnerName = partnerName => ({
  type: SET_EDIT_PARTNER_NAME,
  payload: {
    partnerName,
  },
});

export const setEditPartnerType = partnerType => ({
  type: SET_EDIT_PARTNER_TYPE,
  payload: {
    partnerType,
  },
});

export const setEditPartnerTerms = terms => ({
  type: SET_EDIT_PARTNER_TERMS,
  payload: {
    terms,
  },
});

export const setEditPartnerLocation = location => ({
  type: SET_EDIT_PARTNER_LOCATION,
  payload: {
    location,
  },
});

export const setEditPartnerSharePercent = sharePercent => ({
  type: SET_EDIT_PARTNER_SHARE_PERCENT,
  payload: {
    sharePercent,
  },
});
