import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ButtonCustom } from '../../buttons';
import { buttonTypes } from '../../buttons/ButtonCustom/constants';


import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const ModalDelete = ({
  dialogMode,
  open,
  eventDelete,
  eventClose,
  title,
  description,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-delete-title'
        aria-describedby='alert-dialog-delete-description'
        className={classes.modalContent}
      >
        <Box>
          <IconButton
            aria-label="close"
            className={classes.buttonClose}
            onClick={eventClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            id='alert-dialog-delete-title'
            className={classes.title}
          >
            {title ? title : 'Delete'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id='alert-dialog-delete-description'
              className={classes.text}
            >
              {description ? description : 'Are you sure you want to delete this?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            className={classes.formButtons}
          >
            <ButtonCustom
              onClick={eventClose}
              customType={buttonTypes.CANCEL}
            />
            <ButtonCustom
              autoFocus
              onClick={eventDelete}
              customType={dialogMode ? buttonTypes.BASIC : buttonTypes.DELETE}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
