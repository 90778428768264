export const getStyles = theme => ({
  modalContent: {
    height: '100%',
    '& .MuiDialog-paper': {
      backgroundColor: theme.mainStyles.mainBackgroundColor,
      color: theme.mainStyles.mainColorText,
    },
  },
  title: {
    padding: '20px 24px',
    '& .MuiTypography-root': {
      fontSize: '18px',
      color: theme.mainStyles.mainColorText,
      paddingRight: '50px',
    }
  },
  buttonClose: {
    position: 'absolute',
    right: '6px',
    top: '10px',
    color: theme.mainStyles.mainColorText,
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContent: {
    '& .MuiPaper-root': {
      height: '100%',
    }
  },
  totalContainer: {
    marginBottom: 15,
    fontSize: 14,
    display: 'flex',
  },
  totalLabel: {
    color: theme.mainStyles.mainColorLink,
    marginRight: 10,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 60,
    alignItems: 'center',
  }
})
