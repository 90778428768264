import {
  SET_REPORTS_LIST,
  SET_REPORTS_SELECTED_DATE,
  SET_REPORTS_SELECTED_TYPE,
  SET_REPORTS_TOTAL_SHARE_AMOUNT,
  EDIT_REPORTS_LIST_ITEM_TELEGRAM_CHECKED,
  EDIT_REPORTS_LIST_ITEM_CONFIRMED_CHECKED,
  RESET_REPORTS,
  RESET_REPORTS_LIST,
  RESET_REPORTS_SEND_PARTNER_IDS_LIST,
} from '../constants';

const initialState = {
  reportsList: [],
  selectedDate: null,
  selectedType: null,
  totalShareAmount: 0,
}

export const reports = (state = initialState, action) => {
  switch (action.type) {
    case RESET_REPORTS_LIST:
      return {
        ...state,
        reportsList: [],
      }
    case RESET_REPORTS_SEND_PARTNER_IDS_LIST:
      return {
        ...state,
        reportsSendPartnerIdsList: initialState.reportsSendPartnerIdsList,
      }
    case RESET_REPORTS:
      return initialState;
    case EDIT_REPORTS_LIST_ITEM_TELEGRAM_CHECKED:
      return {
        ...state,
        reportsList: state.reportsList.map(item => {
          if (item?.telegram?.id === action.payload.partnerId) {
            return {
              ...item,
              telegram: {
                ...item.telegram,
                checked: !item.telegram.checked
              }
            };
          }

          return item;
        })
      };
    case EDIT_REPORTS_LIST_ITEM_CONFIRMED_CHECKED:
      return {
        ...state,
        reportsList: state.reportsList.map(item => {
          if (item?.confirmed?.id === action.payload.partnerId) {
            return {
              ...item,
              confirmed: {
                ...item.confirmed,
                checked: !item.confirmed.checked
              }
            };
          }

          return item;
        }),
      };
    case SET_REPORTS_LIST:
    case SET_REPORTS_SELECTED_DATE:
    case SET_REPORTS_SELECTED_TYPE:
    case SET_REPORTS_TOTAL_SHARE_AMOUNT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
