import {
  SET_TRANSACTIONS_LIST,
  SET_TRANSACTIONS_SUB_TYPE,
  SET_TRANSACTION_TYPE,
  RESET_TRANSACTIONS_PAGE,
  SET_TRANSACTION_FILTERED_LIST,
} from '../constants';

const initialState = {
  transactionsList: [],
  transactionsFilteredList: [],
  transactionType: null,
}

export const transactions = (state = initialState, action) => {
  switch (action.type) {
    case RESET_TRANSACTIONS_PAGE:
      return initialState;
    case SET_TRANSACTIONS_LIST:
    case SET_TRANSACTION_TYPE:
    case SET_TRANSACTION_FILTERED_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case SET_TRANSACTIONS_SUB_TYPE:
      return {
        ...state,
        transactionsList: state.transactionsList.map(item => ({
          ...item,
          subType: item.id === action.payload.id
            ? {
              ...item.subType,
              value: action.payload.value,
            }
            : item.subType,
        }))
      }
    default:
      return state;
  }
}
