import { removeAccountItem, startStopUpdateAccount, store } from '../../store';
import {
  setAccountsList,
  setCreateAccountPartnersList,
  setCreateAccountSiteTypesList,
  setEditAccount,
} from '../../store';
import {
  accountsForTableToParser,
  partnersToAccountPartnersListParser,
  siteTypesToAccountSiteTypesListParser,
  accountToBackendAccountParser,
  dataToEditAccountParser,
  editAccountToBackendAccountParser,
  partnersWithSharePercentToAccountPartnersListParser,
} from './reformatters';

import {
  getLocalStorageData,
} from '../../utils';
import {
  GET_ACCOUNTS_FOR_TABLE,
  GET_PARTNERS_AND_SITE_TYPES,
  GET_ACCOUNT_AND_LISTS_FOR_EDIT,
  ADD_ACCOUNT,
  EDIT_ACCOUNT,
  DELETE_ACCOUNT,
  START_STOP_ACCOUNT,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';

// export class Accounts {
  export async function accountsForTable(type) {
    await requestWrapper('AccountsForTable', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_ACCOUNTS_FOR_TABLE)
        .setBearerToken(token)
        .setVariables({
          filter: {
            type,
          },
          skipShortAccountName: type !== 'Application',
          skipUrl: type !== 'Deposit' && type !== 'PayPerHead',
          skipUsername: type === 'HandBets',
          skipPassword: type === 'HandBets',
          skipSiteType: type !== 'PayPerHead',
          skipSubType: type !== 'PayPerHead' && type !== 'HandBets',
        })
        .build();

      const { accounts } = data || null;

      if (!loading && !error && accounts) {
        const reformattedAccounts = accountsForTableToParser(accounts);
        store.dispatch(setAccountsList(reformattedAccounts));
      } else {

      }
    });
  }

  export async function getNetworksAndSiteTypesForAccount() {
    await requestWrapper('GetNetworksAndSiteTypesForAccount', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_PARTNERS_AND_SITE_TYPES)
        .setBearerToken(token)
        .build();

      const { partners, networks } = data || null;

      if (!loading && !error && partners && networks) {
        partners && store.dispatch(setCreateAccountPartnersList(partnersWithSharePercentToAccountPartnersListParser(partners)));
        networks && store.dispatch(setCreateAccountSiteTypesList(siteTypesToAccountSiteTypesListParser(networks)));
      } else {

      }
    });
  }

  export async function addAccount({
    newAccount,
    type,
  }) {
    await requestWrapper('AddAccount', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(ADD_ACCOUNT)
        .setBearerToken(token)
        .setVariables({ payload: accountToBackendAccountParser(newAccount)})
        .build();

      const { createAccount } = data || null;

      if (!loading && !error && createAccount?._id) {
        await accountsForTable(type);
      } else {

      }
    });
  }

  export async function getForEditAccount(id) {
    if (!id) return;
    await requestWrapper('GetForEditAccount', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_ACCOUNT_AND_LISTS_FOR_EDIT)
        .setBearerToken(token)
        .setVariables({ _id: id, })
        .build();

      const { account, partners, networks } = data || null;

      if (!loading && !error && account && partners && networks) {
        store.dispatch(setEditAccount(dataToEditAccountParser(data)));
      } else {

      }
    });
  }

  export async function editAccount({
    editedAccount,
    type,
  }) {
    await requestWrapper('EditAccount', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(EDIT_ACCOUNT)
        .setBearerToken(token)
        .setVariables({ payload: editAccountToBackendAccountParser(editedAccount)})
        .build();

      const { updateAccount } = data || null;
      const { _id } = updateAccount || null;

      if (!loading && !error && _id) {
        // await getForEditAccount(_id);
        await accountsForTable(type);
      } else {

      }
    });
  }

  export async function editAccountWithoutUpdate({
    editedAccount,
  }) {
    await requestWrapper('EditAccount', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(EDIT_ACCOUNT)
        .setBearerToken(token)
        .setVariables({ payload: editAccountToBackendAccountParser(editedAccount)})
        .build();

      const { updateAccount } = data || null;
      const { _id } = updateAccount || null;

      if (!loading && !error && _id) {
        // await getForEditAccount(_id);
      } else {

      }
    });
  }

export async function startStopAccount({
  accountId,
	stopDateUTC = null,
  stop,
}) {
  await requestWrapper('StartStopAccount', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(START_STOP_ACCOUNT)
      .setBearerToken(token)
      .setVariables({
        payload: {
          _id: accountId,
          stop,
          stopDateUTC,
        }})
      .build();

    const { startStopAccount } = data || null;
    const { _id } = startStopAccount || null;

    if (!loading && !error && _id) {
      store.dispatch(startStopUpdateAccount({
        id: startStopAccount?._id,
        date: startStopAccount?.stopDateUTC,
        stop: startStopAccount?.stop,
        status: startStopAccount?.status,
      }))
    } else {

    }
  });
}

  export async function deleteAccount({
    id,
    type,
  }) {
    await requestWrapper('DeleteAccount', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(DELETE_ACCOUNT)
        .setBearerToken(token)
        .setVariables({ _id: id, })
        .build();

      const { deleteAccount } = data || null;

      if (!loading && !error && deleteAccount?._id) {
        store.dispatch(removeAccountItem(deleteAccount._id));
      } else {

      }
    });
  }
// }
