import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { TabNavigation } from '../../components/TabNavigation';
import { WeekSwitcher } from '../../components/WeekSwitcher';
import { ButtonCustom } from '../../components/UIComponents';
import { buttonTypes } from '../../components/UIComponents/buttons/ButtonCustom/constants';
import {
  getAgentsPartnersReports,
  sendReportsToTelegram,
  walletsReportsForTable,
} from '../../api';
import {
  resetReports as resetReportsAction,
  resetReportsList as resetReportsListAction,
  resetReportsSendPartnerIdsList as resetReportsSendPartnerIdsListAction,
  setReportsSelectedDate as setReportsSelectedDateAction,
} from '../../store';
import {
  getReportsListSelector,
  getReportsSelectedDateSelector,
  getReportsSelectedTypeSelector,
} from './selectors';
import { navigationList } from './constants';
import moment from 'moment';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const ReportsPageContainer = ({
  children,
  selectedDate,
  selectedType,
  reportsList,
  setReportsSelectedDate,
  resetReports,
  resetReportsList,
  resetReportsSendPartnerIdsList,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const today = moment().subtract(1, 'weeks');

  useEffect(() => {
    const firstDay = today.clone().weekday(0);
    setReportsSelectedDate(firstDay);

    return () => resetReports();
  }, []);

  useEffect(() => {
    resetReportsList();
  }, [selectedDate]);

  const handleButtonClick = () => {
    if (selectedDate) {
      const week = selectedDate.week();
      const year = selectedDate.year();
      if (selectedType !== 'Wallets') {
        const getReportsList = async () => getAgentsPartnersReports({
          accountType: selectedType,
          week,
          year,
        });
        getReportsList().then(() => Promise.resolve());
      } else {
        const getReportsWallets = async () => walletsReportsForTable({
          year,
          week,
          isPrivate: false,
          isObject: false,
        });

        getReportsWallets().then(() => Promise.resolve());
      }
    }
  }

  const handleSendClick = () => {
    if (selectedDate) {
      const week = selectedDate.week();
      const year = selectedDate.year();

      const checkedReports = reportsList
        ?.filter(item => item?.telegram?.checked)
        ?.map(item => item?.telegram?.id);

      if (selectedType !== 'Wallets' && checkedReports?.length > 0) {
        Promise.resolve(sendReportsToTelegram({
          accountType: selectedType,
          year,
          week,
          partnerIds: checkedReports,
        }))
          .then(resetReportsSendPartnerIdsList());
      }
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.pickerContainer}>
        <WeekSwitcher
          selectedDate={selectedDate}
          handleChangeSelectedDate={setReportsSelectedDate}
        />
        <ButtonCustom
          onClick={handleButtonClick}
          customType={buttonTypes.EDIT}
          text='Show report'
        />
        {
          selectedType !== 'Wallets' &&
          <ButtonCustom
            onClick={handleSendClick}
            customType={buttonTypes.EDIT}
            text='Send reports'
          />
        }
      </Box>
      <TabNavigation
        navigationList={navigationList}
        eventItemClick={() => resetReportsList()}
      />

      <Box className={classes.content}>
        { children }
      </Box>
    </Box>
  );
}

const mapStateToProps = state => ({
  selectedDate: getReportsSelectedDateSelector(state),
  selectedType: getReportsSelectedTypeSelector(state),
  reportsList: getReportsListSelector(state),
})

const mapDispatchToProps = {
  setReportsSelectedDate: setReportsSelectedDateAction,
  resetReports: resetReportsAction,
  resetReportsList: resetReportsListAction,
  resetReportsSendPartnerIdsList: resetReportsSendPartnerIdsListAction,
};

export const ReportsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsPageContainer);
