export const getStyles = theme => ({
  wrapper: {
    width: '100%',
    color: theme.SelectsStyles.colorText,
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: theme.SelectsStyles.backgroundColor,
      border: `1px solid ${theme.SelectsStyles.colorText}`,
      borderRadius: '3px',
      minHeight: '39px',
    },
    '& .MuiAutocomplete-inputRoot.Mui-error': {
      border: `1px solid #ff0000`,
    },
    '& .MuiInput-underline.Mui-error:after': {
      border: 'none',
    },
    '& .MuiAutocomplete-inputFocused': {
      color: theme.SelectsStyles.colorText,
      paddingLeft: '5px',
    },
    '& .MuiIconButton-root': {
      color: theme.SelectsStyles.colorText,
    },
    '& .MuiInputLabel-formControl': {
      color: theme.SelectsStyles.labelColorText,
      zIndex: 10,
      left: '15px',
      top: '5px',
    },
    // '& .MuiInput-formControl': {
    //   marginTop: 0,
    // },
    '& .MuiInputLabel-formControl.MuiInputLabel-shrink': {
      top: '0px',
    },
    '& .MuiChip-root': {
      backgroundColor: theme.SelectsStyles.backgroundColor,
      color: theme.SelectsStyles.colorText,
      border: `1px solid ${theme.SelectsStyles.colorText}`,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.SelectsStyles.colorText,
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '1px',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: '15px',
    },
    '& .MuiAutocomplete-inputRoot.Mui-disabled': {
      opacity: 0.8,
      border: 'none',
    }
  },
  list: {
    backgroundColor: theme.SelectsStyles.backgroundColor,
    color: theme.SelectsStyles.colorText,
    maxHeight: '350px',
    overflowY: 'auto',
    '& .MuiAutocomplete-groupLabel': {
      backgroundColor: theme.SelectsStyles.backgroundColorLabel,
      color: theme.SelectsStyles.colorCategoryText,
    },
    '& .MuiAutocomplete-option:hover': {
      backgroundColor: theme.SelectsStyles.backgroundColorHover,
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: theme.SelectsStyles.backgroundColorHover,
    },
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: theme.SelectsStyles.backgroundColorItemSelected,
    },
    '& .inactive': {
      opacity: 0.4,
    },
    '& .inactive-desc': {
      marginLeft: 5,
    },
  },
})
