import React from 'react'

import Button from '@material-ui/core/Button';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const ButtonSubmit = ({
  text,
  size,
  onClick,
  variant,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      variant={variant ? variant : 'contained'}
      size={size ? size : 'small'}
      type='submit'
      className={classes.button}
      onClick={onClick ? () => onClick() : null}
    >
      {text ? text : 'Submit'}
    </Button>
  )
}
