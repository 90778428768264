import { gql } from '@apollo/client';

export const GET_AGENTS_PARTNERS_REPORTS_FOR_TABLE = gql`
  query GetAgentsPartnersReports(
    $getAgentsPartnerDto: GetAgentsPartnerDto!
  ) {
    agentsPartners(getAgentsPartnerDto: $getAgentsPartnerDto) {
      partner {
        _id
        name
      }
      telegramMessage
      weeklyFigure
      weeklyFiguresList {
        _id
        account {
          _id
          username
          password
          sharePercent
          subOwnerPercent
          subOwner {
            name
          }
          type
          subType
          url
        }
        subOwnerPercent
        sharePercent
        redLineAmt
        shareAmt
        amount
        week
        year
      }
      previousCarry
      balance
      shareAmt
      settled
      redLineAmt
      shareAmt
      previousMakeUp
      subOwnerAmount
      makeUp
      confirmed
      partnerApprove {
        status
        approveMessage
        feedback
      }
      net
      telegramActivated
    }
  }
`;

export const UPDATE_PARTNER_WEEKLY_FIGURE = gql`
  mutation createOrUpdateReportsConfirmation($createReportsConfirmationInput: CreateReportsConfirmationInput!) {
    createOrUpdateReportsConfirmation(createReportsConfirmationInput: $createReportsConfirmationInput)
  }
`;
