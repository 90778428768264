import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { ApolloProvider } from '@apollo/client/core';

import { App } from './components/App';
import { store } from './store';
// import { apolloClient } from './utils';

import 'typeface-roboto';

import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    {/* <ApolloProvider client={apolloClient}> */}
      <Provider store={store}>
        <App />
      </Provider>
    {/* </ApolloProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);