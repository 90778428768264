import {
  RESET_CREATE_WALLET,
  SET_CREATE_WALLET_PARTNER,
  SET_CREATE_WALLET_TYPE,
  SET_CREATE_WALLET_IS_PRIVATE,
  SET_CREATE_WALLET_NOTES,
  SET_CREATE_WALLET_PARTNERS_LIST,
  SET_CREATE_WALLET_WALLET_TYPES_LIST,
} from '../../constants';

const initialState = {
  partner: '',
  type: '',
  isPrivate: false,
  notes: '',
  partnersList: [],
  typesList: [],
}

export const createWallet = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CREATE_WALLET:
      return initialState;
    case SET_CREATE_WALLET_PARTNER:
    case SET_CREATE_WALLET_TYPE:
    case SET_CREATE_WALLET_IS_PRIVATE:
    case SET_CREATE_WALLET_NOTES:
    case SET_CREATE_WALLET_PARTNERS_LIST:
    case SET_CREATE_WALLET_WALLET_TYPES_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
