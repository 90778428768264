import React from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const SelectMultipleCustom = ({
  label,
  placeholder,
  onChange,
  value,
  disableClearable,
  list = [],
  error,
  multiple,
  defaultValue = [],
  optionTitle = 'name',
  optionGroup = false,
  optionGroupByName = 'category',
  renderOption,
  disabled,
  hidden,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.wrapper}>
      <Autocomplete
        multiple={multiple}
        fullWidth
        variant="outlined"
        options={list}
        groupBy={optionGroup ? option => option[optionGroupByName] : null}
        getOptionLabel={option => {
          if (Array.isArray(optionTitle)) {
            if (!option[optionTitle[0]]) {
              return null;
            }
            return `${option[optionTitle[0]]} (${option[optionTitle[1]]})`;
          }
          return option[optionTitle]
        }}
        disableClearable={disableClearable}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value || []}
        disabled={disabled}
        ListboxProps={{
          className: classes.list,
        }}
        hidden={hidden}
        renderOption={renderOption}
        renderInput={params => {
          return (
          <TextField
            {...params}
            variant="standard"
            className={classes.textInput}
            label={label || null}
            error={error}
            placeholder={placeholder || null}
          />
        )}}
      />
    </div>
  );
}
