import {
  RESET_MODAL_TABLE_INFO_DATA,
  SET_MODAL_TABLE_INFO_DATA,
} from '../constants';

export const resetModalTableInfoData = () => ({
  type: RESET_MODAL_TABLE_INFO_DATA,
})

export const setModalTableInfoData = (data) => ({
  type: SET_MODAL_TABLE_INFO_DATA,
  payload: {
    ...data,
  },
});
