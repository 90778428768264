import {
  RESET_CREATE_WALLET,
  SET_CREATE_WALLET_NOTES,
  SET_CREATE_WALLET_PARTNER,
  SET_CREATE_WALLET_TYPE,
  SET_CREATE_WALLET_IS_PRIVATE,
  SET_CREATE_WALLET_PARTNERS_LIST,
  SET_CREATE_WALLET_WALLET_TYPES_LIST,
} from '../../constants';

export const resetCreateWallet = () => ({
  type: RESET_CREATE_WALLET,
});

export const setCreateWalletType = type => ({
  type: SET_CREATE_WALLET_TYPE,
  payload: {
    type,
  },
});

export const setCreateWalletIsPrivate = isPrivate => ({
  type: SET_CREATE_WALLET_IS_PRIVATE,
  payload: {
    isPrivate,
  },
});

export const setCreateWalletPartner = partner => ({
  type: SET_CREATE_WALLET_PARTNER,
  payload: {
    partner,
  },
});

export const setCreateWalletNotes = notes => ({
  type: SET_CREATE_WALLET_NOTES,
  payload: {
    notes,
  },
});

export const setCreateWalletPartnersList = partnersList => ({
  type: SET_CREATE_WALLET_PARTNERS_LIST,
  payload: {
    partnersList,
  },
});

export const setCreateWalletWalletTypesList = typesList => ({
  type: SET_CREATE_WALLET_WALLET_TYPES_LIST,
  payload: {
    typesList,
  },
});
