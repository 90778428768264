import {
  SET_NETWORKS_LIST,
} from '../../constants';

const initialState = {
  networksList: [],
}

export const networks = (state = initialState, action) => {
  switch (action.type) {
    case SET_NETWORKS_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}