import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import {
  resetPartnersTelegramCodesList as resetPartnersTelegramCodesListAction,
} from '../../../store';
import {
  getPartnersTelegramCodesListSelector,
} from './selectors';

import { InputCustom } from '../../../components/UIComponents';
import { ModalDelete } from '../../../components/UIComponents/modals';
import { getStyles } from './styles';
import {
  getTelegramUsersForTable,
  deletePartnerTelegramCode,
} from '../../../api';
import { TableComponent } from '../../../components/TableComponent';
import {
  filterObjArray,
  removeBlacklistedKeys,
} from '../../../utils';
import { sortEvent } from '../../../utils/sortingService';
import {
  telegramUsersPageColumn,
  telegramUsersCodesSort,
} from './constants';

const useStyles = makeStyles(getStyles);

const UsersPageContainer = ({
  partnerTelegramCodesList,
  resetPartnersTelegramCodesList,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [partnerTelegramCodeId, setPartnerTelegramCodeId] = useState(null);

  const [handleSortBy, setHandleSortBy] = useState(false);
  const [handleSortDirection, setHandleSortDirection] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    const getTelegramUsers = async () =>
      await getTelegramUsersForTable();

    getTelegramUsers().then(() => Promise.resolve());

    return () => {
      resetPartnersTelegramCodesList();
    }
  }, []);

  useEffect(() => {
    setFilteredList(searchValue
      ? filterObjArray(partnerTelegramCodesList, searchValue, ['id', 'action'])
      : partnerTelegramCodesList
    );
  }, [partnerTelegramCodesList, searchValue]);

  useEffect(() => {
    if (showModalDelete === false && partnerTelegramCodesList?.length > 0) {
      const getTelegramUsers = async () =>
      await getTelegramUsersForTable();

      getTelegramUsers().then(() => Promise.resolve());
    }
  }, [showModalDelete]);

  const openModalDeleteAction = id => {
    setShowModalDelete(true);
    setPartnerTelegramCodeId(id);
  };

  const closeModalDeleteAction = () => setShowModalDelete(false);

  const deletePartnerTelegramCodeById = id => {
    Promise.resolve(deletePartnerTelegramCode({
        id,
      }))
      .then(setPartnerTelegramCodeId(null))
      .then(closeModalDeleteAction());
  }

  const sort = sortEvent({
    handleSortBy,
    sortData: telegramUsersCodesSort,
    setHandleSortDirection,
    setHandleSortBy,
    list: partnerTelegramCodesList,
  });

  const handleBlurInput = event => {
    const value = event.target.value;
    setSearchValue(value);

    setFilteredList(value
      ? filterObjArray(partnerTelegramCodesList, value, ['id', 'action'])
      : partnerTelegramCodesList
    );
  }

  return (
    <Box className={classes.container}>
      <InputCustom
        className={classes.input}
        value={searchValue}
        onChange={handleBlurInput}
      />

      <TableComponent
        columns={telegramUsersPageColumn}
        rows={filteredList}
        deleteRowAction={openModalDeleteAction}
        sort={sort}
        sortBy={handleSortBy}
        sortDirection={handleSortDirection}
      />

      <ModalDelete
        open={showModalDelete}
        title={'Delete Partner Telegram Code'}
        description={'Are you sure you want to delete this partner telegram code?'}
        eventDelete={() => deletePartnerTelegramCodeById(partnerTelegramCodeId)}
        eventClose={closeModalDeleteAction}
      />
    </Box>
  );
}

const mapStateToProps = state => ({
  partnerTelegramCodesList: getPartnersTelegramCodesListSelector(state),
});

const mapDispatchToProps = {
  resetPartnersTelegramCodesList: resetPartnersTelegramCodesListAction,
};

export const UsersPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPageContainer);
