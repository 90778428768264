import { numberToDollar } from '../../../utils';

export const getText = data => (
`Site Name:      ${data?.site_name ?? ''}
Username:       ${data?.username ?? ''}
Agent Name:     ${data?.agent_name ?? ''}
Monday:         ${data?.monday ? numberToDollar(data.monday) : ''}
Tuesday:        ${data?.tuesday ? numberToDollar(data.tuesday) : ''}
Wednesday:      ${data?.wednesday ? numberToDollar(data.wednesday) : ''}
Thursday:       ${data?.thursday ? numberToDollar(data.thursday) : ''}
Friday:         ${data?.friday ? numberToDollar(data.friday) : ''}
Saturday:       ${data?.saturday ? numberToDollar(data.saturday) : ''}
Sunday:         ${data?.sunday ? numberToDollar(data.sunday) : ''}
Result:         ${data?.result ? numberToDollar(data.result) : ''}
Volume:         ${data?.volume ? numberToDollar(data.volume) : ''}
Figure Update:  ${data?.figure_update ?? ''}

`);

export const getRowData = data => {
	const array = [];
	array.push({
		label: 'Site Name',
		value: data?.site_name ?? '',
	});
	array.push({
		label: 'User Name',
		value: data?.username ?? '',
	});
	array.push({
		label: 'Agent Name',
		value: data?.agent_name ?? '',
	});
	array.push({
		label: 'Monday',
		value: data?.monday ? numberToDollar(data.monday) : '',
		isDayOfWeek: true,
	});
	array.push({
		label: 'Tuesday',
		value: data?.tuesday ? numberToDollar(data.tuesday) : '',
		isDayOfWeek: true,
	});
	array.push({
		label: 'Wednesday',
		value: data?.wednesday ? numberToDollar(data.wednesday) : '',
		isDayOfWeek: true,
	});
	array.push({
		label: 'Thursday',
		value: data?.thursday ? numberToDollar(data.thursday) : '',
		isDayOfWeek: true,
	});
	array.push({
		label: 'Friday',
		value: data?.friday ? numberToDollar(data.friday) : '',
		isDayOfWeek: true,
	});
	array.push({
		label: 'Saturday',
		value: data?.saturday ? numberToDollar(data.saturday) : '',
		isDayOfWeek: true,
	});
	array.push({
		label: 'Sunday',
		value: data?.sunday ? numberToDollar(data.sunday) : '',
		isDayOfWeek: true,
	});
	array.push({
		label: 'Result',
		value: data?.result ? numberToDollar(data.result) : '',
		isResult: true,
	});
	array.push({
		label: 'Volume',
		value: data?.volume ? numberToDollar(data.volume) : '',
	});
	array.push({
		label: 'Figure Update',
		value: data?.figure_update ?? '',
	});
	return array;
}