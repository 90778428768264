import { INFO_BUTTON } from '../../components/TableComponent/constants';

const PAY_PER_HEAD = 'PayPerHead';
const HAND_BETS = 'HandBets';
const WALLETS = 'Wallets';

export const reportsLinksConstants = {
  agents: '/Reports/Agents',
  partners: '/Reports/Partners',
  wallets: '/Reports/Wallets',
}

export const navigationList = [
  {
    title: 'Agents',
    link: reportsLinksConstants.agents,
  },
  {
    title: 'Partners',
    link: reportsLinksConstants.partners,
  },
  {
    title: 'Wallets',
    link: reportsLinksConstants.wallets,
  },
];

export const reportPageConst = {
  AGENTS: {
    LINK: reportsLinksConstants.agents,
    TYPE: PAY_PER_HEAD,
  },
  PARTNERS: {
    LINK: reportsLinksConstants.partners,
    TYPE: HAND_BETS,
  },
  WALLETS: {
    LINK: reportsLinksConstants.wallets,
    TYPE: WALLETS,
  },
}

const getName = type => {
  switch (type) {
    case PAY_PER_HEAD:
      return 'Agents';
    case HAND_BETS:
      return 'Partners';
    case WALLETS:
      return 'Wallets';
    default:
      return 'Unknown';
  }
}

export const getReportsPageColumn = type => {
  switch (type) {
    case PAY_PER_HEAD:
      return getReportsColumn(type);
    case HAND_BETS:
      return getReportsColumn(type);
    case WALLETS:
      return getWalletsReportsColumn;
    default:
      return [];
  }
}

export const getReportsColumn = (type) => {
  const array = [
    {
      width: 80,
      label: 'Send',
      dataKey: 'telegram',
      isContainer: true,
      isPanelCheckbox: true,
    },
    {
      width: 80,
      label: 'TR',
      dataKey: 'telegramMessage',
      handlerCellName: 'showTelegramMessage',
    },
    {
      width: 200,
      label: getName(type),
      dataKey: 'partnerName',
    },
    {
      width: 200,
      label: 'Weekly Figure',
      dataKey: 'weeklyFigure',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'PrevMakeUp',
      dataKey: 'previousMakeUp',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'Redline Amt',
      dataKey: 'redLineAmt',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'Sub Own Amt',
      dataKey: 'subOwnerAmount',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'Share Amt',
      dataKey: 'shareAmt',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'Prev Carry',
      dataKey: 'previousCarry',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'Balance',
      dataKey: 'balance',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'Settled',
      dataKey: 'settled',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'Net',
      dataKey: 'net',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'MakeUp',
      dataKey: 'makeUp',
      isDollarHandler: true,
    },
    {
      width: 200,
      label: 'PA',
      dataKey: 'partnerApprove',
      handlerCellName: 'telegramApproveStatus'
    },
    {
      width: 150,
      label: 'IsConfirmed',
      dataKey: 'confirmed',
      isContainer: true,
      isPanelCheckboxConfirm: true,
    },
  ];

  if (type === PAY_PER_HEAD) {
    array.unshift({
      width: 80,
      label: '',
      dataKey: 'weeklyDetails',
      handlerColumn: INFO_BUTTON,
    });
  }
  return array;
}

export const getWalletsReportsColumn = [
  {
    width: 100,
    label: '',
    dataKey: 'walletTransactions',
    handlerColumn: INFO_BUTTON,
  },
  {
    width: 600,
    label: 'Wallet Type',
    dataKey: 'type',
  },
  {
    width: 800,
    label: 'Partner Name',
    dataKey: 'partnerName',
  },
  {
    width: 1000,
    label: 'Weekly Figure',
    dataKey: 'weeklyAmount',
    isDollarHandler: true,
  },
  {
    width: 1000,
    label: 'Total Figure',
    dataKey: 'amount',
    isDollarHandler: true,
  },
];

export const reportsSort = [
  {
    sortType: 'string',
    dataKey: 'partnerName',
  },
  {
    sortType: 'number',
    dataKey: 'weeklyFigure',
  },
  {
    sortType: 'number',
    dataKey: 'previousMakeUp',
  },
  {
    sortType: 'number',
    dataKey: 'redLineAmt',
  },
  {
    sortType: 'number',
    dataKey: 'makeUp',
  },
  {
    sortType: 'number',
    dataKey: 'shareAmt',
  },
  {
    sortType: 'number',
    dataKey: 'previousCarry',
  },
  {
    sortType: 'number',
    dataKey: 'balance',
  },
  {
    sortType: 'number',
    dataKey: 'settled',
  },
  {
    sortType: 'number',
    dataKey: 'net',
  },
  {
    sortType: 'string',
    dataKey: 'type',
  },
  {
    sortType: 'number',
    dataKey: 'weeklyAmount',
  },
  {
    sortType: 'number',
    dataKey: 'amount',
  },
]
