import {
  RESET_EDIT_NETWORK,
  SET_EDIT_NETWORK,
  SET_EDIT_NETWORK_NAME,
} from '../../constants';

const initialState = {
  id: null,
  name: '',
}

export const editNetwork = (state = initialState, action) => {
  switch (action.type) {
    case RESET_EDIT_NETWORK:
      return initialState;
    case SET_EDIT_NETWORK:
      return action.payload.network;
    case SET_EDIT_NETWORK_NAME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
