import {
  RESET_CREATE_ACCOUNT,
  SET_CREATE_ACCOUNT_TYPE,
  SET_CREATE_ACCOUNT_SUB_TYPE,
  SET_CREATE_ACCOUNT_SHORT_ACCOUNT_NAME,
  SET_CREATE_ACCOUNT_URL,
  SET_CREATE_ACCOUNT_USERNAME,
  SET_CREATE_ACCOUNT_PASSWORD,
  SET_CREATE_ACCOUNT_SHARE_PERCENT,
  SET_CREATE_ACCOUNT_NOTES,
  SET_CREATE_ACCOUNT_SITE_TYPE,
  SET_CREATE_ACCOUNT_PARTNER,
  SET_CREATE_ACCOUNT_SITE_TYPES_LIST,
  SET_CREATE_ACCOUNT_PARTNERS_LIST,
  SET_CREATE_ACCOUNT_SUB_OWNER,
  SET_CREATE_ACCOUNT_SUB_OWNER_PERCENT,
} from '../constants';

export const resetCreateAccount = () => ({
  type: RESET_CREATE_ACCOUNT,
});

export const setCreateAccountType = type => ({
  type: SET_CREATE_ACCOUNT_TYPE,
  payload: {
    type,
  },
});

export const setCreateAccountSubType = subType => ({
  type: SET_CREATE_ACCOUNT_SUB_TYPE,
  payload: {
    subType,
  },
});

export const setCreateAccountShortAccountName = shortAccountName => ({
  type: SET_CREATE_ACCOUNT_SHORT_ACCOUNT_NAME,
  payload: {
    shortAccountName,
  },
});

export const setCreateAccountUrl = url => ({
  type: SET_CREATE_ACCOUNT_URL,
  payload: {
    url,
  },
});

export const setCreateAccountUsername = username => ({
  type: SET_CREATE_ACCOUNT_USERNAME,
  payload: {
    username,
  },
});

export const setCreateAccountPassword = password => ({
  type: SET_CREATE_ACCOUNT_PASSWORD,
  payload: {
    password,
  },
});

export const setCreateAccountNotes = notes => ({
  type: SET_CREATE_ACCOUNT_NOTES,
  payload: {
    notes,
  },
});

export const setCreateAccountSharePercent = sharePercent => ({
  type: SET_CREATE_ACCOUNT_SHARE_PERCENT,
  payload: {
    sharePercent,
  },
});

export const setCreateAccountSubOwnerPercent = subOwnerPercent => ({
  type: SET_CREATE_ACCOUNT_SUB_OWNER_PERCENT,
  payload: {
    subOwnerPercent,
  },
});

export const setCreateAccountSiteType = siteType => ({
  type: SET_CREATE_ACCOUNT_SITE_TYPE,
  payload: {
    siteType,
  },
});

export const setCreateAccountPartner = partner => ({
  type: SET_CREATE_ACCOUNT_PARTNER,
  payload: {
    partner,
  },
});

export const setCreateAccountSiteTypesList = siteTypesList => ({
  type: SET_CREATE_ACCOUNT_SITE_TYPES_LIST,
  payload: {
    siteTypesList,
  },
});

export const setCreateAccountPartnersList = partnersList => ({
  type: SET_CREATE_ACCOUNT_PARTNERS_LIST,
  payload: {
    partnersList,
  },
});

export const setCreateAccountSubOwner = subOwner => ({
  type: SET_CREATE_ACCOUNT_SUB_OWNER,
  payload: {
    subOwner,
  },
});
