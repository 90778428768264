export const getStyles = theme => ({
  wrapper: {
    marginTop: 15,
    paddingTop: 5,
    paddingBottom: 15,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: -5,
    marginRight: -5,
  },
  column: {
    paddingLeft: 5,
    paddingRight: 5,
  },
})
