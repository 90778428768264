import {
  RESET_CREATE_NETWORK,
  SET_CREATE_NETWORK_NAME,
} from '../../constants';

const initialState = {
  name: '',
}

export const createNetwork = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CREATE_NETWORK:
      return initialState;
    case SET_CREATE_NETWORK_NAME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
