export const getStyles = theme => ({
  wrapper: {
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    borderRadius: 3,
    display: 'flex',
    height: 40,
  },
  container: {
    maxWidth: 100,
  }
});
