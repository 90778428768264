export const getStyles = theme => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
  },
  toolBox: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'minmax(min-content, max-content) 16px max-content',
    gridTemplateAreas: "'toolBox-total . toolBox-button'",
  },
  totalContainer: {
    gridArea: 'toolBox-total',
    margin: 'auto 0',
    fontSize: 14,
    display: 'flex',
  },
  totalLabel: {
    color: theme.mainStyles.mainColorLink,
    marginRight: 10,
  },
  button: {
    gridArea: 'toolBox-button',
  },
  input: {
    maxWidth: 300,
    marginTop: 16,
  },
  containerForFilters: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  toolBoxContainer: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  exportBtnContain: {
    marginLeft: 15,
  },
})
