export const getStyles = (theme) => ({
  input: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: 5,
      color: theme.inputsStyles.colorText,
      fontSize: 12,
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.inputsStyles.borderColor,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.inputsStyles.borderColorHover,
    },
  },
})
