import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux'

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { AppRouters } from '../AppRouters';
import { LoginPage } from '../../../pages';

import { getStyles } from '../styles';
import { ModalLoaderGlobal } from '../../UIComponents';
import { StatusModalContainer } from '../../StatusModalContainer';

const useStyles = makeStyles(getStyles);

export const AppWrapper = ({
  isAuthenticated,
  //isFetching,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { showLoader } = useSelector(state => state.settings);

  return (
    <Box className={classes.wrapper}>
      <Switch>
        <Fragment>
          {/*{!isAuthenticated && (*/}
          {/*  <Redirect to='/' />*/}
          {/*)}*/}
          {!isAuthenticated && (
            <Route
              exact
              // path='/'
              component={LoginPage}
            />
          )}
          {isAuthenticated && (
            <AppRouters />
          )}
        </Fragment>
      </Switch>
      <ModalLoaderGlobal
        open={showLoader}
      />
      <StatusModalContainer />
    </Box>
  )
}