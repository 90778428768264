import {
  RESET_CREATE_TRANSACTION,
  SET_CREATE_TRANSACTION,
  SET_CREATE_TRANSACTION_PARTNER_SENDER,
  SET_CREATE_TRANSACTION_PARTNER_SENDER_WALLET,
  SET_CREATE_TRANSACTION_PARTNER_RECEIVER,
  SET_CREATE_TRANSACTION_PARTNER_RECEIVER_WALLET,
  SET_CREATE_TRANSACTION_SUB_TYPE,
  SET_CREATE_TRANSACTION_AMOUNT,
  SET_CREATE_TRANSACTION_PARTNERS_LIST,
  SET_CREATE_TRANSACTION_SELECTED_DATE,
  SET_CREATE_TRANSACTION_NOTES,
} from '../constants';

const initialState = {
  partnerSender: null,
  partnerSenderWallet: null,
  partnerReceiver: null,
  partnerReceiverWallet: null,
  subType: null,
  amount: '',
  partnersList: [],
  selectedDate: null,
  notes: '',
}

export const createTransaction = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CREATE_TRANSACTION:
      return initialState;
    case SET_CREATE_TRANSACTION:
      return action.payload.transaction;
    case SET_CREATE_TRANSACTION_PARTNER_SENDER:
    case SET_CREATE_TRANSACTION_PARTNER_SENDER_WALLET:
    case SET_CREATE_TRANSACTION_PARTNER_RECEIVER:
    case SET_CREATE_TRANSACTION_PARTNER_RECEIVER_WALLET:
    case SET_CREATE_TRANSACTION_SUB_TYPE:
    case SET_CREATE_TRANSACTION_AMOUNT:
    case SET_CREATE_TRANSACTION_PARTNERS_LIST:
    case SET_CREATE_TRANSACTION_SELECTED_DATE:
    case SET_CREATE_TRANSACTION_NOTES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
