import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { TableComponent } from '../../components/TableComponent';
import {
  TaskButtonsPanel,
  InputCustom,
} from '../../components/UIComponents';
import { networksPageColumn, networksPageSort } from './constants';
import {
  getNetworksListSelector,
  getCreateNetworkNameSelector,
  getEditNetworkIdSelector,
  getEditNetworkNameSelector,
} from './selectors';
import {
  resetCreateNetwork as resetCreateNetworkAction,
  setCreateNetworkName as setCreateNetworkNameAction,
  resetEditNetwork as resetEditNetworkAction,
  setEditNetworkName as setEditNetworkNameAction,
} from '../../store/actions';
import {
  ModalDelete,
  ModalNetwork,
} from '../../components/UIComponents/modals';
import { handlerEditData } from './actions';
import {
  networksForTable,
  addNetwork,
  editNetwork,
  deleteNetwork,
} from '../../api';

import { sortEvent } from '../../utils/sortingService';
import {
  filterObjArray,
  removeBlacklistedKeys,
} from '../../utils/objects';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const NetworksPageContainer = ({
  networksList,
  createNetworkName,
  editNetworkId,
  editNetworkName,
  resetCreateNetwork,
  setCreateNetworkName,
  resetEditNetwork,
  setEditNetworkName,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [networkId, setNetworkId] = useState(null);
  const [showModalCreateNetwork, setShowModalCreateNetwork] = useState(false);
  const [showModalEditNetwork, setShowModalEditNetwork] = useState(false);

  const [ handleSortBy, setHandleSortBy ] = useState(false);
  const [ handleSortDirection, setHandleSortDirection ] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [filteredList, setFilteredList] = useState([]);

  const getNetworks = useCallback(async () =>
    await networksForTable(),
  []);

  useEffect(() => {
    getNetworks();
  }, [getNetworks]);

  useEffect(() => {
    setFilteredList(searchValue
      ? filterObjArray(networksList, searchValue, ['id', 'action'])
      : networksList
    );
  }, [networksList, searchValue]);

  const closeModalDeleteAction = () => setShowModalDelete(false);

  const openModalDeleteAction = id => {
    setShowModalDelete(true);
    setNetworkId(id);
  };

  const deleteNetworkById = id => {
    Promise.resolve(deleteNetwork(id))
      .then(setNetworkId(null))
      .then(closeModalDeleteAction());
  }

  const sendCreateNetwork = () => {
    Promise.resolve(addNetwork({
      name: createNetworkName,
    }))
      .then(closeModalCreateNetworkAction())
      .then(resetCreateNetwork());
  }

  const sendEditNetwork = () => {
    Promise.resolve(editNetwork({
      id: editNetworkId,
      name: editNetworkName,
    }))
      .then(closeModalEditNetworkAction());
  }

  const closeModalCreateNetworkAction = () => {
    setShowModalCreateNetwork(false);
    resetCreateNetwork();
  }

  const closeModalEditNetworkAction = () => {
    setShowModalEditNetwork(false);
    resetEditNetwork();
    setNetworkId(null);
  }

  const openModalEditAccount = id => {
    const agent = networksList.find(elem => elem.id === id);
    handlerEditData(agent);
    setShowModalEditNetwork(true);
  }

  const sort = sortEvent({
    handleSortBy,
    sortData: networksPageSort,
    setHandleSortDirection,
    setHandleSortBy,
    list: filteredList,
  });

  const handleBlurInput = event => {
    const value = event.target.value;
    setSearchValue(value);

    setFilteredList(value
      ? filterObjArray(networksList, value, ['id', 'action'])
      : networksList
    );
  }

  return (
    <Box className={classes.container}>
      <InputCustom
        className={classes.input}
        value={searchValue}
        onChange={handleBlurInput}
      />

      <TableComponent
        columns={networksPageColumn}
        rows={filteredList}
        deleteRowAction={openModalDeleteAction}
        editRowAction={openModalEditAccount}
        sort={sort}
        sortBy={handleSortBy}
        sortDirection={handleSortDirection}
      />

      <TaskButtonsPanel
        createButton
        createButtonName="Create Network"
        createButtonAction={() => setShowModalCreateNetwork(true)}
      />

      <ModalDelete
        open={showModalDelete}
        title={'Delete Network'}
        description={'Are you sure you want to delete this agent?'}
        eventDelete={() => deleteNetworkById(networkId)}
        eventClose={closeModalDeleteAction}
      />

      <ModalNetwork
        open={showModalCreateNetwork}
        title={'Create Network'}
        eventClose={closeModalCreateNetworkAction}
        eventApply={sendCreateNetwork}
        name={createNetworkName}
        setName={setCreateNetworkName}
      />

      <ModalNetwork
        open={showModalEditNetwork}
        title={'Edit Network'}
        eventClose={closeModalEditNetworkAction}
        eventApply={sendEditNetwork}
        name={editNetworkName}
        setName={setEditNetworkName}
      />
    </Box>
  )
}

const mapStateToProps = state => ({
  networksList: getNetworksListSelector(state),
  createNetworkName: getCreateNetworkNameSelector(state),
  editNetworkId: getEditNetworkIdSelector(state),
  editNetworkName: getEditNetworkNameSelector(state),
});

const mapDispatchToProps = {
  resetCreateNetwork: resetCreateNetworkAction,
  setCreateNetworkName: setCreateNetworkNameAction,
  resetEditNetwork: resetEditNetworkAction,
  setEditNetworkName: setEditNetworkNameAction,
}

export const NetworksPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworksPageContainer);
