import {
  SET_TRANSACTIONS_LIST,
  SET_TRANSACTIONS_SUB_TYPE,
  SET_TRANSACTION_TYPE,
  SET_TRANSACTION_FILTERED_LIST,
  RESET_TRANSACTIONS_PAGE,
} from '../constants';

export const setTransactionsSubType = (id, value) => ({
  type: SET_TRANSACTIONS_SUB_TYPE,
  payload: {
    id,
    value,
  }
});
export const setTransactionType = transactionType => ({
  type: SET_TRANSACTION_TYPE,
  payload: {
    transactionType,
  }
});

export const setTransactionsList = transactionsList => ({
  type: SET_TRANSACTIONS_LIST,
  payload: {
    transactionsList,
  }
});

export const setTransactionsFilteredList = transactionsFilteredList => ({
  type: SET_TRANSACTION_FILTERED_LIST,
  payload: {
    transactionsFilteredList,
  }
});

export const resetTransactions = () => ({
  type: RESET_TRANSACTIONS_PAGE,
});
