import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';

import {
  wallets,
  walletTypes,
  createNetwork,
  createWallet,
  createWalletType,
  editNetwork,
  editWallet,
  editWalletType,
  networks,
} from './reducers';
import { isDev } from '../utils';
import { reports } from './reports';
import { accountFigures } from './accountFigures';
import { balancesReports } from './balancesReports';
import { createTransaction } from './createTransaction';
import { createTelegramCode } from './createTelegramCode';
import { transactions } from './transaction';
import { partners } from './partners';
import { partnersTelegramCodes } from './partnersTelegramCodes';
import { editPartner } from './editPartner';
import { createPartner } from './createPartner';
import { accounts } from './accounts';
import { createAccount } from './createAccount';
import { editAccount } from './editAccount';
import { modalTableInfo } from './modalTableInfo';
import { appDepPayment } from './appDepPayment';
import { settings } from './settings';
import { statusModal } from './statusModal';

const middleware = [thunk];
let composeEnhancers = compose;

if (isDev) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const rootReducer = combineReducers({
  accounts,
  accountFigures,
  balancesReports,
  partners,
  partnersTelegramCodes,
  wallets,
  walletTypes,
  transactions,
  reports,
  createAccount,
  createTransaction,
  createPartner,
  createNetwork,
  createWallet,
  createWalletType,
  createTelegramCode,
  editAccount,
  editPartner,
  editNetwork,
  editWallet,
  editWalletType,
  networks,
  settings,
  modalTableInfo,
  appDepPayment,
  statusModal,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(...middleware),
  )
);
