export const getStyles = theme => ({
	dayWrapper: {
		position: "relative",
	},
	day: {
		width: 36,
		height: 36,
		fontSize: theme.typography.caption.fontSize,
		margin: "0 2px",
		color: "inherit"
	},
	customDayHighlight: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: "2px",
		right: "2px",
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: "50%"
	},
	nonCurrentMonthDay: {
		color: theme.palette.text.disabled
	},
	highlightNonCurrentMonthDay: {
		color: "#676767"
	},
	highlight: {
		background: theme.palette.primary.main,
		color: theme.palette.common.white
	},
	endHighlight: {
		extend: "highlight",
		borderTopRightRadius: "50%",
		borderBottomRightRadius: "50%"
	},
	firstHighlight: {
		extend: "highlight",
		borderTopLeftRadius: "50%",
		borderBottomLeftRadius: "50%"
	},
	wrapper: {
		'& .MuiInputBase-input.MuiInput-input': {
			color: '#bbdefb',
			paddingLeft: 6,
			paddingRight: 6,
			fontWeight: 400,
			fontSize: 12,
			minWidth: 200,
		},
		'& .MuiInputBase-root.MuiInput-root': {
			'&:after': {
				borderBottom: '2px solid #bbdefb',
			},
			'&:before': {
				borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
			},
		},
	},
})