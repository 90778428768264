import {
  SET_WALLETS_LIST,
} from '../../constants';

const initialState = {
  walletsList: [],
}

export const wallets = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLETS_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}