import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { SelectCustom } from '../../UIComponents';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const typesList = [
  {
    id: 'Agent',
    name: 'Agent',
  },
  {
    id: 'Handbet',
    name: 'Handbet',
  },
  {
    id: 'Private',
    name: 'Private',
  },
];

export const PanelSelectData = ({
  id,
  value = typesList[0].name,
  handleChange = f=>f,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <SelectCustom
          value={value}
          onChange={e => {
            handleChange({
              id,
              event: e,
            });
          }}
          disableClearable
          itemList={typesList}
          optionTitle='name'
        />
      </Box>
    </Box>
  )
}
