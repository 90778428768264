import React from 'react';
import { NavLink } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { navigationListConstant } from './constants';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const Navigation = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box
      component='nav'
      className={classes.navigation}
    >
      {
        navigationListConstant.map(item => (
          <Box
            component={NavLink}
            className={classes.navigation_item}
            activeClassName='active'
            exact={item.exact}
            to={item.link}
          >
            { item.title }
          </Box>
        ))
      }

    </Box>
  )
}
