import {
  SET_WALLET_TYPES_LIST,
} from '../../constants';

export const setWalletTypesList = walletTypesList => ({
  type: SET_WALLET_TYPES_LIST,
  payload: {
    walletTypesList,
  }
});
