import {
  RESET_BALANCES_REPORTS,
  SET_BALANCES_REPORTS,
  SET_BALANCES_REPORTS_SELECTED_DATE,
} from '../constants';

export const resetBalancesReports = () => ({
  type: RESET_BALANCES_REPORTS,
});

export const setBalancesReports = balancesReports => ({
  type: SET_BALANCES_REPORTS,
  payload: {
    reports: balancesReports,
  }
});

export const setBalancesReportsSelectedDate = selectedDate => ({
  type: SET_BALANCES_REPORTS_SELECTED_DATE,
  payload: {
    selectedDate,
  }
});
