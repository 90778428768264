import React from 'react'

import AddIcon from '@material-ui/icons/Add';

export const buttonTypes = {
  BASIC: 'BASIC',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  SUBMIT: 'SUBMIT',
  CANCEL: 'CANCEL',
  PANEL: 'PANEL',
} 

export const buttonParams = {
  BASIC: {
    icon: null,
    text: 'Ok',
    style: 'buttonDefault',
  },
  CREATE: {
    icon: <AddIcon />,
    text: 'Create',
    style: 'buttonCreate',
  },
  EDIT: {
    icon: null,
    text: 'Edit',
    style: 'buttonEdit',
  },
  DELETE: {
    icon: null,
    text: 'Delete',
    style: 'buttonDelete',
  },
  CANCEL: {
    icon: null,
    text: 'Cancel',
    style: 'buttonCancel',
  },
  PANEL: {
    icon: null,
    text: 'Panel',
    style: 'buttonPanel',
  },
}
