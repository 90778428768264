export const getStyles = theme => ({
  wrapper: {
    minWidth: 1200,
    maxWidth: 2560,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.mainStyles.mainWrapperBackgroundColor,
    color: theme.mainStyles.mainColorText,
    fontFamily: '"Roboto", sans-serif',
  },
  main: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.mainStyles.mainBackgroundColor,
  },
});
