import moment from 'moment';

import { setModalTableInfoData } from '../../../store';
import { numberToDollar } from '../../../utils/number';

const reformatAgentReportData = data => ({
  partnerName: data.partnerName,
  list: data?.list?.map(elem => ({
    userName: elem.account?.username,
    url: elem.account?.url,
    password: elem.account?.password,
    subType: elem.account?.subType,
    redlinePercent: elem.account?.subType === 'FreeRoll'
      ? (elem?.sharePercent || elem.account?.sharePercent)
      : undefined,
    redLineAmt: numberToDollar(elem?.redLineAmt),    // TODO: why don't show?
    sharePercent: elem.account?.subType === 'Share'
      ? (elem?.sharePercent || elem.account?.sharePercent)
      : undefined,
    shareAmt: numberToDollar(elem?.shareAmt),    // TODO: why don't show?
    subOwnerName: elem?.account?.subOwner?.name,
    subOwnerPercent: elem.subOwnerPercent || elem.account?.subOwnerPercent,
    subOwnerAmount: (elem.subOwnerPercent || elem.account?.subOwnerPercent)
      ? (elem.amount * (elem.subOwnerPercent || elem.account?.subOwnerPercent) / 100)
      : undefined,
    week: elem.week,
    year: elem.year,
    amount: elem.amount,
  }))
});

const reformatWalletReportData = data => ({
  partnerName: data.partnerName,
  list: data?.list?.map(elem => ({
    id: elem?._id,
    senderName: elem?.partnerSender?.name,
    senderWalletType: elem?.walletSender?.type?.name,
    receiverName: elem?.partnerReceiver?.name,
    receiverWalletType: elem?.walletReceiver?.type?.name,
    transactionAffectsOn: `${elem?.wallet?.partner?.name} (${elem?.wallet?.type?.name})`,
    transactionType: elem?.transactionType,
    subType: elem?.subType,
    amount: elem?.amount,
    week: elem?.week,
    year: elem?.year,
    date: moment(elem?.date).format('MMM Do YY'),
    createDate: moment(elem?.createDateUTC).format('MMM Do YY, h:mm:ss a'),
    notes: elem?.notes,
  })),
});


export const dataSwitcher = data => {
  switch (data.handlerType) {
    case 'AgentReport':
      return setModalTableInfoData(reformatAgentReportData(data));
    case 'WalletsReport':
      return setModalTableInfoData(reformatWalletReportData(data));
    default:
      return null;
  }
}
