import {
  SET_PARTNERS_LIST,
} from '../constants';

export const setPartnersList = partnersList => ({
  type: SET_PARTNERS_LIST,
  payload: {
    partnersList,
  }
});
