import moment from 'moment';

export const transactionsForTableTransactionsParser = transactions => transactions?.map(transaction => (
  {
    id: transaction?._id,
    senderName: transaction?.partnerSender?.name,
    senderWalletType: transaction?.walletSender?.type?.name,
    receiverName: transaction?.partnerReceiver?.name,
    receiverWalletType: transaction?.walletReceiver?.type?.name,
    transactionAffectsOn: `${transaction?.wallet?.partner?.name} (${transaction?.wallet?.type?.name})`,
    transactionType: transaction?.transactionType,
    subType: {
      id: transaction?._id,
      value: transaction?.subType,
    },
    amount: transaction?.amount,
    week: transaction?.week,
    year: transaction?.year,
    date: moment(transaction?.date).format('MMM Do YY'),
    createDate: moment(transaction?.createDateUTC).format('MMM Do YY, h:mm:ss a'),
    notes: transaction?.notes,
    // wallet: walletWithoutNotesParser(transaction?.wallet),
    action: {
      id: transaction?._id,
    },
  }
));

export const transactionsForTableJoinTransactionsParser = transactions => {
  const newTransactionsList = [];
  transactions.forEach((transaction, i) => {
    if (i % 2 === 0) {
      newTransactionsList.push({
        date: moment(transaction?.date).format('MMM Do YY'),
        senderName: transaction?.partnerSender?.name,
        senderWalletType: transaction?.walletSender?.type?.name,
        receiverName: transaction?.partnerReceiver?.name,
        receiverWalletType: transaction?.walletReceiver?.type?.name,
        amountIn: transaction?.amount,
        week: transaction?.week,
        notes: transaction?.notes,
        subType: transaction?.subType,
        action: {
          id: [transaction?._id],
        },
      })
    } else {
      newTransactionsList[Math.floor(i / 2)].amountOut = transaction?.amount;
      newTransactionsList[Math.floor(i / 2)].action?.id.push(transaction?._id);
    }
  });

  return newTransactionsList;
}

export const transactionsForTableTransactionsBackupParser = transactions => transactions?.map(transaction => (
  {
      id: transaction?._id,
      senderName: transaction?.partnerSender?.name,
      senderWalletType: transaction?.walletSender?.type?.name,
      receiverName: transaction?.partnerReceiver?.name,
      receiverWalletType: transaction?.walletReceiver?.type?.name,
      transactionAffectsOn: `${transaction?.wallet?.partner?.name} (${transaction?.wallet?.type?.name})`,
      transactionType: transaction?.transactionType,
      subType: transaction?.subType,
      amount: transaction?.amount,
      week: transaction?.week,
      year: transaction?.year,
      date: moment(transaction?.date).format('MMM Do YY'),
      createDate: moment(transaction?.createDateUTC).format('MMM Do YY, h:mm:ss a'),
      notes: transaction?.notes,
      // wallet: walletWithoutNotesParser(transaction?.wallet),
      action: {
          id: transaction?._id,
      },
  }
));

export const dataPartnersForTransaction = data => (
  {
    partnersList: partnersToTransactionPartnersList(data?.partners),
  }
);

export const addTransactionAppAndDepositToBackendParser = transaction => (
  {
    account: transaction?.account,
    amount: Number(transaction?.amount?.replace(',', '.')),
    week: moment(transaction?.date)?.weekday(0)?.startOf('isoWeek')?.week(),
    year: moment(transaction?.date)?.weekday(0)?.startOf('isoWeek')?.year(),
    date: moment(transaction?.date)?.toDate(),
    partnerSender: transaction?.partnerSender?.id,
    walletSender: transaction?.partnerSenderWallet?.id,
    partnerReceiver: transaction?.partnerReceiver?.id,
    walletReceiver: transaction?.partnerReceiverWallet?.id,
    subType: transaction?.subType,
    notes: transaction?.notes,
  }
);

export const addTransactionToBackendParser = transaction => (
  {
    amount: Number(transaction?.amount?.replace(',', '.')),
    week: moment(transaction?.date)?.weekday(0)?.startOf('isoWeek')?.week(),
    year: moment(transaction?.date)?.weekday(0)?.startOf('isoWeek')?.year(),
    date: moment(transaction?.date)?.toDate(),
    partnerSender: transaction?.partnerSender?.id,
    walletSender: transaction?.partnerSenderWallet?.id,
    partnerReceiver: transaction?.partnerReceiver?.id,
    walletReceiver: transaction?.partnerReceiverWallet?.id,
    subType: transaction?.subType,
    notes: transaction?.notes,
    account: transaction?.account?.id,
    accountType: transaction?.account?.type,
    transactionType: transaction?.transactionType,
    expenses: transaction?.expenses ?? false,
  }
);

export const updateTransactionToBackendParser = transaction => (
  {
    _id: transaction?._id,
    amount: transaction?.amount ? Number(transaction?.amount?.replace(',', '.')) : undefined,
    week: transaction?.week ? moment(transaction?.date)?.weekday(0)?.startOf('isoWeek')?.week() : undefined,
    year: transaction?.year ? moment(transaction?.date)?.weekday(0)?.startOf('isoWeek')?.year() : undefined,
    date: transaction?.date ? moment(transaction?.date)?.toDate() : undefined,
    partnerSender: transaction?.partnerSender ? transaction?.partnerSender?.id : undefined,
    walletSender: transaction?.walletSender ? transaction?.partnerSenderWallet?.id : undefined,
    partnerReceiver: transaction?.partnerReceiver ? transaction?.partnerReceiver?.id : undefined,
    walletReceiver: transaction?.walletReceiver ? transaction?.partnerReceiverWallet?.id : undefined,
    subType: transaction?.subType ? transaction?.subType : undefined,
  }
);

export const partnersToTransactionPartnersList = partners => partners.map(partner => (
  {
    id: partner?._id,
    name: partner?.name,
    wallets: walletsToWalletsList(partner?.wallets),
  }
));

const walletsToWalletsList = wallets => wallets.map(wallet => (
  {
    id: wallet?._id,
    name: wallet?.type?.name,
    isPrivate: wallet?.isPrivate,
  }
));

const shortWalletParser = shortWallet => (
  {
    id: shortWallet?._id,
    type: shortWalletTypeParser(shortWallet?.type),
  }
);

const walletWithoutNotesParser = walletWithoutNotes => (
  {
    ...shortWalletParser(walletWithoutNotes),
    partner: shortPartnerParser(walletWithoutNotes?.partner),
  }
);

const shortWalletTypeParser = shortWalletType => (
  {
    id: shortWalletType?._id,
    name: shortWalletType?.name,
  }
);

const shortPartnerParser = shortPartner => (
  {
    id: shortPartner?._id,
    name: shortPartner?.name,
  }
);
