export const walletsReportsParser = walletsReports =>
  walletsReports?.map(item => ({
    type: item?.wallet?.type?.name,
    partnerName: item?.wallet?.partner?.name,
    weeklyAmount: item?.weeklyAmount,
    amount: item?.allTransactionsAmount,
    walletTransactions: {
      partnerName: item?.wallet?.partner?.name,
      list: (item && item?.walletTransactions)
        ? item?.walletTransactions.map(elem => ({
            ...elem,
            wallet: item?.wallet,
          }))
        : undefined,
      handlerType: 'WalletsReport',
    }
  }));
