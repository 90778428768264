export const getStyles = theme => ({
	container: {
		flexGrow: 1,
		flexShrink: 0,
		flexBasis: 'auto',
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: 10,
	},
	table: {
		flexGrow: 1,
		flexShrink: 0,
		flexBasis: 'auto',
	},
	panel: {
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 'auto',
	},
	input: {
		maxWidth: 300,
		marginTop: 16,
	},
	inputBackup: {
		width: '100%',
		marginBottom: 15,
	},
	containerMini: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	containerDate: {
		display: 'flex',
		flexDirection: 'row-reverse',
		marginBottom: 10,
		alignItems: 'center',
	},
	containerForFilters: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	containerDeleteButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: 10,
	}
})
