import {
  SET_WALLETS_LIST,
} from '../../constants';

export const setWalletsList = walletsList => ({
  type: SET_WALLETS_LIST,
  payload: {
    walletsList,
  }
});
