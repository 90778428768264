import {
  SET_PARTNERS_TELEGRAM_CODES_LIST,
  RESET_PARTNERS_TELEGRAM_CODES_LIST,
} from '../constants';

export const resetPartnersTelegramCodesList = () => ({
  type: RESET_PARTNERS_TELEGRAM_CODES_LIST,
});

export const setPartnersTelegramCodesList = partnersTelegramCodesList => ({
  type: SET_PARTNERS_TELEGRAM_CODES_LIST,
  payload: {
    partnersTelegramCodesList,
  },
});
