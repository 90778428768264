import {
  SET_PARTNERS_TELEGRAM_CODES_LIST,
  RESET_PARTNERS_TELEGRAM_CODES_LIST,
} from '../constants';

const initialState = {
  partnersTelegramCodesList: [],
}

export const partnersTelegramCodes = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PARTNERS_TELEGRAM_CODES_LIST:
      return initialState;
    case SET_PARTNERS_TELEGRAM_CODES_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
