import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';
import { CustomCheckbox } from '../../UIComponents';

const useStyles = makeStyles(getStyles);

export const PanelCheckboxData = ({
  id,
  checked,
  handleChange,
  data,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <CustomCheckbox
          label=""
          checked={checked}
          onChange={() => {
            handleChange(id, checked, data?.week, data?.year);
          }}
        />
      </Box>
    </Box>
  )
}
