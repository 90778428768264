import React, { useState, } from 'react';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TableComponent } from '../../../TableComponent';
import { sortEvent } from '../../../../utils/sortingService';

import { getStyles } from './styles';
import {buttonTypes} from "../../buttons/ButtonCustom/constants";
import {ButtonCustom} from "../../buttons";
import {getReportsPageColumn} from "../../../../pages";
import {excludeKeys, reformatDataKeys} from "../../../../pages/ReportsPage/ReportsTablePage/constants";

const useStyles = makeStyles(getStyles);

export const ModalTableInfo = ({
  open,
  eventClose,
  title,
  list,
  columns,
  sortData,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [ handleSortBy, setHandleSortBy ] = useState(false);
  const [ handleSortDirection, setHandleSortDirection ] = useState(false);

  const sort = sortEvent({
    handleSortBy,
    sortData,
    setHandleSortDirection,
    setHandleSortBy,
    list,
  });

  const handleExportToExcel = async () => {
    const keys = columns;

    const actualKeys = keys.map(key => ({
      header: key.label,
      key: key.dataKey,
    })).filter(key => !excludeKeys.includes(key.key));

    const actualAccountList = list
      .map((account) => {
        const newAccount = {};
        actualKeys.forEach(key => {
          const reformatData = reformatDataKeys.find(el => el.name === key.key);
          let value = '';
          if (reformatData && typeof account[key.key] === 'object') {
            value = account[key.key]?.[reformatData.value];
          } else {
            value = account[key.key];
          }

          if (value === undefined || value === null || value === 'disabled') {
            value = '';
          }

          if (typeof value === 'number') {
            value = value.toFixed(2);
          }

          newAccount[key.key] = value;
        });
        return newAccount;
      })

    let data = actualAccountList;
    const workSheetName = `${title} Report`;
    const workBookName = workSheetName;

    const workbook = new Excel.Workbook();
    const fileName = workBookName;
    const worksheet = workbook.addWorksheet(workSheetName);
    worksheet.columns = actualKeys;
    worksheet.getRow(1).font = {bold: true};
    worksheet.columns.forEach(column => {
      column.width = column.header.length + 5;
      column.alignment = {horizontal: 'center'};
    });
    data.forEach(singleData => {
      worksheet.addRow(singleData);
    });
    worksheet.eachRow({includeEmpty: false}, row => {
      const currentCell = row._cells;
      currentCell.forEach(singleCell => {
        const cellAddress = singleCell._address;
        worksheet.getCell(cellAddress).border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });
    });
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  }

  return (
    <Box>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-table-title'
        aria-describedby='alert-dialog-table-description'
        className={classes.modalContent}
        fullScreen={true}
      >
        <Box
          className={classes.wrapper}
        >
          <IconButton
            aria-label="close"
            className={classes.buttonClose}
            onClick={eventClose}
          >
            <CloseIcon />
          </IconButton>
          <Box className={classes.titleContainer}>
            {
              title && (
                <DialogTitle
                  id='alert-dialog-table-title'
                  className={classes.title}
                >
                  { title }
                </DialogTitle>
              )
            }
            <ButtonCustom
              onClick={handleExportToExcel}
              customType={buttonTypes.CREATE}
              text='export to excel'
              style={{
                height: 35,
              }}
            />
          </Box>

          <DialogContent
            className={classes.dialogContent}
          >
            <TableComponent
              rows={list}
              columns={columns}
              sort={sort}
              sortBy={handleSortBy}
              sortDirection={handleSortDirection}
            />
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
}
