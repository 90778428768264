import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const ModalText = ({
	open,
	text,
	textPreFormat,
	textTop,
	title,
	eventClose,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	return (
		<Box>
			<Dialog
				open={open}
				aria-labelledby='alert-dialog-text-title'
				aria-describedby='alert-dialog-text-description'
				className={classes.modalContent}
			>
				<Box>
					<IconButton
						aria-label="close"
						className={classes.buttonClose}
						onClick={eventClose}
					>
						<CloseIcon />
					</IconButton>
					<DialogTitle
						id='alert-dialog-text-title'
						className={classes.title}
					>
						{ title }
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							id='alert-dialog-text-description'
							className={classes.text}
						>
							{textTop && (
								<Box className={classes.textTop}>
									{ textTop }
								</Box>
							)}
							{textPreFormat && (
								<pre>{ text }</pre>
							)}
							{!textPreFormat && (
								text
							)}
						</DialogContentText>
					</DialogContent>
				</Box>
			</Dialog>
		</Box>
	);
}
