import {
  SET_ACCOUNTS_LIST,
  RESET_ACCOUNTS,
  REMOVE_ACCOUNT_FROM_LIST,
  SET_ACCOUNT_ITEM_VALUE,
  SET_ACCOUNT_ITEM_DISABLED,
  SET_ACCOUNT_SUB_OWNER_ITEM_VALUE,
  START_STOP_UPDATE_ACCOUNT,
} from '../constants';
import moment from 'moment';
import { getAccountClassFromStatus } from '../../api/accounts/utils';

const initialState = {
  isLoading: false,
  accountsList: [],
}

export const accounts = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNTS_LIST:
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    case REMOVE_ACCOUNT_FROM_LIST:
      return {
        ...state,
        accountsList: state.accountsList.filter(item => item.id !== action.payload.id)
      }
    case SET_ACCOUNT_ITEM_VALUE:
      return {
        ...state,
        accountsList: state.accountsList.map(item => ({
          ...item,
          sharePercent: item.id === action.payload.id
            ? {
              ...item.sharePercent,
              value: action.payload.value,
            }
            : item.sharePercent,
        }))
      }
    case SET_ACCOUNT_SUB_OWNER_ITEM_VALUE:
      return {
        ...state,
        accountsList: state.accountsList.map(item => ({
          ...item,
          subOwnerPercent: item.id === action.payload.id
            ? {
              ...item.subOwnerPercent,
              value: action.payload.value,
            }
            : item.subOwnerPercent,
        }))
      }
    case SET_ACCOUNT_ITEM_DISABLED:
      return {
        ...state,
        accountsList: state.accountsList.map(item => ({
          ...item,
          disabled: item.id === action.payload.id
            ? action.payload.disabled
            : item.disabled,
          action: item.id === action.payload.id
            ? {
                ...item.action,
                disabled: action.payload.disabled,
              }
            : item.action,
        }))
      };
    case START_STOP_UPDATE_ACCOUNT:
      return {
        ...state,
        accountsList: state.accountsList.map(item => ({
          ...item,
          status: item.id === action.payload.id
            ? action.payload.stop
              ? `STOP on ${moment(action.payload.date).format('MMM Do')}`
              : action.payload.status
            : item.status,
          isCustomTextColor: item.id === action.payload.id
            ? getAccountClassFromStatus(action.payload.stop ? 'stop' : action.payload.status)
            : item.isCustomTextColor,
          action: item.id === action.payload.id
            ? {
              ...item.action,
              isStop: action.payload.stop,
            }
            : item.action,
        }))
      }
    case RESET_ACCOUNTS:
      return initialState;
    default:
      return state;
  }
}
