import React, { useState, Fragment } from 'react';

import TextField from "@material-ui/core/TextField";
import { DateRangePicker, DateRangeDelimiter, LocalizationProvider  } from 'ui-picker4';
import DateFnsAdapter from "ui-picker4/adapter/date-fns";
import enLocale from "date-fns/locale/en-US";
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

import { getStyles } from './styles';
import { InputCustom } from '../inputs';

const useStyles = makeStyles(getStyles);

export const RangeDatePicker = ({
	value,
	setValue,
	style,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	return (
		<Box style={style}>
			<LocalizationProvider dateAdapter={DateFnsAdapter} locale={enLocale}>
				<DateRangePicker
					calendars={1}
					value={value}
					onChange={(newValue) => setValue(newValue)}
					renderInput={(startProps, endProps) => (
						<Fragment>
							<InputCustom
								{...startProps}
								helperText={null}
							/>
							<DateRangeDelimiter> to </DateRangeDelimiter>
							<InputCustom
								{...endProps}
								helperText={null}
							/>
						</Fragment>
					)}
				/>
			</LocalizationProvider>
		</Box>
	);
}