import {
  RESET_CREATE_PARTNER,
  SET_CREATE_PARTNER_NAME,
  SET_CREATE_PARTNER_TYPE,
  SET_CREATE_PARTNER_TERMS,
  SET_CREATE_PARTNER_LOCATION,
  SET_CREATE_PARTNER_SHARE_PERCENT,
} from '../constants';

export const resetCreatePartner = () => ({
  type: RESET_CREATE_PARTNER,
});

export const setCreatePartnerName = partnerName => ({
  type: SET_CREATE_PARTNER_NAME,
  payload: {
    partnerName,
  },
});

export const setCreatePartnerType = partnerType => ({
  type: SET_CREATE_PARTNER_TYPE,
  payload: {
    partnerType,
  },
});

export const setCreatePartnerTerms = terms => ({
  type: SET_CREATE_PARTNER_TERMS,
  payload: {
    terms,
  },
});

export const setCreatePartnerLocation = location => ({
  type: SET_CREATE_PARTNER_LOCATION,
  payload: {
    location,
  },
});

export const setCreatePartnerSharePercent = sharePercent => ({
  type: SET_CREATE_PARTNER_SHARE_PERCENT,
  payload: {
    sharePercent,
  },
});
