import { store } from '../../store';
import {
  setWalletsList,
  setCreateWalletWalletTypesList,
  setCreateWalletPartnersList,
  setEditWallet,
} from '../../store/actions';
import {
  walletsForTableToParser,
  walletToBackendWalletParser,
  editWalletToBackendWalletParser,
  dataToEditWalletParser,
  walletTypesToWalletTypesListParser,
} from './reformatters';

import {
  getLocalStorageData,
} from '../../utils';
import {
  GET_WALLETS_FOR_TABLE,
  GET_WALLET_AND_LISTS_FOR_EDIT,
  ADD_WALLET,
  EDIT_WALLET,
  DELETE_WALLET,
  GET_PARTNERS_AND_WALLET_TYPES_FOR_WALLET,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';
import { partnersToAccountPartnersListParser } from '../accounts/reformatters';

// export class Accounts {
  export async function walletsForTable() {
    await requestWrapper('WalletsForTable', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_WALLETS_FOR_TABLE)
        .setBearerToken(token)
        .build();

      const { findAllWallets } = data || null;

      if (!loading && !error && findAllWallets) {
        const reformattedWallets = walletsForTableToParser(findAllWallets);
        store.dispatch(setWalletsList(reformattedWallets));
      } else {
      
      }
    });
  }

  export async function getPartnersAndWalletTypesForWallet() {
    await requestWrapper('GetPartnersAndWalletTypesForWallet', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_PARTNERS_AND_WALLET_TYPES_FOR_WALLET)
        .setBearerToken(token)
        .build();

      const { findAllWalletTypes, partners } = data || null;

      if (!loading && !error && findAllWalletTypes && partners) {
        store.dispatch(setCreateWalletWalletTypesList(walletTypesToWalletTypesListParser(findAllWalletTypes)));
        store.dispatch(setCreateWalletPartnersList(partnersToAccountPartnersListParser(partners)));
      } else {
      
      }
    });
  }

  export async function addWallet(newWallet) {
    await requestWrapper('AddWallet', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(ADD_WALLET)
        .setBearerToken(token)
        .setVariables({ payload: walletToBackendWalletParser(newWallet)})
        .build();

      const { createWallet } = data || null;

      if (!loading && !error && createWallet?._id) {
        await walletsForTable();
      } else {
      
      }
    });
  }

  export async function getForEditWallet(id) {
    await requestWrapper('GetForEditWallet', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_WALLET_AND_LISTS_FOR_EDIT)
        .setBearerToken(token)
        .setVariables({ _id: id, })
        .build();

      const { findOneWallet, findAllWalletTypes, partners } = data || null;
      
      if (!loading && !error && findOneWallet && findAllWalletTypes && partners) {
        store.dispatch(setEditWallet(dataToEditWalletParser(data)));
      } else {
      
      }
    });
  }

  export async function editWallet(editedWallet) {
    await requestWrapper('EditAccount', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(EDIT_WALLET)
        .setBearerToken(token)
        .setVariables({ payload: editWalletToBackendWalletParser(editedWallet)})
        .build();
     
      const { updateWallet } = data || null;
      const { _id } = updateWallet || null;

      if (!loading && !error && _id) {
        await getForEditWallet(_id);
        await walletsForTable();
      } else {
      
      }
    });
  }

  export async function deleteWallet(id) {
    await requestWrapper('DeleteWallet', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(DELETE_WALLET)
        .setBearerToken(token)
        .setVariables({ _id: id, })
        .build();
      
      const { removeWallet } = data || null;

      if (!loading && !error && removeWallet?._id) {
        await walletsForTable();
      } else {
      
      }
    });
  }
// }
