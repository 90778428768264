import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { ButtonCustom } from '../../../components/UIComponents';
import { buttonTypes } from '../../../components/UIComponents/buttons/ButtonCustom/constants';

import { getStyles } from './styles';
import { NamedPanel } from '../../../components/UIComponents/panels/NamedPanel';
import { ModalPartnerTelegramCode } from '../../../components/UIComponents/modals';
import {
  resetCreateTelegramCodePartner as resetCreateTelegramCodePartnerAction,
  setCreateTelegramCodePartner as setCreateTelegramCodePartnerAction,
} from '../../../store';
import {
  getPartnersForGenerateCode,
  generateCode,
} from '../../../api';
import {
  getCreateTelegramCodePartnerSelector,
  getCreateTelegramCodePartnersListSelector,
} from './selectors';

const useStyles = makeStyles(getStyles);

const GetPartnerCodePageContainer = ({
  history,
  partner,
  partnersList,
  resetPartner,
  setPartner,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [showModalGenerateCode, setShowModalGenerateCode] = useState(false);

  useEffect(() => {
    const getPartners = async () => await getPartnersForGenerateCode();

    if (setShowModalGenerateCode) {
      getPartners().then(() => Promise.resolve());
    }
  }, [showModalGenerateCode]);

  const openModalGenerateCodeAction = () => setShowModalGenerateCode(true);

  const sendGenerateCode = () => {
    Promise.resolve(generateCode({
      partner,
    }))
      .then(closeModalGenerateCodeAction());
  }

  const closeModalGenerateCodeAction = () => {
    setShowModalGenerateCode(false);
    resetPartner();
  }

  return (
    <Box className={classes.container}>
      <NamedPanel types={classes.panel} title='Code'>
        <ButtonCustom
          customType={buttonTypes.PANEL}
          text={'Generate Code'}
          onClick={openModalGenerateCodeAction}
        />
      </NamedPanel>
      {
        showModalGenerateCode && (
          <ModalPartnerTelegramCode
            open={showModalGenerateCode}
            eventApply={sendGenerateCode}
            eventClose={closeModalGenerateCodeAction}
            title='Generate Code'
            partnersList={partnersList}
            partner={partner}
            setPartner={setPartner}
          />
        )
      }
    </Box>
  )
}

const mapStateToProps = state => ({
  partner: getCreateTelegramCodePartnerSelector(state),
  partnersList: getCreateTelegramCodePartnersListSelector(state),
});

const mapDispatchToProps = {
  resetPartner: resetCreateTelegramCodePartnerAction,
  setPartner: setCreateTelegramCodePartnerAction,
}

export const GetPartnerCodePage = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GetPartnerCodePageContainer)
);
