export const networksForTableParser = (networks) => networks?.map(network => (
  {
    id: network?._id,
    name: network?.name,
    action: {
      id: network?._id,
    },
  }
));

export const addNetworkToBackendParser = network => ({
  name: network?.name,
});

export const editNetworkToBackendParser = network => ({
  _id: network?.id,
  name: network?.name,
});

export const networkToEditParser = network => ({
  id: network?._id,
  name: network?.name,
});
