import {
  SET_SETTINGS_IS_ADMIN,
  SET_SETTINGS_IS_AUTHENTICATED,
  SET_SETTINGS_SHOW_LOADER,
} from '../constants';

export const setSettingsIsAuthenticated = isAuthenticated => ({
  type: SET_SETTINGS_IS_AUTHENTICATED,
  payload: {
    isAuthenticated,
  },
});

export const setSettingsShowLoader = showLoader => ({
  type: SET_SETTINGS_SHOW_LOADER,
  payload: {
    showLoader,
  },
});

export const setSettingsIsAdmin = isAdmin => ({
  type: SET_SETTINGS_IS_ADMIN,
  payload: {
    isAdmin,
  },
});