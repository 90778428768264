import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { ButtonCustom } from '../../buttons';
import { buttonTypes } from '../../buttons/ButtonCustom/constants';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const TaskButtonsPanel = ({
  createButtonAction,
  createButton,
  createButtonName,
  updateButton,
  updateButtonName,
  updateButtonAction,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box
      component='footer'
      className={classes.wrapper}
    >
      <Box className={classes.container}>
      {createButton && (
        <Box className={classes.column}>
          <ButtonCustom
            text={createButtonName}
            onClick={createButtonAction}
            customType={buttonTypes.CREATE}
            iconCustom
          />
        </Box>
      )}
      {updateButton && (
        <Box className={classes.column}>
          <ButtonCustom
            text={updateButtonName}
            onClick={updateButtonAction}
            customType={buttonTypes.EDIT}
          />
        </Box>
      )}
      </Box>
    </Box>
  )
}
