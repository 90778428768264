export const getStyles = theme => ({
  wrapper: {
    alignItems: 'center',
    paddingLeft: 3,
    paddingRight: 3,
    width: '100%',
    borderRadius: 3,
    display: 'flex',
    height: 40,
  },
  buttonContainer: {
    marginLeft: 3,
    marginRight: 3,
  },
  buttonStyle: {
    padding: 5,
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    }
  }
});
