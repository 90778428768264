export const walletTypesForTableToParser = walletTypes => walletTypes?.map(walletType => (
  {
    id: walletType?._id,
    name: walletType?.name,
    notes: walletType?.notes,
    action: {
      id: walletType?._id,
    },
  }
));

export const walletTypeToBackendWalletTypeParser = walletType => ({
  name: walletType?.name,
  notes: walletType?.notes,
});

export const editWalletTypeToBackendWalletTypeParser = walletType => ({
  _id: walletType?.id,
  name: walletType?.name,
  notes: walletType?.notes,
});

export const dataToEditWalletTypeParser = data => ({
  id: data?.findOneWalletType?._id,
  name: data?.findOneWalletType?.name,
  notes: data?.findOneWalletType?.notes,
});
