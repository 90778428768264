export const accountFiguresLinksConstants = {
  DEPOSIT: '/AccountFigures/Deposit',
  APPLICATION: '/AccountFigures/Application',
  PAY_PER_HEAD: '/AccountFigures/PayPerHead',
  HAND_BETS: '/AccountFigures/HandBets',
  PRIVATE_WALLETS: '/AccountFigures/PrivateWallets',
}

export const navigationList = [
  {
    title: 'Deposit',
    link: accountFiguresLinksConstants.DEPOSIT,
  },
  {
    title: 'Application',
    link: accountFiguresLinksConstants.APPLICATION,
  },
  {
    title: 'Pay Per Head',
    link: accountFiguresLinksConstants.PAY_PER_HEAD,
  },
  {
    title: 'HandBets',
    link: accountFiguresLinksConstants.HAND_BETS,
  },
  {
    title: 'Private Wallets',
    link: accountFiguresLinksConstants.PRIVATE_WALLETS,
  },
];
