import {
  SET_WALLET_TYPES_LIST,
} from '../../constants';

const initialState = {
  walletTypesList: [],
}

export const walletTypes = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLET_TYPES_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}