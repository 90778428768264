import { setAppDepPaymentPartnersList, setCreateTransactionPartnersList, store } from '../../store';
import {
  setTransactionsList,
} from '../../store';
import {
  transactionsForTableTransactionsParser,
  transactionsForTableJoinTransactionsParser,
  transactionsForTableTransactionsBackupParser,
  dataPartnersForTransaction,
  partnersToTransactionPartnersList,
  addTransactionToBackendParser,
  updateTransactionToBackendParser,
} from './reformatters';

import {
  getLocalStorageData,
} from '../../utils';
import {
  GET_TRANSACTIONS_FOR_TABLE,
  GET_TRANSACTIONS_FOR_TABLE_BACKUP,
  GET_PARTNERS_FOR_SELECT,
  ADD_TRANSACTION,
  DELETE_TRANSACTION,
  PERMANENT_DELETE_TRANSACTION,
  UPDATE_TRANSACTION,
  RESTORE_TRANSACTION,
  GET_TRANSACTIONS_FOR_TABLE_DATE_RANGE,
  GET_TRANSACTIONS_FOR_TABLE_UNIQUE,
  GET_TRANSACTIONS_FOR_TABLE_SEPARATE,
  GET_TRANSACTIONS_FOR_JOIN_TABLE_DATE_RANGE, DELETE_JOIN_TRANSACTIONS, PERMANENT_DELETE_ALL_BACKUP_TRANSACTIONS,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';
import { transactionPageConst } from '../../pages';

export async function getTransactionsForTable() {
  await requestWrapper('GetTransactionsForTable', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_TRANSACTIONS_FOR_TABLE)
      .setBearerToken(token)
      .build();
    const { transactionFindAll } = data || null;

    if (!loading && !error && transactionFindAll) {
      const { transactionType } = store.getState().transactions;
      if (transactionPageConst.ACTIVE.TYPE === transactionType) {
        store.dispatch(
          setTransactionsList(
            transactionsForTableTransactionsParser(transactionFindAll)
          )
        );
      }
    } else {

    }
  });
}

export async function getTransactionsForTableJoinTransactions() {
  await requestWrapper('GetTransactionsForTableUnique', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(GET_TRANSACTIONS_FOR_TABLE_SEPARATE)
      .setBearerToken(token)
      .setVariables({
        transactionType: 'cashInCashOut'
      })
      .build();
    const { transactionFindAllSeparate } = data || null;

    if (!loading && !error && transactionFindAllSeparate) {
      store.dispatch(setTransactionsList(transactionsForTableJoinTransactionsParser(transactionFindAllSeparate)))
    } else {

    }
  });
}

export async function getTransactionsForTableJoinTransactionsDateRange(date) {
  await requestWrapper('GetTransactionsForTableJoinTransactionsDateRange', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_TRANSACTIONS_FOR_JOIN_TABLE_DATE_RANGE)
      .setBearerToken(token)
      .setVariables({
        payload: date,
      })
      .build();
    const { getTransactionsForTableJoinTransactionsDateRange } = data || null;

    if (!loading && !error && getTransactionsForTableJoinTransactionsDateRange) {
      const { transactionType } = store.getState().transactions;
      if (transactionPageConst.JOIN.TYPE === transactionType) {
        store.dispatch(
          setTransactionsList(transactionsForTableJoinTransactionsParser(getTransactionsForTableJoinTransactionsDateRange))
        );
      }
    } else {

    }
  });
}

export async function getTransactionsForTableDateRange(date) {
  await requestWrapper('GetTransactionsForTableDateRange', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_TRANSACTIONS_FOR_TABLE_DATE_RANGE)
      .setBearerToken(token)
      .setVariables({
        payload: date,
      })
      .build();
    const { transactionFindAllDateRange } = data || null;

    if (!loading && !error && transactionFindAllDateRange) {
      const { transactionType } = store.getState().transactions;
      if (transactionPageConst.ACTIVE.TYPE === transactionType) {
        store.dispatch(
          setTransactionsList(
            transactionsForTableTransactionsParser(transactionFindAllDateRange)
          )
        );
      }
    } else {

    }
  });
}

export async function getTransactionsForTableBackup() {
  await requestWrapper('GetTransactionsForTableBackup', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_TRANSACTIONS_FOR_TABLE_BACKUP)
      .setBearerToken(token)
      .build();
    const { transactionBackupFindAll } = data || null;

    if (!loading && !error && transactionBackupFindAll) {
      const { transactionType } = store.getState().transactions;
      if (transactionPageConst.BACKUP.TYPE === transactionType) {
        store.dispatch(
          setTransactionsList(
            transactionsForTableTransactionsBackupParser(transactionBackupFindAll)
          )
        );
      }

    } else {

    }
  });
}

export async function getPartnersForTransaction() {
  await requestWrapper('GetPartnersForTransaction', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_PARTNERS_FOR_SELECT)
      .setBearerToken(token)
      .build();
    const { partners } = data || null;

    if (!loading && !error && partners) {
      // return partnersToTransactionPartnersList(partners);
      console.log('partners', partners);
      store.dispatch(
        setCreateTransactionPartnersList(
          partnersToTransactionPartnersList(partners)
        )
      );
      store.dispatch(
        setAppDepPaymentPartnersList(
          partnersToTransactionPartnersList(partners)
        )
      );
    } else {

    }
  });
}

export async function addTransaction(newTransaction) {
  await requestWrapper('AddTransaction', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(ADD_TRANSACTION)
      .setBearerToken(token)
      .setVariables({
        payload: addTransactionToBackendParser(newTransaction)
      })
      .build();

    const { createTransaction } = data || null;

    if (!loading &&
        !error &&
        createTransaction?.idReceiver
    ) {
      // await partnersForTable();
    } else {

    }
  });
}

export async function updateTransaction(updatedtransaction) {
  await requestWrapper('UpdateTransaction', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(UPDATE_TRANSACTION)
      .setBearerToken(token)
      .setVariables({
        payload: updateTransactionToBackendParser(updatedtransaction)
      })
      .build();

    const { updateTransaction } = data || null;

    if (!loading &&
        !error &&
        updateTransaction?._id
    ) {
      // await partnersForTable();
    } else {

    }
  });
}

export const deleteTransaction = async ({
  id
}) => {
  await requestWrapper('DeleteTransaction', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(DELETE_TRANSACTION)
      .setBearerToken(token)
      .setVariables({ _id: id, })
      .build();

    const { removeTransaction } = data || null;

    if (!loading && !error && removeTransaction?._id) {
      await getTransactionsForTable();
    } else {

    }
  });
}

export const deleteJoinTransactions = async ({
  idsList
}) => {
  await requestWrapper('MultiRemoveTransactions', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(DELETE_JOIN_TRANSACTIONS)
      .setBearerToken(token)
      .setVariables({ idsList })
      .build();

    const { multiRemoveTransactions } = data || null;

    if (!loading && !error && multiRemoveTransactions) {
      await getTransactionsForTableJoinTransactions();
    } else {

    }
  });
}

export const permanentDeleteTransaction = async ({
  id
}) => {
  await requestWrapper('PermanentDeleteTransaction', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(PERMANENT_DELETE_TRANSACTION)
      .setBearerToken(token)
      .setVariables({ _id: id, })
      .build();

    const { permanentRemoveTransaction } = data || null;

    if (!loading && !error && permanentRemoveTransaction?._id) {
      await getTransactionsForTableBackup();
    } else {

    }
  });
}

export const permanentDeleteAllBackupTransactions = async () => {
  await requestWrapper('PermanentDeleteAllBackupTransactions', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(PERMANENT_DELETE_ALL_BACKUP_TRANSACTIONS)
      .setBearerToken(token)
      .build();

    const { permanentDeleteAllBackupTransactions } = data || null;

    if (!loading && !error && permanentDeleteAllBackupTransactions) {
      await getTransactionsForTableBackup();
    } else {

    }
  });
}

export const restoreTransaction = async ({
  id
}) => {
  await requestWrapper('RestoreTransaction', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .mutate(RESTORE_TRANSACTION)
      .setBearerToken(token)
      .setVariables({ _id: id, })
      .build();

    const { restoreTransaction } = data || null;

    if (!loading && !error && restoreTransaction?._id) {
      await getTransactionsForTableBackup();
    } else {

    }
  });
}
