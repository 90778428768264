import React, { useEffect } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { ButtonCustom } from '../../buttons';
import { buttonTypes } from '../../buttons/ButtonCustom/constants';


import { getStyles } from './styles';
import { WeekSwitcher } from '../../../WeekSwitcher';
import moment from 'moment';

const useStyles = makeStyles(getStyles);


export const ModalStopAccount = ({
	open,
	eventApply,
	eventClose,
	selectedDate,
	setSelectedDate,
	title,
	accountName,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	useEffect(() => {
		const today = moment();
		const lastDay = today.clone();
		setSelectedDate(lastDay);
	}, []);

	const validate = () => {
		let result = true;
		if (!selectedDate) {
			result = false;
		}
		return result;
	}

	const sendApplyData = () => {
		if (validate()) {
			eventApply();
		}
	}

	return (
		<Box>
			<Dialog
				open={open}
				aria-labelledby='alert-dialog-create-title'
				className={classes.modalContent}
			>
				<Box>
					<IconButton
						aria-label="close"
						className={classes.buttonClose}
						onClick={eventClose}
					>
						<CloseIcon />
					</IconButton>
					<DialogTitle
						id='alert-dialog-create-title'
						className={classes.title}
					>
						{title ? title : 'Stop Account'}
					</DialogTitle>
					<DialogContent>

						<Box className={classes.rowWrapper}>
							<Box className={classes.colWrapper_100}>
								<div className={classes.text}>
									Username: { accountName }
								</div>
							</Box>
						</Box>

						<Box className={classes.rowWrapper}>
							<Box className={classes.colWrapper_100}>
								<div className={classes.text}>
									Select the date from which the account should be stopped.
								</div>
							</Box>
						</Box>

						<Box className={classes.rowWrapper}>
							<Box className={classes.colWrapper_100}>
								<WeekSwitcher
									selectedDate={selectedDate}
									handleChangeSelectedDate={setSelectedDate}
									isOneDay
								/>
							</Box>
						</Box>

					</DialogContent>
					<DialogActions
						className={classes.formButtons}
					>
						<ButtonCustom
							onClick={eventClose}
							customType={buttonTypes.CANCEL}
						/>

						<ButtonCustom
							autoFocus
							onClick={sendApplyData}
							customType={buttonTypes.CREATE}
							text='Apply'
						/>
					</DialogActions>
				</Box>
			</Dialog>
		</Box>
	);
}
