import { createSelector } from 'reselect';

const getSettingsSelector = state => state.settings;

export const getThemeNameSelector = createSelector(
  getSettingsSelector,
  settings => settings.themeName
);

export const getIsFetchingSelector = createSelector(
  getSettingsSelector,
  settings => settings.isFetching
);

export const getIsAuthenticatedSelector = createSelector(
  getSettingsSelector,
  settings => settings.isAuthenticated
);
