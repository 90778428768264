import { createSelector } from 'reselect';

// Default Selectors
const getCreateWalletTypeSelector = state => state.createWalletType;
const getEditWalletTypeSelector = state => state.editWalletType;

const getWalletTypesSelector = state => state.walletTypes;

export const getWalletTypesListSelector = createSelector(
  getWalletTypesSelector,
  walletTypes => walletTypes.walletTypesList
);

// Create Selectors
export const getCreateWalletTypeNameSelector = createSelector(
  getCreateWalletTypeSelector,
  createWalletType => createWalletType.name
);

export const getCreateWalletTypeNotesSelector = createSelector(
  getCreateWalletTypeSelector,
  createWalletType => createWalletType.notes
);

// Edit Selectors
export const getEditWalletTypeIdSelector = createSelector(
  getEditWalletTypeSelector,
  editWalletType => editWalletType.id
);

export const getEditWalletTypeNameSelector = createSelector(
  getEditWalletTypeSelector,
  editWalletType => editWalletType.name
);

export const getEditWalletTypeNotesSelector = createSelector(
  getEditWalletTypeSelector,
  editWalletType => editWalletType.notes
);
