import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { Header } from '../../Header';
import {
  AccountsPage,
  AccountFiguresPage,
  AccountFiguresTablePage,
  PartnersPage,
  NetworksPage,
  ReportsPage,
  PaymentsPage,
  WalletsPage,
  WalletTypesPage,
  WalletsTablePage,
  transactionPageConst,
  TransactionsTablePage,
  TransactionsPage,
  AccountsTablePage,
  ReportsTablePage,
  BalancesReportsPage,
  TelegramPage,
  GetPartnerCodePage,
  GeneratedPartnerCodesPage,
  UsersPage,
  accountFiguresLinksConstants,
  accountsLinksConstants,
  reportPageConst,
  telegramLinksConstants,
} from '../../../pages';

import {
  ACCOUNTS_ROUTE,
  accountTypes,
} from './constants';

import { getStyles } from '../styles';

const useStyles = makeStyles(getStyles);

export const AppRouters = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.container}>

      <Header />

      <Switch>
        <Box
          className={classes.main}
          component='main'
        >

          <Route
            exact
            path='/'
            component={BalancesReportsPage}
          />

          <Route
            exact
            path='/Payments'
            component={PaymentsPage}
          />

          <Route
            exact path={`${ACCOUNTS_ROUTE}`}
            component={AccountsPage}
          />

          <Route
            path='/Accounts'
            render={(props) => (
              <AccountsPage {...props}>
                <Switch>
                  <Route
                    key={1}
                    exact
                    path={accountsLinksConstants.DEPOSIT}
                    render={() =>
                      <AccountsTablePage selectedType={accountTypes.DEPOSIT} />
                    }
                  />
                  <Route
                    key={2}
                    exact
                    path={accountsLinksConstants.APPLICATION}
                    render={() =>
                      <AccountsTablePage selectedType={accountTypes.APPLICATION} />
                    }
                  />
                  <Route
                    key={3}
                    exact
                    path={accountsLinksConstants.PAY_PER_HEAD}
                    render={() =>
                      <AccountsTablePage selectedType={accountTypes.PAY_PER_HEAD} />
                    }
                  />
                  <Route
                    key={4}
                    exact
                    path={accountsLinksConstants.HAND_BETS}
                    render={() =>
                      <AccountsTablePage selectedType={accountTypes.HAND_BETS} />
                    }
                  />
                  <Redirect exact from='/Accounts' to={accountsLinksConstants.DEPOSIT} />
                </Switch>
              </AccountsPage>
            )}
          />

          <Route
            exact path='/Partners'
            component={PartnersPage}
          />

          <Route
            exact path='/Networks'
            component={NetworksPage}
          />

          <Route
            path='/AccountFigures'
            render={(props) => (
              <AccountFiguresPage {...props}>
                <Switch>
                  <Route
                    key={1}
                    exact
                    path={accountFiguresLinksConstants.DEPOSIT}
                    render={() => (
                      <AccountFiguresTablePage selectedType={accountTypes.DEPOSIT} />
                    )}
                  />
                  <Route
                    key={2}
                    exact
                    path={accountFiguresLinksConstants.APPLICATION}
                    render={() => (
                      <AccountFiguresTablePage selectedType={accountTypes.APPLICATION} />
                    )}
                  />
                  <Route
                    key={3}
                    exact
                    path={accountFiguresLinksConstants.PAY_PER_HEAD}
                    render={() => (
                      <AccountFiguresTablePage selectedType={accountTypes.PAY_PER_HEAD} />
                    )}
                  />
                  <Route
                    key={4}
                    exact
                    path={accountFiguresLinksConstants.HAND_BETS}
                    render={() => (
                      <AccountFiguresTablePage selectedType={accountTypes.HAND_BETS} />
                    )}
                  />
                  <Route
                    key={5}
                    exact
                    path={accountFiguresLinksConstants.PRIVATE_WALLETS}
                    render={() => (
                      <AccountFiguresTablePage selectedType={accountTypes.PRIVATE_WALLETS} />
                    )}
                  />
                  <Redirect
                    exact
                    from='/AccountFigures'
                    to={accountFiguresLinksConstants.DEPOSIT}
                  />
                </Switch>
              </AccountFiguresPage>
            )}
          />

          <Route
            path='/Reports'
            render={(props) => (
              <ReportsPage {...props}>
                <Switch>
                  <Route
                    exact
                    key={1}
                    path={reportPageConst.AGENTS.LINK}
                    render={() =>
                      <ReportsTablePage selectedType={reportPageConst.AGENTS.TYPE} />
                    }
                  />
                  <Route
                    exact
                    key={2}
                    path={reportPageConst.PARTNERS.LINK}
                    render={() =>
                      <ReportsTablePage selectedType={reportPageConst.PARTNERS.TYPE} />
                    }
                  />
                  <Route
                    exact
                    key={3}
                    path={reportPageConst.WALLETS.LINK}
                    render={() =>
                      <ReportsTablePage selectedType={reportPageConst.WALLETS.TYPE} />
                    }
                  />
                  <Redirect
                    exact
                    from='/Reports'
                    to={reportPageConst.AGENTS.LINK}
                  />
                </Switch>
            </ReportsPage>
            )}
          />

          <Route
            path='/Wallets'
            render={props => (
              <WalletsPage {...props}>
                <Switch>
                  <Route exact path='/Wallets' component={WalletsTablePage} />
                  <Route exact path='/Wallets/WalletTypes' component={WalletTypesPage} />
                </Switch>
              </WalletsPage>
            )}
          />

          <Route
            path='/Transactions'
            render={(props) => (
              <TransactionsPage {...props}>
                <Switch>
                  <Route
                    exact
                    key={1}
                    path={transactionPageConst.ACTIVE.LINK}
                    render={() => (
                      <TransactionsTablePage selectedType={transactionPageConst.ACTIVE.TYPE} />
                    )}
                  />
                  <Route
                    exact
                    key={2}
                    path={transactionPageConst.BACKUP.LINK}
                    render={() => (
                      <TransactionsTablePage selectedType={transactionPageConst.BACKUP.TYPE} />
                    )}
                  />
                  <Route
                    exact
                    key={3}
                    path={transactionPageConst.JOIN.LINK}
                    render={() => (
                      <TransactionsTablePage selectedType={transactionPageConst.JOIN.TYPE} />
                    )}
                  />
                  <Redirect
                    exact
                    from='/Transactions'
                    to={transactionPageConst.ACTIVE.LINK}
                  />
                </Switch>
              </TransactionsPage>
            )}
          />

          <Route
            path='/Telegram'
            render={(props) => (
              <TelegramPage {...props}>
                <Switch>
                  <Route
                    exact
                    key={1}
                    path={telegramLinksConstants.users}
                    render={() =>
                      <UsersPage />
                    }
                  />
                  <Route
                    exact
                    key={2}
                    path={telegramLinksConstants.generatedPartnerCodes}
                    render={() =>
                      <GeneratedPartnerCodesPage />
                    }
                  />
                  <Route
                    exact
                    key={3}
                    path={telegramLinksConstants.getPartnerCode}
                    render={() =>
                      <GetPartnerCodePage />
                    }
                  />
                  <Redirect
                    exact
                    from='/Telegram'
                    to={telegramLinksConstants.users}
                  />
                </Switch>
              </TelegramPage>
            )}
          />

          {/* <Redirect
            from='/'
            to={ACCOUNTS_ROUTE}
          /> */}

        </Box>
      </Switch>
    </Box>
  )
}
