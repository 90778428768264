import {
	setAppDepPaymentPartnersList,
	setCreateTransactionPartnersList,
	store,
} from '../../store';

export const setActionTransactionPartners = data => {
	store.dispatch(setCreateTransactionPartnersList(data));
}

export const setActionAppDepPaymentPartners = data => {
	store.dispatch(setAppDepPaymentPartnersList(data));
}
