import { getAccountClassFromStatus } from './utils';
import moment from 'moment';

export const accountsForTableToParser = accounts => accounts?.map(account => (
  {
    id: account?._id,
    name: account?.shortAccountName || account?.url || null,
    type: account?.type,
    subType: account?.subType,
    username: account?.username,
    password: account?.password,
    sharePercent: {
      id: account?._id,
      value: (account?.sharePercent || account?.sharePercent === 0)
        ? String(account?.sharePercent)
        : null,
    },
    subOwnerPercent: {
      id: account?._id,
      value: (account?.subOwnerPercent || account?.subOwnerPercent === 0)
        ? String(account?.subOwnerPercent)
        : account?.subOwner?._id
          ? null
          : 'disabled',
    },
    notes: account?.notes,
    disabled: account?.disabled,
    siteTypeId: account?.siteType?._id,
    siteTypeName: account?.siteType?.name,
    subOwnerId: account?.subOwner?._id,
    subOwnerName: account?.subOwner?.name,
    partnerId: account?.partner?._id,
    partnerName: account?.partner?.name,
    status: account?.stop
      ? `STOP on ${moment(account?.stopDateUTC).format('MMM Do')}`
      : account?.status,
    isCustomTextColor: getAccountClassFromStatus(account?.status),
    action: {
      id: account?._id,
      disabled: account?.disabled,
      isStop: account?.stop,
    },
  }
));

export const accountToBackendAccountParser = account => ({
  type: account?.type,
    subType: account?.type === 'PayPerHead' || account?.type === 'HandBets'
  ? account?.subType?.id
  : undefined,
  shortAccountName: account?.type === 'Application'
  ? account?.shortAccountName
  : undefined,
  url: account?.type === 'Deposit' || account?.type === 'PayPerHead'
  ? account?.url
  : undefined,
  username: account?.type !== 'HandBets'
  ? account?.username
  : undefined,
  password: account?.type !== 'HandBets'
  ? account?.password
  : undefined,
  sharePercent: account?.sharePercent ? Number(account?.sharePercent) : undefined,
  notes: account?.notes || undefined,
  siteType: account?.type === 'PayPerHead'
  ? account?.siteType?.id
  : undefined,
  partner: account?.partner?.id,
  subOwner: account?.subOwner ? account?.subOwner?.id : null,
  subOwnerPercent: account?.subOwnerPercent ? Number(account?.subOwnerPercent) : null,
});

export const editAccountToBackendAccountParser = account => ({
  _id: account?.id,
  disabled: account?.disabled,
  ...accountToBackendAccountParser(account),
});

export const dataToEditAccountParser = data => ({
  id: data?.account?._id,
  type: data?.account?.type,
  subType: {
    id: data?.account?.subType,
    name: data?.account?.subType,
  },
  shortAccountName: data?.account?.shortAccountName,
  url: data?.account?.url,
  username: data?.account?.username,
  password: data?.account?.password,
  sharePercent: data?.account?.sharePercent,
  notes: data?.account?.notes,
  siteType: {
    id: data?.account?.siteType?._id,
    name: data?.account?.siteType?.name,
  },
  partner: {
    id: data?.account?.partner?._id,
    name: data?.account?.partner?.name,
  },
  subOwner: {
    id: data?.account?.subOwner?._id,
    name: data?.account?.subOwner?.name,
  },
  subOwnerPercent: data?.account?.subOwnerPercent,
  partnersList: partnersWithSharePercentToAccountPartnersListParser(data?.partners),
  siteTypesList: siteTypesToAccountSiteTypesListParser(data?.networks),
});

export const partnersWithSharePercentToAccountPartnersListParser = partners => partners?.map(partner => (
  {
    id: partner?._id,
    name: partner?.name,
    sharePercent: partner?.sharePercent,
  }
));

export const partnersToAccountPartnersListParser = partners => partners?.map(partner => (
  {
    id: partner?._id,
    name: partner?.name,
  }
));

export const siteTypesToAccountSiteTypesListParser = siteTypes => siteTypes?.map(siteType => (
  {
    id: siteType?._id,
    name: siteType?.name,
  }
));
