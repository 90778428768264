import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { ButtonIconCustom } from '../../UIComponents';
import { iconTypes } from '../../UIComponents/buttons/ButtonIconCustom/constants';
import { store } from '../../../store';

import { getStyles } from './styles';
import { dataSwitcher } from './utils';

const useStyles = makeStyles(getStyles);


export const PanelButtonInfo = ({
  data,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleClick = () => {
    store.dispatch(dataSwitcher(data));
  }

  return Boolean(data && data.list && data.list.length) && (
    <Box>
      <ButtonIconCustom
        customType={iconTypes.INFO}
        className={classes.buttonInfo}
        onClick={handleClick}
      />
    </Box>
  )
}
