import { createSelector } from 'reselect';

// Default Selectors
const getCreateTransactionSelector = state => state.createTransaction;
const getAppDepPaymentSelector = state => state.appDepPayment;
const getAccountsSelector = state => state.accounts;
const getSettingsSelector = state => state.settings;

// Create Transaction selectors
export const getCreateTransactionAmountSelector = createSelector(
  getCreateTransactionSelector,
  createTransaction => createTransaction.amount
);

export const getCreateTransactionPartnerSenderSelector = createSelector(
  getCreateTransactionSelector,
  createTransaction => createTransaction.partnerSender
);

export const getCreateTransactionPartnerSenderWalletSelector = createSelector(
  getCreateTransactionSelector,
  createTransaction => createTransaction.partnerSenderWallet
);

export const getCreateTransactionPartnerReceiverSelector = createSelector(
  getCreateTransactionSelector,
  createTransaction => createTransaction.partnerReceiver
);

export const getCreateTransactionPartnerReceiverWalletSelector = createSelector(
  getCreateTransactionSelector,
  createTransaction => createTransaction.partnerReceiverWallet
);

export const getCreateTransactionSubTypeSelector = createSelector(
  getCreateTransactionSelector,
  createTransaction => createTransaction.subType
);

export const getCreateTransactionPartnersListSelector = createSelector(
  getCreateTransactionSelector,
  createTransaction => createTransaction.partnersList
);

export const getCreateTransactionSelectedDateSelector = createSelector(
  getCreateTransactionSelector,
  createTransaction => createTransaction.selectedDate
);

export const getCreateTransactionNotesSelector = createSelector(
  getCreateTransactionSelector,
  createTransaction => createTransaction.notes
);

export const getAccountsListSelector = createSelector(
	getAccountsSelector,
	accounts => accounts.accountsList
);

export const getAppDepPaymentSelectedDateSelector = createSelector(
	getAppDepPaymentSelector,
	appDepPayment => appDepPayment.selectedDate
);

export const getAppDepPaymentNotesSelector = createSelector(
	getAppDepPaymentSelector,
	appDepPayment => appDepPayment.notes
);

export const getAppDepPaymentAmountSelector = createSelector(
	getAppDepPaymentSelector,
	appDepPayment => appDepPayment.amount
);

export const getAppDepPaymentAccountSelector = createSelector(
	getAppDepPaymentSelector,
	appDepPayment => appDepPayment.account
);

export const getAppDepPaymentPartnersListSelector = createSelector(
	getAppDepPaymentSelector,
	appDepPayment => appDepPayment.partnersList
);

export const getAppDepPaymentPartnerReceiverSelector = createSelector(
	getAppDepPaymentSelector,
	appDepPayment => appDepPayment.partnerReceiver
);

export const getAppDepPaymentPartnerReceiverWalletSelector = createSelector(
	getAppDepPaymentSelector,
	appDepPayment => appDepPayment.partnerReceiverWallet
);

export const getSettingsIsAdminSelector = createSelector(
	getSettingsSelector,
	settings => settings.isAdmin
);
