export const SET_SETTINGS_THEME = 'SET_SETTINGS_THEME';
export const SET_SETTINGS_IS_FETCHING = 'SET_SETTINGS_IS_FETCHING';
export const SET_SETTINGS_IS_AUTHENTICATED = 'SET_SETTINGS_IS_AUTHENTICATED';
export const SET_SETTINGS_SHOW_LOADER = 'SET_SETTINGS_SHOW_LOADER';
export const SET_SETTINGS_IS_ADMIN = 'SET_SETTINGS_IS_ADMIN';

export const SET_ACCOUNTS_LIST = 'SET_ACCOUNTS_LIST';
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS';
export const SET_ACCOUNT_ITEM_VALUE = 'SET_ACCOUNT_ITEM_VALUE';
export const SET_ACCOUNT_ITEM_DISABLED = 'SET_ACCOUNT_ITEM_DISABLED';
export const SET_ACCOUNT_SUB_OWNER_ITEM_VALUE = 'SET_ACCOUNT_SUB_OWNER_ITEM_VALUE';
export const START_STOP_UPDATE_ACCOUNT = 'START_STOP_UPDATE_ACCOUNT';

export const RESET_BALANCES_REPORTS = 'RESET_BALANCES_REPORTS';
export const SET_BALANCES_REPORTS = 'SET_BALANCES_REPORTS';
export const SET_BALANCES_REPORTS_SELECTED_DATE = 'SET_BALANCES_REPORTS_SELECTED_DATE';

export const SET_PARTNERS_LIST = 'SET_PARTNERS_LIST';

export const SET_WALLETS_LIST = 'SET_WALLETS_LIST';

export const SET_WALLET_TYPES_LIST = 'SET_WALLET_TYPES_LIST';

export const RESET_TRANSACTIONS_PAGE = 'RESET_TRANSACTIONS_PAGE';
export const SET_TRANSACTIONS_SUB_TYPE = 'SET_TRANSACTIONS_SUB_TYPE';
export const SET_TRANSACTION_TYPE = 'SET_TRANSACTION_TYPE';
export const SET_TRANSACTION_FILTERED_LIST = 'SET_TRANSACTION_FILTERED_LIST';
export const SET_TRANSACTIONS_LIST = 'SET_TRANSACTIONS_LIST';

export const RESET_CREATE_ACCOUNT = 'RESET_CREATE_ACCOUNT';
export const REMOVE_ACCOUNT_FROM_LIST = 'REMOVE_ACCOUNT_FROM_LIST';
export const SET_CREATE_ACCOUNT_TYPE = 'SET_CREATE_ACCOUNT_TYPE';
export const SET_CREATE_ACCOUNT_SUB_TYPE = 'SET_CREATE_ACCOUNT_SUB_TYPE';
export const SET_CREATE_ACCOUNT_SHORT_ACCOUNT_NAME = 'SET_CREATE_ACCOUNT_SHORT_ACCOUNT_NAME';
export const SET_CREATE_ACCOUNT_URL = 'SET_CREATE_ACCOUNT_URL';
export const SET_CREATE_ACCOUNT_USERNAME = 'SET_CREATE_ACCOUNT_USERNAME';
export const SET_CREATE_ACCOUNT_PASSWORD = 'SET_CREATE_ACCOUNT_PASSWORD';
export const SET_CREATE_ACCOUNT_SHARE_PERCENT = 'SET_CREATE_ACCOUNT_SHARE_PERCENT';
export const SET_CREATE_ACCOUNT_NOTES = 'SET_CREATE_ACCOUNT_NOTES';
export const SET_CREATE_ACCOUNT_SITE_TYPE = 'SET_CREATE_ACCOUNT_SITE_TYPE';
export const SET_CREATE_ACCOUNT_PARTNER = 'SET_CREATE_ACCOUNT_PARTNER';
export const SET_CREATE_ACCOUNT_SITE_TYPES_LIST = 'SET_CREATE_ACCOUNT_SITE_TYPES_LIST';
export const SET_CREATE_ACCOUNT_PARTNERS_LIST = 'SET_CREATE_ACCOUNT_PARTNERS_LIST';
export const SET_CREATE_ACCOUNT_SUB_OWNER = 'SET_CREATE_ACCOUNT_SUB_OWNER';
export const SET_CREATE_ACCOUNT_SUB_OWNER_PERCENT = 'SET_CREATE_ACCOUNT_SUB_OWNER_PERCENT';

export const RESET_CREATE_PARTNER = 'RESET_CREATE_PARTNER';
export const SET_CREATE_PARTNER_NAME ='SET_CREATE_PARTNER_NAME';
export const SET_CREATE_PARTNER_TYPE ='SET_CREATE_PARTNER_TYPE';
export const SET_CREATE_PARTNER_TERMS ='SET_CREATE_PARTNER_TERMS';
export const SET_CREATE_PARTNER_LOCATION ='SET_CREATE_PARTNER_LOCATION';
export const SET_CREATE_PARTNER_SHARE_PERCENT ='SET_CREATE_PARTNER_SHARE_PERCENT';

export const RESET_CREATE_NETWORK = 'RESET_CREATE_NETWORK';
export const SET_CREATE_NETWORK_NAME ='SET_CREATE_NETWORK_NAME';

export const RESET_CREATE_WALLET = 'RESET_CREATE_WALLET';
export const SET_CREATE_WALLET_TYPE ='SET_CREATE_WALLET_TYPE';
export const SET_CREATE_WALLET_IS_PRIVATE ='SET_CREATE_WALLET_IS_PRIVATE';
export const SET_CREATE_WALLET_PARTNER = 'SET_CREATE_WALLET_PARTNER';
export const SET_CREATE_WALLET_NOTES ='SET_CREATE_WALLET_NOTES';
export const SET_CREATE_WALLET_PARTNERS_LIST = 'SET_CREATE_WALLET_PARTNERS_LIST';
export const SET_CREATE_WALLET_WALLET_TYPES_LIST = 'SET_CREATE_WALLET_WALLET_TYPES_LIST';

export const RESET_CREATE_WALLET_TYPE = 'RESET_CREATE_WALLET_TYPE';
export const SET_CREATE_WALLET_TYPE_NAME ='SET_CREATE_WALLET_TYPE_NAME';
export const SET_CREATE_WALLET_TYPE_NOTES ='SET_CREATE_WALLET_TYPE_NOTES';

export const RESET_EDIT_ACCOUNT = 'RESET_EDIT_ACCOUNT';
export const SET_EDIT_ACCOUNT = 'SET_EDIT_ACCOUNT';
export const SET_EDIT_ACCOUNT_TYPE = 'SET_EDIT_ACCOUNT_TYPE';
export const SET_EDIT_ACCOUNT_SUB_TYPE = 'SET_EDIT_ACCOUNT_SUB_TYPE';
export const SET_EDIT_ACCOUNT_SHORT_ACCOUNT_NAME = 'SET_EDIT_ACCOUNT_SHORT_ACCOUNT_NAME';
export const SET_EDIT_ACCOUNT_URL = 'SET_EDIT_ACCOUNT_URL';
export const SET_EDIT_ACCOUNT_USERNAME = 'SET_EDIT_ACCOUNT_USERNAME';
export const SET_EDIT_ACCOUNT_PASSWORD = 'SET_EDIT_ACCOUNT_PASSWORD';
export const SET_EDIT_ACCOUNT_SHARE_PERCENT = 'SET_EDIT_ACCOUNT_SHARE_PERCENT';
export const SET_EDIT_ACCOUNT_NOTES = 'SET_EDIT_ACCOUNT_NOTES';
export const SET_EDIT_ACCOUNT_SITE_TYPE = 'SET_EDIT_ACCOUNT_SITE_TYPE';
export const SET_EDIT_ACCOUNT_PARTNER = 'SET_EDIT_ACCOUNT_PARTNER';
export const SET_EDIT_ACCOUNT_SITE_TYPES_LIST = 'SET_EDIT_ACCOUNT_SITE_TYPES_LIST';
export const SET_EDIT_ACCOUNT_PARTNERS_LIST = 'SET_EDIT_ACCOUNT_PARTNERS_LIST';
export const SET_EDIT_ACCOUNT_SUB_OWNER = 'SET_EDIT_ACCOUNT_SUB_OWNER';
export const SET_EDIT_ACCOUNT_SUB_OWNER_PERCENT = 'SET_EDIT_ACCOUNT_SUB_OWNER_PERCENT';

export const RESET_EDIT_PARTNER = 'RESET_EDIT_PARTNER';
export const SET_EDIT_PARTNER = 'SET_EDIT_PARTNER';
export const SET_EDIT_PARTNER_NAME ='SET_EDIT_PARTNER_NAME';
export const SET_EDIT_PARTNER_TYPE ='SET_EDIT_PARTNER_TYPE';
export const SET_EDIT_PARTNER_TERMS ='SET_EDIT_PARTNER_TERMS';
export const SET_EDIT_PARTNER_LOCATION ='SET_EDIT_PARTNER_LOCATION';
export const SET_EDIT_PARTNER_SHARE_PERCENT ='SET_EDIT_PARTNER_SHARE_PERCENT';

export const RESET_EDIT_NETWORK = 'RESET_EDIT_NETWORK';
export const SET_EDIT_NETWORK = 'SET_EDIT_NETWORK';
export const SET_EDIT_NETWORK_NAME ='SET_EDIT_NETWORK_NAME';

export const RESET_EDIT_WALLET = 'RESET_EDIT_WALLET';
export const SET_EDIT_WALLET = 'SET_EDIT_WALLET';
export const SET_EDIT_WALLET_PARTNER = 'SET_EDIT_WALLET_PARTNER';
export const SET_EDIT_WALLET_WALLET_TYPE ='SET_EDIT_WALLET_WALLET_TYPE';
export const SET_EDIT_WALLET_IS_PRIVATE ='SET_EDIT_WALLET_IS_PRIVATE';
export const SET_EDIT_WALLET_NOTES ='SET_EDIT_WALLET_NOTES';
export const SET_EDIT_WALLET_PARTNERS_LIST = 'SET_EDIT_WALLET_PARTNERS_LIST';
export const SET_EDIT_WALLET_WALLET_TYPES_LIST = 'SET_EDIT_WALLET_WALLET_TYPES_LIST';

export const RESET_EDIT_WALLET_TYPE = 'RESET_EDIT_WALLET_TYPE';
export const SET_EDIT_WALLET_TYPE = 'SET_EDIT_WALLET_TYPE';
export const SET_EDIT_WALLET_TYPE_NAME ='SET_EDIT_WALLET_TYPE_NAME';
export const SET_EDIT_WALLET_TYPE_NOTES ='SET_EDIT_WALLET_TYPE_NOTES';

export const SET_NETWORKS_LIST = 'SET_NETWORKS_LIST';

export const RESET_PARTNERS_TELEGRAM_CODES_LIST = 'RESET_PARTNERS_TELEGRAM_CODES_LIST';
export const SET_PARTNERS_TELEGRAM_CODES_LIST = 'SET_PARTNERS_TELEGRAM_CODES_LIST';

export const RESET_CREATE_TELEGRAM_CODE_PARTNER = 'RESET_CREATE_TELEGRAM_CODE_PARTNER';
export const SET_CREATE_TELEGRAM_CODE_PARTNER = 'SET_CREATE_TELEGRAM_CODE_PARTNER';
export const SET_CREATE_TELEGRAM_CODE_PARTNERS_LIST = 'SET_CREATE_TELEGRAM_CODE_PARTNERS_LIST';

export const CONSTS = {
  SET: {
    CREATE: {
      BTC: {
        TRANSACTION: {

        }
      }
    },
    EDIT: {

    }
  }
}

export const RESET_CREATE_TRANSACTION = 'RESET_CREATE_TRANSACTION';
export const SET_CREATE_TRANSACTION = 'SET_CREATE_TRANSACTION';
export const SET_CREATE_TRANSACTION_PARTNER_SENDER = 'SET_CREATE_TRANSACTION_PARTNER_SENDER';
export const SET_CREATE_TRANSACTION_PARTNER_SENDER_WALLET = 'SET_CREATE_TRANSACTION_PARTNER_SENDER_WALLET';
export const SET_CREATE_TRANSACTION_PARTNER_RECEIVER = 'SET_CREATE_TRANSACTION_PARTNER_RECEIVER';
export const SET_CREATE_TRANSACTION_PARTNER_RECEIVER_WALLET = 'SET_CREATE_TRANSACTION_PARTNER_RECEIVER_WALLET';
export const SET_CREATE_TRANSACTION_SUB_TYPE = 'SET_CREATE_TRANSACTION_SUB_TYPE';
export const SET_CREATE_TRANSACTION_AMOUNT = 'SET_CREATE_TRANSACTION_AMOUNT';
export const SET_CREATE_TRANSACTION_PARTNERS_LIST = 'SET_CREATE_TRANSACTION_PARTNERS_LIST';
export const SET_CREATE_TRANSACTION_SELECTED_DATE = 'SET_CREATE_TRANSACTION_SELECTED_DATE';
export const SET_CREATE_TRANSACTION_NOTES = 'SET_CREATE_TRANSACTION_NOTES';

export const RESET_ACCOUNT_FIGURES = 'RESET_ACCOUNT_FIGURES';
export const RESET_ACCOUNT_FIGURES_TAB = 'RESET_ACCOUNT_FIGURES_TAB';
export const SET_ACCOUNT_FIGURES_LIST = 'SET_ACCOUNT_FIGURES_LIST';
export const SET_ACCOUNT_FIGURES_SELECTED_DATE = 'SET_ACCOUNT_FIGURES_SELECTED_DATE';
export const SET_ACCOUNT_FIGURES_SELECTED_TYPE = 'SET_ACCOUNT_FIGURES_SELECTED_TYPE';
export const SET_ACCOUNT_FIGURES_ITEM_VALUE = 'SET_ACCOUNT_FIGURES_ITEM_VALUE';
export const SET_ACCOUNT_FIGURES_SHARE_PERCENT = 'SET_ACCOUNT_FIGURES_SHARE_PERCENT';
export const SET_ACCOUNT_FIGURES_SUB_OWNER_PERCENT = 'SET_ACCOUNT_FIGURES_SUB_OWNER_PERCENT';
export const SET_ACCOUNT_FIGURES_IS_CLOSED_WEEK = 'SET_ACCOUNT_FIGURES_IS_CLOSED_WEEK';
export const SET_ACCOUNT_FIGURES_TOTAL_WEEKLY_FIGURE = 'SET_ACCOUNT_FIGURES_TOTAL_WEEKLY_FIGURE';

export const SET_REPORTS_LIST = 'SET_REPORTS_LIST';
export const SET_REPORTS_SELECTED_DATE = 'SET_REPORTS_SELECTED_DATE';
export const SET_REPORTS_SELECTED_TYPE = 'SET_REPORTS_SELECTED_TYPE';
export const SET_REPORTS_TOTAL_SHARE_AMOUNT = 'SET_REPORTS_TOTAL_SHARE_AMOUNT';
export const EDIT_REPORTS_LIST_ITEM_TELEGRAM_CHECKED = 'EDIT_REPORTS_LIST_ITEM_TELEGRAM_CHECKED';
export const EDIT_REPORTS_LIST_ITEM_CONFIRMED_CHECKED = 'EDIT_REPORTS_LIST_ITEM_CONFIRMED_CHECKED';
export const RESET_REPORTS = 'RESET_REPORTS';
export const RESET_REPORTS_LIST = 'RESET_REPORTS_LIST';
export const RESET_REPORTS_SEND_PARTNER_IDS_LIST = 'RESET_REPORTS_SEND_PARTNER_IDS_LIST';

export const SET_MODAL_TABLE_INFO_DATA = 'SET_MODAL_TABLE_INFO_DATA';
export const RESET_MODAL_TABLE_INFO_DATA = 'RESET_MODAL_TABLE_INFO_DATA';

export const SET_APP_DEP_PAYMENT_SELECTED_DATE = 'SET_APP_DEP_PAYMENT_SELECTED_DATE';
export const SET_APP_DEP_PAYMENT_NOTES = 'SET_APP_DEP_PAYMENT_NOTES';
export const SET_APP_DEP_PAYMENT_AMOUNT = 'SET_APP_DEP_PAYMENT_AMOUNT';
export const SET_APP_DEP_PAYMENT_ACCOUNT = 'SET_APP_DEP_PAYMENT_ACCOUNT';
export const SET_APP_DEP_PAYMENT_PARTNERS_LIST = 'SET_APP_DEP_PAYMENT_PARTNERS_LIST';
export const SET_APP_DEP_PAYMENT_PARTNER_RECEIVER = 'SET_APP_DEP_PAYMENT_PARTNER_RECEIVER';
export const SET_APP_DEP_PAYMENT_PARTNER_RECEIVER_WALLET = 'SET_APP_DEP_PAYMENT_PARTNER_RECEIVER_WALLET';
export const RESET_APP_DEP_PAYMENT = 'RESET_APP_DEP_PAYMENT';

export const STATUS_MODAL_ADD_ITEM = 'STATUS_MODAL_ADD_ITEM';
export const STATUS_MODAL_REMOVE_ITEM = 'STATUS_MODAL_REMOVE_ITEM';
