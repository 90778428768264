import {
  SET_PARTNERS_LIST,
} from '../constants';

const initialState = {
  partnersList: []/*mockAgentsList*/,
}

export const partners = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTNERS_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
