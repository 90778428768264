export const getStyles = theme => ({
  buttonInfo: {
    padding: 0,
    width: 18,
    height: 18,
    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18,
    }
  },
  table: {
    minWidth: 320,
    color: theme.mainStyles.mainColorText,
    '& .MuiTableCell-body': {
      color: 'inherit',
    }
  },
  tableBody: {
    color: 'inherit',
  },
  tableCell: {
    border: `1px solid ${theme.mainStyles.mainColorLink}`,
    padding: '7px 12px',
    width: '50%',
  },
  tableCellAlignRight: {
    textAlign: 'right',
  },
  tableRowResult: {
    color: '#ffe082',
  },
  tableRowDayOfWeek: {
    color: theme.mainStyles.mainColorLink,
  },
});
