import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const compareData = (data, key, key1) => {
  switch(typeof data) {
    case 'string':
      return data;
    case 'number':
      return data;
    default:
      return  data[key] ? data[key] : data[key1];
  }
};

export const SelectCustom = ({
  labelId,
  value,
  onChange,
  itemList,
  label,
  variant,
  multiple,
  disabled,
  renderValue,
  error
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <FormControl
      variant={variant ? variant : 'outlined'}
      className={classes.formControl}
    >
      <InputLabel id={labelId}>{ label }</InputLabel>
      <Select
        labelId={labelId}
        value={value}
        onChange={onChange}
        label={label}
        multiple={multiple}
        disabled={disabled}
        renderValue={renderValue}
        error={error}
        MenuProps={{
          classes: {
            paper: classes.wrapper
          }
        }}
      >
        {
          Array.isArray(itemList) && (
            itemList.map(item => {
              return (
                <MenuItem
                  value={compareData(item, 'id')}
                  key={compareData(item, 'id')}
                  name={compareData(item, 'name', 'account')}
                >
                  {compareData(item, 'name', 'account')}
                </MenuItem>
              )
            })
          )
        }
      </Select>
    </FormControl>
  )
}
