import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import {
	ButtonCustom,
	InputCustom,
	ModalDelete,
} from '../../../components/UIComponents';
import { TableComponent } from '../../../components/TableComponent';
import {
	getTransactionColumn,
	getTransactionSort,
	transactionPageConst,
	uniqueJoinLines,
	uniqueLines,
} from '../constants';
import {
	deleteTransaction,
	deleteJoinTransactions,
	permanentDeleteTransaction,
	permanentDeleteAllBackupTransactions,
	restoreTransaction,
	updateTransaction,
	getTransactionsForTable,
	getTransactionsForTableBackup,
	getTransactionsForTableDateRange,
	getTransactionsForTableJoinTransactions,
	getTransactionsForTableJoinTransactionsDateRange,
} from '../../../api';
import { changeFilteredList, getUniqueValues } from '../../../utils';
import { sortEvent } from '../../../utils/sortingService';
import { getTransactionsListSelector } from '../selectors';
import {
	setTransactionsSubType as setTransactionsSubTypeAction,
	resetTransactions as resetTransactionsAction,
	setTransactionType as setTransactionTypeAction,
	setTransactionsFilteredList as setTransactionsFilteredListAction,
} from '../../../store';
import { RangeDatePicker } from '../../../components/UIComponents/RangeDatePicker';
import { buttonTypes } from '../../../components/UIComponents/buttons/ButtonCustom/constants';
import { MultiFilterPanel } from '../../../components/MultiFilterPanel';

import { getStyles } from './styles';
import moment from 'moment';

const useStyles = makeStyles(getStyles);

export const TransactionsTablePageContainer = ({
	transactionsList,
	resetTransactions,
	setTransactionsSubType,
	setTransactionType,
	setTransactionsFilteredList,
	selectedType,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	const [showModalDelete, setShowModalDelete] = useState(false);
	const [transactionId, setTransactionId] = useState(null);

	const [showModalRestore, setShowModalRestore] = useState(false);
	const [transactionRestoreId, setTransactionRestoreId] = useState(null);

	const [ searchValue, setSearchValue ] = useState(null);
	const [ filteredList, setFilteredList ] = useState([]);

	const [ handleSortBy, setHandleSortBy ] = useState(false);
	const [ handleSortDirection, setHandleSortDirection ] = useState(false);

	const [ selectedValue, setSelectedValue ] = useState([null, null]);
	const [ uniqueValuesList, setUniqueValuesList ] = useState([]);
	const [ uniqueFiltersList, setUniqueFiltersList] = useState([]);

	const [showModalDeleteAll, setShowModalDeleteAll] = useState(false);

	const handleButtonClick = async () => {
		if (selectedType === transactionPageConst.ACTIVE.TYPE) {
			if (selectedValue[0] && selectedValue[1]) {
				await getTransactionsForTableDateRange({
					startDate: moment(selectedValue[0]).toISOString(),
					endDate: moment(selectedValue[1]).toISOString(),
				});
			} else {
				setSelectedValue([null, null]);
				await getTransactionsForTable();
			}
		}
		if (selectedType === transactionPageConst.JOIN.TYPE) {
			if (selectedValue[0] && selectedValue[1]) {
				await getTransactionsForTableJoinTransactionsDateRange({
					startDate: selectedValue[0].toISOString(),
					endDate: selectedValue[1].toISOString(),
				});
			} else {
				setSelectedValue([null, null]);
				await getTransactionsForTableJoinTransactions();
			}
		}
	}

	const changeUniqueValues = (items, dataKey) => {
		const newUniqueValuesList = uniqueValuesList.map(elem => {
			if (elem.dataKey === dataKey) {
				return {
					...elem,
					selectedValues: items,
				}
			}
			return elem;
		})
		setUniqueValuesList(newUniqueValuesList);
		setUniqueFiltersList(newUniqueValuesList.filter(el => el.selectedValues.length > 0));
	}

	const getTransactions = useCallback(async () => {
		const date = new Date();
		const y = date.getFullYear();
		const m = date.getMonth();
		const firstDay = new Date(y, m, 1);
		setSelectedValue([firstDay, date]);
		switch(selectedType) {
			case transactionPageConst.ACTIVE.TYPE:
				await getTransactionsForTableDateRange({
					startDate: firstDay.toISOString(),
					endDate: date.toISOString(),
				})
				break;
			case transactionPageConst.BACKUP.TYPE:
				await getTransactionsForTableBackup();
				break;
			case transactionPageConst.JOIN.TYPE:
				await getTransactionsForTableJoinTransactionsDateRange({
					startDate: firstDay.toISOString(),
					endDate: date.toISOString(),
				})
				break;
			default:
				await getTransactionsForTable();
		}
	},	[]);

	useEffect(() => {
		setTransactionType(selectedType);
		return () => resetTransactions();
	}, [])

	useEffect(() => {
		getTransactions();
	}, [getTransactions]);

	useEffect(() => {
		setUniqueFiltersList([]);
		if (transactionsList && transactionsList.length) {
			const uniqueValues = getUniqueValues(
				transactionsList,
				(transactionPageConst.JOIN.TYPE === selectedType)
					? uniqueJoinLines
					: uniqueLines
			);

			setUniqueValuesList(uniqueValues.map(elem => ({
				...elem,
				selectedValues: [],
				values: elem.values.map(item => ({
					name: String(item),
					value: item,
				}))
			})));
		}
	}, [transactionsList])

	useEffect(() => {
		changeFilteredList(transactionsList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'action']);
	}, [transactionsList, searchValue, uniqueFiltersList]);

	useEffect(() => {
		setTransactionsFilteredList(filteredList)
	}, [filteredList])

	const closeModalDeleteAction = () => {
		setShowModalDelete(false);
		setTransactionId(null);
	}
	const closeModalRestoreAction = () => {
		setShowModalRestore(false);
		setTransactionRestoreId(null)
	}

	const openModalDeleteAction = id => {
		setShowModalDelete(true);
		setTransactionId(id);
	};

	const openModalRestoreAction = id => {
		setShowModalRestore(true);
		setTransactionRestoreId(id)
	}

	const deleteTransactionById = id => {
		Promise.resolve(deleteTransaction({id}))
			.then(setTransactionId(null))
			.then(closeModalDeleteAction);
	}

	const deleteJoinTransactionsById = idsList => {
		Promise.resolve(deleteJoinTransactions({idsList}))
			.then(setTransactionId(null))
			.then(closeModalDeleteAction);
	}

	const permanentDeleteTransactionById = id => {
		Promise.resolve(permanentDeleteTransaction({id}))
			.then(() => setTransactionId(null))
			.then(closeModalDeleteAction);
	}

	const permanentDeleteAll = () => {
		Promise.resolve(permanentDeleteAllBackupTransactions())
			.then(closeModalDeleteAll);
	};

	const restoreTransactionById = id => {
		Promise.resolve(restoreTransaction({id}))
			.then(() => setTransactionRestoreId(null))
			.then(closeModalRestoreAction);
	}

	const handleBlurInput = event => {
		const value = event.target.value;
		setSearchValue(value);
		changeFilteredList(transactionsList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'action']);
	}

	const handleChangeSelect = ({
		id,
		event,
	}) => {
		const update = async () => updateTransaction({
			_id: id,
			subType: event.target.value,
		});

		update().then(() => {
			setTransactionsSubType(id, event.target.value);
		});
	}

	const sort = sortEvent({
		handleSortBy,
		sortData: getTransactionSort(selectedType),
		setHandleSortDirection,
		setHandleSortBy,
		list: filteredList,
	});

	const closeModalDeleteAll = () => setShowModalDeleteAll(false);

	return (
		<Box className={classes.container}>
			{(selectedType !== transactionPageConst.BACKUP.TYPE) && (
				<Box className={classes.containerMini}>
					<Box></Box>
					<Box className={classes.containerDate}>
						<RangeDatePicker
							value={selectedValue}
							setValue={setSelectedValue}
						/>
						<ButtonCustom
							onClick={handleButtonClick}
							customType={buttonTypes.EDIT}
							text='Show transactions'
							style={{
								marginRight: 15,
							}}
						/>
					</Box>
				</Box>
			)}

			{(selectedType !== transactionPageConst.BACKUP.TYPE) && (
				<Box className={classes.containerForFilters}>
					<MultiFilterPanel
						list={uniqueValuesList}
						onChange={changeUniqueValues}
					/>
					<InputCustom
						className={classes.input}
						value={searchValue}
						onChange={handleBlurInput}
					/>
				</Box>
			)}

			{(selectedType === transactionPageConst.BACKUP.TYPE) && (
				<>
					<Box className={classes.containerDeleteButton}>
						<ButtonCustom
							text='Delete all Backup Transactions'
							onClick={() => setShowModalDeleteAll(true)}
							customType={buttonTypes.DELETE}
						/>
					</Box>

					<InputCustom
						className={classes.inputBackup}
						value={searchValue}
						onChange={handleBlurInput}
					/>
				</>

			)}

			<TableComponent
				columns={getTransactionColumn(selectedType)}
				rows={filteredList}
				deleteRowAction={openModalDeleteAction}
				restoreRowAction={selectedType === transactionPageConst.BACKUP.TYPE ? openModalRestoreAction : null}
				eventHandlerChangePanelSelect={handleChangeSelect}
				sort={sort}
				sortBy={handleSortBy}
				sortDirection={handleSortDirection}
			/>

			<ModalDelete
				open={showModalDelete}
				title={'Delete Transaction'}
				description={'Are you sure you want to delete this transaction?'}
				eventDelete={() => {
					if (selectedType === transactionPageConst.ACTIVE.TYPE) {
						deleteTransactionById(transactionId)
					}
					if (selectedType === transactionPageConst.BACKUP.TYPE) {
						permanentDeleteTransactionById(transactionId)
					}
					if (selectedType === transactionPageConst.JOIN.TYPE) {
						deleteJoinTransactionsById(transactionId)
					}
				}}
				eventClose={closeModalDeleteAction}
			/>

			<ModalDelete
				open={showModalDeleteAll}
				title={'Delete all backup transactions'}
				description={'Would you like delete all backup transactions?'}
				eventDelete={permanentDeleteAll}
				eventClose={closeModalDeleteAll}
			/>

			<ModalDelete
				dialogMode
				open={showModalRestore}
				title={'Restore Transaction'}
				description={'Would you like restore transaction?'}
				eventDelete={() => restoreTransactionById(transactionRestoreId)}
				eventClose={closeModalRestoreAction}
			/>
		</Box>
	)
};

const mapStateToProps = state => ({
	transactionsList: getTransactionsListSelector(state),
});

const mapDispatchToProps = {
	resetTransactions: resetTransactionsAction,
	setTransactionsSubType: setTransactionsSubTypeAction,
	setTransactionType: setTransactionTypeAction,
	setTransactionsFilteredList: setTransactionsFilteredListAction,
};

export const TransactionsTablePage = connect(
	mapStateToProps,
	mapDispatchToProps,
)(TransactionsTablePageContainer)

