export const transactionsTablePageColumn = value => ([
  {
    width: 400,
    label: 'Sender',
    dataKey: 'senderName',
  },
  {
    width: 400,
    label: 'Sender Wallet',
    dataKey: 'senderWalletType',
  },
  {
    width: 550,
    label: 'Receiver',
    dataKey: 'receiverName',
  },
  {
    width: 400,
    label: 'Receiver Wallet',
    dataKey: 'receiverWalletType',
  },
  {
    width: 600,
    label: 'Affects On',
    dataKey: 'transactionAffectsOn',
  },
  {
    width: 350,
    label: 'Type',
    dataKey: 'transactionType',
  },
  {
    width: 600,
    label: 'Amount',
    dataKey: 'amount',
    isDollarHandler: true,
  },
  {
    width: 200,
    label: 'Week',
    dataKey: 'week',
  },
  {
    width: 200,
    label: 'Year',
    dataKey: 'year',
  },
  {
    width: 350,
    label: 'Date',
    dataKey: 'date',
  },
  {
    width: 700,
    label: 'Create Date',
    dataKey: 'createDate',
  },
  {
    width: 500,
    label: 'SubType',
    dataKey: 'subType',
    isContainer: value,
    isPanelSelect: value,
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
  {
    width: 700,
    label: 'Notes',
    dataKey: 'notes',
    isTitleWrap: true,
  }
]);

export const transactionsTableJoinPageColumn = [
  {
    width: 350,
    label: 'Date',
    dataKey: 'date',
  },
  {
    width: 400,
    label: 'Sender',
    dataKey: 'senderName',
    isCSS: {
      borderLeft: '1px solid #fff',
      backgroundColor: 'rgba(255,255,0,0.05)',
    }
  },
  {
    width: 400,
    label: 'Sender Wallet',
    dataKey: 'senderWalletType',
    isCSS: {
      backgroundColor: 'rgba(255,255,0,0.05)',
    }
  },
  {
    width: 300,
    label: 'Amount Out',
    dataKey: 'amountOut',
    isCSS: {
      borderRight: '1px solid #fff',
      backgroundColor: 'rgba(255,255,0,0.05)',
    }
  },
  {
    width: 550,
    label: 'Receiver',
    dataKey: 'receiverName',
    isCSS: {
      backgroundColor: 'rgba(0,255,0,0.05)',
    }
  },
  {
    width: 400,
    label: 'Receiver Wallet',
    dataKey: 'receiverWalletType',
    isCSS: {
      backgroundColor: 'rgba(0,255,0,0.05)',
    }
  },
  {
    width: 300,
    label: 'Amount In',
    dataKey: 'amountIn',
    isCSS: {
      backgroundColor: 'rgba(0,255,0,0.05)',
      borderRight: '1px solid #fff'
    }
  },
  {
    width: 200,
    label: 'Week',
    dataKey: 'week',
  },
  {
    width: 300,
    label: 'SubType',
    dataKey: 'subType',
  },
  {
    width: 150,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
  {
    width: 500,
    label: 'Notes',
    dataKey: 'notes',
    isTitleWrap: true,
  }
]

const transactionSort = value => ([
  {
    sortType: 'string',
    dataKey: 'senderName',
  },
  {
    sortType: 'string',
    dataKey: 'senderWalletType',
  },
  {
    sortType: 'string',
    dataKey: 'receiverName',
  },
  {
    sortType: 'string',
    dataKey: 'receiverWalletType',
  },
  {
    sortType: 'string',
    dataKey: 'transactionAffectsOn',
  },
  {
    sortType: 'string',
    dataKey: 'transactionType',
  },
  {
    sortType: 'number',
    dataKey: 'amount',
  },
  {
    sortType: 'number',
    dataKey: 'week',
  },
  {
    sortType: 'number',
    dataKey: 'year',
  },
  {
    sortType: 'string',
    dataKey: 'date',
  },
  {
    sortType: 'string',
    dataKey: 'createDate',
  },
  {
    sortType: 'string',
    dataKey: 'subType',
    sortKey: value ? 'value' : false,
  },
  {
    sortType: 'string',
    dataKey: 'notes',
  }
]);

const transactionJoinSort = [
  {
    sortType: 'string',
    dataKey: 'senderName',
  },
  {
    sortType: 'string',
    dataKey: 'senderWalletType',
  },
  {
    sortType: 'string',
    dataKey: 'receiverName',
  },
  {
    sortType: 'string',
    dataKey: 'receiverWalletType',
  },
  {
    sortType: 'string',
    dataKey: 'transactionType',
  },
  {
    sortType: 'number',
    dataKey: 'amountIn',
  },
  {
    sortType: 'number',
    dataKey: 'amountOut',
  },
  {
    sortType: 'number',
    dataKey: 'week',
  },
  {
    sortType: 'string',
    dataKey: 'date',
  },
  {
    sortType: 'string',
    dataKey: 'subType',
  },
  {
    sortType: 'string',
    dataKey: 'notes',
  }
]

export const transactionsConstants = {
  active: '/Transactions/Active',
  backup: '/Transactions/Backup',
  join: '/Transactions/Partners',
}

export const navigationList = [
  {
    title: 'Active',
    link: transactionsConstants.active,
  },
  {
    title: 'Backup',
    link: transactionsConstants.backup,
  },
  {
    title: 'Partners',
    link: transactionsConstants.join,
  },
]

export const transactionPageConst = {
  ACTIVE: {
    LINK: transactionsConstants.active,
    TYPE: 'Active',
  },
  BACKUP: {
    LINK: transactionsConstants.backup,
    TYPE: 'Backup',
  },
  JOIN: {
    LINK: transactionsConstants.join,
    TYPE: 'Join',
  },
};

export const getTransactionColumn = type => {
  switch (type) {
    case transactionPageConst.ACTIVE.TYPE:
      return transactionsTablePageColumn(true);
    case transactionPageConst.BACKUP.TYPE:
      return transactionsTablePageColumn(false);
    case transactionPageConst.JOIN.TYPE:
      return transactionsTableJoinPageColumn;
    default:
      return transactionsTablePageColumn(true);
  }
}

export const getTransactionSort = type => {
  switch (type) {
    case transactionPageConst.ACTIVE.TYPE:
      return transactionSort(true);
    case transactionPageConst.BACKUP.TYPE:
      return transactionSort(false);
    case transactionPageConst.JOIN.TYPE:
      return transactionJoinSort;
    default:
      return transactionSort(true);
  }
}

export const uniqueLines = [
  {
    label: 'Sender',
    dataKey: 'senderName',
  },
  {
    label: 'Sender Wallet',
    dataKey: 'senderWalletType',
  },
  {
    label: 'Receiver',
    dataKey: 'receiverName',
  },
  {
    label: 'Receiver Wallet',
    dataKey: 'receiverWalletType',
  },
  {
    label: 'Type',
    dataKey: 'transactionType',
  },
  {
    label: 'Week',
    dataKey: 'week',
  },
]

export const uniqueJoinLines = [
  {
    label: 'Sender',
    dataKey: 'senderName',
  },
  {
    label: 'Sender Wallet',
    dataKey: 'senderWalletType',
  },
  {
    label: 'Receiver',
    dataKey: 'receiverName',
  },
  {
    label: 'Sub Type',
    dataKey: 'subType',
  },
  {
    label: 'Receiver Wallet',
    dataKey: 'receiverWalletType',
  },
  {
    label: 'Week',
    dataKey: 'week',
  },
]
