export const getStyles = theme => ({
  container: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  containerNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
})
