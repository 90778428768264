import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { store, removeStatusModalItem } from '../../../store';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const StatusModal = ({
	id,
	type,
	text,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	const [ idTimeout, setIdTimeout ] = useState(null);

	const addHiddenComponent = () => {
		setIdTimeout(setTimeout(() => store.dispatch(removeStatusModalItem(id)), 4000));
	}

	useEffect(() => {
		addHiddenComponent();
	}, [])

	return (
		<Box
			className={clsx(
				classes.container,
				{[classes.errorType]: type === 'error'},
				{[classes.successType]: type === 'success'},
			)}
			tabIndex={0}
			onBlur={addHiddenComponent}
			onFocus={() => clearTimeout(idTimeout)}
		>
			{text}
		</Box>
	)
}