import {
  RESET_EDIT_WALLET_TYPE,
  SET_EDIT_WALLET_TYPE,
  SET_EDIT_WALLET_TYPE_NAME,
  SET_EDIT_WALLET_TYPE_NOTES,
} from '../../constants';

export const resetEditWalletType = () => ({
  type: RESET_EDIT_WALLET_TYPE,
});

export const setEditWalletType = walletType => ({
  type: SET_EDIT_WALLET_TYPE,
  payload: {
    walletType,
  }
});

export const setEditWalletTypeName = name => ({
  type: SET_EDIT_WALLET_TYPE_NAME,
  payload: {
    name,
  },
});

export const setEditWalletTypeNotes = notes => ({
  type: SET_EDIT_WALLET_TYPE_NOTES,
  payload: {
    notes,
  },
});
