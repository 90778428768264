import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { TableComponent } from '../../../components/TableComponent';
import { buttonTypes } from '../../../components/UIComponents/buttons/ButtonCustom/constants';
import {
  TaskButtonsPanel,
  InputCustom,
  ButtonCustom,
} from '../../../components/UIComponents';
import {
  getAccountsListSelector,
  getCreateAccountShortAccountNameSelector,
  getCreateAccountUrlSelector,
  getCreateAccountNotesSelector,
  getCreateAccountPartnerSelector,
  getCreateAccountPartnersListSelector,
  getCreateAccountPasswordSelector,
  getCreateAccountSiteTypeSelector,
  getCreateAccountSiteTypesListSelector,
  getCreateAccountTypeSelector,
  getCreateAccountSubTypeSelector,
  getCreateAccountUsernameSelector,
  getCreateAccountSharePercentSelector,
  getEditAccountIdSelector,
  getEditAccountShortAccountNameSelector,
  getEditAccountUrlSelector,
  getEditAccountNotesSelector,
  getEditAccountPartnerSelector,
  getEditAccountPartnersListSelector,
  getEditAccountPasswordSelector,
  getEditAccountSiteTypeSelector,
  getEditAccountSiteTypesListSelector,
  getEditAccountTypeSelector,
  getEditAccountSubTypeSelector,
  getEditAccountUsernameSelector,
  getEditAccountSharePercentSelector,
  getCreateAccountSubOwnerSelector,
  getCreateAccountSubOwnerPercentSelector,
  getEditAccountSubOwnerSelector, getEditAccountSubOwnerPercentSelector,
} from './selectors';
import {
  resetAccounts as resetAccountsAction,
  resetCreateAccount as resetCreateAccountAction,
  setAccountItemValue as setAccountItemValueAction,
  setAccountItemDisabled as setAccountItemDisabledAction,
  setCreateAccountType as setCreateAccountTypeAction,
  setCreateAccountSubType as setCreateAccountSubTypeAction,
  setCreateAccountShortAccountName as setCreateAccountShortAccountNameAction,
  setCreateAccountUrl as setCreateAccountUrlAction,
  setCreateAccountUsername as setCreateAccountUsernameAction,
  setCreateAccountPassword as setCreateAccountPasswordAction,
  setCreateAccountSharePercent as setCreateAccountSharePercentAction,
  setCreateAccountNotes as setCreateAccountNotesAction,
  setCreateAccountSiteType as setCreateAccountSiteTypeAction,
  setCreateAccountPartner as setCreateAccountPartnerAction,
  setCreateAccountSubOwner as setCreateAccountSubOwnerAction,
  setCreateAccountSubOwnerPercent as setCreateAccountSubOwnerPercentAction,
  resetEditAccount as resetEditAccountAction,
  setEditAccountType as setEditAccountTypeAction,
  setEditAccountSubType as setEditAccountSubTypeAction,
  setEditAccountShortAccountName as setEditAccountShortAccountNameAction,
  setEditAccountUrl as setEditAccountUrlAction,
  setEditAccountUsername as setEditAccountUsernameAction,
  setEditAccountPassword as setEditAccountPasswordAction,
  setEditAccountSharePercent as setEditAccountSharePercentAction,
  setEditAccountNotes as setEditAccountNotesAction,
  setEditAccountSiteType as setEditAccountSiteTypeAction,
  setEditAccountPartner as setEditAccountPartnerAction,
  setEditAccountSubOwner as setEditAccountSubOwnerAction,
  setEditAccountSubOwnerPercent as setEditAccountSubOwnerPercentAction,
  setAccountSubOwnerItemValue as setAccountSubOwnerItemValueAction,
} from '../../../store';
import {
  ModalDelete,
  ModalAccount,
  ModalStopAccount,
} from '../../../components/UIComponents/modals';

import {
  accountsForTable,
  addAccount,
  editAccount,
  editAccountWithoutUpdate,
  deleteAccount,
  getNetworksAndSiteTypesForAccount,
  getForEditAccount, startStopAccount,
} from '../../../api';
import {
  excludeKeys,
  getAccountPageColumn,
  getAccountPageSort,
  getUniqueAccountLines,
  reformatDataKeys
} from './constants';
import {
  changeFilteredList,
  getUniqueValuesList,
} from '../../../utils';
import { sortEvent } from '../../../utils/sortingService';
import { accountTypes } from '../../../components/App';
import { MultiFilterPanel } from '../../../components/MultiFilterPanel';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const AccountsTablePageContainer = ({
  selectedType,
  createShortAccountName,
  createUrl,
  createType,
  createSubType,
  createUsername,
  createPassword,
  createSharePercent,
  createNotes,
  createSiteType,
  createPartner,
  createSubOwner,
  createSubOwnerPercent,
  createPartnersList,
  createSiteTypesList,
  editId,
  editShortAccountName,
  editUrl,
  editType,
  editSubType,
  editUsername,
  editPassword,
  editSharePercent,
  editNotes,
  editSiteType,
  editPartner,
  editSubOwner,
  editSubOwnerPercent,
  editPartnersList,
  editSiteTypesList,
  accountsList,
  resetCreateAccount,
  setAccountItemValue,
  setAccountItemDisabled,
  setCreateAccountType,
  setCreateAccountSubType,
  setCreateAccountShortAccountName,
  setCreateAccountUrl,
  setCreateAccountUsername,
  setCreateAccountPassword,
  setCreateAccountSharePercent,
  setCreateAccountNotes,
  setCreateAccountSiteType,
  setCreateAccountPartner,
  setCreateAccountSubOwner,
  setCreateAccountSubOwnerPercent,
  resetEditAccount,
  setEditAccountType,
  setEditAccountSubType,
  setEditAccountShortAccountName,
  setEditAccountUrl,
  setEditAccountUsername,
  setEditAccountPassword,
  setEditAccountSharePercent,
  setEditAccountNotes,
  setEditAccountSiteType,
  setEditAccountPartner,
  setEditAccountSubOwner,
  setEditAccountSubOwnerPercent,
  setAccountSubOwnerItemValue,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [accountIdDelete, setAccountIdDelete] = useState(null);
  const [showModalCreateAccount, setShowModalCreateAccount] = useState(false);
  const [showModalEditAccount, setShowModalEditAccount] = useState(false);

  const [ handleSortBy, setHandleSortBy ] = useState(false);
  const [ handleSortDirection, setHandleSortDirection ] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [filteredList, setFilteredList] = useState([]);

  const [showDisabled, setShowDisabled] = useState(false);

  const [selectedStopDate, setSelectedStopDate] = useState(null);
  const [stopUserid, setStopUserId] = useState(null);
  const [showModalStopAccount, setShowModalStopAccount] = useState(false);

  const [uniqueValuesList, setUniqueValuesList] = useState([]);
  const [uniqueFiltersList, setUniqueFiltersList] = useState([]);

  useEffect(() => {
    setUniqueValuesList(getUniqueValuesList(accountsList, getUniqueAccountLines(selectedType)));
  }, [accountsList]);

  const sortingData = getAccountPageSort(selectedType);

  useEffect(() => {
    const getAccounts = async () => await accountsForTable(selectedType);

    getAccounts().then(() => Promise.resolve());

    // return () => {
    //   resetAccounts();
    // }
  }, []);

  useEffect(() => {
    changeFilteredList(accountsList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'partnerId', 'siteTypeId', 'action', 'type', 'disabled']);
  }, [accountsList, searchValue, uniqueFiltersList]);

  useEffect(() => {
    const getForCreate = async () => await getNetworksAndSiteTypesForAccount();

    if (showModalCreateAccount === true) {
      getForCreate().then(() => Promise.resolve());
    }
  }, [showModalCreateAccount]);

  useEffect(() => {
    const getForEdit = async (accountId) => await getForEditAccount(accountId);

    if (showModalEditAccount === true) {
      getForEdit(accountId).then(() => Promise.resolve());
    }
  }, [accountId, showModalEditAccount]);

  const closeModalDeleteAction = () => setShowModalDelete(false);

  const openModalDeleteAction = id => {
    setShowModalDelete(true);
    setAccountIdDelete(id);
  };

  const deleteAccountById = id => {
    Promise.resolve(deleteAccount({
        id,
        type: selectedType,
      }))
      .then(() => setAccountIdDelete(null))
      .then(closeModalDeleteAction);
  }

  const sendCreateAccount = () => {
    Promise.resolve(addAccount({
      newAccount: {
        type: createType,
        subType: createSubType,
        shortAccountName: createShortAccountName,
        url: createUrl,
        username: createUsername,
        password: createPassword,
        sharePercent: createSharePercent,
        notes: createNotes,
        siteType: createSiteType,
        partner: createPartner,
        subOwner: createSubOwner ? createSubOwner : null,
        subOwnerPercent: createSubOwnerPercent ? createSubOwnerPercent : null,
      },
      type: selectedType,
    }))
      .then(closeModalCreateAccountAction)
      .then(resetCreateAccount);
  }

  const sendEditAccount = () => {
    Promise.resolve(editAccount({
      editedAccount: {
        id: editId,
        type: editType,
        subType: editSubType,
        shortAccountName: editShortAccountName,
        url: editUrl,
        username: editUsername,
        password: editPassword,
        sharePercent: editSharePercent,
        notes: editNotes,
        siteType: editSiteType,
        partner: editPartner,
        subOwner: editSubOwner ? editSubOwner : null,
        subOwnerPercent: editSubOwnerPercent ? editSubOwnerPercent : null,
      },
      type: selectedType,
    }))
      .then(closeModalEditAccountAction)
      .then(resetEditAccount);
  }

  const closeModalCreateAccountAction = () => {
    setShowModalCreateAccount(false);
    resetCreateAccount();
  }

  const closeModalEditAccountAction = () => {
    resetEditAccount();
    setAccountId(null);
    setShowModalEditAccount(false);
  }

  const openModalCreateAccount = () => {
    setCreateAccountType(selectedType);
    setShowModalCreateAccount(true);
  }

  const openModalEditAccount = id => {
    setAccountId(id);
    setShowModalEditAccount(true);
  }

  const handleChangeInput = ({
    id,
    event,
  }) => {
    setAccountItemValue({
      id,
      value: event.target.value,
    })
  }

  const handleChangeSubOwnerInput = ({
    id,
    event,
  }) => {
    setAccountSubOwnerItemValue({
      id,
      value: event.target.value,
    })
  }

  const handleBlurInput = ({
    id,
    event,
  }) => {
    const updateAccountSharePercent = async () => editAccountWithoutUpdate({
      editedAccount: {
        id,
        sharePercent: event.target.value,
      },
    });

    updateAccountSharePercent().then(() => {
      sort({
        sortBy: handleSortBy,
        sortDirection: handleSortDirection,
      });
      return Promise.resolve();
    });
  }

  const handleBlurSubOwnerInput = ({
    id,
    event,
  }) => {
    const updateAccountSharePercent = async () => editAccountWithoutUpdate({
      editedAccount: {
        id,
        subOwnerPercent: event.target.value,
      },
    });

    updateAccountSharePercent().then(() => {
      sort({
        sortBy: handleSortBy,
        sortDirection: handleSortDirection,
      });
      return Promise.resolve();
    });
  }

  const sort = sortEvent({
    handleSortBy,
    sortData: sortingData,
    setHandleSortDirection,
    setHandleSortBy,
    list: filteredList,
  });

  const handleBlurSearchInput = event => {
    const value = event.target.value;
    setSearchValue(value);
    changeFilteredList(accountsList, searchValue, uniqueFiltersList, setFilteredList, ['id', 'partnerId', 'siteTypeId', 'action', 'type', 'disabled']);
  }

  const handleEnableAccount = id => {
    const updateAccountDisabled = async () => editAccountWithoutUpdate({
      editedAccount: {
        id,
        disabled: false,
      },
    });

    updateAccountDisabled()
      .then(() => setAccountItemDisabled({
        id,
        disabled: false,
      }));
  }

  const handleDisableAccount = id => {
    const updateAccountDisabled = async () => editAccountWithoutUpdate({
      editedAccount: {
        id,
        disabled: true,
      },
    });

    updateAccountDisabled()
      .then(() => setAccountItemDisabled({
        id,
        disabled: true,
      }));
  }

  const handleShowDisabledClick = () => setShowDisabled(!showDisabled);

  const handleStopAccount = id => {
    setStopUserId(id);
    setShowModalStopAccount(true);
  }

  const closeModalStopAccount = () => {
    setStopUserId(null);
    setShowModalStopAccount(false);
    setSelectedStopDate(null);
  }

  const sendStopAccount = () => {

    const updateAccountStop = async () => startStopAccount({
      accountId: stopUserid,
      stopDateUTC: selectedStopDate,
      stop: true,
    });
    updateAccountStop().then(() => Promise.resolve());
    // updateAccountDisabled()
    //   .then(() => setAccountItemDisabled({
    //     id,
    //     disabled: true,
    //   }));

    closeModalStopAccount();
  }

  const handleStartAccount = (id) => {
    const updateAccountStop = async () => startStopAccount({
      accountId: id,
      stopDateUTC: null,
      stop: false,
    });
    updateAccountStop().then(() => Promise.resolve());
  }

  const changeUniqueValues = (items, dataKey) => {
    const newUniqueValuesList = uniqueValuesList.map(elem => {
      if (elem.dataKey === dataKey) {
        return {
          ...elem,
          selectedValues: items,
        }
      }
      return elem;
    })
    setUniqueValuesList(newUniqueValuesList);
    setUniqueFiltersList(newUniqueValuesList.filter(el => el.selectedValues.length > 0));
  }

  const handleExportToExcel = async () => {
    const keys = getAccountPageColumn(selectedType);

    const actualKeys = keys.map(key => ({
      header: key.label,
      key: key.dataKey,
    })).filter(key => !excludeKeys.includes(key.key));

    const actualAccountList = filteredList
      .filter(account => !showDisabled ? !account.disabled : true )
        .map((account) => {
          const newAccount = {};
          actualKeys.forEach(key => {
            const reformatData = reformatDataKeys.find(el => el.name === key.key);
            let value = '';
            if (reformatData && typeof account[key.key] === 'object') {
              value = account[key.key]?.[reformatData.value];
            } else {
              value = account[key.key];
            }

            if (value === undefined || value === null || value === 'disabled') {
              value = '';
            }
            newAccount[key.key] = value;
          });
          return newAccount;
        })

    let data = actualAccountList;
    const workSheetName = `${selectedType} Accounts-${new Date().toDateString()}`;
    const workBookName = workSheetName;

    const workbook = new Excel.Workbook();
    const fileName = workBookName;
    const worksheet = workbook.addWorksheet(workSheetName);
    worksheet.columns = actualKeys;
    worksheet.getRow(1).font = {bold: true};
    worksheet.columns.forEach(column => {
      column.width = column.header.length + 5;
      column.alignment = {horizontal: 'center'};
    });
    data.forEach(singleData => {
      worksheet.addRow(singleData);
    });
    worksheet.eachRow({includeEmpty: false}, row => {
      const currentCell = row._cells;
      currentCell.forEach(singleCell => {
        const cellAddress = singleCell._address;
        worksheet.getCell(cellAddress).border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });
    });
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.disabledButtonContainer}>
        <ButtonCustom
          onClick={handleShowDisabledClick}
          customType={buttonTypes.EDIT}
          text={showDisabled ? 'Hide disabled accounts' : 'Show disabled accounts'}
        />
        <ButtonCustom
          onClick={handleExportToExcel}
          customType={buttonTypes.CREATE}
          text='export to excel'
        />
      </Box>

      <Box className={classes.containerForFilters}>
        <MultiFilterPanel
          list={uniqueValuesList}
          onChange={changeUniqueValues}
        />
        <InputCustom
          className={classes.input}
          value={searchValue}
          onChange={handleBlurSearchInput}
        />
      </Box>

      <TableComponent
        columns={getAccountPageColumn(selectedType)}
        rows={filteredList.filter(account => !showDisabled ? !account.disabled : true )}
        deleteRowAction={
          (selectedType === accountTypes.APPLICATION || selectedType === accountTypes.DEPOSIT)
            ? openModalDeleteAction
            : null
        }
        editRowAction={openModalEditAccount}
        enableRowAction={handleEnableAccount}
        disableRowAction={handleDisableAccount}
        startRowAction={accountTypes.HAND_BETS !== selectedType ? handleStartAccount : null}
        stopRowAction={accountTypes.HAND_BETS !== selectedType ? handleStopAccount : null}
        eventHandleChangePanelInput={[handleChangeSubOwnerInput, handleChangeInput]}
        eventHandleBlurPanelInput={[handleBlurSubOwnerInput, handleBlurInput]}
        sort={sort}
        sortBy={handleSortBy}
        sortDirection={handleSortDirection}
      />

      <TaskButtonsPanel
        createButton
        createButtonName="Create Account"
        createButtonAction={openModalCreateAccount}
      />

      <ModalDelete
        open={showModalDelete}
        title={'Delete Account'}
        description={'Are you sure you want to delete this account?'}
        eventDelete={() => deleteAccountById(accountIdDelete)}
        eventClose={closeModalDeleteAction}
      />

      {showModalStopAccount && (
        <ModalStopAccount
          open={showModalStopAccount}
          title={'Do you want stop account?'}
          accountName={accountsList.find(el => el.id === stopUserid).username}
          eventApply={sendStopAccount}
          eventClose={closeModalStopAccount}
          selectedDate={selectedStopDate}
          setSelectedDate={setSelectedStopDate}
        />
      )}

      <ModalAccount
        open={showModalCreateAccount}
        title='Create Account'
        eventClose={closeModalCreateAccountAction}
        eventApply={sendCreateAccount}
        partnersList={createPartnersList}
        siteTypesList={createSiteTypesList}
        type={createType}
        subType={createSubType}
        shortAccountName={createShortAccountName}
        url={createUrl}
        username={createUsername}
        password={createPassword}
        sharePercent={createSharePercent}
        notes={createNotes}
        siteType={createSiteType}
        partner={createPartner}
        subOwner={createSubOwner}
        subOwnerPercent={createSubOwnerPercent}
        setAccountType={setCreateAccountType}
        setAccountSubType={setCreateAccountSubType}
        setAccountShortAccountName={setCreateAccountShortAccountName}
        setAccountUrl={setCreateAccountUrl}
        setAccountUsername={setCreateAccountUsername}
        setAccountPassword={setCreateAccountPassword}
        setSharePercent={setCreateAccountSharePercent}
        setAccountNotes={setCreateAccountNotes}
        setAccountSiteType={setCreateAccountSiteType}
        setAccountPartner={setCreateAccountPartner}
        setAccountSubOwner={setCreateAccountSubOwner}
        setAccountSubOwnerPercent={setCreateAccountSubOwnerPercent}
      />

      <ModalAccount
        open={showModalEditAccount}
        title='Edit Account'
        eventClose={closeModalEditAccountAction}
        eventApply={sendEditAccount}
        partnersList={editPartnersList}
        siteTypesList={editSiteTypesList}
        type={editType}
        subType={editSubType}
        shortAccountName={editShortAccountName}
        url={editUrl}
        username={editUsername}
        password={editPassword}
        sharePercent={editSharePercent}
        notes={editNotes}
        siteType={editSiteType}
        partner={editPartner}
        subOwner={editSubOwner}
        subOwnerPercent={editSubOwnerPercent}
        setAccountType={setEditAccountType}
        setAccountSubType={setEditAccountSubType}
        setAccountShortAccountName={setEditAccountShortAccountName}
        setAccountUrl={setEditAccountUrl}
        setAccountUsername={setEditAccountUsername}
        setAccountPassword={setEditAccountPassword}
        setSharePercent={setEditAccountSharePercent}
        setAccountNotes={setEditAccountNotes}
        setAccountSiteType={setEditAccountSiteType}
        setAccountPartner={setEditAccountPartner}
        setAccountSubOwner={setEditAccountSubOwner}
        setAccountSubOwnerPercent={setEditAccountSubOwnerPercent}
        disabledList={['Type']}
      />
    </Box>
  )
}

const mapStateToProps = state => ({
  createShortAccountName: getCreateAccountShortAccountNameSelector(state),
  createUrl: getCreateAccountUrlSelector(state),
  createType: getCreateAccountTypeSelector(state),
  createSubType: getCreateAccountSubTypeSelector(state),
  createUsername: getCreateAccountUsernameSelector(state),
  createPassword: getCreateAccountPasswordSelector(state),
  createSharePercent: getCreateAccountSharePercentSelector(state),
  createNotes: getCreateAccountNotesSelector(state),
  createSiteType: getCreateAccountSiteTypeSelector(state),
  createPartner: getCreateAccountPartnerSelector(state),
  createSubOwner: getCreateAccountSubOwnerSelector(state),
  createSubOwnerPercent: getCreateAccountSubOwnerPercentSelector(state),
  createPartnersList: getCreateAccountPartnersListSelector(state),
  createSiteTypesList: getCreateAccountSiteTypesListSelector(state),
  editId: getEditAccountIdSelector(state),
  editShortAccountName: getEditAccountShortAccountNameSelector(state),
  editUrl: getEditAccountUrlSelector(state),
  editType: getEditAccountTypeSelector(state),
  editSubType: getEditAccountSubTypeSelector(state),
  editUsername: getEditAccountUsernameSelector(state),
  editPassword: getEditAccountPasswordSelector(state),
  editSharePercent: getEditAccountSharePercentSelector(state),
  editNotes: getEditAccountNotesSelector(state),
  editSiteType: getEditAccountSiteTypeSelector(state),
  editPartner: getEditAccountPartnerSelector(state),
  editSubOwner: getEditAccountSubOwnerSelector(state),
  editSubOwnerPercent: getEditAccountSubOwnerPercentSelector(state),
  editPartnersList: getEditAccountPartnersListSelector(state),
  editSiteTypesList: getEditAccountSiteTypesListSelector(state),
  // partnersList: getPartnersListSelector(state),
  // siteTypesList: getSiteTypesListSelector(state),
  accountsList: getAccountsListSelector(state),
});

const mapDispatchToProps = {
  resetAccounts: resetAccountsAction,
  setAccountItemValue: setAccountItemValueAction,
  setAccountItemDisabled: setAccountItemDisabledAction,
  resetCreateAccount: resetCreateAccountAction,
  setCreateAccountType: setCreateAccountTypeAction,
  setCreateAccountSubType: setCreateAccountSubTypeAction,
  setCreateAccountShortAccountName: setCreateAccountShortAccountNameAction,
  setCreateAccountUrl: setCreateAccountUrlAction,
  setCreateAccountUsername: setCreateAccountUsernameAction,
  setCreateAccountPassword: setCreateAccountPasswordAction,
  setCreateAccountSharePercent: setCreateAccountSharePercentAction,
  setCreateAccountNotes: setCreateAccountNotesAction,
  setCreateAccountSiteType: setCreateAccountSiteTypeAction,
  setCreateAccountPartner: setCreateAccountPartnerAction,
  setCreateAccountSubOwner: setCreateAccountSubOwnerAction,
  setCreateAccountSubOwnerPercent: setCreateAccountSubOwnerPercentAction,
  resetEditAccount: resetEditAccountAction,
  setEditAccountType: setEditAccountTypeAction,
  setEditAccountSubType: setEditAccountSubTypeAction,
  setEditAccountShortAccountName: setEditAccountShortAccountNameAction,
  setEditAccountUrl: setEditAccountUrlAction,
  setEditAccountUsername: setEditAccountUsernameAction,
  setEditAccountPassword: setEditAccountPasswordAction,
  setEditAccountSharePercent: setEditAccountSharePercentAction,
  setEditAccountNotes: setEditAccountNotesAction,
  setEditAccountSiteType: setEditAccountSiteTypeAction,
  setEditAccountPartner: setEditAccountPartnerAction,
  setEditAccountSubOwner: setEditAccountSubOwnerAction,
  setEditAccountSubOwnerPercent: setEditAccountSubOwnerPercentAction,
  setAccountSubOwnerItemValue: setAccountSubOwnerItemValueAction,
}

export const AccountsTablePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsTablePageContainer)
