import React from 'react';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { AutoSizer, Column, Table } from 'react-virtualized';

import { PanelEventButtons } from './PanelEventButtons';
import { statusConstants } from './constants';
import { PanelInputData } from './PanelInputData';
import { PanelSelectData } from './PanelSelectData';
import { SortIndicator } from './SortIndicator';
import { getHandlerColumn, handlerBooleanValues, isHttpOrHttps } from './utils';
import { PanelCheckboxData } from './PanelCheckboxData';
import { TelegramApproveStatus } from './TelegramApproveStatus';
import { numberToDollar } from '../../utils';
import { PanelButtonInfoBotWeeklyFigure } from './PanelButtonInfoBotWeeklyFigure';
import { TelegramShowMessage } from './TelegramShowMessage';

import { getStyles } from './styles';


const useStyles = makeStyles(getStyles);

export const MuiVirtualizedTable = ({
  columns,
  onRowClick,
  rowHeight = 40,
  headerHeight = 40,
  deleteRowAction,
  restoreRowAction,
  editRowAction,
  infoRowAction,
  enableRowAction,
  disableRowAction,
  startRowAction,
  stopRowAction,
  eventHandleChangePanelCheckbox,
  eventHandleChangePanelCheckboxConfirm,
  eventHandleChangePanelInput,
  eventHandleBlurPanelInput,
  eventHandleChangeSharePercentInput,
  eventHandleBlurSharePercentInput,
  eventHandlerChangePanelSelect,
  sort,
  sortBy,
  sortDirection,
  ...tableProps
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const getRowClassName = ({
    index,
  }) => {
    return clsx(
      classes.tableRow,
      classes.flexContainer,
      'body_row',
      {
        [classes.tableRowHover]: index !== -1 && onRowClick != null,
      },
    );
  };

  const cellRenderer = ({
    cellData,
    columnIndex,
    dataKey,
    isContainer = false,
    isPanelCheckbox = false,
    isPanelCheckboxConfirm = false,
    isPanelInput = false,
    isSharePercent = false,
    isSharePercentId = false,
    isBooleanValueHandler = false,
    isPanelSelect = false,
    isDollarHandler = false,
    handlerCellName = false,
    isTitleWrap = false,
    isCSS = {},
    handlerColumn,
    rowData,
  }) => (
    <TableCell
      className={
        clsx(
          classes.tableCell,
          classes.flexContainer,
          { [classes.noClick]: onRowClick == null, },
          (isContainer && 'buttons_container'),
          (rowData.isCustomTextColor)
        )
      }
      variant='body'
      style={{
        height: rowHeight,
        ...isCSS,
      }}
      align={
        (columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'
      }
    >
      {handlerCellName && handlerCellName === 'telegramApproveStatus' && (
        <TelegramApproveStatus
          {...cellData}
        />
      )}
      {handlerCellName && handlerCellName === 'botWeeklyFigure' && (
        <PanelButtonInfoBotWeeklyFigure
          data={cellData}
        />
      )}
      {handlerCellName && handlerCellName === 'showTelegramMessage' && cellData && (
        <TelegramShowMessage
          {...cellData}
        />
      )}
      {
        handlerColumn && getHandlerColumn(
          handlerColumn,
          {
            cellData,
          },
        )
      }
      {
        dataKey === statusConstants.ACTION &&
        cellData?.id && (
          <PanelEventButtons
            id={cellData?.id}
            deleteItem={deleteRowAction}
            restoreAction={restoreRowAction}
            editItem={editRowAction}
            infoItem={infoRowAction}
            startAction={startRowAction}
            stopAction={stopRowAction}
            enableItem={cellData?.disabled ? enableRowAction : null}
            disableItem={!cellData?.disabled ? disableRowAction : null}
            startItem={cellData?.isStop ? startRowAction : null}
            stopItem={!cellData?.isStop ? stopRowAction : null}
          />
        )
      }
      {
        dataKey === 'url' && cellData && (
          <Link
            href={isHttpOrHttps(cellData) ? cellData : `//${cellData}`}
            target='_blank'
            className={classes.link}
          >
            { cellData }
          </Link>
        )
      }
      {
        isPanelCheckbox &&
        cellData.activated && (
          <PanelCheckboxData
            id={cellData?.id}
            checked={cellData?.checked}
            handleChange={eventHandleChangePanelCheckbox}
            data={cellData}
          />
        )
      }
      {
        isTitleWrap && (
          <span title={cellData}>
            { cellData }
          </span>
        )
      }
      {
        isPanelCheckboxConfirm &&
        <PanelCheckboxData
          id={cellData?.id}
          checked={cellData?.checked}
          handleChange={eventHandleChangePanelCheckboxConfirm}
          data={cellData}
        />
      }
      {
        isPanelInput && (
          <PanelInputData
            id={cellData?.id}
            inputValue={cellData?.value}
            handleChange={
              Array.isArray(eventHandleChangePanelInput)
                ? eventHandleChangePanelInput[isSharePercentId]
                : eventHandleChangePanelInput
            }
            handleBlur={
              Array.isArray(eventHandleBlurPanelInput)
                ? eventHandleBlurPanelInput[isSharePercentId]
                : eventHandleBlurPanelInput
            }
            data={cellData}
          />
        )
      }
      {
        isSharePercent && (
          <PanelInputData
            id={cellData?.id}
            inputValue={cellData?.value}
            handleChange={cellData?.amount ? (
              Array.isArray(eventHandleChangeSharePercentInput)
                ? eventHandleChangeSharePercentInput[isSharePercentId]
                : eventHandleChangeSharePercentInput
            ) : f=>f}
            handleBlur={cellData?.amount ? (
              Array.isArray(eventHandleBlurSharePercentInput)
                ? eventHandleBlurSharePercentInput[isSharePercentId]
                : eventHandleBlurSharePercentInput
            ) : f=>f}
            data={cellData}
          />
        )
      }
      {
        isPanelSelect &&
        <PanelSelectData
          id={cellData?.id}
          value={cellData?.value}
          handleChange={eventHandlerChangePanelSelect}
        />
      }

      {
        isBooleanValueHandler && handlerBooleanValues(cellData, isBooleanValueHandler)
      }

      {
        isDollarHandler && numberToDollar(cellData)
      }

      {
        !handlerColumn
        && (typeof (cellData) === 'string' || typeof (cellData) === 'number')
        && dataKey !== 'url'
        && !isDollarHandler
        && !isTitleWrap
        && !handlerCellName
        && cellData
      }
    </TableCell>
  );

  const headerRenderer = ({
    label,
    columnIndex,
    sortBy,
    dataKey,
    sortDirection,
  }) => {
    return (
      <TableCell
        component='div'
        className={
        clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          classes.tableHeader
        )}
        variant='head'
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        <span>{label}</span>
        {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
      </TableCell>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          height={height}
          width={width}
          rowHeight={rowHeight}
          gridStyle={{
            direction: 'inherit',
          }}
          headerHeight={headerHeight}
          className={classes.table}
          sort={sort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          {...tableProps}
          rowClassName={getRowClassName}
        >
          {columns.map((
            {
              dataKey,
              isContainer,
              isPanelCheckbox,
              isPanelCheckboxConfirm,
              isPanelInput,
              isSharePercent,
              isSharePercentId,
              isBooleanValueHandler,
              isPanelSelect,
              isDollarHandler,
              handlerCellName,
              isTitleWrap,
              isCSS,
              handlerColumn,
              ...props
            },
            index
          ) => {

            return (
              <Column
                key={dataKey}
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    ...headerProps,
                    columnIndex: index,
                  })
                }
                className={classes.flexContainer}
                cellRenderer={(props) =>
                  cellRenderer({
                    isContainer,
                    isPanelCheckbox,
                    isPanelCheckboxConfirm,
                    isPanelInput,
                    isSharePercent,
                    isSharePercentId,
                    isBooleanValueHandler,
                    isPanelSelect,
                    isDollarHandler,
                    handlerCellName,
                    isTitleWrap,
                    isCSS,
                    handlerColumn,
                    ...props,
                  })
                }
                dataKey={dataKey}
                {...props}
              />
            );
          })}
        </Table>
      )}
    </AutoSizer>
  );
}

export const TableComponent = ({
  columns,
  rows,
  deleteRowAction,
  restoreRowAction,
  editRowAction,
  infoRowAction,
  enableRowAction,
  disableRowAction,
  startRowAction,
  stopRowAction,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper
      className={classes.paper}
    >
      <MuiVirtualizedTable
        rowCount={rows.length}
        rowGetter={({ index }) => rows[index]}
        columns={columns}
        deleteRowAction={deleteRowAction}
        restoreRowAction={restoreRowAction}
        editRowAction={editRowAction}
        infoRowAction={infoRowAction}
        enableRowAction={enableRowAction}
        disableRowAction={disableRowAction}
        startRowAction={startRowAction}
        stopRowAction={stopRowAction}
        { ...props }
      />
    </Paper>
  );
}
