export const partnersTelegramCodesParser = list => list?.map(item => (
  {
    id: item?._id,
    partnerName: item?.partner?.name,
    code: item?.code,
    activated: item?.activated ? 'Yes' : 'No',
    action: item?.activated
      ? undefined
      : {
          id: item?._id,
        },
  }
));

export const telegramUsersParser = list => list?.map(item => (
  {
    id: item?._id,
    user: item?.user,
    nickname: item?.nickname,
    telegramUserId: item?.telegramUserId,
    partnerName: item?.partner?.name,
    action: item?.activated
      ? {
          id: item?._id,
        }
      : undefined,
  }
));

export const generateCodeDtoParser = data => (
  {
    partner: data?.partner?.id,
  }
);

export const sendReportsToTelegramDtoParser = ({
  year,
  week,
  partnerIds,
  accountType,
}) => (
  {
    year,
    week,
    partnerIds,
    accountType,
  }
);
