
export const excludeKeys = ['action'];

export const reformatDataKeys = [];

export const partnersPageColumn = [
  {
    width: 600,
    label: 'Name',
    dataKey: 'partnerName',
  },
  {
    width: 500,
    label: 'Partner Type',
    dataKey: 'partnerType',
  },
  {
    width: 500,
    label: 'Share Percent',
    dataKey: 'sharePercent',
  },
  {
    width: 500,
    label: 'Location',
    dataKey: 'location',
  },
  {
    width: 500,
    label: 'Terms',
    dataKey: 'terms',
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
];

export const partnersPageSort = [
  {
    sortType: 'string',
    dataKey: 'partnerName',
  },
  {
    sortType: 'string',
    dataKey: 'partnerType',
  },
  {
    sortType: 'number',
    dataKey: 'sharePercent',
  },
  {
    sortType: 'string',
    dataKey: 'location',
  },
  {
    sortType: 'string',
    dataKey: 'terms',
  },
]

export const uniqueLines = [
  {
    label: 'Name',
    dataKey: 'partnerName',
  },
  {
    label: 'Partner Type',
    dataKey: 'partnerType',
  },
  {
    label: 'Location',
    dataKey: 'location',
  },
]
