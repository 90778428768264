export const naturalSort = (stringArray, dataKey, SortingDirection, direction, sortKey = null) => {
  let xor = function(a, b) {
    return a ? !b : b;
  };
  let isDigit = function(chr) {
    let charCode = function(ch) {
      return ch.charCodeAt(0);
    };
    let code = charCode(chr);
    return (code >= charCode('0')) && (code <= charCode('9'));
  };

  let splitString = function(str) {
    let from = 0;
    let index = 0;
    let count = 0;
    let splitter = {};

    splitter.count = function () {
      return count;
    }

    splitter.next = function() {
      if (index === str.length) {
        return null;
      }

      while(++index) {
        let currentIsDigit = isDigit(str.charAt(index - 1));
        let nextChar = str.charAt(index);
        let currentIsLast = (index === str.length);

        let isBorder = currentIsLast ||
          xor(currentIsDigit, isDigit(nextChar));
        if (isBorder) {
          let part = str.slice(from, index);
          from = index;
          count++;
          return {
            IsNumber: currentIsDigit,
            Value: currentIsDigit ? Number(part) : part
          };
        }
      }
    };
    return splitter;
  };

  return stringArray.sort((a, b) => {
    let str1 = sortKey ? String(b[dataKey][sortKey]).toLowerCase() : String(b[dataKey]).toLowerCase();
    let str2 = sortKey ? String(a[dataKey][sortKey]).toLowerCase() : String(a[dataKey]).toLowerCase();
    if (SortingDirection === direction) {
      str1 = sortKey ? String(a[dataKey][sortKey]).toLowerCase() : String(a[dataKey]).toLowerCase();
      str2 = sortKey ? String(b[dataKey][sortKey]).toLowerCase() : String(b[dataKey]).toLowerCase();

    }
    let compare = function(a, b) {
      return (a < b) ? -1 : (a > b) ? 1 : 0;
    };
    let splitter1 = splitString(str1);
    let splitter2 = splitString(str2);
    
    while (true) {
      let first = splitter1.next();
      let second = splitter2.next();
      
      if (null !== first && null !== second) {
        
        if (xor(first.IsNumber, second.IsNumber)) {
          
          return first.IsNumber ? -1 : 1;
          
        } else {
          let comp = compare(first.Value, second.Value);
          if (comp !== 0) {
            return comp;
          }
        }
        
      } else {
        return compare(splitter1.count(), splitter2.count());
      }
    }
  });
}
