import { accountTypes } from '../../../components/App';

export const excludeKeys = ['action'];

export const reformatDataKeys = [
  {
    name: 'sharePercent',
    value: 'value',
  },
  {
    name: 'subOwnerPercent',
    value: 'value',
  },
  {
    name: 'partner',
    value: 'name',
  },
  {
    name: 'siteType',
    value: 'name',
  },
];

const accountsPageColumn = [
  {
    width: 200,
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    width: 200,
    label: 'Share %',
    dataKey: 'sharePercent',
    isContainer: true,
    isPanelInput: true,
    isSharePercentId: 1,
  },
  {
    width: 300,
    label: 'Notes',
    dataKey: 'notes',
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
  {
    width: 200,
    label: 'Status',
    dataKey: 'status',
    isTitleWrap: true,
  },
];

const accountsDepositColumn = [
  {
    width: 300,
    label: 'Url',
    dataKey: 'name',
  },
  {
    width: 300,
    label: 'Username',
    dataKey: 'username',
  },
  {
    width: 300,
    label: 'Password',
    dataKey: 'password',
  },
  ...accountsPageColumn,
];

const accountsApplicationColumn = [
  {
    width: 300,
    label: 'Name',
    dataKey: 'name',
  },
  {
    width: 300,
    label: 'Username',
    dataKey: 'username',
  },
  {
    width: 300,
    label: 'Password',
    dataKey: 'password',
  },
  ...accountsPageColumn,
];

const accountsPayPerHeadColumn = [
  {
    width: 300,
    label: 'Site Type',
    dataKey: 'siteTypeName',
  },
  {
    width: 300,
    label: 'SubType',
    dataKey: 'subType',
  },
  {
    width: 300,
    label: 'Url',
    dataKey: 'name',
  },
  {
    width: 300,
    label: 'Username',
    dataKey: 'username',
  },
  {
    width: 300,
    label: 'Password',
    dataKey: 'password',
  },
  {
    width: 200,
    label: 'Sub Owner',
    dataKey: 'subOwnerName',
  },
  {
    width: 200,
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    width: 200,
    label: 'Sub Owner %',
    dataKey: 'subOwnerPercent',
    isContainer: true,
    isPanelInput: true,
    isSharePercentId: 0,
  },
  {
    width: 200,
    label: 'Share %',
    dataKey: 'sharePercent',
    isContainer: true,
    isPanelInput: true,
    isSharePercentId: 1,
  },
  {
    width: 300,
    label: 'Notes',
    dataKey: 'notes',
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
  {
    width: 200,
    label: 'Status',
    dataKey: 'status',
    isTitleWrap: true,
  },
];

const accountsHandBetsColumn = [
  {
    width: 300,
    label: 'SubType',
    dataKey: 'subType',
  },
  {
    width: 600,
    label: 'Sub Owner',
    dataKey: 'subOwnerName',
  },
  {
    width: 600,
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    width: 400,
    label: 'Sub Owner %',
    dataKey: 'subOwnerPercent',
    isContainer: true,
    isPanelInput: true,
    isSharePercentId: 0,
  },
  {
    width: 400,
    label: 'Share %',
    dataKey: 'sharePercent',
    isContainer: true,
    isPanelInput: true,
    isSharePercentId: 1,
  },
  {
    width: 1000,
    label: 'Notes',
    dataKey: 'notes',
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
  {
    width: 200,
    label: 'Status',
    dataKey: 'status',
    isTitleWrap: true,
  },
];

export const getAccountPageColumn = type => {
  switch (type) {
    case accountTypes.DEPOSIT:
      return accountsDepositColumn;
    case accountTypes.APPLICATION:
      return accountsApplicationColumn;
    case accountTypes.PAY_PER_HEAD:
      return accountsPayPerHeadColumn;
    case accountTypes.HAND_BETS:
      return accountsHandBetsColumn;
    default:
      return [];
  }
}

const accountsDepositSort = [
  {
    dataKey: 'name',
    sortType: 'string',
  },
  {
    dataKey: 'username',
    sortType: 'string',
  },
  {
    dataKey: 'password',
    sortType: 'string',
  },
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'notes',
    sortType: 'string',
  },
  {
    sortType: 'string',
    dataKey: 'status',
  },
];

const accountsApplicationSort = [
  {
    dataKey: 'name',
    sortType: 'string',
  },
  {
    dataKey: 'username',
    sortType: 'string',
  },
  {
    dataKey: 'password',
    sortType: 'string',
  },
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'notes',
    sortType: 'string',
  },
  {
    sortType: 'string',
    dataKey: 'status',
  },
];

const accountsPayPerHeadSort = [
  {
    dataKey: 'siteTypeName',
    sortType: 'string',
  },
  {
    dataKey: 'name',
    sortType: 'string',
  },
  {
    dataKey: 'username',
    sortType: 'string',
  },
  {
    dataKey: 'password',
    sortType: 'string',
  },
  // {
  //   dataKey: 'sharePercent',
  //   sortType: 'number',
  // },
  {
    dataKey: 'subOwnerName',
    sortType: 'string',
  },
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'notes',
    sortType: 'string',
  },
  {
    sortType: 'string',
    dataKey: 'status',
  },
];

const accountsHandBetsSort = [
  {
    dataKey: 'subOwnerName',
    sortType: 'string',
  },
  {
    dataKey: 'partnerName',
    sortType: 'string',
  },
  {
    dataKey: 'notes',
    sortType: 'string',
  },
  {
    sortType: 'string',
    dataKey: 'status',
  },
]

export const getAccountPageSort = type => {
  switch (type) {
    case accountTypes.DEPOSIT:
      return accountsDepositSort;
    case accountTypes.APPLICATION:
      return accountsApplicationSort;
    case accountTypes.PAY_PER_HEAD:
      return accountsPayPerHeadSort;
    case accountTypes.HAND_BETS:
      return accountsHandBetsSort;
    default:
      return [];
  }
}

const uniqueDepositLines = [
  {
    label: 'Url',
    dataKey: 'name',
  },
  {
    label: 'Username',
    dataKey: 'username',
  },
  {
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    label: 'Status',
    dataKey: 'status',
  },
]

const uniqueApplicationLines = [
  {
    label: 'Name',
    dataKey: 'name',
  },
  {
    label: 'Username',
    dataKey: 'username',
  },
  {
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    label: 'Status',
    dataKey: 'status',
  },
]

const uniquePayPerHeadLines = [
  {
    label: 'Site Type',
    dataKey: 'siteTypeName',
  },
  {
    label: 'SubType',
    dataKey: 'subType',
  },
  {
    label: 'Url',
    dataKey: 'name',
  },
  {
    label: 'Sub Owner',
    dataKey: 'subOwnerName',
  },
  {
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    label: 'Status',
    dataKey: 'status',
  },
]

const uniqueHandBetsLines = [
  {
    label: 'SubType',
    dataKey: 'subType',
  },
  {
    label: 'Sub Owner',
    dataKey: 'subOwnerName',
  },
  {
    label: 'Partner',
    dataKey: 'partnerName',
  },
]

export const getUniqueAccountLines = type => {
  switch (type) {
    case accountTypes.DEPOSIT:
      return uniqueDepositLines;
    case accountTypes.APPLICATION:
      return uniqueApplicationLines;
    case accountTypes.PAY_PER_HEAD:
      return uniquePayPerHeadLines;
    case accountTypes.HAND_BETS:
      return uniqueHandBetsLines;
    default:
      return [];
  }
}
