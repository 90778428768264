import {
  RESET_EDIT_NETWORK,
  SET_EDIT_NETWORK,
  SET_EDIT_NETWORK_NAME,
} from '../../constants';

export const resetEditNetwork = () => ({
  type: RESET_EDIT_NETWORK,
});

export const setEditNetwork = network => ({
  type: SET_EDIT_NETWORK,
  payload: {
    network,
  },
});

export const setEditNetworkName = name => ({
  type: SET_EDIT_NETWORK_NAME,
  payload: {
    name,
  },
});
