import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { navigationList } from './constants';
import { TabNavigation } from '../../components/TabNavigation';
import { WeekSwitcher } from '../../components/WeekSwitcher';
import {
  getAccountFiguresSelectedDateSelector,
  getAccountFiguresSelectedTypeSelector,
} from './selectors';
import {
  resetAccountFigures as resetAccountFiguresAction,
  resetAccountFiguresTab as resetAccountFiguresTabAction,
  setAccountFiguresSelectedDate as setAccountFiguresSelectedDateAction
} from '../../store';
import {
  accountFiguresForTable,
  privateWalletsForTable,
} from '../../api';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

const AccountFiguresPageContainer = ({
  children,
  selectedDate,
  setAccountFiguresSelectedDate,
  selectedType,
  resetAccountFigures,
  resetAccountFiguresTab,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const today = moment().subtract(1, 'weeks');

  useEffect(() => {
    const firstDay = today.clone().weekday(0);
    setAccountFiguresSelectedDate(firstDay);

    return () => {
      resetAccountFigures()
    }
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const week = selectedDate.week();
      const year = selectedDate.year();
      
      if (selectedType !== 'PrivateWallets') {
        if (selectedType) {
          const getAccountsFigures = async () => accountFiguresForTable({
            year,
            week,
            type: selectedType,
          });

          getAccountsFigures().then(() => Promise.resolve());
        }
      } else {
        const getPrivateWalletsFigures = async () => privateWalletsForTable({
          year,
          week,
          isPrivate: true,
        });

        getPrivateWalletsFigures().then(() => Promise.resolve());
      }
    }
  }, [selectedDate, selectedType]);

  return (
    <Box className={classes.wrapper}>
      <WeekSwitcher
        selectedDate={selectedDate}
        handleChangeSelectedDate={setAccountFiguresSelectedDate}
      />

      <TabNavigation
        navigationList={navigationList}
        eventItemClick={() => resetAccountFiguresTab()}
      />

      <Box className={classes.content}>
        {children}
      </Box>
    </Box>
  );
};

const mapStateToProps = state => ({
  selectedDate: getAccountFiguresSelectedDateSelector(state),
  selectedType: getAccountFiguresSelectedTypeSelector(state),
});

const mapDispatchToProps = {
  resetAccountFigures: resetAccountFiguresAction,
  resetAccountFiguresTab: resetAccountFiguresTabAction,
  setAccountFiguresSelectedDate: setAccountFiguresSelectedDateAction,
};

export const AccountFiguresPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountFiguresPageContainer);
