import {
  RESET_EDIT_ACCOUNT,
  SET_EDIT_ACCOUNT,
  SET_EDIT_ACCOUNT_TYPE,
  SET_EDIT_ACCOUNT_SUB_TYPE,
  SET_EDIT_ACCOUNT_SHORT_ACCOUNT_NAME,
  SET_EDIT_ACCOUNT_URL,
  SET_EDIT_ACCOUNT_USERNAME,
  SET_EDIT_ACCOUNT_PASSWORD,
  SET_EDIT_ACCOUNT_SHARE_PERCENT,
  SET_EDIT_ACCOUNT_NOTES,
  SET_EDIT_ACCOUNT_SITE_TYPE,
  SET_EDIT_ACCOUNT_PARTNER,
  SET_EDIT_ACCOUNT_SITE_TYPES_LIST,
  SET_EDIT_ACCOUNT_PARTNERS_LIST,
  SET_EDIT_ACCOUNT_SUB_OWNER,
  SET_EDIT_ACCOUNT_SUB_OWNER_PERCENT,
} from '../constants';

export const resetEditAccount = () => ({
  type: RESET_EDIT_ACCOUNT
});

export const setEditAccount = account => ({
  type: SET_EDIT_ACCOUNT,
  payload: {
    account,
  },
});

export const setEditAccountType = type => ({
  type: SET_EDIT_ACCOUNT_TYPE,
  payload: {
    type,
  },
});

export const setEditAccountSubType = subType => ({
  type: SET_EDIT_ACCOUNT_SUB_TYPE,
  payload: {
    subType,
  },
});

export const setEditAccountShortAccountName = shortAccountName => ({
  type: SET_EDIT_ACCOUNT_SHORT_ACCOUNT_NAME,
  payload: {
    shortAccountName,
  },
});

export const setEditAccountUrl = url => ({
  type: SET_EDIT_ACCOUNT_URL,
  payload: {
    url,
  },
});

export const setEditAccountUsername = username => ({
  type: SET_EDIT_ACCOUNT_USERNAME,
  payload: {
    username,
  },
});

export const setEditAccountPassword = password => ({
  type: SET_EDIT_ACCOUNT_PASSWORD,
  payload: {
    password,
  },
});

export const setEditAccountSharePercent = sharePercent => ({
  type: SET_EDIT_ACCOUNT_SHARE_PERCENT,
  payload: {
    sharePercent,
  },
});

export const setEditAccountNotes = notes => ({
  type: SET_EDIT_ACCOUNT_NOTES,
  payload: {
    notes,
  },
});

export const setEditAccountSiteType = siteType => ({
  type: SET_EDIT_ACCOUNT_SITE_TYPE,
  payload: {
    siteType,
  },
});

export const setEditAccountPartner = partner => ({
  type: SET_EDIT_ACCOUNT_PARTNER,
  payload: {
    partner,
  },
});

export const setEditAccountSiteTypesList = siteTypesList => ({
  type: SET_EDIT_ACCOUNT_SITE_TYPES_LIST,
  payload: {
    siteTypesList,
  },
});

export const setEditAccountPartnersList = partnersList => ({
  type: SET_EDIT_ACCOUNT_PARTNERS_LIST,
  payload: {
    partnersList,
  },
});

export const setEditAccountSubOwner = subOwner => ({
  type: SET_EDIT_ACCOUNT_SUB_OWNER,
  payload: {
    subOwner,
  },
});

export const setEditAccountSubOwnerPercent = subOwnerPercent => ({
  type: SET_EDIT_ACCOUNT_SUB_OWNER_PERCENT,
  payload: {
    subOwnerPercent,
  },
});
