import React from 'react';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AlarmIcon from '@material-ui/icons/Alarm';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EnableIcon from '@material-ui/icons/Visibility';
import DisableIcon from '@material-ui/icons/VisibilityOff';
import RestoreIcon from '@material-ui/icons/Restore';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

import { iconTypes } from './constants';

export const getIconByType = type => {
  switch(type) {
    case iconTypes.DELETE:
      return <HighlightOffIcon />
    case iconTypes.ENABLE:
      return <EnableIcon />
    case iconTypes.DISABLE:
      return <DisableIcon />
    case iconTypes.EDIT:
      return <EditIcon />
    case iconTypes.INFO:
      return <InfoIcon />
    case iconTypes.LOGOUT:
      return <ExitToAppIcon />
    case iconTypes.RESTORE:
      return <RestoreIcon />
    case iconTypes.START:
      return <PlayArrowIcon />
    case iconTypes.STOP:
      return <StopIcon />
    default:
      return <AlarmIcon />
  }
}
