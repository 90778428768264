export const getStyles = theme => ({
  navigation: {
    display: 'flex',
  },
  navigation_item: {
    color: theme.mainStyles.mainColorText,
    textDecoration: 'none',
    borderBottom: `2px solid rgba(0,0,0,0)`,
    fontSize: 16,
    padding: '9px 15px',
    "&:hover": {
      color: theme.navigations.colorText,
      textDecoration: 'none',
      borderBottom: `2px solid ${theme.navigations.borderColor}`,
    },
    "&.active": {
      color: theme.navigations.hoverColorText,
      textDecoration: 'none',
      borderBottom: `2px solid ${theme.navigations.hoverBorderColor}`,
    }
  }
})
