import { numberToDollar } from '../../utils/number'

export const balancesReportsReformat = balancesReports => (
  {
    cashBalance: numberToDollar(balancesReports?.cashBalance),
    previousCarry: numberToDollar(balancesReports?.previousCarry),
    onlineBalances: numberToDollar(balancesReports?.onlineBalances),
    appBalances: numberToDollar(balancesReports?.appBalances),
    agentBalances: numberToDollar(balancesReports?.agentBalances),
    agentBalancesShare: numberToDollar(balancesReports?.agentBalancesShare),
    partnerBalances: numberToDollar(balancesReports?.partnerBalances),
    bitcoinHarout: numberToDollar(balancesReports?.btcHarout),
    bitcoinDrew: numberToDollar(balancesReports?.btcDrew),
    bankrollValue: numberToDollar(balancesReports?.bankrollValue),
  }
);
