import { gql } from '@apollo/client';

export const WEEKLY_FIGURES_FRAGMENT = gql`
  fragment CoreWeeklyFiguresFields on AccountWeeklyFiguresEntity {
    account {
      _id
      sharePercent
      subOwnerPercent
      type
      partner {
        name
      }
      subOwner {
        name
      }
      username @skip(if: $skipUsername)
      password
      url @skip(if: $skipUrl)
      shortAccountName @skip(if: $skipShortAccountName)
      siteType @skip(if :$skipSiteType) {
        name
      }
      notes
    }
    botWeeklyFigure{
				username
				agent_name
				figure_update
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				result
				site_name
				start_date
				volume
				week
				year
				
		}
    isClosed
    amount
    subOwnerPercent
    sharePercent
    week
    year
  }
`;

export const GET_WEEKLY_FIGURES_BY_TYPE_WEEK_YEAR = gql`
  ${WEEKLY_FIGURES_FRAGMENT}
  query GetWeeklyFiguresByTypeWeekYear(
    $weeklyFigureByWeekDto: WeeklyFigureByWeekDto!,
    $skipUsername: Boolean!,
    $skipUrl: Boolean!,
    $skipShortAccountName: Boolean!,
    $skipSiteType: Boolean!
  ) {
    getAllFiguresForAccountsByWeek(weeklyFigureByWeekDto: $weeklyFigureByWeekDto) {
      ...CoreWeeklyFiguresFields
    }
  }
`;

export const GET_WEEKLY_FIGURES_WALLETS_WEEK_YEAR = gql`
  query GetWeeklyFiguresWalletsByWeekYear(
    $weeklyFiguresWalletByWeekDto: WeeklyFiguresWalletByWeekDto!
  ) {
    weeklyFiguresWallet(weeklyFigureByWeekDto: $weeklyFiguresWalletByWeekDto) {
      wallet {
        _id
        type {
          name
        }
        partner {
          name
        }
      }
      amount
      transactionsAmount
      week
      year
    }
  }
`;

export const UPDATE_BOT_WEEKLY_FIGURE = gql`
  mutation UpdateBotWeeklyFigure($payload: UpdateBotWeeklyFigureInput!) {
    updateBotWeeklyFigure(updateBotWeeklyFigureInput: $payload)
  }
`;

export const ADD_ACCOUNT_FIGURE = gql`
  mutation AddAccountFigure($payload: CreateWeeklyFigureDto!) {
    createWeeklyFigure(createWeeklyFigureDto: $payload) {
      _id
      amount
      subOwnerPercent
      sharePercent
      week
      year
    }
  }
`;

export const ADD_PRIVATE_WALLET_FIGURE = gql`
  mutation AddPrivateWalletFigure($payload: CreateWeeklyFiguresWalletDto!) {
    createWeeklyFiguresWallet(createWeeklyFiguresWalletDto: $payload) {
      _id
      amount
      week
      year
    }
  }
`;

export const SUBMIT_WEEKLY_FIGURES_WEEK = gql`
  mutation SubmitWeeklyFiguresWeek($payload: CreatePartnerWeeklyFiguresDto!) {
    createPartnerWeeklyFigure(createPartnerWeeklyFigureDto: $payload)
  }
`;

export const DELETE_WEEKLY_FIGURES_WEEK = gql`
  mutation DeleteWeeklyFiguresWeek($payload: DeletePartnerWeeklyFiguresDto!) {
    deletePartnerWeeklyFigure(deletePartnerWeeklyFigureInput: $payload)
  }
`;
