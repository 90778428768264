export const walletsPageColumn = [
  {
    width: 700,
    label: 'Name',
    dataKey: 'name',
  },
  {
    width: 1500,
    label: 'Notes',
    dataKey: 'notes',
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
];

export const walletsPageSort = [
  {
    sortType: 'string',
    dataKey: 'name',
  },
  {
    sortType: 'string',
    dataKey: 'notes',
  },
]
