import { gql } from '@apollo/client';
import { NETWORK_FRAGMENT } from '../networks/constants';
import { PARTNER_SHORT_FRAGMENT } from '../partners/constants';

export const ACCOUNT_SHORT_FRAGMENT = gql`
  fragment CoreShortAccountFields on Account {
    _id
    username
  }
`;

export const ACCOUNT_DEFAULT_TABLE_FRAGMENT = gql`
  ${PARTNER_SHORT_FRAGMENT}
  fragment DefaultTableAccountFields on Account {
    _id
    sharePercent
    subOwnerPercent
    partner {
      ...CorePartnerShortFields
    }
    subOwner {
      ...CorePartnerShortFields
    }
    notes
    status
    disabled
    stop
    stopDateUTC
  }
`;

export const ACCOUNT_FRAGMENT = gql`
  ${PARTNER_SHORT_FRAGMENT}
  ${NETWORK_FRAGMENT}
  fragment CoreAccountFields on Account {
    _id
    type
    subType
    shortAccountName
    url
    username
    password
    sharePercent
    subOwnerPercent
    siteType {
      ...CoreNetworkFields
    }
    partner {
      ...CorePartnerShortFields
    }
    subOwner {
      ...CorePartnerShortFields
    }
    notes
    disabled
    status
    stop
    stopDateUTC
  }
`;

export const GET_ACCOUNTS_FOR_TABLE = gql`
  ${ACCOUNT_DEFAULT_TABLE_FRAGMENT}
  ${NETWORK_FRAGMENT}
  query GetAccountForTable(
    $filter: FilterAccountDto!,
    $skipShortAccountName: Boolean!,
    $skipUrl: Boolean!,
    $skipUsername: Boolean!,
    $skipPassword: Boolean!,
    $skipSiteType: Boolean!
    $skipSubType: Boolean!
  ) {
    accounts(filter: $filter) {
      ...DefaultTableAccountFields
      shortAccountName @skip(if: $skipShortAccountName)
      url @skip(if: $skipUrl)
      type
      username @skip(if: $skipUsername)
      password  @skip(if: $skipPassword)
      siteType @skip(if: $skipSiteType) {
        ...CoreNetworkFields
      }
      subType @skip(if: $skipSubType)
      status
    }
  }
`;

export const GET_PARTNERS_AND_SITE_TYPES = gql`
  ${PARTNER_SHORT_FRAGMENT}
  ${NETWORK_FRAGMENT}
  query GetPartnersAndSiteTypes {
    networks {
      ...CoreNetworkFields
    }
    partners {
      ...CorePartnerShortFields
      sharePercent
    }
  }
`;

export const GET_ACCOUNT_AND_LISTS_FOR_EDIT = gql`
  ${ACCOUNT_FRAGMENT}
  ${PARTNER_SHORT_FRAGMENT}
  ${NETWORK_FRAGMENT}
  query GetAccountAndListsForEdit($_id: String!) {
    account(_id: $_id) {
      ...CoreAccountFields
    }
    networks {
      ...CoreNetworkFields
    }
    partners {
      ...CorePartnerShortFields
      sharePercent
    }
  }
`;

export const ADD_ACCOUNT = gql`
  mutation AddAccount($payload: CreateAccountDto!) {
    createAccount(payload: $payload) {
      _id
    }
  }
`;

export const EDIT_ACCOUNT = gql`
  ${ACCOUNT_FRAGMENT}
  mutation EditAccount($payload: UpdateAccountDto!) {
    updateAccount(payload: $payload) {
      ...CoreAccountFields
    }
  }
`;

export const START_STOP_ACCOUNT = gql`
  ${ACCOUNT_FRAGMENT}
  mutation StartStopAccount($payload: StartStopAccountDto!) {
    startStopAccount(payload: $payload) {
      ...CoreAccountFields
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($_id: String!) {
    deleteAccount(_id: $_id) {
      _id
    }
  }
`;
