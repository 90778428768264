import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';

import { SelectMultipleCustom } from '../UIComponents';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const MultiFilterPanel = ({
	list,
	onChange,
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);

	return (
		<Box className={classes.container}>
			{list && list.map((elem) => (
				<Box
					key={elem.dataKey}
					className={classes.block}
				>
					<SelectMultipleCustom
						label={elem.label}
						multiple
						disableClearable={true}
						list={elem.values}
						value={elem.selectedValues}
						onChange={(e, items) => onChange(items, elem.dataKey)}
					/>
				</Box>
			))}
		</Box>
	)
}