export const accountsLinksConstants = {
  DEPOSIT: '/Accounts/Deposit',
  APPLICATION: '/Accounts/Application',
  PAY_PER_HEAD: '/Accounts/PayPerHead',
  HAND_BETS: '/Accounts/HandBets',
}

export const navigationList = [
  {
    title: 'Deposit',
    link: accountsLinksConstants.DEPOSIT,
  },
  {
    title: 'Application',
    link: accountsLinksConstants.APPLICATION,
  },
  {
    title: 'PayPerHead',
    link: accountsLinksConstants.PAY_PER_HEAD,
  },
  {
    title: 'HandBets',
    link: accountsLinksConstants.HAND_BETS,
  },
];
