export const telegramLinksConstants = {
  users: '/Telegram/Users',
  generatedPartnerCodes: '/Telegram/GeneratedPartnerCodes',
  getPartnerCode: '/Telegram/GetPartnerCode',
}

export const navigationList = [
  {
    title: 'Users',
    link: telegramLinksConstants.users,
  },
  {
    title: 'Generated Partner Codes',
    link: telegramLinksConstants.generatedPartnerCodes,
  },
  {
    title: 'Get Partner Code',
    link: telegramLinksConstants.getPartnerCode,
  },
];
