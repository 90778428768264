import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { balancesReportsRows } from './constants';
import { getBalancesReportsObjectSelector, getBalancesReportsSelectedDateSector } from './selectors';
import { useEffect } from 'react';
import { getBalancesReports } from '../../api/balancesReports';
import {
  resetBalancesReports as resetBalancesReportsAction,
  setBalancesReportsSelectedDate as setBalancesReportsSelectedDateAction
} from '../../store';

import { getStyles } from './styles';
import { WeekSwitcher } from '../../components/WeekSwitcher';

const useStyles = makeStyles(getStyles);

const BalancesReportsPageContainer = ({
  reports,
  selectedDate,
  resetBalancesReports,
  setBalancesReportsSelectedDate,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const rows = balancesReportsRows(reports);

  const today = moment().subtract(1, 'weeks');

  useEffect(() => {
    const firstDay = today.clone().weekday(0);
    setBalancesReportsSelectedDate(firstDay);

    return () => resetBalancesReports();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const week = selectedDate.week();
      const year = selectedDate.year();

      const balancesReports = async () => await getBalancesReports({
        week,
        year,
      });

      balancesReports().then(() => Promise.resolve());
    }
  }, [selectedDate]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <WeekSwitcher
          selectedDate={selectedDate}
          handleChangeSelectedDate={setBalancesReportsSelectedDate}
        />
        <Box className={classes.tableCard}>
          <Table aria-label="balances table">
            <TableBody>
              {
                rows?.map(row => (
                  <TableRow
                    key={row.name}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: row?.isColor ? row?.isColor : null,
                      }}
                    >
                      { row?.name }
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: row?.isColor ? row?.isColor : null,
                      }}
                    >
                      { row?.value }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => ({
  reports: getBalancesReportsObjectSelector(state),
  selectedDate: getBalancesReportsSelectedDateSector(state),
});

const mapDispatchToProps = {
  resetBalancesReports: resetBalancesReportsAction,
  setBalancesReportsSelectedDate: setBalancesReportsSelectedDateAction,
};

export const BalancesReportsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BalancesReportsPageContainer);
