import { gql } from '@apollo/client';

import {
    PARTNER_SHORT_FRAGMENT,
} from '../partners/constants';
import { WALLET_SHORT_FRAGMENT } from '../wallets/constants';

export const GET_WALLETS_REPORTS = gql`
  ${PARTNER_SHORT_FRAGMENT}
  ${WALLET_SHORT_FRAGMENT}
  query GetWalletsReports(
    $weeklyFiguresWalletByWeekDto: WeeklyFiguresWalletByWeekDto!
  ) {
    reportWallets(weeklyFigureByWeekDto: $weeklyFiguresWalletByWeekDto) {
      wallet {
        _id
        type {
          name
        }
        partner {
          name
        }
      }
      weeklyAmount
      allTransactionsAmount
      walletTransactions {
        _id
        partnerSender {
          ...CorePartnerShortFields
        }
        walletSender {
          ...CoreWalletShortFields
        }
        partnerReceiver {
          ...CorePartnerShortFields
        }
        walletReceiver {
          ...CoreWalletShortFields
        }
        transactionType
        subType
        amount
        week
        year
        date
        createDateUTC
        notes
      }
      week
      year
    }
  }
`;
