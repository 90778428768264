export const networksPageColumn = [
  {
    width: 2000,
    label: 'Name',
    dataKey: 'name',
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
];

export const networksPageSort = [
  {
    sortType: 'string',
    dataKey: 'name',
  },
];
