export const getStyles = theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    paddingTop: 10,
    paddingBottom: 20,
  } 
})
