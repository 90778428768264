import React from 'react';
import clsx from 'clsx';

import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import IconButton from '@material-ui/core/IconButton';

import { getIconByType } from './utils';
import { iconTypes } from './constants';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);


export const ButtonIconCustom = ({
  icon,
  customType,
  className,
  onClick,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <IconButton
      className={
        clsx(
          classes[customType ? customType : iconTypes.DEFAULT],
          className ? className : ''
        )
      }
      onClick={onClick ? onClick : null}
    >
      { icon ? icon : getIconByType(customType) }
    </IconButton>
  )
}

