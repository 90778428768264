import React from 'react';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { LoginForm } from '../../components/LoginForm';

import { getStyles } from './styles';
import './style.scss';

const useStyles = makeStyles(getStyles);

export const LoginPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box
      className={
        clsx(
          classes.container,
          'login-page'
        )}
    >
      <LoginForm />
    </Box>
  )
}
