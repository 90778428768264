import {
  RESET_CREATE_NETWORK,
  SET_CREATE_NETWORK_NAME,
} from '../../constants';

export const resetCreateNetwork = () => ({
  type: RESET_CREATE_NETWORK,
});

export const setCreateNetworkName = name => ({
  type: SET_CREATE_NETWORK_NAME,
  payload: {
    name,
  },
});
