import { store } from '../../store';
import {
  setPartnersList,
  setEditPartner,
} from '../../store';
import {
  partnersForTableParser,
  partnerToEditParser,
  addPartnerToBackendParser,
  editPartnerToBackendParser,
} from './reformatters';

import {
  getLocalStorageData,
} from '../../utils';
import {
  GET_PARTNERS_FOR_TABLE,
  GET_PARTNER_FOR_EDIT,
  ADD_PARTNER,
  EDIT_PARTNER,
  DELETE_PARTNER,
} from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';

// export class Accounts {
  export async function partnersForTable() {
    await requestWrapper('PartnersForTable', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_PARTNERS_FOR_TABLE)
        .setBearerToken(token)
        .build();

      const { partners } = data || null;

      if (!loading && !error && partners) {
        const reformattedPartners = partnersForTableParser(partners);
        store.dispatch(setPartnersList(reformattedPartners));
      } else {

      }
    });
  }

  export async function addPartner(newPartner) {
    await requestWrapper('AddPartner', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(ADD_PARTNER)
        .setBearerToken(token)
        .setVariables({
          payload: addPartnerToBackendParser(newPartner)
        })
        .build();
      const { createPartner } = data || null;

      if (!loading && !error && createPartner?._id) {
        await partnersForTable();
      } else {

      }
    });
  }

  export async function getForEditPartner(id) {
    await requestWrapper('GetForEditPartner', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .query(GET_PARTNER_FOR_EDIT)
        .setBearerToken(token)
        .setVariables({ _id: id, })
        .build();

      const { partner } = data || null;

      if (!loading && !error && partner) {
        store.dispatch(setEditPartner(partnerToEditParser(partner)));
      } else {

      }
    });
  }

  export async function editPartner(editedPartner) {
    await requestWrapper('EditPartner', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(EDIT_PARTNER)
        .setBearerToken(token)
        .setVariables({
          payload: editPartnerToBackendParser(editedPartner)
        })
        .build();

      const { updatePartner } = data || null;

      if (!loading && !error && updatePartner) {
        setEditPartner(partnerToEditParser(updatePartner));
        await partnersForTable();
      } else {

      }
    });
  }

  export async function deletePartner(id) {
    await requestWrapper('DeletePartner', async () => {
      const token = getLocalStorageData('token');
      const apolloClient = new apolloClientQueryBuilder();
      const { loading, error, data } = await apolloClient
        .mutate(DELETE_PARTNER)
        .setBearerToken(token)
        .setVariables({ _id: id, })
        .build();

      const { deletePartner } = data || null;

      if (!loading && !error && deletePartner?._id) {
        await partnersForTable();
      } else {

      }
    });
  }
// }
