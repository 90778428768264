import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { TabNavigation } from '../../components/TabNavigation';

import { getStyles } from './styles';
import { navigationList } from './navigations';

const useStyles = makeStyles(getStyles);

export const TelegramPage = ({
  children,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={classes.wrapper}>
      <TabNavigation
        navigationList={navigationList}
      />

      <Box className={classes.content}>
        { children }
      </Box>
    </Box>
  );
}
