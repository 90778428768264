import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import {
  InputCustom,
  InputPasswordCustom,
  ButtonSubmit,
} from '../UIComponents';
import { login } from '../../api';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const LoginForm = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [loginData, setLoginData] = useState('');
  const [passwordData, setPasswordData] = useState('');

  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  
  const validate = () => {
    let valid = true;
    if (loginData.trim().length < 1) {
      setLoginError(true);
      valid = false;
    }
    if (passwordData.trim().length < 1) {
      setPasswordError(true);
      valid = false;
    }
    return valid;
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      login(loginData, passwordData);
    }
  }

  return (
    <Box className={classes.container}>
      <form
        onSubmit={e => handleSubmit(e)}
      >
        <Box className={classes.block}>
          <InputCustom
            label="Login"
            value={loginData}
            error={loginError}
            onChange={e => {
              setLoginData(e.target.value);
              setLoginError(false);
            }}
          />
        </Box>
        <Box className={classes.block}>
          <InputPasswordCustom
            variant="outlined"
            value={passwordData}
            error={passwordError}
            onChange={e => {
              setPasswordData(e.target.value);
              setPasswordError(false);
            }}
          />
        </Box>
        <Box className={classes.block}>
          <ButtonSubmit />
        </Box>
      </form>
    </Box>
  )
}
