export const navigationListConstant = [
  {
    link: '/',
    title: 'Home',
    exact: true,
  },
  {
    link: '/AccountFigures',
    title: 'Figures',
  },
  {
    link: '/Payments',
    title: 'Payments',
  },
  {
    link: '/Accounts',
    title: 'Accounts',
  },
  {
    link: '/Partners',
    title: 'Partners',
  },
  {
    link: '/Networks',
    title: 'Networks',
  },
  {
    link: '/Reports',
    title: 'Reports',
  },
  {
    link: '/Wallets',
    title: 'Wallets',
  },
  {
    link: '/Transactions',
    title: 'Transactions',
  },
  {
    link: '/Telegram',
    title: 'Telegram',
  },
]
