import {
  RESET_CREATE_TELEGRAM_CODE_PARTNER,
  SET_CREATE_TELEGRAM_CODE_PARTNER,
  SET_CREATE_TELEGRAM_CODE_PARTNERS_LIST,
} from '../constants';

export const resetCreateTelegramCodePartner = () => ({
  type: RESET_CREATE_TELEGRAM_CODE_PARTNER,
});

export const setCreateTelegramCodePartner = partner => ({
  type: SET_CREATE_TELEGRAM_CODE_PARTNER,
  payload: {
    partner,
  },
});

export const setCreateTelegramCodePartnersList = partnersList => ({
  type: SET_CREATE_TELEGRAM_CODE_PARTNERS_LIST,
  payload: {
    partnersList,
  },
})
