export const getStyles = theme => ({
	container: {
		flexGrow: 1,
		marginRight: 16,
		display: 'flex',
		flexWrap: 'wrap',
	},
	block: {
		paddingLeft: 5,
		paddingRight: 5,
		maxWidth: 250,
		minWidth: 150,
		width: '100%',
	}
})
