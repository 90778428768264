import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import moment from "moment";

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import {
  getAccountFiguresListSelector,
  getAccountFiguresSelectedDateSelector,
  getAccountFiguresIsWeekClosedSelector,
  getAccountFiguresTotalWeeklyFigureSelector,
} from '../selectors';
import { getAccountPageColumn, getAccountPageSort, getUniqueLines } from './constants';
import { ButtonCustom, InputCustom } from '../../../components/UIComponents';
import { TableComponent } from '../../../components/TableComponent';
import {
  setAccountFiguresItemValue as setAccountFiguresItemValueAction,
  setAccountFiguresItemSharePercent as setAccountFiguresItemSharePercentAction,
  setAccountFiguresList as setAccountFiguresListAction,
  setAccountFiguresSelectedType as setAccountFiguresSelectedTypeAction,
  setAccountFigureIsClosedWeek as setAccountFiguresIsWeekClosedAction,
  setAccountFiguresItemSubOwnerPercent as setAccountFiguresItemSubOwnerPercentAction,
} from '../../../store';
import {
  accountFiguresForTable,
  updateBotFiguresForTable,
  addWeeklyFigure,
  addWeeklyFigureWallet,
  privateWalletsForTable,
  confirmWeeklyFigureWeek,
  deleteWeeklyFigureWeek,
} from '../../../api/accountFigures';
import { changeFilteredList, getUniqueValuesList } from '../../../utils';
import { sortEvent } from '../../../utils/sortingService';
import { MultiFilterPanel } from '../../../components/MultiFilterPanel';
import { accountTypes } from '../../../components/App';
import { buttonTypes } from '../../../components/UIComponents/buttons/ButtonCustom/constants';
import { excludeKeys, reformatDataKeys } from './constants';

import { getStyles } from '../styles';

const useStyles = makeStyles(getStyles);

const AccountFiguresTablePageContainer = ({
  accountsList,
  selectedType,
  selectedDate,
  isWeekClosed,
  totalWeeklyFigure,
  setAccountFiguresList,
  setAccountFiguresSelectedType,
  setAccountFiguresIsWeekClosed,
  setAccountFiguresItemValue,
  setAccountFiguresItemSharePercent,
  setAccountFiguresItemSubOwnerPercent,
  setAccountFigureTotalWeeklyFigure,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [ handleSortBy, setHandleSortBy ] = useState(false);
  const [ handleSortDirection, setHandleSortDirection ] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [filteredList, setFilteredList] = useState([]);

  const [ uniqueValuesList, setUniqueValuesList ] = useState([]);
  const [ uniqueFiltersList, setUniqueFiltersList] = useState([]);

  const sortingData = getAccountPageSort(selectedType);

  useEffect(() => {
    setUniqueValuesList(getUniqueValuesList(accountsList, getUniqueLines(selectedType)));
  }, [accountsList])

  useEffect(() => {
    setFilteredList([]);
  }, [selectedType]);

  useEffect(() => {
    setAccountFiguresSelectedType(selectedType);
    if (selectedDate) {
      const week = selectedDate.week();
      const year = selectedDate.year();

      if (selectedType !== 'PrivateWallets') {
        const getAccountsFigures = async () => accountFiguresForTable({
          year,
          week,
          type: selectedType,
        });

        getAccountsFigures().then(() => Promise.resolve());
      } else {
        const getPrivateWalletsFigures = async () => privateWalletsForTable({
          year,
          week,
          isPrivate: true,
        });

        getPrivateWalletsFigures().then(() => Promise.resolve());
      }
    }
  }, []);

  useEffect(() => {
    changeFilteredList(accountsList,searchValue, uniqueFiltersList, setFilteredList, ['id', 'week', 'year', 'type']);
  }, [accountsList, searchValue, uniqueFiltersList]);

  const handleChangeInput = ({
    id,
    event,
  }) => {
    setAccountFiguresItemValue({
      id,
      value: event.target.value,
    });
  }

  const handleBlurInput = ({
    id,
    event,
    year,
    week,
  }) => {
    if (!year || !week) {
      week = selectedDate.week();
      year = selectedDate.year();
    }

    if (selectedType !== 'PrivateWallets') {
      const addWeeklyFigureEvent = async () => addWeeklyFigure({
        year,
        week,
        amount: event.target.value,
        accountId: id,
        type: selectedType,
      });

      addWeeklyFigureEvent().then(() => {
        sort({
          sortBy: handleSortBy,
          sortDirection: handleSortDirection,
        })
        return Promise.resolve();
      });
    } else {
      const addWeeklyFigureWalletEvent = async () => addWeeklyFigureWallet({
        year,
        week,
        amount: event?.target?.value,
        walletId: id,
      });

      addWeeklyFigureWalletEvent().then(() => {
        sort({
          sortBy: handleSortBy,
          sortDirection: handleSortDirection,
        })
        return Promise.resolve();
      });
    }
  }

  const handleBlurSharePercentInput = ({
    id,
    event,
    amount,
    year,
    week,
  }) => {
    if (!year || !week) {
      week = selectedDate.week();
      year = selectedDate.year();
    }

    if (selectedType !== 'PrivateWallets') {
      const addWeeklyFigureEvent = async () => addWeeklyFigure({
        year,
        week,
        amount,
        sharePercent: event.target.value,
        accountId: id,
        type: selectedType,
      });

      addWeeklyFigureEvent().then(() => {
        sort({
          sortBy: handleSortBy,
          sortDirection: handleSortDirection,
        })
        return Promise.resolve();
      });
    }
  }

  const handleBlurSubOwnerPercentInput = ({
    id,
    event,
    amount,
    year,
    week,
  }) => {
    if (!year || !week) {
      week = selectedDate.week();
      year = selectedDate.year();
    }

    if (selectedType !== 'PrivateWallets') {
      const addWeeklyFigureEvent = async () => addWeeklyFigure({
        year,
        week,
        amount,
        subOwnerPercent: event.target.value,
        accountId: id,
        type: selectedType,
      });

      addWeeklyFigureEvent().then(() => {
        sort({
          sortBy: handleSortBy,
          sortDirection: handleSortDirection,
        })
        return Promise.resolve();
      });
    }
  }

  const handleChangeSharePercentInput = ({
    id,
    event,
  }) => {
    setAccountFiguresItemSharePercent({
      id,
      value: event.target.value,
    });
  }

  const handleChangeSubOwnerPercentInput = ({
    id,
    event,
  }) => {
    setAccountFiguresItemSubOwnerPercent({
      id,
      value: event.target.value,
    });
  }

  const sort = sortEvent({
    handleSortBy,
    sortData: sortingData,
    setHandleSortDirection,
    setHandleSortBy,
    list: filteredList,
    setNewList: setAccountFiguresList,
  });

  const handleBlurSearchInput = event => {
    const value = event.target.value;
    setSearchValue(value);
    changeFilteredList(accountsList,searchValue, uniqueFiltersList, setFilteredList, ['id', 'week', 'year', 'type']);
  }

  const handleSubmitCurrentWeek = () => {
    const week = selectedDate.week();
    const year = selectedDate.year();

    const getAccountsFigures = async () => accountFiguresForTable({
      year,
      week,
      type: selectedType,
    });

    Promise.resolve(confirmWeeklyFigureWeek({
      week,
      year,
      accountType: selectedType,
    }))
      .then(() => getAccountsFigures());
  }

  const handleUpdateCurrentWeek = () => {
    const week = selectedDate.week();
    const year = selectedDate.year();

    const getAccountsFigures = async () => accountFiguresForTable({
      year,
      week,
      type: selectedType,
    });

    Promise.resolve(deleteWeeklyFigureWeek({
      week,
      year,
      accountType: selectedType,
    }))
      .then(() => setFilteredList([]))
      .then(() => getAccountsFigures());
  }

  const changeUniqueValues = (items, dataKey) => {
    const newUniqueValuesList = uniqueValuesList.map(elem => {
      if (elem.dataKey === dataKey) {
        return {
          ...elem,
          selectedValues: items,
        }
      }
      return elem;
    })
    setUniqueValuesList(newUniqueValuesList);
    setUniqueFiltersList(newUniqueValuesList.filter(el => el.selectedValues.length > 0));
  }

  const updateBotFigures = () => {
    if (selectedDate) {
      const week = selectedDate.week();
      const year = selectedDate.year();

      const updateBotFiguresAction = async () => updateBotFiguresForTable({
        year,
        week,
        type: selectedType,
      });

      updateBotFiguresAction().then(() => Promise.resolve());
    }
  }

  const handleExportToExcel = async () => {
    const keys = getAccountPageColumn(selectedType, isWeekClosed);

    const actualKeys = keys.map(key => ({
      header: key.label,
      key: key.dataKey,
    })).filter(key => !excludeKeys.includes(key.key));

    const actualAccountList = filteredList
      .map((account) => {
        const newAccount = {};
        actualKeys.forEach(key => {
          const reformatData = reformatDataKeys.find(el => el.name === key.key);
          let value = '';
          if (reformatData && typeof account[key.key] === 'object') {
            value = account[key.key]?.[reformatData.value];
          } else {
            value = account[key.key];
          }

          if (value === undefined || value === null || value === 'disabled') {
            value = '';
          }

          if (typeof value === 'number') {
            value = value.toFixed(2);
          }

          newAccount[key.key] = value;
        });
        return newAccount;
      })

    let data = actualAccountList;
    const workSheetName = `${selectedType} AccountFigures-${moment(selectedDate).format('YYYY-MM-DD')}`;
    const workBookName = workSheetName;

    const workbook = new Excel.Workbook();
    const fileName = workBookName;
    const worksheet = workbook.addWorksheet(workSheetName);
    worksheet.columns = actualKeys;
    worksheet.getRow(1).font = {bold: true};
    worksheet.columns.forEach(column => {
      column.width = column.header.length + 5;
      column.alignment = {horizontal: 'center'};
    });
    data.forEach(singleData => {
      worksheet.addRow(singleData);
    });
    worksheet.eachRow({includeEmpty: false}, row => {
      const currentCell = row._cells;
      currentCell.forEach(singleCell => {
        const cellAddress = singleCell._address;
        worksheet.getCell(cellAddress).border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });
    });
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.toolBoxContainer}>
        <Box className={classes.toolBox}>
          <Box className={classes.totalContainer}>
            <box className={classes.totalLabel}>Total Weekly Figure:</box>
            <box>{totalWeeklyFigure}</box>
          </Box>

          {selectedType !== accountTypes.PRIVATE_WALLETS && (
            <Box className={classes.button}>
              <ButtonCustom
                text={isWeekClosed ? 'Update week' : 'Close week'}
                customType={isWeekClosed ? buttonTypes.EDIT : buttonTypes.CREATE}
                onClick={isWeekClosed ? handleUpdateCurrentWeek : handleSubmitCurrentWeek}
              />
            </Box>
          )}
        </Box>

        {selectedType === accountTypes.PAY_PER_HEAD && (
          <Box>
            <ButtonCustom
              text={'Update Bot Figures'}
              style={{
                whiteSpace: 'nowrap',
              }}
              customType={buttonTypes.CANCEL}
              onClick={updateBotFigures}
            />
          </Box>
        )}
        <Box className={classes.exportBtnContain}>
          <ButtonCustom
            onClick={handleExportToExcel}
            customType={buttonTypes.CREATE}
            text='export to excel'
          />
        </Box>

      </Box>

      <Box className={classes.containerForFilters}>
        <MultiFilterPanel
          list={uniqueValuesList}
          onChange={changeUniqueValues}
        />
        <InputCustom
          className={classes.input}
          value={searchValue}
          onChange={handleBlurSearchInput}
        />
      </Box>

      <TableComponent
        columns={getAccountPageColumn(selectedType, isWeekClosed)}
        rows={filteredList}
        eventHandleChangePanelInput={handleChangeInput}
        eventHandleBlurPanelInput={handleBlurInput}
        eventHandleChangeSharePercentInput={[handleChangeSubOwnerPercentInput, handleChangeSharePercentInput]}
        eventHandleBlurSharePercentInput={[handleBlurSubOwnerPercentInput, handleBlurSharePercentInput]}
        sort={sort}
        sortBy={handleSortBy}
        sortDirection={handleSortDirection}
      />
    </Box>
  )
};

const mapStateToProps = state => ({
  selectedDate: getAccountFiguresSelectedDateSelector(state),
  isWeekClosed: getAccountFiguresIsWeekClosedSelector(state),
  totalWeeklyFigure: getAccountFiguresTotalWeeklyFigureSelector(state),
  accountsList: getAccountFiguresListSelector(state),
});

const mapDispatchToProps = {
  setAccountFiguresList: setAccountFiguresListAction,
  setAccountFiguresSelectedType: setAccountFiguresSelectedTypeAction,
  setAccountFiguresIsWeekClosed: setAccountFiguresIsWeekClosedAction,
  setAccountFiguresItemValue: setAccountFiguresItemValueAction,
  setAccountFiguresItemSharePercent: setAccountFiguresItemSharePercentAction,
  setAccountFiguresItemSubOwnerPercent: setAccountFiguresItemSubOwnerPercentAction,
};

export const AccountFiguresTablePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountFiguresTablePageContainer);
