import { createSelector } from 'reselect';

const getAccountFiguresSelector = state => state.accountFigures;

export const getAccountFiguresListSelector = createSelector(
  getAccountFiguresSelector,
  accounts => accounts.accountsList
);

export const getAccountFiguresSelectedDateSelector = createSelector(
  getAccountFiguresSelector,
  accounts => accounts.selectedDate
);

export const getAccountFiguresSelectedTypeSelector = createSelector(
  getAccountFiguresSelector,
  accounts => accounts.selectedType
);

export const getAccountFiguresIsWeekClosedSelector = createSelector(
  getAccountFiguresSelector,
  accounts => accounts.isWeekClosed
);

export const getAccountFiguresTotalWeeklyFigureSelector = createSelector(
  getAccountFiguresSelector,
  accounts => accounts.totalWeeklyFigure
);
