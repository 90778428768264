export const getStyles = theme => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  pickerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > .MuiButtonBase-root': {
      marginLeft: 25,
    }
  },
});
