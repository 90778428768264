import { gql } from '@apollo/client';

export const GET_BALANCES_REPORTS_FOR_TABLE = gql`
  query GetBalancesReports(
    $week: Float!,
    $year: Float!
  ) {
    balancesReports(week: $week, year: $year) {
      previousCarry
      cashBalance
      onlineBalances
      appBalances
      agentBalances
      agentBalancesShare
      partnerBalances
      btcHarout
      btcDrew
      bankrollValue
    }
  }
`;
