import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { InputCustom } from '../../inputs';
import { ButtonCustom } from '../../buttons';
import { buttonTypes } from '../../buttons/ButtonCustom/constants';
import { SelectMultipleCustom, SelectCustom } from '../../selects';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const ModalAccount = ({
  open,
  eventApply,
  eventClose,
  title,
  partnersList,
  siteTypesList,
  type,
  subType,
  shortAccountName,
  url,
  username,
  password,
  sharePercent,
  notes,
  siteType,
  partner,
  subOwner,
  subOwnerPercent,
  setAccountType,
  setAccountSubType,
  setAccountShortAccountName,
  setAccountUrl,
  setAccountUsername,
  setAccountPassword,
  setSharePercent,
  setAccountNotes,
  setAccountSiteType,
  setAccountPartner,
  setAccountSubOwner,
  setAccountSubOwnerPercent,
  disabledList = [],
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [typeError, setTypeError] = useState(false);
  const [subTypeError, setSubTypeError] = useState(false);
  const [shortAccountNameError, setShortAccountNameError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [sharePercentError, setSharePercentError] = useState(false);
  const [subOwnerError, setSubOwnerError] = useState(false);
  const [subOwnerPercentError, setSubOwnerPercentError] = useState(false);
  const [notesError, setNotesError] = useState(false);
  const [siteTypeError, setSiteTypeError] = useState(false);
  const [partnerError, setPartnerError] = useState(false);

  const typesList = [
    {
      id: 'Deposit',
      name: 'Deposit',
    },
    {
      id: 'Application',
      name: 'Application',
    },
    {
      id: 'PayPerHead',
      name: 'PayPerHead',
    },
    {
      id: 'HandBets',
      name: 'HandBets',
    },
  ];

  const subTypesList = [
    {
      id: 'Share',
      name: 'Share',
    },
    {
      id: 'FreeRoll',
      name: 'FreeRoll',
    },
  ];

  const handleChangeSiteType = elem => {
    setAccountSiteType(siteTypesList.find(el => el.id === elem.id));
  }

  const handleChangeSubType = elem => {
    setAccountSubType(subTypesList.find(el => el.id === elem.id));
  }

  const handleChangePartner = elem => {
    const currentPartner = partnersList.find(el => el.id === elem.id);
    setAccountPartner(currentPartner);
    setSharePercent(currentPartner?.sharePercent);
  }

  const handleChangeSubOwner = elem => {
    const currentPartner = partnersList.find(el => el.id === elem?.id);
    setAccountSubOwner(currentPartner ?? null);
    setAccountSubOwnerPercent(currentPartner?.sharePercent ? String(currentPartner?.sharePercent) : '');
    if (currentPartner?.sharePercent) {
      setSubOwnerPercentError(false);
    }
  }

  const validate = () => {
    let valid = true;

    if (!Boolean(type)) {
      valid = false;
      setTypeError(true);
    }
    if (!Boolean(partner)) {
      valid = false;
      setPartnerError(true);
    }
    if (!Boolean(sharePercent)) {
      valid = false;
      setSharePercentError(true);
    }

    if (type === 'Deposit') {
      valid = validateDeposit();
    }

    if (type === 'Application') {
      valid = validateApplication();
    }

    if (type === 'PayPerHead') {
      valid = validatePayPerHead();
    }

    if (type !== 'HandBets') {
      if (!Boolean(username)) {
        valid = false;
        setUsernameError(true);
      }
      if (!Boolean(password)) {
        valid = false;
        setPasswordError(true);
      }
    }
    if (type === 'PayPerHead' || type === 'HandBets') {
      if (Boolean(subOwner) && !Boolean(subOwnerPercent) || !Boolean(subOwner) && Boolean(subOwnerPercent)) {
        valid = false;
        if (!Boolean(subOwnerPercent)) {
          setSubOwnerPercentError(true);
        }
        if (!Boolean(subOwner)) {
          setSubOwnerError(true);
        }
      }
    }

    return valid;
  }

  const validateDeposit = () => {
    let valid = true;
    if (!Boolean(url)) {
      valid = false;
      setUrlError(true);
    }
    return valid;
  }

  const validateApplication = () => {
    let valid = true;
    if (!Boolean(shortAccountName)) {
      valid = false;
      setShortAccountNameError(true);
    }
    return valid;
  }

  const validatePayPerHead = () => {
    let valid = true;
    if (!Boolean(url)) {
      valid = false;
      setUrlError(true);
    }
    if (!Boolean(siteType)) {
      valid = false;
      setSiteTypeError(true);
    }
    if (!Boolean(subType)) {
      valid = false;
      setSubTypeError(true);
    }
    return valid;
  }

  const sendApplyData = () => {
    if (validate()) {
      eventApply();
    }
  }

  return (
    <Box>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-create-title'
        className={classes.modalContent}
      >
        <Box>
          <IconButton
            aria-label="close"
            className={classes.buttonClose}
            onClick={eventClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            id='alert-dialog-create-title'
            className={classes.title}
          >
            {title ? title : 'Create'}
          </DialogTitle>
          <DialogContent>

            <Box className={classes.rowWrapper}>
              <Box className={classes.colWrapper_50}>
                <SelectCustom
                  label="Type"
                  placeholder='Type'
                  error={typeError}
                  value={type}
                  onChange={e => {
                    setAccountType(e.target.value);
                    setTypeError(false);
                  }}
                  disableClearable
                  itemList={typesList}
                  optionTitle='name'
                  disabled={disabledList.find(el => el === 'Type')}
                />
              </Box>

              <Box className={classes.colWrapper_50}>
                {type === 'Application' && (
                  <InputCustom
                    label={'App Name'}
                    error={shortAccountNameError}
                    value={shortAccountName}
                    onChange={e => {
                      setAccountShortAccountName(e.target.value);
                      setShortAccountNameError(false);
                    }}
                    disabled={disabledList.find(el => el === 'AppName')}
                  />
                )}

                {(type === 'Deposit' || type === 'PayPerHead') && (
                  <InputCustom
                    label={'Url'}
                    error={urlError}
                    value={url}
                    onChange={e => {
                      setAccountUrl(e.target.value);
                      setUrlError(false);
                    }}
                    disabled={disabledList.find(el => el === 'Url')}
                  />
                )}

                {type === 'HandBets' && (
                  <SelectMultipleCustom
                    label='Sub Type'
                    placeholder='Sub type'
                    onChange={(e, elem) => {
                      handleChangeSubType(elem);
                      setSubTypeError(false);
                    }}
                    value={subType}
                    error={subTypeError}
                    disableClearable
                    list={subTypesList}
                    optionTitle='name'
                    disabled={disabledList.find(el => el === 'SubType')}
                  />
                )}

              </Box>
            </Box>

            <Box className={classes.rowWrapper}>
              <Box className={classes.colWrapper_50}>
                <SelectMultipleCustom
                  label='Partner'
                  placeholder='Partner'
                  error={partnerError}
                  value={partner}
                  onChange={(e, elem) => {
                    handleChangePartner(elem);
                    setPartnerError(false);
                  }}
                  disableClearable
                  list={partnersList}
                  optionTitle='name'
                  disabled={disabledList.find(el => el === 'Partner')}
                />
              </Box>

              <Box className={classes.colWrapper_50}>
                <InputCustom
                  label={subType?.id === 'FreeRoll' ? 'Redline %' : 'Share Partner %'}
                  error={sharePercentError}
                  value={sharePercent}
                  onChange={e => {
                    setSharePercent(e.target.value);
                    setSharePercentError(false);
                  }}
                  disabled={disabledList.find(el => el === 'SharePercent')}
                />
              </Box>
            </Box>

            {(type === 'PayPerHead' || type === 'HandBets') && (
              <Box className={classes.rowWrapper}>
                <Box className={classes.colWrapper_50}>
                  <SelectMultipleCustom
                    label='Sub Owner'
                    placeholder='Sub Owner'
                    error={subOwnerError}
                    value={subOwner}
                    onChange={(e, elem) => {
                      handleChangeSubOwner(elem);
                      setSubOwnerError(false);
                    }}
                    list={partnersList}
                    optionTitle='name'
                    disabled={disabledList.find(el => el === 'SubOwner')}
                  />
                </Box>

                <Box className={classes.colWrapper_50}>
                  <InputCustom
                    label='Sub Owner %'
                    error={subOwnerPercentError}
                    value={subOwnerPercent}
                    onChange={e => {
                      setAccountSubOwnerPercent(e.target.value);
                      setSubOwnerPercentError(false);
                    }}
                  />
                </Box>
              </Box>
            )}

            {
              type === 'PayPerHead' &&
              <Box className={classes.rowWrapper}>
                <Box className={classes.colWrapper_50}>
                  <SelectMultipleCustom
                    label='Sub Type'
                    placeholder='Sub type'
                    onChange={(e, elem) => {
                      handleChangeSubType(elem);
                      setSubTypeError(false);
                    }}
                    value={subType}
                    error={subTypeError}
                    disableClearable
                    list={subTypesList}
                    optionTitle='name'
                    disabled={disabledList.find(el => el === 'SubType')}
                  />
                </Box>
                <Box className={classes.colWrapper_50}>
                  <SelectMultipleCustom
                    label='Site Type'
                    placeholder='Site Type'
                    onChange={(e, elem) => {
                      handleChangeSiteType(elem);
                      setSiteTypeError(false);
                    }}
                    value={siteType}
                    error={siteTypeError}
                    disableClearable
                    list={siteTypesList}
                    optionTitle='name'
                    disabled={disabledList.find(el => el === 'SiteType')}
                  />
                </Box>
              </Box>
            }

            {
              type !== 'HandBets' &&
              <Box className={classes.rowWrapper}>
                <Box className={classes.colWrapper_50}>
                  <InputCustom
                    label="Username"
                    error={usernameError}
                    value={username}
                    onChange={e => {
                      setAccountUsername(e.target.value);
                      setUsernameError(false);
                    }}
                    disabled={disabledList.find(el => el === 'Username')}
                  />
                </Box>
                <Box className={classes.colWrapper_50}>
                  <InputCustom
                    label="Password"
                    error={passwordError}
                    value={password}
                    onChange={e => {
                      setAccountPassword(e.target.value);
                      setPasswordError(false);
                    }}
                    disabled={disabledList.find(el => el === 'Password')}
                  />
                </Box>
              </Box>
            }

            <Box
              component='div'
              className={classes.rowWrapper}
            >
              <Box
                component='div'
                className={classes.colWrapper_100}
              >
                <InputCustom
                  label="Notes"
                  error={notesError}
                  value={notes}
                  onChange={e => {
                    setAccountNotes(e.target.value);
                    setNotesError(false);
                  }}

                  disabled={disabledList.find(el => el === 'Notes')}
                />
              </Box>
            </Box>

          </DialogContent>
          <DialogActions
            className={classes.formButtons}
          >
            <ButtonCustom
              onClick={eventClose}
              customType={buttonTypes.CANCEL}
            />

            <ButtonCustom
              autoFocus
              onClick={sendApplyData}
              customType={buttonTypes.CREATE}
              text='Apply'
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
