import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

export const getStyles = theme => ({
	feedback: {
		color: yellow[600],
		cursor: 'pointer',
		'&:hover': {
			color: yellow[500],
		},
	},
	accept: {
		color: green[600],
		cursor: 'pointer',
		'&:hover': {
			color: green[500],
		},
	},
});
