import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

export const getStyles = theme => ({
  container: {
    marginBottom: 10,
    color: theme.mainStyles.mainColorText,
    width: 200,
    borderRadius: 3,
    padding: '12px 20px',
    backgroundColor: 'gray',
    fontSize: 12,
    opacity: 0.8,
    '&:last-child': {
      marginBottom: 0,
    }
  },
  errorType: {
    backgroundColor: red[700],
  },
  successType: {
    backgroundColor: green[700],
  },
});