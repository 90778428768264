import React from 'react';

import Box from '@material-ui/core/Box';

export const getStatusItem = ({
	classes,
	status,
}) => {
	switch(status) {
		case 'Feedback':
			return (
				<Box className={classes.feedback}>With feedback</Box>
			)
		case 'Accepted':
			return (
				<Box className={classes.accept}>Accepted</Box>
			)
		default:
			return (
				<Box></Box>
			)
	}
}