export const filterObjArray = (arr, searchSubStr, blackList) => {
  return arr?.filter(obj => isValidObj(obj, searchSubStr, blackList));
}

const isValidObj = (obj, searchSubStr, blackList) => {
  if (!obj) {
    return false;
  }

  const objArr = Object.entries(obj);
  return objArr?.find(entry => {
    if (blackList?.find(item => item === entry[0])) {
      return false;
    }

    const value = entry[1];
    if (typeof value === 'string') {
      return value?.toLowerCase().indexOf(searchSubStr.toLowerCase()) !== -1;
    }

    if (typeof value === 'number') {
      return String(value)?.indexOf(searchSubStr) !== -1; 
    }

    if (typeof value === 'boolean') {
      return searchSubStr === 'true' || searchSubStr === 'false';
    }
    
    if (typeof value === 'object') {
      return isValidObj(value, searchSubStr, blackList);
    }

    return false;
  });
}

// export const filterObjArray = (arr, searchString, blackList) => {
//   return arr.filter(element => {
//       return Object.values(element).find(value => {
//           return String(value).indexOf(searchString) !== -1
//       })
//   })
// }
