export const walletsPageColumn = [
  {
    width: 600,
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    width: 600,
    label: 'Type',
    dataKey: 'typeName',
  },
  {
    width: 400,
    label: 'IsPrivate',
    dataKey: 'isPrivate',
    isBooleanValueHandler: {
      isTrue: 'Private',
      isFalse: '',
    },
  },
  {
    width: 800,
    label: 'Notes',
    dataKey: 'notes',
  },
  {
    width: 300,
    label: 'Action',
    dataKey: 'action',
    isContainer: true,
  },
];

export const walletsPageSort = [
  {
    sortType: 'string',
    dataKey: 'partnerName',
  },
  {
    sortType: 'string',
    dataKey: 'typeName',
  },
  {
    sortType: 'string',
    dataKey: 'isPrivate',
  },
  {
    sortType: 'string',
    dataKey: 'notes',
  },
];

export const uniqueLines = [
  {
    label: 'Partner',
    dataKey: 'partnerName',
  },
  {
    label: 'Type',
    dataKey: 'typeName',
  },
]

