export const getStyles = theme => ({
  modalContent: {
    '& .MuiDialog-paper': {
      maxWidth: 700,
      minWidth: 300,
      backgroundColor: theme.mainStyles.mainBackgroundColor,
      color: theme.mainStyles.mainColorText,
    },
  },
  title: {
    padding: '20px 24px 15px',
    '& .MuiTypography-root': {
      fontSize: 18,
      color: theme.mainStyles.mainColorText,
      paddingRight: '50px',
    }
  },
  text: {
    color: theme.mainStyles.mainColorText,
    fontSize: 14,
  },
  textTop: {
    color: '#1a9a07',
    marginBottom: 15,
  },
  buttonClose: {
    position: 'absolute',
    right: '6px',
    top: '10px',
    color: theme.mainStyles.mainColorText,
  },
})
