import { LOCAL_STORAGE_KEY } from './constants';

export const getLocalStorageData = key => {
  const storageData = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!storageData) {
    return null;
  }
  if (key) {
    return JSON.parse(storageData)[key];
  }
  return JSON.parse(storageData);
}

export const setLocalStorageData = (key, data) => {
  const storageData = getLocalStorageData();
  if (!storageData) {
    return window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ [key]: data }));
  }
  storageData[key] = data;
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storageData));
}
