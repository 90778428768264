import React from 'react';

import Button from '@material-ui/core/Button';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';

import {
  getIcon,
} from './utils';
import {
  buttonParams,
  buttonTypes,
} from './constants';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const ButtonCustom = ({
  text,
  size,
  onClick,
  variant,
  iconCustom,
  disabled,
  icon,
  customType,
  autoFocus,
  style,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      style={style ? style : null}
      variant={variant ? variant : 'contained'}
      size={size ? size : 'small'}
      startIcon={getIcon(icon, iconCustom, customType)}
      className={classes[buttonParams[customType ? customType : buttonTypes.BASIC].style]}
      onClick={onClick ? () => onClick() : null}
      autoFocus={autoFocus}
      disabled={disabled}
    >
      {text ? text : buttonParams[customType ? customType : buttonTypes.BASIC].text}
    </Button>
  )
}
