import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from '@material-ui/core/styles/useTheme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ButtonCustom } from '../../buttons';
import { buttonTypes } from '../../buttons/ButtonCustom/constants';
import { SelectMultipleCustom } from '../../selects/SelectMultipleCustom';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const ModalPartnerTelegramCode = ({
  open,
  eventApply,
  eventClose,
  title,
  partnersList,
  partner,
  setPartner,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [partnerError, setPartnerError] = useState(false);

  const handleChangePartner = elem => {
    setPartner(partnersList?.find(el => el?.id === elem?.id));
  }

  const validate = () => {
    let valid = true;
    if (!Boolean(partner)) {
      valid = false;
      setPartnerError(true);
    }
    
    return valid;
  }

  const sendApplyData = () => {
    if (validate()) {
      eventApply();
    }
  }

  return (
    <Box
      component='div'
    >
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-create-title'
        className={classes.modalContent}
      >
        <Box
          component='div'
        >
          <IconButton
            aria-label="close"
            className={classes.buttonClose}
            onClick={eventClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            id='alert-dialog-create-title'
            className={classes.title}
          >
            {title ? title : 'Create'}
          </DialogTitle>
          <DialogContent>

            <Box
              component='div'
              className={classes.rowWrapper}
            >
              <Box
                component='div'
                className={classes.colWrapper_50}
              >
                <SelectMultipleCustom
                  label='Partner'
                  placeholder='Partner'
                  onChange={(e, elem) => {
                    handleChangePartner(elem);
                    setPartnerError(false);
                  }}
                  value={partner}
                  error={partnerError}
                  disableClearable
                  list={partnersList ?? []}
                  optionTitle='name'
                />
              </Box>
            </Box>

          </DialogContent>
          <DialogActions
            className={classes.formButtons}
          >
            <ButtonCustom
              onClick={eventClose}
              customType={buttonTypes.CANCEL}
            />

            <ButtonCustom
              autoFocus
              onClick={sendApplyData}
              customType={buttonTypes.CREATE}
              text='Generate'
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
