export const getStyles = theme => ({
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
  },
  title: {
    width: '100%',
    margin: '0 auto .5rem 0'
  },
  panel: {
    minHeight: '25rem',
  }
})
