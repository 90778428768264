import { createSelector } from 'reselect';

// Default Selectors
const getBalancesReportsSelector = state => state.balancesReports;

export const getBalancesReportsObjectSelector = createSelector(
  getBalancesReportsSelector,
  balancesReports => balancesReports.reports
);

export const getBalancesReportsSelectedDateSector = createSelector(
  getBalancesReportsSelector,
  balancesReports => balancesReports.selectedDate
);
