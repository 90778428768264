import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import { WeekPicker } from '../UIComponents/WeekPicker';

import { getStyles } from './styles';

const useStyles = makeStyles(getStyles);

export const WeekSwitcher = ({
  title,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box
      className={classes.container}
    >
      <Box
        className={classes.title}
      >
        { title ? title : 'Date:' }
      </Box>
      <WeekPicker
        {...props}
      />
    </Box>
  )
}
