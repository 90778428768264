import { store } from '../../store';
import { setBalancesReports } from '../../store';
import { balancesReportsReformat } from './reformatters';

import { getLocalStorageData } from '../../utils';
import { GET_BALANCES_REPORTS_FOR_TABLE } from './constants';
import { apolloClientQueryBuilder } from '../../utils/apolloClientBuilder';
import { requestWrapper } from '../common/request-wrapper';

export const getBalancesReports = async ({
  week,
  year,
}) => {
  await requestWrapper('GetBalancesReports', async () => {
    const token = getLocalStorageData('token');
    const apolloClient = new apolloClientQueryBuilder();
    const { loading, error, data } = await apolloClient
      .query(GET_BALANCES_REPORTS_FOR_TABLE)
      .setBearerToken(token)
      .setVariables({
        week,
        year
      })
      .build();

    if (!loading && !error && data) {
      store.dispatch(setBalancesReports(balancesReportsReformat(data.balancesReports)));
    } else {

    }
  });
}
