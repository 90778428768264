import { createSelector } from 'reselect';

// Default selectors
const getPartnersSelector = state => state.partners;
const getCreatePartnerSelector = state => state.createPartner;
const getEditPartnerSelector = state => state.editPartner;

export const getPartnersListSelector = createSelector(
  getPartnersSelector,
  partners => partners.partnersList
);

// Create selectors
export const getCreatePartnerNameSelector = createSelector(
  getCreatePartnerSelector,
  createPartner => createPartner.partnerName
);

export const getCreatePartnerTypeSelector = createSelector(
  getCreatePartnerSelector,
  createPartner => createPartner.partnerType
);

export const getCreatePartnerTermsSelector = createSelector(
  getCreatePartnerSelector,
  createPartner => createPartner.terms
);

export const getCreatePartnerLocationSelector = createSelector(
  getCreatePartnerSelector,
  createPartner => createPartner.location
);

export const getCreatePartnerSharePercentSelector = createSelector(
  getCreatePartnerSelector,
  createPartner => createPartner.sharePercent
);

// Edit selectors
export const getEditPartnerIdSelector = createSelector(
  getEditPartnerSelector,
  editPartner => editPartner.id
);

export const getEditPartnerNameSelector = createSelector(
  getEditPartnerSelector,
  editPartner => editPartner.partnerName
);

export const getEditPartnerTypeSelector = createSelector(
  getEditPartnerSelector,
  editPartner => editPartner.partnerType
);

export const getEditPartnerTermsSelector = createSelector(
  getEditPartnerSelector,
  editPartner => editPartner.terms
);

export const getEditPartnerLocationSelector = createSelector(
  getEditPartnerSelector,
  editPartner => editPartner.location
);

export const getEditPartnerSharePercentSelector = createSelector(
  getEditPartnerSelector,
  editPartner => editPartner.sharePercent
);
