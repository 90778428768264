import {
  RESET_CREATE_TELEGRAM_CODE_PARTNER,
  SET_CREATE_TELEGRAM_CODE_PARTNER,
  SET_CREATE_TELEGRAM_CODE_PARTNERS_LIST,
} from '../constants';

const initialState = {
  partner: null,
  partnersList: null,
}

export const createTelegramCode = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CREATE_TELEGRAM_CODE_PARTNER:
      return initialState;
    case SET_CREATE_TELEGRAM_CODE_PARTNER:
    case SET_CREATE_TELEGRAM_CODE_PARTNERS_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
