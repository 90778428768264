import {
  SET_SETTINGS_THEME,
  SET_SETTINGS_IS_FETCHING,
  SET_SETTINGS_IS_AUTHENTICATED,
  SET_SETTINGS_IS_ADMIN,
  SET_SETTINGS_SHOW_LOADER,
} from '../constants';
import { DARK_THEME } from '../../config/themes';

const initialState = {
  themeName: DARK_THEME,
  showLoader: false,
  isFetching: true,
  isAuthenticated: false,
  isAdmin: true,
}

export const settings = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS_THEME:
    case SET_SETTINGS_IS_FETCHING:
    case SET_SETTINGS_IS_AUTHENTICATED:
    case SET_SETTINGS_IS_ADMIN:
    case SET_SETTINGS_SHOW_LOADER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
